import { Component, OnInit, Input } from "@angular/core"

@Component({
  // tslint:disable-next-line:component-selector
  selector: "image",
  templateUrl: "./image.component.html",
  styleUrls: ["./image.component.scss"],
})
export class ImageComponent implements OnInit {
  _src = ""
  @Input() set src(url: string) {
    if (url === undefined || url.length === 0) return

    this._src = url
    this.isImageBroken = false
  }

  _altText = ""
  @Input() set altText(text: string) {
    if (text === undefined) return

    this._altText = text
  }
  @Input() width = 32

  _height = 32
  @Input() set height(h: number) {
    if (h <= 0) return

    this._height = h
    this.fontSize = h * 0.34
  }
  @Input() borderStyle = "none"
  fontSize = 14

  isImageBroken = true

  constructor() {}

  ngOnInit() {}

  setBrokenImage() {
    this.isImageBroken = true
  }
}
