import { Directive, Output, EventEmitter, HostBinding, HostListener } from "@angular/core"

@Directive({
  selector: "[mediaLibraryDragDrop]",
})
export class MediaLibraryDragImageDirective {
  @Output() onFileDropped = new EventEmitter<any>()
  @Output() imageHovered = new EventEmitter<any>()

  @HostBinding("style.background-color") private background = "#FFF"

  // Dragover listener
  @HostListener("dragover", ["$event"]) onDragOver(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    this.background = "#F0F6FF"
    this.imageHovered.emit(true)
  }

  // Dragleave listener
  @HostListener("dragleave", ["$event"]) public onDragLeave(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    this.background = "#FFF"
    this.imageHovered.emit(false)
  }

  // Drop listener
  @HostListener("drop", ["$event"]) public ondrop(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    this.background = "#FFF"
    this.imageHovered.emit(false)
    var fileList: FileList
    let list = new DataTransfer()
    const items = evt.dataTransfer?.items
    if (items) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i].webkitGetAsEntry()
        if (item && item.isDirectory) {
          this.readDirectory(item, item.name + "/")
        } else if (item && item.isFile) {
          const file = items[i].getAsFile()
          if (file) {
            list.items.add(file)
          }
        }
      }
      fileList = list.files
    }
    if (fileList.length > 0) {
      this.onFileDropped.emit({ Files: fileList, paths: null })
    }
  }
  readDirectory(entry: any, path: string) {
    var fileList: FileList
    let list = new DataTransfer()
    let relativePathMap: Map<string, string> = new Map<string, string>()
    const dirReader = entry.createReader()
    dirReader.readEntries((entries: any) => {
      const pendingEntries = entries.length
      let processedEntries = 0
      for (let i = 0; i < entries.length; i++) {
        if (entries[i].isDirectory) {
          this.readDirectory(entries[i], `${path}${entry.name}/`)
        } else {
          entries[i].file((file: File) => {
            var filePath = `${path}${file.name}`
            relativePathMap.set(file.name, filePath)
            list.items.add(file)
            processedEntries++
            if (processedEntries === pendingEntries) {
              fileList = list.files
              if (fileList.length > 0) {
                this.onFileDropped.emit({ Files: fileList, paths: relativePathMap })
              }
            }
          })
        }
      }
    })
  }
}
