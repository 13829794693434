<div style="position: relative; width: fit-content" (click)="insideClick = true">
  <div class="pill" (click)="openDropDown()">
    <p [ngClass]="filtersShown || filtersActive !== 0 ? 'p-semibold' : 'p-medium'">{{ label }}</p>
    <div class="numberOfFiltersActive" *ngIf="filtersActive !== 0">
      <p class="p-medium">{{ filtersActive }}</p>
    </div>
    <img style="cursor: pointer" height="16" width="16" [src]="filtersShown ? iconService.Icon.up_1_5 : iconService.Icon.down_1_5" />
  </div>
  <div class="drop-down" *ngIf="filtersShown">
    <div class="filter">
      <div *ngIf="style === 'singleOutput'" style="width: 100%">
        <div class="outputList" *ngFor="let item of list" (click)="selectSingle(item)">
          <div style="display: inline-flex">
            <img *ngIf="item.img" width="20" height="20" style="margin-right: 10px" [src]="item.img" [style]="item.imgFilter ? item.imgFilter : ''" />
            <p [ngClass]="item.value ? 'p-medium' : ''">{{ item.label }}</p>
          </div>
          <img *ngIf="item.value" width="20" height="20" [src]="iconService.Icon.check" style="filter: invert(67%) sepia(63%) saturate(357%) hue-rotate(110deg) brightness(85%) contrast(85%)" />
        </div>
      </div>
      <div *ngIf="style === 'multipleOutput'" style="width: 100%">
        <div class="outputList" *ngFor="let item of list" (click)="selectMultiple(item)">
          <div style="display: inline-flex; gap: 12px; align-items: center">
            <app-ag-checkbox-v2 size="small" [initValue]="item.value" [checked]="item.value ? 'checked' : 'unchecked'" (valueChange)="item.value = $event"></app-ag-checkbox-v2>
            <p [ngClass]="item.value ? 'p-medium' : ''">{{ item.label }}</p>
          </div>
        </div>
      </div>
      <div *ngIf="style === 'spandOutput'" style="width: 100%">
        <div class="spandOutput">
          <input class="spandInput" type="number" oninput="this.value = Math.max(this.value)" [ngClass]="isMinFocused ? 'focused' : ''" [value]="spandMin" (focus)="onFocus('min')" (blur)="onBlur('min')" placeholder="Min." (input)="spandInputMin($event)" />
          <p>-</p>
          <input class="spandInput" type="number" oninput="this.value = Math.max(this.value)" [ngClass]="isMaxFocused ? 'focused' : ''" [value]="spandMax" (focus)="onFocus('max')" (blur)="onBlur('max')" placeholder="Max." (input)="spandInputMax($event)" />
        </div>
      </div>
    </div>
    <div class="button-area">
      <ag-button-v2 label="Clear" (click)="clearInput()" type="secondary" size="small" [disabled]="!clearActive" specificWidth="105px"></ag-button-v2>
      <ag-button-v2 label="Apply" (click)="applyInput()" type="primary" size="small" [disabled]="!applyActive" specificWidth="105px"></ag-button-v2>
    </div>
  </div>
</div>
