<div class="info-box" *ngIf="show === true">
  <div class="content" style="margin: 0">
    <div class="info-container">
      <H2 class="SemiBold" style="margin-bottom: 16px">{{ headerText }}</H2>
      <div class="Regular">{{ bodyText }}</div>
      <div style="position: inherit; margin-top: auto">
        <ag-button-v2 label="{{ buttonText }}" type="primary" size="large" (click)="infoBoxClicked()"></ag-button-v2>
      </div>
    </div>
    <div style="display: flex; width: auto; height: 200px; padding-left: 32px; align-items: center; overflow: hidden; justify-content: flex-end">
      <img class="image" [ngStyle]="{ height: imageHeight, width: imageWidth, 'object-fit': objectFit }" src="{{ imageSource }}" />
    </div>
  </div>
</div>
