import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output } from "@angular/core"
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog"
import { CommunicationApiService } from "@api/communication/communication-api.service"
import { CSharpTypeMapper, DynamicVariableList, DynamicVariableResponse, InputValue, Properties } from "@api/communication/models/communication"
import { Function, FunctionInfo } from "@api/communication/models/functions"
import { Page } from "@api/communication/models/hotel-directory"
import { LabelValue } from "@shared/animations/round-drop-down-list-v3"
import { AgChecked } from "@shared/components/ag-checkbox-v2/ag-checkbox-v2.component"
import { Employee } from "@shared/models/employee"
import { IconImageService } from "@shared/services/icon-image.service"
import { StateService } from "@shared/services/state.service"
import { ToastServiceV2 } from "@shared/services/toast-v2/toast-v2.service"
import { TemplateService } from "app/communication/messages/template.service"
import { v4 as uuidv4 } from "uuid"

@Component({
  selector: "app-dynamic-variable-dialog",
  templateUrl: "./dynamic-variable-dialog.component.html",
  styleUrls: ["./dynamic-variable-dialog.component.scss"],
})
export class DynamicVariableDialogComponent implements OnInit {
  public searchText: string
  public dynamicVariableList: DynamicVariableResponse[]
  public categoryList: LabelValue[] = []
  public DynamicDataList: DynamicVariableResponse
  public propertiesList: Properties[] = []
  public filteredPropertiesList: Properties[] = []
  public selectedDynamicVariable: Properties
  public selectedVariable: boolean = false
  public selectedOption: string = "Hide"
  public defaultValue: string = ""
  public listOfPages: Page[] = []
  public subPageOptions: LabelValue[] = []
  public selectedSubPage: LabelValue
  public hotelId: string
  hideDialog: boolean = true

  @Input() communicationMode: boolean = true
  @Input() templateId: string = ""
  @Input() existingFunctions: DynamicVariableList[]
  @Output() toggleDialog = new EventEmitter<boolean>()
  @Output() savedFunctionInfo = new EventEmitter<FunctionInfo>()

  @HostListener("document:click", ["$event"])
  clickedOutside(event) {
    if (!this.eRef.nativeElement.contains(event.target) && !this.hideDialog) {
      this.hideDialog = true
      this.toggleDialog.emit(true)
    } else {
      this.hideDialog = false
    }
  }

  constructor(public toastService: ToastServiceV2, public communicationApiService: CommunicationApiService, public templateService: TemplateService, public stateService: StateService, private eRef: ElementRef, public iconService: IconImageService) {}

  ngOnInit(): void {
    this.hotelId = this.stateService.selectedHotel.Id
    var template = this.communicationMode ? this.templateService.getTemplate() : null
    var user: Employee = JSON.parse(localStorage.getItem("user"))
    this.communicationApiService.Communication.GetDynamicVariableList(this.stateService.selectedHotel.Id).subscribe((list) => {
      this.dynamicVariableList = list
      this.DynamicDataList = this.dynamicVariableList.find((x) => x.isRoot === true)
      this.findLowerLayer(this.dynamicVariableList)
      this.propertiesList.push({ context: "HotelDirectory", displayName: "Hotel Directory link", imageUrl: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/hotel_directory_link.svg", name: "HotelDirectoryLink", type: "String" })
      this.propertiesList.sort((a, b) => a.displayName.localeCompare(b.displayName))
      this.filteredPropertiesList = this.propertiesList
      this.filteredPropertiesList = this.filteredPropertiesList.filter((x) => x.name !== "RatePlanCode" && x.name !== "Comments")
      if (template?.templateType === "EMAIL" && this.communicationMode) {
        this.filteredPropertiesList = this.filteredPropertiesList.filter((x) => x.name !== "CheckinLinkSMS" && x.name !== "CheckinLink" && x.name !== "Check in Link")
      } else if (this.communicationMode) {
        this.filteredPropertiesList = this.filteredPropertiesList.filter((x) => x.name !== "CheckinLinkEmail" && x.name !== "CheckinLink" && x.name !== "Check in Link")
      }
      var topLevelNodes: string[] = []
      list.forEach((node) => {
        topLevelNodes.push(node.typeName)
      })
      this.filteredPropertiesList = this.filteredPropertiesList.filter((x) => !topLevelNodes.includes(x.type))
    })
    this.communicationApiService.HotelDirectory.getAllDirectory(user?.OrganizationId, this.hotelId).subscribe((x) => {
      var listOfDirectorys = x
      this.communicationApiService.HotelDirectory.getAllContent(this.hotelId, listOfDirectorys[0].id).subscribe((pages) => {
        this.listOfPages = pages
        this.listOfPages.forEach((page) => {
          this.subPageOptions.push({ label: page.name, value: page.pageId })
        })
        this.selectedSubPage = this.subPageOptions[0]
      })
    })
    this.hideDialog = true
  }
  private findLowerLayer(dynamicVariableList: DynamicVariableResponse[]) {
    dynamicVariableList.forEach((dv) => {
      if (dv.isRoot === true) {
        dv.properties.forEach((variable) => {
          if (dv.typeName !== variable.name) {
            variable.context = "None"
            this.propertiesList.push(variable)
          }
        })
      }

      if (dv.properties.length > 0 && !dv.isRoot && this.DynamicDataList.properties.find((x) => x.type === dv.typeName)) {
        dv.properties.forEach((variable) => {
          const dvPrefix = this.DynamicDataList.properties.find((x) => x.type === dv.typeName).name
          variable.context = dvPrefix
          if (this.dynamicVariableList.find((x) => x.typeName === variable.type)) {
            var list = this.dynamicVariableList.find((x) => x.typeName === variable.type)
            var subdvName = dv.properties.find((x) => x.type === list.typeName).name
            list.properties.forEach((listvar) => {
              listvar.context = dvPrefix + ":" + subdvName
            })
            this.propertiesList = [...this.propertiesList, ...list.properties]
          } else {
            this.propertiesList.push(variable)
          }
        })
      }
    })
  }
  public closeDialog(event: Event): void {
    event.stopPropagation()
    this.hideDialog = true
    this.toggleDialog.emit(true)
  }

  public cancelDialog(): void {
    this.hideDialog = true
    this.toggleDialog.emit(true)
  }

  public insertDynamicVariable(): void {
    if (this.selectedDynamicVariable.name === "HotelDirectoryLink") {
      this.buildHotelDirectoryLink()
      return
    }
    var chosenString: object = null
    var chosenFallback: object = null

    const dvPrefix = this.selectedDynamicVariable.context
    const dvName = dvPrefix === "None" ? this.selectedDynamicVariable.name : dvPrefix + ":" + this.selectedDynamicVariable.name

    if (this.selectedDynamicVariable.type === "IList<String>") {
      var list = {
        BodyDataType: null,
        BodyType: "dv",
        BodyData: dvName,
        ChildBodies: [],
      }
      var listToString = {
        BodyData: "listOfTextToText",
        BodyDataType: null,
        BodyType: "f",
        ChildBodies: [
          list,
          {
            BodyDataType: "String",
            BodyType: "sv",
            BodyData: "<br>",
            ChildBodies: [],
          },
        ],
      }
      var fallBack = {
        BodyData: "variableWithStringFallback",
        BodyDataType: null,
        BodyType: "f",
        ChildBodies: [
          datetimeToString,
          {
            BodyDataType: "String",
            BodyType: "sv",
            BodyData: this.defaultValue,
            ChildBodies: [],
          },
        ],
      }
      chosenString = listToString
      chosenFallback = fallBack
    } else if (this.selectedDynamicVariable.name === "StartDate" || this.selectedDynamicVariable.name === "EndDate") {
      var date = {
        BodyDataType: null,
        BodyType: "dv",
        BodyData: dvName,
        ChildBodies: [],
      }
      var datetimeToString = {
        BodyData: "dateTimeToString",
        BodyDataType: null,
        BodyType: "f",
        ChildBodies: [
          date,
          {
            BodyDataType: "String",
            BodyType: "sv",
            BodyData: "MMM. dd, yyyy",
            ChildBodies: [],
          },
        ],
      }
      var fallBackWrapper = {
        BodyData: "variableWithStringFallback",
        BodyDataType: null,
        BodyType: "f",
        ChildBodies: [
          datetimeToString,
          {
            BodyDataType: "String",
            BodyType: "sv",
            BodyData: this.defaultValue,
            ChildBodies: [],
          },
        ],
      }
      chosenString = datetimeToString
      chosenFallback = fallBackWrapper
    } else {
      var dynamicVariable = {
        BodyDataType: null,
        BodyType: "dv",
        BodyData: dvName,
        ChildBodies: [],
      }

      var bodyFallback = {
        BodyData: "variableWithStringFallback",
        BodyDataType: null,
        BodyType: "f",
        ChildBodies: [
          dynamicVariable,
          {
            BodyDataType: "String",
            BodyType: "sv",
            BodyData: this.defaultValue,
            ChildBodies: [],
          },
        ],
      }
      chosenString = dynamicVariable
      chosenFallback = bodyFallback
    }

    var func: Function = {
      hotelId: this.stateService.selectedHotel.Id,
      rootId: this.templateId,
      id: uuidv4(),
      description: "",
      name: this.selectedDynamicVariable.name,
      _body: this.selectedOption === "Hide" ? JSON.stringify(chosenString) : JSON.stringify(chosenFallback),
    }
    this.saveFunction(func)
  }
  public buildHotelDirectoryLink(): void {
    var pageAlias = this.listOfPages.find((x) => x.pageId === this.selectedSubPage.value)?.alias ?? ""
    pageAlias.replace("_", "/")
    var chosenString = {
      BodyData: "hotelDirectoryLink",
      BodyDataType: null,
      BodyType: "f",
      ChildBodies: [
        {
          BodyDataType: "String",
          BodyType: "sv",
          BodyData: pageAlias,
          ChildBodies: [],
        },
        {
          BodyDataType: null,
          BodyType: "dv",
          BodyData: "Reservation:PersonalTag",
          ChildBodies: [],
        },
      ],
    }

    var func: Function = {
      hotelId: this.stateService.selectedHotel.Id,
      rootId: this.templateId,
      id: uuidv4(),
      description: "",
      name: this.selectedDynamicVariable.name,
      _body: JSON.stringify(chosenString),
    }
    this.saveFunction(func)
  }

  private saveFunction(func: Function): void {
    if (this.existingFunctions) {
      var nameToCheck = `[${this.selectedDynamicVariable.displayName}]`
      if (this.existingFunctions.findIndex((x) => x.placeholder === nameToCheck) !== -1) {
        this.savedFunctionInfo.emit({ functionId: func.id, functionName: this.selectedDynamicVariable.displayName, imageUrl: this.selectedDynamicVariable.imageUrl })
        this.hideDialog = true
        this.toggleDialog.emit(true)
      } else {
        this.communicationApiService.Function.SaveFunction(func).subscribe(
          (x) => {
            this.savedFunctionInfo.emit({ functionId: func.id, functionName: this.selectedDynamicVariable.displayName, imageUrl: this.selectedDynamicVariable.imageUrl })
            this.hideDialog = true
            this.toggleDialog.emit(true)
          },
          (error) => {
            console.log(error)
          }
        )
      }
    } else {
      this.communicationApiService.Function.SaveFunction(func).subscribe(
        (x) => {
          this.savedFunctionInfo.emit({ functionId: func.id, functionName: this.selectedDynamicVariable.displayName, imageUrl: this.selectedDynamicVariable.imageUrl })
          this.hideDialog = true
          this.toggleDialog.emit(true)
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  changedSubPage(subPage: LabelValue) {
    this.selectedSubPage = subPage
  }

  public onSearchChange(searchString: any): void {
    this.filteredPropertiesList = this.filteredPropertiesList.filter((x) => x.displayName.toLocaleLowerCase().includes(searchString.value.toLocaleLowerCase()))
    if (!searchString.value) {
      this.filteredPropertiesList = this.propertiesList
    }
  }

  public changeDefaultValue(event: any): void {
    this.defaultValue = event.value
  }
  public selectVariable(property: Properties): void {
    this.selectedVariable = true
    this.selectedDynamicVariable = property
  }

  public insertClickable(): boolean {
    if (this.selectedOption) {
      if (this.selectedOption === "Fallback" && !this.defaultValue) return false
      return true
    }
    return false
  }
}
