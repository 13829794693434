<div class="bounding-box">
  <div class="left-box">
    <!--    <mat-form-field style="width: 75px" [floatLabel]="'auto'">
      <input matInput type="text" placeholder="left" [(ngModel)]="leftValue">
      <button mat-button *ngIf="leftValue" matSuffix mat-icon-button aria-label="Clear" (click)="leftValue=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>-->

    <mat-form-field>
      <input matInput placeholder="left" />
    </mat-form-field>
  </div>

  <div class="spacer">
    <img src="assets/shared/vertical-line.svg" />
  </div>

  <div class="right-box">
    <mat-form-field>
      <input matInput placeholder="right" />
    </mat-form-field>
  </div>
</div>
