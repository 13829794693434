import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-mobile-template",
  templateUrl: "./mobile-template.component.html",
  styleUrls: ["./mobile-template.component.scss"],
})
export class MobileTemplateComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
