import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"

@Component({
  selector: "app-fixed-bottom-bar",
  templateUrl: "./fixed-bottom-bar.component.html",
  styleUrls: ["./fixed-bottom-bar.component.scss"],
})
export class FixedBottomBarComponent implements OnInit {
  @Input()
  headerText?: string

  @Input()
  bodyText?: string

  @Input()
  buttonLeftText?: string

  @Input()
  buttonRightText?: string

  @Input()
  buttonRightactive?: boolean = true

  @Output()
  buttonRightEvent = new EventEmitter<boolean>()

  @Output()
  buttonLeftEvent = new EventEmitter<boolean>()

  constructor() {}

  ngOnInit(): void {}

  buttonRightClicked() {
    this.buttonRightEvent.emit(true)
  }

  buttonLeftClicked() {
    this.buttonLeftEvent.emit(true)
  }
}
