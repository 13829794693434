import { NavigatorgraphTypeStrategy } from "./navigatorgraph-type-strategy"

export class AreaNavigatorgraphTypeStrategy implements NavigatorgraphTypeStrategy {
  constructNavigatorGraph(fc: any, xScale: any, yScale: any) {
    return fc
      .seriesWebglArea()
      .xScale(xScale)
      .yScale(yScale)
      .crossValue((d) => d.data.Date)
      .mainValue((d) => d[1])
  }
}
