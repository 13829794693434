import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, RouterStateSnapshot, Route, Router } from "@angular/router"
import { Observable } from "rxjs"
import { AuthenticationService } from "./authentication.service"

import { STORAGE_TOKEN, STORAGE_USER, STORAGE_PIN_SESSION } from "app/app-settings"
import { Employee } from "app/shared/models/employee"

@Injectable()
export class LockedGuard  {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    const { user, token } = this.extractData()
    if (!user || !user.Id || !(user.Id.length > -1) || !token) {
      this.authService.logout()
      this.router.navigate(["/login"])
      return false
    }

    return true
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const { user, token } = this.extractData()
    if (!user || !user.Id || !(user.Id.length > -1) || !token) {
      this.authService.logout()
      this.router.navigate(["/login"])
      return false
    }

    return true
  }

  extractData() {
    const user = <Employee>JSON.parse(localStorage.getItem(STORAGE_USER))
    const token = localStorage.getItem(STORAGE_TOKEN)
    return { user, token }
  }
}
