<div class="ag-tree-node" [ngClass]="{ open: open }">
  <div (click)="toggle()" class="flex-row flex-space-between flex-align-center row-container">
    <div style="flex-grow: 1" class="flex-row flex-space-between">
      <div>
        <span *ngIf="agDate" class="agDate">
          {{ agDate }}
        </span>
        <span class="agTitle">
          <b>{{ agTitle }}</b>
        </span>
      </div>
      <div>
        <span *ngIf="agItems" class="agItems">{{ agItems }} items</span>
        <span *ngIf="agTotalPrice" class="agTotalPrice">{{ agTotalPrice }}</span>
        <span *ngIf="agDescription" class="agDescription">{{ agDescription }}</span>
      </div>
    </div>
    <div class="flex-row flex-space-between flex-align-center" style="display: flex; gap: 24px">
      <ng-content select="[inline]"></ng-content>
    </div>
    <div class="flex-center flex-align-center">
      <div *ngIf="collapsible" class="flex-row flex-center flex-align-center node-toggle">
        <img width="20" height="20" [src]="open ? iconService.Icon.up_1_5 : iconService.Icon.down_1_5" />
      </div>
    </div>
  </div>
  <div class="ag-tree-content-background" *ngIf="!collapsible || open">
    <div [ngClass]="noPadding ? '' : 'ag-tree-content'" [@inOutAnimation]>
      <ng-content></ng-content>
    </div>
  </div>
</div>
