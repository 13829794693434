
<div
  *ngIf="size === 'Big'"
  [ngClass]="isInputFocused || (isInputHovered && !disable) ? 'search-container-focused' : 'search-container'"
  (mouseenter)="onEnter()"
  (mouseleave)="onLeave()"
  [ngStyle]="{ border: !isInputFocused && !isInputHovered && !disable ? styles.border : '', 'background-color': !isInputFocused && !isInputHovered && styles.backgroundColor && !disable ? styles.backgroundColor : '', width: styles.specificWidth ? styles.specificWidth : '' }">
  <div class="ag-value" #agValue>
    <label class="label" *ngIf="label" for="input-search" [class.above]="isInputFocused || labelClicked || searchInput?.length > 0" [ngStyle]="{ color: styles.labelColor }" (click)="clickLabel()">{{ label }}</label>
    <input
      class="input"
      #input
      name="input-search"
      [placeholder]="showPlaceholder()"
      [formControl]="control"
      [value]="initValue"
      [disabled]="disable"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (input)="onInput($event)"
      [ngStyle]="{
        height: isInputFocused ? '100%' : '26px',
        transform: (isInputFocused && label) || (label && searchInput?.length > 0) ? 'translateY(9px)' : '',
        'background-color': !isInputFocused && !isInputHovered && styles.backgroundColor && !disable ? styles.backgroundColor : ''
      }"
      [ngClass]="isInputFocused || (isInputHovered && !disable) ? 'input-focused' : 'input'" />
  </div>
  <img class="search-img" *ngIf="searchInput?.length === 0" [src]="iconService.Icon.magnifier" />
  <img class="cross-img" *ngIf="searchInput?.length > 0" [src]="iconService.Icon.cross" (click)="clearSearch()" />
</div>
<div
  *ngIf="size === 'small'"
  [ngClass]="isInputFocused || (isInputHovered && !disable) ? 'small-search-container-focused' : 'small-search-container'"
  [ngStyle]="{ border: !isInputFocused && !isInputHovered && !disable ? styles.border : '', 'background-color': !isInputFocused && !isInputHovered && styles.backgroundColor && !disable ? styles.backgroundColor : '', width: styles.specificWidth ? styles.specificWidth : '' }"
  (mouseenter)="onEnter()"
  (mouseleave)="onLeave()">
  <input
    class="input"
    #input
    name="input-search"
    [placeholder]="placeholder"
    [formControl]="control"
    [value]="initValue"
    [disabled]="disable"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (input)="onInput($event)"
    [ngStyle]="{
      height: isInputFocused ? '100%' : '26px',
      'background-color': !isInputFocused && !isInputHovered && styles.backgroundColor && !disable ? styles.backgroundColor : ''
    }"
    [ngClass]="isInputFocused || (isInputHovered && !disable) ? 'input-focused' : 'input'" />
  <img class="search-img" *ngIf="searchInput?.length === 0" [src]="iconService.Icon.magnifier" />
  <img class="cross-img" *ngIf="searchInput?.length > 0" [src]="iconService.Icon.cross" (click)="clearSearch()" />
</div>
