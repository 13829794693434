<div style="height: 514px; width: 400px">
  <div class="dynamic-variable-dialog-container">
    <div class="flex-row" style="padding: 24px; justify-content: space-between">
      <h4 *ngIf="!selectedVariable" class="h4-medium">Insert a tag</h4>
      <h4 *ngIf="selectedVariable" class="h4-medium">{{ selectedDynamicVariable.displayName }}</h4>
      <ng-container *ngIf="selectedVariable">
        <div (click)="selectedVariable = false" class="flex-row" style="align-items: center">
          <img height="16px" width="16px" [src]="iconService.Icon.left_1" />
          <button class="back-button">Back</button>
        </div>
      </ng-container>
      <ng-container *ngIf="!selectedVariable">
        <div (click)="closeDialog($event)" class="flex-row" style="align-items: center">
          <button class="back-button">Close</button>
          <img height="16px" width="16px" [src]="iconService.Icon.cross" />
        </div>
      </ng-container>
    </div>
    <hr style="margin: 0px" />
    <div *ngIf="!selectedVariable" class="input-wrapper">
      <ng-container>
        <img style="opacity: 0.3" height="24px" width="24px" [src]="iconService.Icon.magnifier" />
        <input (input)="onSearchChange($event.target)" class="input-field" placeholder="Search for personalized tag" type="text" [value]="''" />
      </ng-container>
    </div>
    <hr style="margin: -0px" />
  </div>
  <div class="data-container" [ngClass]="!selectedVariable ? 'data-container' : 'select-container'">
    <ng-container *ngIf="!selectedVariable">
      <div *ngFor="let data of filteredPropertiesList">
        <div class="flex-row entryfields" (click)="selectVariable(data)">
          <div class="body-regular">{{ data.displayName }}</div>
          <img style="width: 16px; height: 16px" [src]="iconService.Icon.right_1" />
        </div>
        <hr style="margin: 16px" />
      </div>
    </ng-container>
    <ng-container *ngIf="selectedVariable && selectedDynamicVariable.name !== 'HotelDirectoryLink'">
      <div class="flex-column" style="gap: 24px">
        <div class="flex-row" style="gap: 12px">
          <input class="black-radio" type="radio" name="options" [(ngModel)]="selectedOption" value="Hide" />
          <!-- <app-ag-checkbox-v2 size="small" (valueChange)="toggleHideMissingData($event, 'Hide')" [initValue]="hideIfMissing"></app-ag-checkbox-v2> -->
          <div class="flex-column">
            <div class="body-medium">Hide when data is missing</div>
            <div class="body-regular">Don’t show any text if the information about [tag’s name here] is missing from a recipient.</div>
          </div>
        </div>
        <div class="line">
          <div class="or-box">or</div>
        </div>
        <div class="flex-row" style="gap: 12px">
          <input class="black-radio" type="radio" name="options" [(ngModel)]="selectedOption" value="Fallback" />
          <!-- <app-ag-checkbox-v2 size="small" (valueChange)="toggleHideMissingData($event, 'Fallback')" [initValue]="useFallBack"></app-ag-checkbox-v2> -->
          <div class="flex-column" style="gap: 8px">
            <div class="body-medium">Show fallback text</div>
            <div class="body-regular">Set an alternative text that will show in the email when the information about [tag’s name here] is missing from a recipient.</div>
            <div class="grey-input-wrapper">
              <input placeholder="Type here" (input)="changeDefaultValue($event.target)" type="text" [value]="defaultValue" class="grey-input-field" />
            </div>
            <div class="flex-row" style="gap: 4px; align-items: center">
              <img height="12px" width="12px" [src]="iconService.Icon.i_letter_1" />
              <div class="label-regular">Fallback text can contain one or multiple words</div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedVariable && selectedDynamicVariable.name === 'HotelDirectoryLink'">
      <div style="margin-bottom: 12px" class="body-medium">Select the page to link to</div>
      <ag-drop-down (changedValue)="changedSubPage($event)" [showSearchField]="false" [list]="subPageOptions" [currentValue]="selectedSubPage"></ag-drop-down>
    </ng-container>
    <ng-container *ngIf="selectedVariable">
      <div class="flex-column" style="margin-top: auto">
        <hr style="margin: 16px -24px" />
        <div class="flex-row" style="justify-content: space-between; margin-bottom: 16px">
          <ag-button-v2 (click)="cancelDialog()" type="tertiary" label="Cancel"></ag-button-v2>
          <ag-button-v2 [specificWidth]="'120px'" (click)="insertDynamicVariable()" [disabled]="insertClickable() ? false : true" [type]="insertClickable() ? 'primary' : 'secondary'" label="Insert"></ag-button-v2>
        </div>
      </div>
    </ng-container>
  </div>
</div>
