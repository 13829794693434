import { Component, Input, OnInit } from "@angular/core"

@Component({
  selector: "app-payment-method-icon",
  templateUrl: "./payment-method-icon.component.html",
  styleUrls: ["./payment-method-icon.component.scss"],
})
export class PaymentMethodIconComponent implements OnInit {
  public imgSrc: string

  private _method: string
  public get method(): string {
    return this._method
  }

  @Input()
  public set method(value: string) {
    if (value != null || value != undefined) {
      const lowerCaseValue = value.toLocaleLowerCase()
      this._method = lowerCaseValue
      this.imgSrc = this.map.get(lowerCaseValue)
    } else {
    }
  }

  public map: Map<string, string> = new Map<string, string>([
    ["american_express", "assets/payment-methods/AMEX.svg"],
    ["apple_pay", "assets/payment-methods/Apple pay.svg"],
    ["dankort", "assets/payment-methods/Dankort.svg"],
    ["diners_club", "assets/payment-methods/Diners.svg"],
    ["discover", "assets/payment-methods/Discover.svg"],
    ["jcb", "assets/payment-methods/JCB.svg"],
    ["maestro", "assets/payment-methods/Maestro.svg"],
    ["master", "assets/payment-methods/MasterCard.svg"],
    ["visa", "assets/payment-methods/Visa.svg"],
  ])

  constructor() {}

  ngOnInit(): void {}
}
