import { Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, Self, SimpleChanges } from "@angular/core"
import { UntypedFormControl, NgControl } from "@angular/forms"
import { IconImageService } from "@shared/services/icon-image.service"

@Component({
  selector: "app-ag-checkbox-v2",
  templateUrl: "./ag-checkbox-v2.component.html",
  styleUrls: ["./ag-checkbox-v2.component.scss"],
})
export class AgCheckboxV2Component implements OnInit, OnChanges {
  public isChecked: boolean
  public control: UntypedFormControl

  @Input() public size: AgSize = "default"
  @Input() public label: string
  @Input() public subtitle: string
  @Input() public initValue: boolean
  @Input() public blackVersion: boolean
  @Input() public checked: AgChecked
  @Input() public disabled: boolean
  @Input() public maxWidth?: number
  @Input() public focus: boolean = false
  @Input() public type: AgCheckboxType = "checkbox"

  @Output() valueChange = new EventEmitter<boolean>()

  constructor(@Optional() @Self() private _ngControl: NgControl, public iconService: IconImageService) {
    if (this._ngControl) {
      this._ngControl.valueAccessor = this
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled?.firstChange === false) {
      changes.disabled?.currentValue ? this.control.disable() : this.control.enable()
    }
    if (changes.checked?.firstChange === false) {
      this.checked = changes.checked.currentValue
      this.checked === "checked" ? this.control.patchValue(true) : this.control.patchValue(false)
    }
  }
  ngOnInit(): void {
    if (this._ngControl?.control) {
      this.control = this._ngControl.control as UntypedFormControl
    } else {
      this.control = new UntypedFormControl(this.initValue)
    }

    if (this.checked !== "intdeterminate") {
      this.control.patchValue(this.initValue)
    }

    this.disabled ? this.control.disable() : this.control.enable()

    this.control.valueChanges.subscribe((x) => {
      if (this.checked !== "intdeterminate") {
        this.valueChange.emit(x)
        return
      }
      if (x && this.checked === "intdeterminate") {
        this.valueChange.emit(x)
      }
    })
  }

  labelClick() {
    if (!this.control.disabled) this.control.value ? this.control.patchValue(false) : this.control.patchValue(true)
  }

  writeValue() {}
  registerOnChange() {}
  registerOnTouched() {}
}

export type AgChecked = "unchecked" | "checked" | "intdeterminate" | ""
export type AgSize = "large" | "default" | "small"
export type AgCheckboxType = "checkbox" | "round-checkbox" | "radio-button"
