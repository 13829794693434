import { Injectable } from "@angular/core"
import { STORAGE_USER } from "app/app-settings"
import { BehaviorSubject, Observable } from "rxjs"
import { Employee, V2EmployeeHotelResponse } from "../models/employee"

const STORAGE_SELECTED_HOTEL = "selectedHotel"

@Injectable()
export class StateService {
  private _selectedHotelSource = new BehaviorSubject<V2EmployeeHotelResponse>({})
  public selectedHotelObservable = this._selectedHotelSource.asObservable()

  public get selectedHotel(): V2EmployeeHotelResponse {
    if (localStorage.getItem(STORAGE_SELECTED_HOTEL) === "undefined") {
      this.deleteSelectedHotel()
    }
    const _selectedHotel = <V2EmployeeHotelResponse>JSON.parse(localStorage.getItem(STORAGE_SELECTED_HOTEL))
    if (_selectedHotel && this._getAllHotelIds().includes(_selectedHotel?.Id)) {
      return _selectedHotel
    }
    const firstHotel = this._selectFirstHotel()
    if (firstHotel) {
      return firstHotel
    }
    return null
  }

  public set selectedHotel(value: V2EmployeeHotelResponse) {
    if (!value) {
      this.deleteSelectedHotel()
    } else {
      localStorage.setItem(STORAGE_SELECTED_HOTEL, JSON.stringify(value))
      this._selectedHotelSource.next(value)
    }
  }

  private _getAllHotelIds = (): string[] => (<Employee>JSON.parse(localStorage.getItem(STORAGE_USER)))?.Hotels?.map((x) => x.Id) ?? []
  private _selectFirstHotel(): V2EmployeeHotelResponse {
    const _firstHotel = (<Employee>JSON.parse(localStorage.getItem(STORAGE_USER)))?.Hotels[0]
    this.selectedHotel = _firstHotel
    return _firstHotel
  }

  public deleteSelectedHotel(): void {
    localStorage.removeItem(STORAGE_SELECTED_HOTEL)
    this._selectedHotelSource.next({})
  }
}
