<div style="position: relative">
  <div [ngStyle]="{ width: forceWidth ? forceWidth : '', height: forceHeight }" [ngClass]="isDateRange ? 'wide' : 'thin'">
    <div [ngClass]="datepickerShown ? 'date-picker-white' : 'date-picker'" [ngStyle]="{ height: forceHeight, background: getBackgroundColor(), 'box-shadow': checkBoxShadow() }" *ngIf="useOpenButton" (click)="openDatePicker($event)">
      <p class="text" *ngIf="!!labelInput">{{ this.labelInput }}</p>
      <p class="text" *ngIf="type !== 'month' && !isDateRange && changedDate" [ngClass]="getLabel()">{{ this.selectedStartDate | date : "dd MMM YYYY" }}</p>
      <p class="text" *ngIf="!changedDate && placeholder" [ngClass]="getLabel()">{{ placeholder }}</p>
      <p class="text" *ngIf="type !== 'month' && !placeholder && startDate && !isDateRange" [ngClass]="getLabel()">{{ this.selectedStartDate | date : "dd MMM YYYY" }}</p>
      <p class="text" *ngIf="type !== 'month' && isDateRange && changedDate" [ngClass]="getLabel()">
        {{ this.selectedStartDate | date : "dd MMM YYYY" }} -
        {{ this.selectedEndDate | date : "dd MMM YYYY" }}
      </p>
      <p class="text" *ngIf="type === 'month' && changedDate && !isDateRange" [ngClass]="getLabel()">
        {{ this.selectedStartMonth | date : "MMM YYYY" }}
      </p>
      <p class="text" *ngIf="type === 'month' && changedDate && isDateRange" [ngClass]="getLabel()">
        {{ this.selectedStartMonth | date : "MMM YYYY" }} -
        {{ this.selectedEndMonth | date : "dd MMM YYYY" }}
      </p>
      <img [ngStyle]="{ filter: checkImageFilter() }" style="height: 20px; width: 20px" [src]="iconService.Icon.calendar" />
    </div>
  </div>
  <div *ngIf="info.showInfo" style="display: flex; gap: 8px; align-items: center; margin-top: 8px">
    <img width="12" height="12" [src]="info.type === 'error' ? iconService.Icon.cross_1_5 : iconService.Icon.exclamation_mark_1_5" [ngClass]="info.type === 'error' ? 'red-img' : 'black-img'" />
    <p class="info-text" [ngStyle]="{ color: info.type === 'error' ? '#E55E5E' : '#222222' }">{{ info.text }}</p>
  </div>
  <div class="date-picker-box" [ngClass]="isDateRange ? 'wide' : 'thin'" [ngStyle]="{ width: forceWidth ? forceWidth : '' }">
    <ng-container *ngIf="type === 'regular'">
      <app-date-regular-box
        [startDate]="selectedStartDate"
        [endDate]="selectedEndDate"
        [placement]="placement"
        [isDateRange]="isDateRange"
        [disableDates]="disableDates"
        [applyButton]="applyButton"
        [maxDateRangeSelectable]="maxDateRangeSelectable"
        [datepickerShown]="datepickerShown"
        (showDatepickerOutput)="closeDatePicker($event)"
        (dateRangeSelected)="onDateOutput($event)"></app-date-regular-box>
    </ng-container>
    <ng-container *ngIf="type === 'double'">
      <app-date-double-box
        [startDate]="selectedStartDate"
        [endDate]="selectedEndDate"
        [placement]="placement"
        [isDateRange]="isDateRange"
        [disableDates]="disableDates"
        [applyButton]="applyButton"
        [maxDateRangeSelectable]="maxDateRangeSelectable"
        [datepickerShown]="datepickerShown"
        (showDatepickerOutput)="closeDatePicker($event)"
        (dateRangeSelected)="onDateOutput($event)"></app-date-double-box>
    </ng-container>
    <ng-container *ngIf="type === 'month'">
      <app-date-month-box
        [datepickerShown]="datepickerShown"
        [startMonth]="selectedStartMonth"
        [endMonth]="selectedEndMonth"
        [applyButton]="applyButton"
        [placement]="placement"
        [isDateRange]="isDateRange"
        [disableDates]="disableDates"
        (showDatepickerOutput)="closeDatePicker($event)"
        (monthSelected)="onMonthOutput($event)"></app-date-month-box>
    </ng-container>
  </div>
</div>
