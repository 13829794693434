import { ElementRef, Component, Input, HostListener, Output, EventEmitter, OnInit, ViewChild, Renderer2 } from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"

@Component({
  selector: "app-drop-down-list",
  template: `
    <div class="additional-charges-div">
      <label class="additional-charges-form" [ngClass]="!disable ? 'additional-charges-clickable' : ''" [attr.disabled]="disable ? '' : null" (click)="onFieldClicked()">{{ currentValue }}</label>
      <img src="https://aeroguestblobstoragedev.blob.core.windows.net/icons/Dropdownlist_arrow.svg" class="code-changeable-img disable-selection" [ngClass]="!disable ? 'additional-charges-clickable' : ''" (click)="onFieldClicked()" #arrowImage />
      <div class="search-box-wrapper">
        <app-search-box [currentValue]="currentValue" [showSearchField]="showSearchField" [maxHeight]="maxHeight" [showBox]="!hideSearchBox" [showHeaders]="false" [alphabeticalList]="list" [useFixedPosition]="useFixedPosition" (output)="onOutput($event)"></app-search-box>
      </div>
    </div>
  `,
  styles: [
    `
                       /* Chrome, Safari, Edge, Opera */
                       input::-webkit-outer-spin-button,
                       input::-webkit-inner-spin-button {
                         -webkit-appearance: none;
                         margin: 0;
                       }
                       /* Firefox */
                       input[type="number"] {
                         -moz-appearance: textfield;
                       }
                       .disable-selection {
                         -webkit-touch-callout: none;
                         -webkit-user-select: none;
                         -khtml-user-select: none;
                         -moz-user-select: none;
                         -ms-user-select: none;
                         user-select: none;
                       }
                       .search-box-wrapper {
                         margin-top: -8px;
                         position: absolute;
                         width: 100%;
                       }
                       .additional-charges-form {
                         background-color: #ffffff;
                         width: 100%;
                         height: 36px;
                         border-radius: 8px;
                         padding-inline-start: 8px;
                         padding-top: 8px;
                         font-family: Lato;
                         font-size: 13px;
                         font-weight: normal;
                         font-stretch: normal;
                         font-style: normal;
                         line-height: normal;
                         letter-spacing: normal;
                         color: #333333;
                         border: solid 1px #c8c7cc;
                         overflow: hidden;
                         text-overflow: ellipsis;
                         white-space: nowrap;
                         padding-right: 24px;
                       }
                       .additional-charges-clickable {
                         cursor: pointer;
                       }
                       .additional-charges-div {
                         position: relative;
                         width: 100%;
                       }
                       .additional-charges-img {
                         position: absolute;
                         left: 52px;
                         top: 8px;
                       }
                       .code-changeable-img {
                         position: absolute;
                         right: 16px;
                         top: 16px;
                       }
                       .spacer {
                         padding-bottom: 4px;
                       }
                     `,
  ],
  animations: [],
})
export class DropDownListComponent implements OnInit {
  @Input() disable = false
  @Input() list: string[] = []
  @Input() currentValue = "Select an option from the menu"
  @Input() useFixedPosition = false
  @Input() showSearchField = true
  @Input() maxHeight: number = 259

  @Output() changedValue = new EventEmitter<string>()

  @ViewChild("arrowImage") arrowImage

  public currentCurrency = 0

  public currencyForm: UntypedFormGroup
  public hideSearchBox = true

  @HostListener("document:click", ["$event"])
  clickedOutside(event) {
    if (!this.eRef.nativeElement.contains(event.target) && !this.hideSearchBox) {
      this.hideSearchBox = true
      this.arrowImage.nativeElement.style.transform = `rotate(${this.hideSearchBox ? 0 : 180}deg)`
    }
  }

  constructor(
    private eRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {}

  onOutput(value: string) {
    this.currentValue = value
    this.changedValue.emit(value)
  }

  onFieldClicked() {
    if (!this.disable) {
      this.hideSearchBox = !this.hideSearchBox
      this.arrowImage.nativeElement.style.transform = `rotate(${this.hideSearchBox ? 0 : 180}deg)`
    }
  }
}
