import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { AgTreeV2NodeComponent } from "./ag-tree-v2-node/ag-tree-v2-node.component"
import { AgTreeV2LeafComponent } from "./ag-tree-v2-leaf/ag-tree-v2-leaf.component"
import { AgTreeV2Component } from "./ag-tree-v2.component"
@NgModule({
  declarations: [AgTreeV2NodeComponent, AgTreeV2LeafComponent, AgTreeV2Component],
  imports: [CommonModule],
  exports: [AgTreeV2LeafComponent, AgTreeV2NodeComponent, AgTreeV2Component],
})
export class AgTreeV2Module {}
