import { Component, Input, Output, EventEmitter, ViewChildren, QueryList, HostListener, OnInit, ElementRef } from "@angular/core"
import { ChangedIndex } from "../models/changed-index"

@Component({
  selector: "app-deletable-item-list",
  template: `
    <div class="parent-list">
      <div *ngFor="let item of list; let i = index" style="max-width: 100%">
        <div ng-hide="!item.toString()" class="child">
          <div [ngClass]="hiddenIndex.includes(i) ? 'button-grey' : 'button'">
            <label class="edit-span" [attr.contenteditable]="canEdit" #textField (keydown.enter)="onKeyDown($event)" (keyup.enter)="blur($event)" spellcheck="false" (focus)="onFocus(textField)" (focusout)="onFocusOut(textField.textContent, i, textField)">{{ item }}</label>
            <div [ngClass]="disableDelete ? 'avoid-clicks' : ''"><img [hidden]="displayHide" class="disable-selection delete-button" (click)="onDelete(item, i)" src="/assets/shared/delete-list-item.svg" /></div>
            <mat-icon [hidden]="!displayHide" class="visibilityToggle white-icon disable-selection" style="cursor:pointer" matSuffix (click)="onHideIndex(i)">{{ hiddenIndex.includes(i) ? "visibility_off" : "visibility" }}</mat-icon>
          </div>
        </div>
      </div>

      <div [ngClass]="disableAdd ? 'disableDiv' : ''" [hidden]="hideAdd || useDropDownAdd" class="plus-button" (click)="onAdd()"><img class="disable-selection" src="/assets/shared/plus.svg" /></div>
      <div style="display: block" *ngIf="!hideAdd && useDropDownAdd">
        <div [ngClass]="[!showSnap ? 'tab-closed' : 'tab-opened-end', disableAdd ? 'disableDiv' : '']" (click)="showSnapOnClick()">
          <img [hidden]="showSnap" class="disable-selection plus-img-padding" src="/assets/shared/plus.svg" />
          <img [hidden]="!showSnap" class="disable-selection cross-img-padding black-icon" src="/assets/property-setup/search-results/cross.svg" />
        </div>
        <div class="box-content-end" [class.opened]="showSnap" *ngIf="showSnap">
          <div class="box-curve"></div>
          <div style="width: 250px; position: sticky;">
            <div class="form-div" *ngIf="displayDropDownSearch">
              <input class="form-text" #inputField placeholder="Search" (input)="onInputChange(inputField.value)" />
              <img src="assets/shared/looking-glass.svg" class="form-img" />
              <div class="line"></div>
            </div>
            <div class="spacer"></div>
            <div class="items-container">
              <div *ngFor="let item of displayedDropDownList">
                <div class="item" (click)="onItemClicked(item)">
                  <label class="item-text">
                    {{ item }}
                  </label>
                </div>
              </div>
            </div>
            <div class="spacer"></div>
            <div class="spacer"></div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
                       .avoid-clicks {
                         pointer-events: none;
                         display: none;
                       }
                       .disableDiv {
                         pointer-events: none;
                       }
                       .plus-img-padding {
                         padding: 3px 0px;
                         margin-inline-start: -2px;
                         margin-inline-end: -2px;
                       }
                       .cross-img-padding {
                         width: 12px;
                         height: 12px;
                         fill: #cccccc;
                         margin-top: 5px;
                         z-index: 50;
                       }
                       .white-icon {
                         fill: white;
                       }
                       .visibilityToggle {
                         width: 14px;
                         height: 14px;
                         font-size: 10px;
                         position: absolute;
                         right: 12px;
                         bottom: 10px;
                         padding: 6px;
                         cursor: pointer;
                       }
                       .parent-list {
                         display: flex;
                         flex-direction: row;
                         flex-wrap: wrap;
                         max-width: 100%;
                       }
                       .child {
                         margin-right: 8px;
                         max-width: 100%;
                       }
                       .button {
                         max-width: 100%;
                         background-color: #333333;
                         height: 28px;
                         border-radius: 14px;
                         margin-bottom: 3px;
                         padding: 5px 32px 8px 10px;
                         position: relative;
                         display: -webkit-box;
                       }
                       .button-grey {
                         max-width: 100%;
                         background-color: #c8c7cc;
                         height: 28px;
                         border-radius: 14px;
                         margin-bottom: 3px;
                         padding: 5px 32px 8px 10px;
                         position: relative;
                         display: -webkit-box;
                       }
                       .delete-button {
                         position: absolute;
                         right: 4px;
                         padding: 6px;
                         cursor: pointer;
                       }
                       .plus-button {
                         width: 28px;
                         height: 28px;
                         border-radius: 19px;
                         background-color: #578a6b;
                         margin-top: 0px;
                         display: flex;
                         justify-content: center;
                         cursor: pointer;
                       }
                       .disable-selection {
                         -webkit-touch-callout: none;
                         -webkit-user-select: none;
                         -khtml-user-select: none;
                         -moz-user-select: none;
                         -ms-user-select: none;
                         user-select: none;
                       }
                       .edit-span {
                         font-family: Lato;
                         font-size: 12px;
                         font-weight: normal;
                         font-stretch: normal;
                         font-style: normal;
                         line-height: normal;
                         letter-spacing: 0.8px;
                         color: #ffffff;
                         text-overflow: ellipsis;
                         white-space: nowrap;
                         overflow: hidden;
                         margin-top: 1px;
                         margin-bottom: -1px;
                         padding-left: 2px;
                         max-width: 100%;
                         outline: none;
                       }
                       .tab-closed {
                         display: -ms-flexbox;
                         display: -webkit-flex;
                         display: flex;
                 
                         background-color: #578a6b;
                         border: none;
                 
                         cursor: pointer;
                         padding: 4px 9px;
                         width: fit-content;
                 
                         -webkit-border-radius: 19px;
                         -moz-border-radius: 19px;
                         border-radius: 19px;
                         position: relative;
                       }
                       .tab-opened-end,
                       .tab-opened {
                         display: -ms-flexbox;
                         display: -webkit-flex;
                         display: flex;
                         cursor: pointer;
                 
                         float: left;
                         background-color: #ffffff;
                         font-family: "Lato-Bold";
                 
                         padding: 4px 9px;
                         height: 28px;
                 
                         -webkit-borderradius: 19px 19px 0px 0px;
                         -moz-border-radius: 19px 19px 0px 0px;
                         border-radius: 19px 19px 0px 0px;
                         z-index: 1;
                         -webkit-box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.2);
                         -moz-box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.2);
                         box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.2);
                       }
                       .tab-opened-end:before,
                       .tab-opened-end:after,
                       .tab-opened:before,
                       .tab-opened:after {
                         content: "";
                         position: absolute;
                         height: 20px;
                         width: 30px;
                         bottom: 0;
                         transform: scale(-1, -1);
                         margin-bottom: 14px;
                         margin-left: 40px;
                       }
                       .tab-opened:before {
                         left: -10px;
                 
                         border-radius: 0 15px 0 0;
                         -moz-border-radius: 0 15px 0 0;
                         -webkit-border-radius: 0 15px 0 0;
                 
                         -webkit-box-shadow: 15px 0 0 0 #ffffff;
                         box-shadow: 15px 0 0 0 #ffffff;
                         z-index: 5;
                       }
                       .tab-opened:after {
                         right: -10px;
                 
                         border-radius: 0 15px 0 0;
                         -moz-border-radius: 0 15px 0 0;
                         -webkit-border-radius: 0 15px 0 0;
                 
                         -webkit-box-shadow: -15px 0 0 0 #ffffff;
                         box-shadow: -15px 0 0 0 #ffffff;
                         z-index: 5;
                       }
                       .box-content-end,
                       .box-content {
                         background-color: #ffffff;
                         color: #f2eee7;
                         overflow-y: hidden;
                         max-height: 0;
                         z-index: 2;
                       }
                       .box-content {
                         -webkit-border-radius: 19px;
                         -moz-border-radius: 19px;
                         border-radius: 19px;
                         z-index: 2;
                       }
                       .box-content-end {
                         -webkit-border-radius: 0px 19px 19px 19px;
                         -moz-border-radius: 0px 19px 19px 19px;
                         border-radius: 0px 19px 19px 19px;
                         z-index: 2;
                       }
                       .box-content-end.opened,
                       .box-content.opened {
                         margin-top: 36px;
                         overflow-y: visible;
                         transition: max-height 0.5s ease-out;
                         height: auto;
                         max-height: 1000px;
                         -webkit-box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.2);
                         -moz-box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.2);
                         box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.2);
                         z-index: 2;
                         position: absolute;
                       }
                       .box-content-end.opened:before {
                         content: "";
                         display: block;
                         position: absolute;
                         top: -12px;
                         left: 0;
                         height: 16px;
                         width: 30px;
                         background-color: white;
                         z-index: 3;
                       }
                       .box-curve {
                       }
                       .box-curve:before {
                         content: "";
                         position: absolute;
                         height: 12px;
                         width: 30px;
                         bottom: 0;
                         transform: scale(-1, -1);
                         margin-left: 41px;
                         z-index: 2;
                         background-color: unset;
                         top: -12px;
                       }
                       .box-curve:before {
                         left: -10px;
                 
                         border-radius: 0 15px 0 0;
                         -moz-border-radius: 0 15px 0 0;
                         -webkit-border-radius: 0 15px 0 0;
                 
                         -webkit-box-shadow: 15px 0 0 0 #ffffff;
                         box-shadow: 15px 0 0 0 #ffffff;
                       }
                       .box-curve:after {
                         right: -10px;
                 
                         border-radius: 0 15px 0 0;
                         -moz-border-radius: 0 15px 0 0;
                         -webkit-border-radius: 0 15px 0 0;
                 
                         -webkit-box-shadow: -15px 0 0 0 #ffffff;
                         box-shadow: -15px 0 0 0 #ffffff;
                       }
                       .form-div {
                         position: relavtive;
                         padding-left: 16px;
                         padding-right: 16px;
                       }
                       .form-img {
                         position: absolute;
                         left: 16px;
                         top: 12px;
                       }
                       .form-text {
                         width: 218px;
                         border: 0px;
                         height: 38px;
                         font-family: Lato;
                         font-size: 14px;
                         font-weight: normal;
                         font-stretch: normal;
                         font-style: normal;
                         line-height: normal;
                         letter-spacing: normal;
                         padding-inline-start: 24px;
                         color: #333333;
                       }
                       input:focus {
                         outline: none;
                       }
                       .line {
                         height: 1px;
                         background-color: #c8c7cc;
                       }
                       ::-webkit-scrollbar {
                         width: 5px;
                       }
                       ::-webkit-scrollbar-thumb {
                         background: #d8d8d8;
                         border-radius: 3px;
                         padding: 16px 13px;
                       }
                       .items-container {
                         padding-left: 8px;
                         padding-right: 8px;
                         margin-right: 8px;
                         overflow-y: auto;
                         overflow-x: hidden;
                         max-height: 160px;
                       }
                       .item {
                         width: 234px;
                         height: 33px;
                         border-radius: 20px;
                         background-color: #ffffff;
                         cursor: pointer;
                         position: relative;
                       }
                       .item-text {
                         position: absolute;
                         left: 8px;
                         top: 8px;
                         vertical-align: sub;
                         font-family: Lato;
                         font-size: 14px;
                         font-weight: normal;
                         font-stretch: normal;
                         font-style: normal;
                         line-height: normal;
                         letter-spacing: normal;
                         color: #333333;
                         overflow: hidden;
                         text-overflow: ellipsis;
                         white-space: nowrap;
                         max-width: 220px;
                         cursor: pointer;
                       }
                       .spacer {
                         padding-bottom: 8px;
                       }
                     `,
  ],
})
export class DeletableItemListComponent implements OnInit {
  private didAdd = -1
  private isFirstLoad = true
  private didPressCtrlEnter = false
  public hiddenIndex: number[] = []
  public showSnap = false
  public displayedDropDownList: any[] = []

  @Input() list: any[] = []
  @Input() canEdit = true
  @Input() hideAdd = false
  @Input() disableShortcut = true
  @Input() useDropDownAdd = false
  @Input() dropDownList: any[] = []
  @Input() displayHide = false
  @Input() displayDropDownSearch = false
  @Input() disableAdd = false
  @Input() disableDelete = false

  @Output() added = new EventEmitter<string>()
  @Output() deleted = new EventEmitter<string>()
  @Output() deletedIndex = new EventEmitter<number>()
  @Output() changed = new EventEmitter<string>()
  @Output() changedIndex = new EventEmitter<ChangedIndex>()
  @Output() outputList = new EventEmitter<any[]>()
  @Output() hid = new EventEmitter<string>()
  @Output() hidIndex = new EventEmitter<number>()
  @Output() showed = new EventEmitter<string>()
  @Output() showedIndex = new EventEmitter<number>()

  public hideDropDownBox = true

  @ViewChildren("textField") textFields: QueryList<any>

  @HostListener("document:click", ["$event"])
  clickedOutside(event) {
    if (!this.eRef.nativeElement.contains(event.target) && this.useDropDownAdd && this.showSnap) {
      this.showSnap = false
    }
  }

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (!this.disableShortcut) {
      if (event.keyCode === 13 && event.ctrlKey) {
        if ((this.textFields && this.textFields.last && this.textFields.last.nativeElement && this.textFields.last.nativeElement.textContent && this.textFields.last.nativeElement.textContent.trim() !== "") || !this.textFields || this.textFields.length === 0) {
          ;(<HTMLElement>document.activeElement).blur()
          this.didPressCtrlEnter = true
          this.onAdd()
        }
      }
    }
  }

  constructor(private eRef: ElementRef) {}

  ngOnInit() {
    this.displayedDropDownList = this.dropDownList
  }

  onDelete(item: any, index: number) {
    if (index > -1) {
      this.list.splice(index, 1)
      this.deleted.emit(item)
      this.deletedIndex.emit(index)
      this.outputList.emit(this.list)
    }
  }

  onAdd() {
    if (this.isFirstLoad) {
      this.isFirstLoad = false
      this.textFields.changes.subscribe((_) => {
        if (this.didAdd === 0) {
          if (this.didPressCtrlEnter) {
            this.didPressCtrlEnter = false
            this.textFields.last.nativeElement.textContent = ""
          }
          this.textFields.last.nativeElement.focus()
          this.didAdd = -1
        }
        this.didAdd--
      })
    }
    if (!this.canEdit && (!this.hideAdd || !this.disableShortcut)) {
      return
    }
    const item = ""
    this.list.push(item)
    this.didAdd = 1
    this.textFields.notifyOnChanges()
    this.added.emit(item)
    this.outputList.emit(this.list)
  }

  onFocus(textField: HTMLElement) {
    textField.setAttribute("style", "white-space: normal")
  }

  onFocusOut(value: string, index: number, textField: HTMLElement) {
    textField.setAttribute("style", "white-space: nowrap")
    if ((value && value.trim() === "") || !value) {
      value = value.trim()
      this.deleted.emit(value)
      this.list.splice(index, 1)
      this.deletedIndex.emit(index)
      this.outputList.emit(this.list)
    } else if (this.list[index] !== value && value.trim() !== "") {
      value = value.trim()
      this.list[index] = value
      this.changed.emit(value)
      this.changedIndex.emit({ Index: index, Value: value })
      this.outputList.emit(this.list)
    }
  }

  onKeyDown(event) {
    event.preventDefault()
  }

  blur(event) {
    event.target.blur()
  }

  onHideIndex(index: number) {
    if (this.hiddenIndex.includes(index)) {
      const i = this.hiddenIndex.indexOf(index)
      this.hiddenIndex.splice(i, 1)
      this.showed.emit(this.list[index])
      this.showedIndex.emit(index)
    } else {
      this.hiddenIndex.push(index)
      this.hid.emit(this.list[index])
      this.hidIndex.emit(index)
    }
  }

  showSnapOnClick() {
    if (!this.showSnap) {
      this.displayedDropDownList = this.dropDownList.filter((x) => !this.list?.includes(x))
    }
    this.showSnap = !this.showSnap
  }

  onInputChange(value: string) {
    if (value === "") {
      this.displayedDropDownList = this.dropDownList
    } else {
      this.displayedDropDownList = this.dropDownList.filter((x) => x.toLowerCase().includes(value.toLowerCase()))
    }
  }

  onItemClicked(value: string) {
    this.list.push(value)
    this.didAdd = 1
    this.textFields.notifyOnChanges()
    this.added.emit(value)
    this.outputList.emit(this.list)
    this.showSnap = false
    this.displayedDropDownList = this.dropDownList.filter((x) => !this.list?.includes(x))
  }
}
