import { Injectable } from "@angular/core"
import { ApiService } from "@shared/services/api.service"
import { Observable } from "rxjs"
import { TemplateResponse, UpdateTemplateDraftRequest, LanguageResponse } from "./models/template"
import { ActiveNode, ArchivedNode, FlowNode, NodeInfo } from "./models/node"
import { Arithmetic, Function } from "./models/functions"
import { DynamicVariableResponse, ListenableEventsResponse } from "./models/communication"
import { HotelConversationResponse, GuestThreadResponse, MultipleUserMessageBody, SearchedGuestsResult, ConversationResponseResult } from "./models/inbox"
import { SiteResponse } from "./models/site"
import { NotificationCenter } from "./models/notificationCenter"
import { HotelDirectory, Page } from "./models/hotel-directory"

const API = "communicationV1/"

@Injectable()
export class CommunicationApiService {
  constructor(private apiService: ApiService) {}

  public Communication = {
    /**Get dynamic variables to be used in email, sms or app messages */
    GetDynamicVariableList: (hotelId: string): Observable<DynamicVariableResponse[]> => this.apiService.get<DynamicVariableResponse[]>(`${API}Communication/DynamicVariables?hotelId=${hotelId}`),
    /**Get listenable event names to be used in email, sms and app messages */
    GetListenableEvents: (): Observable<ListenableEventsResponse[]> => this.apiService.get<ListenableEventsResponse[]>(`${API}Communication/ListenableEvents`),
  }

  public Function = {
    /**Get Arithmetic that is possible to use for different functions*/
    GetArithmetic: (): Observable<Arithmetic[]> => this.apiService.get<Arithmetic[]>(`${API}Function/Info`),

    /**Save function to be used in a node*/
    SaveFunction: (func: Function): Observable<any> => this.apiService.post<any>(`${API}Function/Save`, func),

    /**Load functions for a specific hotel's flowchart*/
    LoadFunctions: (hotelId: string, rootId: string): Observable<Function> => this.apiService.get<Function>(`${API}Function/Load/${hotelId}/${rootId}`),

    /**Get one specific function */
    GetFunction: (hotelid: string, rootid: string, functionid: string): Observable<Function> => this.apiService.get<Function>(`${API}Function/GetFunction/${hotelid}/${rootid}/${functionid}`),

    /**Delete function */
    DeleteFunction: (hotelId: string, rootId: string, id: string): Observable<any> => this.apiService.delete(`${API}Function/Delete?hotelId=${hotelId}&rootId=${rootId}&id=${id}`),
  }

  public Node = {
    /**Get node info - Possible nodes to be made*/
    GetNodeInfo: (hotelId: string): Observable<NodeInfo[]> => this.apiService.get<NodeInfo[]>(`${API}Node/Info?hotelid=${hotelId}`),

    /**Save/Update node with list of function ids and child nodes */
    SaveNode: (node: FlowNode): Observable<any> => this.apiService.post<any>(`${API}Node/Save`, node),

    /** Delete specfic node*/
    DeleteNode: (node: FlowNode): Observable<any> => this.apiService.delete(`${API}Node/Delete`, node),

    /**Load all nodes for a specfic hotel and rootid (Load specific flowchart) */
    LoadNodes: (hotelId: string, rootId: string): Observable<FlowNode[]> => this.apiService.get<FlowNode[]>(`${API}Node/Load/${hotelId}/${rootId}`),

    /**Save draft for a archived flow */
    SaveArchivedDraft: (hotelId: string, rootId: string, newDraftId: string): Observable<any> => this.apiService.post<any>(`${API}Node/ArchivedFlow/SaveDraft?hotelId=${hotelId}&rootId=${rootId}&newDraftId=${newDraftId}`),

    /**Save draft for a active flow */
    SaveActiveDraft: (hotelId: string, rootId: string, newDraftId: string): Observable<any> => this.apiService.post<any>(`${API}Node/ActiveFlow/SaveDraft?hotelId=${hotelId}&rootId=${rootId}&newDraftId=${newDraftId}`),

    /**Create a active flow */
    CreateActiveFlow: (activeFlow: ActiveNode): Observable<any> => this.apiService.post<any>(`${API}/Node/ActiveFlow/Create`, activeFlow),

    /**Create a archived flow */
    CreateArchivedFlow: (archivedFlow: ArchivedNode): Observable<any> => this.apiService.post<any>(`${API}/Node/ArchivedFlow/Create`, archivedFlow),

    /**Delete active flow */
    DeleteActiveFlow: (hotelId: string, rootId: string, draftId: string): Observable<any> => this.apiService.delete(`${API}/Node/ActiveFlow/Delete?hotelId=${hotelId}&rootId=${rootId}&draftId=${draftId}`),

    /**Delete archived flow */
    DeleteArchivedFlow: (hotelId: string, rootId: string, draftId: string): Observable<any> => this.apiService.delete(`${API}/Node/ArchivedFlow/Delete?hotelId=${hotelId}&rootId=${rootId}&draftId=${draftId}`),

    /** Get all active nodes for hotel*/
    GetAllActiveNodes: (hotelId: string): Observable<ActiveNode[]> => this.apiService.get<ActiveNode[]>(`${API}Node/ActiveFlow/Get/${hotelId}`),

    /**Get all archived nodes for hotel  (find out if filtering is needed)*/
    GetAllArchivedNodes: (hotelId: string): Observable<ArchivedNode[]> => this.apiService.get<ArchivedNode[]>(`${API}Node/ArchivedFlow/Get/${hotelId}`),

    /**Activate an archived flow. This deletes the archived flow and creates an active node with the same data. You should change its status*/
    activateArchivedFlow: (hotelId: string, rootId: string, status: string): Observable<any> => this.apiService.post<any>(`${API}/Node/ArchivedFlow/Activate?hotelId=${hotelId}&rootId=${rootId}&status=${status}`),

    /**Archive an active flow. This deletes the active flow and creates an archived node with the same data. You should change its status*/
    archiveActiveFlow: (hotelId: string, rootId: string, status: string): Observable<any> => this.apiService.post<any>(`${API}/Node/ActiveFlow/Archive?hotelId=${hotelId}&rootId=${rootId}&status=${status}`),

    duplicateArchivedFlow: (hotelId: string, rootId: string, duplicateHotelId: string, status: string, name: string, description: string, objective: string) =>
      this.apiService.post<any>(`${API}/Node/ArchivedFlow/Duplicate?hotelId=${hotelId}&rootId=${rootId}&duplicateHotelId=${duplicateHotelId}&status=${status}&name=${name}&description=${description}&objective=${objective}`),

    duplicateActiveFlow: (hotelId: string, rootId: string, duplicateHotelId: string, status: string, name: string, description: string, objective: string) =>
      this.apiService.post<any>(`${API}/Node/ActiveFlow/Duplicate?hotelId=${hotelId}&rootId=${rootId}&duplicateHotelId=${duplicateHotelId}&status=${status}&name=${name}&description=${description}&objective=${objective}`),

    /**Gets all events that needs to be run though the flowchart - Listenable events only */
    getBookingDataToBeRun: (hotelId: string, rootId: string, days: number) => this.apiService.post<any>(`${API}/Node/Hotel/${hotelId}/FlowChart/${rootId}/Start?days=${days}`),
  }

  public Notification = {
    /**Gets all notification settings for hotel */
    GetAllNotification: (hotelId: string): Observable<NotificationCenter[]> => this.apiService.get<NotificationCenter[]>(`${API}/Notification/${hotelId}`),

    /**Gets specific context of notification settings for hotel */
    GetNotificationContext: (hotelId: string, context: string): Observable<NotificationCenter> => this.apiService.get<NotificationCenter>(`${API}/Notification/${hotelId}/${context}`),

    /**Saves Notification center settings */
    PutNotificationCenter: (notificationCenter: NotificationCenter): Observable<NotificationCenter> => this.apiService.put<NotificationCenter>(`? ${API}/Notification/Save`, notificationCenter),

    /**Saves a list of notificationcenters settings */
    PutAllNotificationCenters: (notificationCenterList: NotificationCenter[]): Observable<NotificationCenter[]> => this.apiService.put<NotificationCenter[]>(`${API}/Notification/SaveList`, notificationCenterList),
  }

  public Template = {
    /** Get all templates for a hotel */
    GetAll: (hotelId: string): Observable<TemplateResponse[]> => this.apiService.get<TemplateResponse[]>(`${API}Template/${hotelId}`),
    GetAllActive: (hotelId: string, messageType: string): Observable<TemplateResponse[]> => this.apiService.get<TemplateResponse[]>(`${API}Template/${hotelId}/Active?messageType=${messageType}`),

    getById: (hotelId: string, id: string): Observable<TemplateResponse> => this.apiService.get<TemplateResponse>(`${API}Template/${hotelId}/${id}`),

    saveTemplate: (template: TemplateResponse): Observable<any> => this.apiService.post<any>(`${API}Template/Save`, template),

    saveDraftTemplate: (template: UpdateTemplateDraftRequest): Observable<any> => this.apiService.put(`${API}Template/Update`, template),

    duplicateTemplate: (sourceHotelId: string, sourceId: string, destHotelId: string, destId: string): Observable<TemplateResponse> => this.apiService.post<TemplateResponse>(`${API}Template/Duplicate?sourceHotelId=${sourceHotelId}&sourceId=${sourceId}&destHotelId=${destHotelId}&destId=${destId}`),

    deleteTemplate: (hotelId: string, templateId): Observable<TemplateResponse> => this.apiService.delete(`${API}Template/Delete?hotelId=${hotelId}&templateId=${templateId}`),

    GetLanguages: (): Observable<LanguageResponse[]> => this.apiService.get<LanguageResponse[]>(`${API}Template/Languages`),

    SendTestEmail: (hotelId: string, templateId: string, email: string, language: string, pms: string, res: string): Observable<any> => {
      var pmsstring = pms !== "" ? "&pmsBookingNumber=" + pms : ""
      var resstring = res !== "" ? "&reservationID=" + res : ""
      var languageString = language !== "" ? "&language=" + language : ""
      return this.apiService.post(`${API}Template/SendTestEmail?hotelId=${hotelId}&templateId=${templateId}&emailAddress=${email}${languageString}${pmsstring}${resstring}`)
    },

    SendTestSMS: (hotelId: string, templateId: string, phoneNumber: string, language: string, pms: string, res: string): Observable<any> => {
      var pmsstring = pms !== "" ? "&pmsBookingNumber=" + pms : ""
      var resstring = res !== "" ? "&reservationID=" + res : ""
      return this.apiService.post(`${API}Template/SendTestSMS?hotelId=${hotelId}&templateId=${templateId}&phoneNumber=${phoneNumber}&testingLanguage=${language}${pmsstring}${resstring}`)
    },
    GetHtmlPreview: (hotelId: string, templateId: string): Observable<any> => this.apiService.get<any>(`${API}Template/GetTemplateHtml?hotelId=${hotelId}&templateId=${templateId}`),

    SendTemplateWithBooking: (hotelId: string, templateId: string, PmsBookingNumber: string, reservationNumber: string, email: string, language: string): Observable<any> => {
      var emailString = email !== "" ? `&recipient=${email}` : ""
      var languageString = language !== "" ? `&language=${language}` : ""
      return this.apiService.post(`${API}Template/Hotel/${hotelId}/Template/${templateId}/Send?pmsBookingNumber=${PmsBookingNumber}&reservationId=${reservationNumber}${emailString}${languageString}`)
    },
  }

  public Inbox = {
    GetHotelConversations: (hotelId: string, pageSize: number, pageState: string): Observable<HotelConversationResponse> => this.apiService.get<HotelConversationResponse>(`${API}Inbox/Hotel/${hotelId}/Conversations?pageSize=${pageSize}&pageState=${pageState}`),

    GetHotelConversationForUser: (hotelId: string, userId: string): Observable<ConversationResponseResult> => this.apiService.get<ConversationResponseResult>(`${API}Inbox/Hotel/${hotelId}/User/${userId}/Conversation`),

    GetUserThread: (hotelId: string, userId: string): Observable<GuestThreadResponse> => this.apiService.get<GuestThreadResponse>(`${API}Inbox/Hotel/${hotelId}/User/${userId}/Thread`),

    senderMessageToUser: (hotelId: string, userId: string, sender: string, message: string): Observable<any> => this.apiService.post<any>(`${API}/Inbox/Hotel/${hotelId}/SendMessageToUser/${userId}?sender=${sender}`, message),

    hotelReadMessage: (hotelId: string, userId: string): Observable<any> => this.apiService.post<any>(`${API}/Inbox/Hotel/${hotelId}/HotelReadMessage/${userId}`),

    deleteConversation: (hotelId: string, userId: string, isUser: boolean): Observable<any> => this.apiService.delete(`${API}/Inbox/Hotel/${hotelId}/Conversation/${userId}/Delete?isUser=${isUser}`),

    sendMessageToMultipleUsers: (hotelId: string, sender: string, messageBody: MultipleUserMessageBody): Observable<any> => this.apiService.post<any>(`${API}/Inbox/Hotel/${hotelId}/SendMessageToMultipleUser?sender=${sender}`, messageBody),

    searchForGuest: (hotelId: string, query: string, pageSize: number, pageState: string, allUsersQueried: boolean): Observable<SearchedGuestsResult> =>
      this.apiService.get<SearchedGuestsResult>(`${API}Inbox/Hotel/${hotelId}/Search/${query}?pageSize=${pageSize}&pageState=${pageState}&allUsersQueried=${allUsersQueried}`),

    filterFunction: (hotelId: string, nodeFunction: Function): Observable<string[]> => this.apiService.put<string[]>(`${API}/Inbox/Hotel/${hotelId}/Function/Filter`, nodeFunction),

    resolveConversation: (hotelId: string, userId: string, isResolved: boolean): Observable<any> => this.apiService.post<any>(`${API}/Inbox/Hotel/${hotelId}/User/${userId}/Conversation/Resolve?isResolved=${isResolved}`),
  }

  public Site = {
    getSite: (hotelId: string): Observable<SiteResponse> => this.apiService.get<SiteResponse>(`${API}/Site/${hotelId}`),
  }

  public HotelDirectory = {
    /**Get specific directory */
    getDirectory: (organisationId: string, hotelId: string, id: string): Observable<HotelDirectory> => this.apiService.get<HotelDirectory>(`${API}/HotelDirectory/Organisation/${organisationId}/Hotel/${hotelId}/Directory/${id}`),
    /**Get all directory for hotel */
    getAllDirectory: (organisationId: string, hotelId: string): Observable<HotelDirectory[]> => this.apiService.get<HotelDirectory[]>(`${API}/HotelDirectory/Organisation/${organisationId}/Hotel/${hotelId}/Directory`),
    /**Create new directory */
    createDirectory: (hotelDirectory: HotelDirectory): Observable<any> => this.apiService.post(`${API}/HotelDirectory/Directory`, hotelDirectory),
    /**Get all content for */
    getAllContent: (hotelId: string, id: string): Observable<Page[]> => this.apiService.get(`${API}/HotelDirectory/Hotel/${hotelId}/Page/${id}`),
    /**Get specfic page in hotel directory */
    getSpecificPage: (hotelId: string, id: string, pageId: string): Observable<Page> => this.apiService.get(`${API}/HotelDirectory/Hotel/${hotelId}/Page/${id}/SubPage/${pageId}`),
    /**Create new page for hotel directory */
    createPage: (page: Page): Observable<any> => this.apiService.post(`${API}HotelDirectory/Page`, page),
    /**Publish a directory */
    publishHotelDirectory: (hotelId: string, id: string, subpages: string[]): Observable<any> => this.apiService.put(`${API}/HotelDirectory/Hotel/${hotelId}/Page/${id}/Publish`, subpages),
    /**Delete page in a hotel directory */
    deletePage: (hotelId: string, id: string, pageId: string[] = []): Observable<any> => this.apiService.delete(`${API}/HotelDirectory/Hotel/${hotelId}/Page/${id}/Delete`, pageId),
    /**Delete hotel directory */
    deleteDirectory: (organisationId: string, hotelId: string, id: string): Observable<any> => this.apiService.delete(`${API}/HotelDirectory/Organisation/${organisationId}/Hotel/${hotelId}/Directory/${id}/Delete`),
    /**Duplicate subpage  */
    duplicateSubPage: (page: Page): Observable<Page> => this.apiService.put(`${API}/HotelDirectory/Hotel/SubPage/Duplicate`, page),
    /**Create Alias */
    createAlias: (hotelId: string, pageId: string, subPageId: string, pageAlias: string): Observable<any> => this.apiService.post(`${API}/HotelDirectory/Hotel/${hotelId}/Page/${pageId}/Subpage/${subPageId}/Alias?pageAlias=${pageAlias}`),
    /**Get Alias */
    getAlias: (pageAlias: string, personal: string = ""): Observable<Page> => this.apiService.get(`${API}/HotelDirectory/Alias?pageAlias=${pageAlias}&personal=${personal}`),
    /** Delete Alias**/
    deleteAlias: (pageAlias: string): Observable<any> => this.apiService.delete(`${API}/HotelDirectory/Alias/Delete?Alias=${pageAlias}`),
    /**Update Alias */
    updateAlias: (oldAlias: string, newAlias: string) => this.apiService.put(`${API}/HotelDirectory/Alias/Update?oldAlias=${oldAlias}&newAlias=${newAlias}`),
  }
}
