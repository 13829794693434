import { Component, EventEmitter, Input, Output } from "@angular/core"
import { InputInfo } from "../ag-input-field/ag-input-field.component"
import { IconImageService } from "@shared/services/icon-image.service"
import { DatePipe } from "@angular/common"
import * as moment from "moment"

@Component({
  selector: "ag-date-picker",
  templateUrl: "./ag-date-picker.component.html",
  styleUrl: "./ag-date-picker.component.scss",
})
export class AgDatePickerComponent {
  selectedMonthYear: Date = new Date()
  weekdays: string[] = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]
  weeks: Date[][] = []
  selectedStartDate: Date | null = null
  selectedEndDate: Date | null = null
  label: any
  currentDate = "today"
  datepickerShown = false
  insideClick: boolean
  changedDate = false
  selectedStartMonth: Date | null = null
  selectedEndMonth: Date | null = null

  @Input() startDate?: Date = null
  @Input() endDate?: Date = null
  @Input() isDateRange?: boolean = false
  @Input() labelInput?: string = null
  @Input() placeholder?: string = "Select a date"
  @Input() applyButton?: boolean = true
  @Input() placement: Placement //if you want to change the left/right position just give the datepicker a parent div & change its width
  @Input() disableDates: DisableDates = "none"
  @Input() forceWidth?: string = null
  @Input() forceHeight?: string = "48px"
  @Input() useOpenButton: boolean = true
  @Input() triggerOpenDatePicker: boolean = false
  @Input() maxDateRangeSelectable: number = 14
  @Input() type: DatePickerType = "regular"

  @Input() info: InputInfo = {
    showInfo: false,
    type: "info",
    text: "Info",
  }

  @Output() dateRangeOutput: EventEmitter<Date[]> = new EventEmitter<Date[]>()
  @Output() monthOutput: EventEmitter<string[]> = new EventEmitter<string[]>()

  constructor(public iconService: IconImageService, private datePipe: DatePipe) {}

  public openDatePicker(event: Event) {
    event.stopPropagation()
    this.insideClick = true
    this.datepickerShown = !this.datepickerShown
  }

  public closeDatePicker(event: any) {
    this.datepickerShown = event
  }

  public submit() {
    this.changedDate = true
    if (this.isDateRange) this.dateRangeOutput.emit([this.selectedStartDate, this.selectedEndDate])
    else this.dateRangeOutput.emit([this.selectedStartDate])
    this.datepickerShown = false
  }

  public onMonthOutput(month: string[]) {
    if (!this.isDateRange) {
      const date = new Date(month[0])
      this.selectedStartMonth = new Date(date + "-01")
      this.monthOutput.emit([month[0]])
    } else {
      const firstDate = new Date(month[0])
      const lastDate = new Date(month[1])
      this.selectedStartMonth = new Date(firstDate + "-01")
      this.selectedEndMonth = new Date(lastDate + "-01")
      this.monthOutput.emit([month[0], month[1]])
    }
    this.datepickerShown = false
    this.changedDate = true
  }

  public onDateOutput(dates: Date[]) {
    this.selectedStartDate = dates[0]
    if (this.isDateRange) {
      this.selectedEndDate = dates[1]
    }
    this.datepickerShown = false
    this.changedDate = true
    this.submit()
  }
}

export type Placement = "top" | "left" | "right" | "bottom" | "top-left" | "top-right" | "bottom-left" | "bottom-right" | "bottom-just-left" | "zero-right" | "zero-left"

export type DisableDates = "none" | "future" | "past"

export type DatePickerType = "regular" | "month" | "double"
