import { Component, OnInit, Inject } from "@angular/core"
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog"
import { DoorUnlockCase } from "app/shared/models/journey/journey-list"

@Component({
  selector: "app-journey-door-unlock-cases-dialog",
  templateUrl: "./journey-door-unlock-cases-dialog.component.html",
  styleUrls: ["./journey-door-unlock-cases-dialog.component.scss"],
})
export class JourneyDoorUnlockCasesDialogComponent implements OnInit {
  dialogTitle: string
  doorUnlockCases: DoorUnlockCase[]
  dialogOKButtonName: string

  constructor(
    public dialogRef: MatDialogRef<JourneyDoorUnlockCasesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.dialogTitle = this.data.title
    this.doorUnlockCases = this.data.doorUnlockCases
    this.dialogOKButtonName = this.data.okButtonName
  }
}
