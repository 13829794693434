import { Component, Input } from "@angular/core"
import { trigger, state, style, transition, animate } from "@angular/animations"

@Component({
  selector: "ag-circle-loader",
  template: `
    <div *ngIf="isLoading" class="circle"></div>
  `,
  styles: [
    `
      .circle {
        border: 6px solid transparent;
        border-top: 6px solid #dfdfdf;
        border-right: 6px solid #dfdfdf;
        border-bottom: 6px solid #dfdfdf;
        border-radius: 50%;
        width: 88px;
        height: 88px;
        animation: spin 2s linear infinite;
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `,
  ],
  animations: [
    // trigger("dropDown", [state("true", style({ transform: "translateY(0px)", "z-index": 0, opacity: 0 })), state("false", style({ transform: "translateY(40px)", "z-index": 1000, opacity: 1 })), transition("true=>false", animate("250ms ease-out"))]),
  ],
})
export class CircleLoaderComponent {
  @Input() isLoading: boolean = false

  constructor() {}
}
