import { Location } from "@angular/common"
import { Component, Input, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { BreadcrumbItem } from "@shared/models/header"
import { IconImageService } from "@shared/services/icon-image.service"

@Component({
  selector: "ag-breadcrumb",
  templateUrl: "./ag-breadcrumb.component.html",
  styleUrls: ["./ag-breadcrumb.component.scss"],
})
export class AgBreadcrumbComponent implements OnInit {
  @Input() paths: BreadcrumbItem[] = []
  @Input() currentPath: string
  @Input() bold: boolean = false

  constructor(
    private _location: Location,
    private router: Router,
    public iconService: IconImageService,
  ) {}

  ngOnInit(): void {}

  hasPath(): boolean {
    return this.paths !== undefined && this.paths.length > 0
  }

  backClicked() {
    if (this.router.url.includes("/core/property-setup/management/employee-setup/edit")) {
      this.router.navigate(["/core/property-setup/management/employee-setup"])
    } else {
      this._location.back()
    }
  }
}
