import { Component, ElementRef, forwardRef, Input, Renderer2, ViewChild } from "@angular/core"
import { AbstractControl, ControlContainer, ControlValueAccessor, UntypedFormControl, FormControlDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms"

@Component({
  selector: "ag-input",
  templateUrl: "./ag-input.component.html",
  styleUrls: ["./ag-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AgInputComponent),
      multi: true,
    },
  ],
})
export class AgInputComponent implements ControlValueAccessor, Validator {
  @Input() label!: string
  @Input() hint?: string
  @Input() inputType?: InputType = "text"

  @ViewChild(FormControlDirective, { static: true })
  formControlDirective!: FormControlDirective

  @Input() formControl!: UntypedFormControl
  @Input() formControlName!: string

  @ViewChild("viewControl") viewControl?: ElementRef

  get control(): UntypedFormControl {
    return this.formControl || <UntypedFormControl>this.controlContainer?.control?.get(this.formControlName || "")
  }

  constructor(
    private controlContainer: ControlContainer,
    private renderer: Renderer2,
  ) {}

  writeValue = (value: string): void => this.formControlDirective.valueAccessor!.writeValue(value)
  registerOnChange = (fn: any): void => this.formControlDirective.valueAccessor!.registerOnChange(fn)
  registerOnTouched = (fn: any): void => this.formControlDirective.valueAccessor!.registerOnTouched(fn)

  validate(control: AbstractControl): ValidationErrors | null {
    return this.control.valid ? null : control.errors
  }

  onFocus = () => this.renderer.addClass(this.viewControl?.nativeElement, "focussed")
  onBlur = () => this.renderer.removeClass(this.viewControl?.nativeElement, "focussed")
}

export type InputType = "text" | "url" | "tel" | "password" | "email" | "number"
