import { Component, OnInit, OnDestroy } from "@angular/core"
import { Toast, ToastType } from "app/shared/models/toast"
import { ToastService } from "app/toast/toast.service"
import { Subject } from "rxjs"
import { takeUntil } from "rxjs/operators"

@Component({
  selector: "app-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"],
})
export class ToastComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject()
  alert: Toast

  constructor(private alertService: ToastService) {
    this.alertService
      .getAlert()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((alert: Toast) => {
        this.alert = alert
      })
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unsubscribe.next(true)
    this.unsubscribe.complete()
  }

  cssClass() {
    if (!this.alert) {
      return
    }

    // return css class based on alert type
    switch (this.alert.type) {
      case ToastType.Success:
        return "toastt alert-success"
      case ToastType.Error:
        return "toastt alert-danger"
      case ToastType.Info:
        return "toastt alert-info"
      case ToastType.Warning:
        return "toastt alert-warning"
    }
  }

  clearToast() {
    this.alertService.clear()
  }
}
