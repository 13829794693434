import { GraphTypeStrategy } from "./graph-type-strategy"

export class AreaTypeStrategy implements GraphTypeStrategy {
  constructGraph(fc: any, xScale: any, yScale: any) {
    return fc
      .seriesWebglArea()
      .xScale(xScale)
      .yScale(yScale)
      .crossValue((d) => d.data.Date)
      .mainValue((d) => d[1])
  }
}
