import { enableProdMode } from "@angular/core"
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic"

import { AppModule } from "./app/app.module"
import { Environment } from "./environments/environment"

import * as $ from "jquery"
window["$"] = $
window["jQuery"] = $

if (Environment.AngularProduction) {
  enableProdMode()
}

platformBrowserDynamic().bootstrapModule(AppModule)
