import { Component, OnInit } from "@angular/core"
import { TooltipService } from "app/shared/services/tooltip.service"

@Component({
  selector: "ag-tooltip-v2-container",
  templateUrl: "./ag-tooltip-v2-container.component.html",
})
export class AgTooltipContainerV2Component {
  constructor(public tooltipService: TooltipService) {}
}
