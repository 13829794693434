import { Component, OnInit } from "@angular/core"
import { STORAGE_USER, STORAGE_TOKEN, API_HOTEL_V2, STORAGE_BOOKING_INFO_REDIRECT } from "app/app-settings"
import { ActivatedRoute, Router } from "@angular/router"
import { AuthenticationService } from "../shared/services/authentication.service"
import { ApiService } from "../shared/services/api.service"
import { BehaviorSubject } from "rxjs"
import { HttpErrorResponse } from "@angular/common/http"

@Component({
  selector: "app-zest",
  templateUrl: "./zest.component.html",
  styleUrls: ["./zest.component.scss"],
})
export class ZestComponent implements OnInit {
  token
  userEmail
  requestedBookingId
  isLoadingHotels = new BehaviorSubject(false)

  constructor(private authService: AuthenticationService, private route: ActivatedRoute, private apiService: ApiService, private router: Router) {}

  ngOnInit(): void {
    this.isLoadingHotels.next(true)
    this.token = this.route.snapshot.paramMap.get("token")
    this.userEmail = this.route.snapshot.paramMap.get("userEmail")
    this.requestedBookingId = this.route.snapshot.paramMap.get("bookingId")
    localStorage.setItem(STORAGE_TOKEN, this.token)
    this.apiService.get(`${API_HOTEL_V2}Employee/Email/${this.userEmail}`).subscribe(
      (employee) => {
        localStorage.setItem(STORAGE_USER, JSON.stringify(employee))
        this.isLoadingHotels.next(false)
        this.navigate()
      },
      (err) => {
        if (err instanceof HttpErrorResponse) {
          this.router.navigate(["/login/hotel"])
        }
      }
    )
  }

  navigate() {
    if (this.requestedBookingId) {
      this.apiService.get(`${API_HOTEL_V2}Booking/${this.requestedBookingId}`).subscribe((booking) => {
        const requestedBooking = {
          PmsBookingNumber: booking.PMSBookingNumber,
          ReservationNumber: booking.ReservationNumber,
          HotelId: booking.HotelId,
          BookingId: booking.Id,
        }
        localStorage.setItem(STORAGE_BOOKING_INFO_REDIRECT, JSON.stringify(requestedBooking))
        this.router.navigate(["/login/select/properties"])
      })
    } else {
      this.router.navigate(["/login/select/properties"])
    }
  }
}
