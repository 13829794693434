import { Injectable } from "@angular/core"
import { Router, NavigationStart } from "@angular/router"
import { Observable } from "rxjs"
import { Subject } from "rxjs"
import { Toast, ToastType } from "app/shared/models/toast"

@Injectable()
export class ToastService {
  private subject = new Subject<Toast>()
  private keepAfterRouteChange = false

  constructor(private router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false
        } else {
          // clear alert messages
          this.clear()
        }
      }
    })
  }

  getAlert(): Observable<any> {
    return this.subject.asObservable()
  }

  success(options: Toast, keepAfterRouteChange = false, delay = 3000) {
    options.type = ToastType.Success
    this.alert(options, keepAfterRouteChange, delay)
  }

  error(options: Toast, keepAfterRouteChange = false, delay = 3000) {
    options.type = ToastType.Error
    this.alert(options, keepAfterRouteChange, delay)
  }

  info(options: Toast, keepAfterRouteChange = false, delay = 3000) {
    options.type = ToastType.Info
    this.alert(options, keepAfterRouteChange, delay)
  }

  warn(options: Toast, keepAfterRouteChange = false, delay = 3000) {
    options.type = ToastType.Warning
    this.alert(options, keepAfterRouteChange, delay)
  }

  alert(options: Toast, keepAfterRouteChange = false, delay = 3000) {
    this.keepAfterRouteChange = keepAfterRouteChange
    this.subject.next(options)
    setTimeout(() => this.clear(), delay)
  }

  clear() {
    // clear alerts
    this.subject.next(null)
  }

  showDefaultError() {
    this.error({ message: `Connection to the server failed. Please check your connection or contact the AeroGuest team.` })
  }
}
