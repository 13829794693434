import { DatePipe } from "@angular/common"
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, HostListener } from "@angular/core"
import { DisableDates, Placement } from "@shared/animations/datePicker-component"
import { IconImageService } from "@shared/services/icon-image.service"
import * as moment from "moment"

@Component({
  selector: "app-date-month-box",
  templateUrl: "./date-month-box.component.html",
  styleUrl: "./date-month-box.component.scss",
})
export class DateMonthBoxComponent implements OnInit {
  selectedMonthYear: Date = new Date()
  selectedStartMonth: Date | null = null
  selectedEndMonth: Date | null = null
  dates: Date[] = []
  label: any
  insideClick: boolean

  @Input() datepickerShown = false
  @Input() startMonth: Date | null = null
  @Input() endMonth: Date | null = null
  @Input() applyButton: boolean = true
  @Input() placement: Placement //if you want to change the left/right position just give the datepicker a parent div & change its width
  @Input() disableDates: DisableDates = "none"
  @Input() isDateRange: boolean = false

  @Output() showDatepickerOutput: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() monthSelected: EventEmitter<string[]> = new EventEmitter<string[]>()

  @HostListener("document:click", ["$event"])
  clickedOutside(event) {
    if (!this.eRef.nativeElement.contains(event.target) && this.datepickerShown) {
      this.showDatepickerOutput.emit(false)
      this.datepickerShown = false
    }
  }

  constructor(public iconService: IconImageService, private datePipe: DatePipe, private eRef: ElementRef) {}

  ngOnInit(): void {
    this.generateCalendar()
    this.initializeDates()
  }

  public previousYear() {
    this.selectedMonthYear.setFullYear(this.selectedMonthYear.getFullYear() - 1)
    this.generateCalendar()
  }

  public nextYear() {
    this.selectedMonthYear.setFullYear(this.selectedMonthYear.getFullYear() + 1)
    this.generateCalendar()
  }

  private generateCalendar() {
    this.label = moment(this.selectedMonthYear).format("YYYY")
    const tempMonths: Date[] = []
    for (let i = 0; i < 12; i++) {
      tempMonths.push(new Date(this.selectedMonthYear.getFullYear(), i, 1))
    }
    this.dates = tempMonths
  }

  private initializeDates() {
    if (this.startMonth !== null) {
      var newStartMonth = new Date(this.startMonth.getFullYear(), this.startMonth.getMonth(), 1)
    }
    if (this.endMonth !== null) {
      var newEndMonth = new Date(this.endMonth.getFullYear(), this.endMonth.getMonth(), 1)
    }
    if (!this.isDateRange && this.startMonth !== null) {
      this.selectedStartMonth = newStartMonth
    }
    if (this.isDateRange) {
      this.selectedStartMonth = newStartMonth
      this.selectedEndMonth = newEndMonth
    }
    if (!this.isDateRange && this.startMonth === null) {
      this.selectedStartMonth = new Date(this.selectedMonthYear.getFullYear(), this.selectedMonthYear.getMonth(), 1)
      this.startMonth = new Date(this.selectedMonthYear.getFullYear(), this.selectedMonthYear.getMonth(), 1)
    }
  }

  public isDateSelected(date: Date): boolean {
    if (this.selectedStartMonth !== null && !this.isDateRange) {
      var thisDate = moment(date).format("YYYY-MM")
      var selectedDate = moment(this.selectedStartMonth).format("YYYY-MM")
      return thisDate === selectedDate
    }
  }

  public isDateBetween(date: Date): boolean {
    if (date !== this.selectedStartMonth && date !== this.selectedEndMonth && this.isDateRange) {
      return this.selectedStartMonth && this.selectedEndMonth && date > this.selectedStartMonth && date < this.selectedEndMonth
    }
  }

  public isFirstDate(date: Date): boolean {
    if (this.selectedStartMonth !== null && this.selectedStartMonth !== undefined) {
      var newStartDate = moment(this.selectedStartMonth).format("YYYY-MM")
      var dateString = moment(date).format("YYYY-MM")
      return dateString === newStartDate
    }
  }

  public isLastDate(date: Date): boolean {
    if (this.selectedEndMonth !== null && this.selectedEndMonth !== undefined) {
      var newEndDate = moment(this.selectedEndMonth).format("YYYY-MM")
      var dateString = moment(date).format("YYYY-MM")
      return dateString === newEndDate
    }
  }

  public selectMonth(date: Date) {
    if (this.isDateDisabled(date)) {
      return
    } else {
      if (this.isDateRange) {
        if (this.selectedEndMonth !== null) {
          this.selectedStartMonth = date
          this.selectedEndMonth = null
        } else if (date < this.selectedStartMonth) {
          this.selectedStartMonth = date
          this.selectedEndMonth = null
        } else if (date >= this.selectedStartMonth) {
          this.selectedEndMonth = date
        }
      } else {
        this.selectedStartMonth = date
      }
      if (!this.applyButton) this.submit()
    }
  }

  public isDateDisabled(day: Date): boolean {
    const newDate = new Date()
    const currentDate = moment(newDate).format("YYYY-MM")
    const selectedDate = moment(day).format("YYYY-MM")
    if (this.disableDates === "future") {
      if (currentDate < selectedDate) {
        return true
      } else if (currentDate === selectedDate) {
        return false
      } else {
        return false
      }
    } else if (this.disableDates === "past") {
      if (currentDate > selectedDate) {
        return true
      } else if (currentDate === selectedDate) {
        return false
      } else {
        return false
      }
    } else {
      return false
    }
  }

  public cancel() {
    this.selectedStartMonth = null
    this.selectedEndMonth = null
    this.showDatepickerOutput.emit(false)
    this.datepickerShown = false
    this.initializeDates()
  }

  public submit() {
    if (this.isDateRange) this.monthSelected.emit([moment(this.selectedStartMonth).format("YYYY-MM"), moment(this.selectedEndMonth).format("YYYY-MM")])
    else this.monthSelected.emit([moment(this.selectedStartMonth).format("YYYY-MM")])
    this.datepickerShown = false
  }
}
