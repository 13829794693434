<div class="dialog-container">
  <!-- <div>
    <ag-button-v2 type="tertiary" specificWidth="83px" [imagePath]="iconService.Icon.cross_1" (click)="closeDialog()"></ag-button-v2>
  </div> -->
  <h1>{{ dialogTitle }}</h1>
  <ul class="dialog-content">
    <li *ngFor="let doorUnlockCase of doorUnlockCases">
      <p>
        Device: {{ doorUnlockCase.DeviceId }}
        <br />
        Manufacture: {{ doorUnlockCase.Manufacture }}
        <br />
        DeviceModel: {{ doorUnlockCase.DeviceModel }}
        <br />
        OSVersion: {{ doorUnlockCase.OSVersion }}
        <br />
        OS: {{ doorUnlockCase.OS }}
        <br />
        AppVersion: {{ doorUnlockCase.AppVersion }}
      </p>
      <ul class="month-items">
        <li *ngFor="let doorUnlockSession of doorUnlockCase.DoorUnlockSessions">{{ doorUnlockSession.Timestamp | date : "dd-MM-yyyy HH:mm:ss" }}: {{ doorUnlockSession.EventType }}</li>
      </ul>
    </li>
  </ul>
  <div>
    <ag-button-v2 [label]="dialogOKButtonName" type="primary" specificWidth="83px" (click)="closeDialog()"></ag-button-v2>
  </div>
</div>
