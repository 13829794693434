import { Component, OnInit, AfterViewInit, Input, EventEmitter, Output } from "@angular/core"
import { Subject } from "rxjs"
import { CheckInService } from "app/dashboard/checkin/checkin.service"
declare var ApexCharts: any

@Component({
  selector: "app-donut-graph",
  templateUrl: "./donut-graph.component.html",
  styleUrls: ["./donut-graph.component.scss"],
})
export class DonutGraphComponent implements OnInit, AfterViewInit {
  TodayNonDigitalCheckIns
  TodayDigitalCheckIns
  TodayAllCheckIns

  TodayReservations
  TodayCancellations
  YesterdayReservations
  YesterdayCancellations

  TodayData
  YesterdayData
  LastweekData

  TodayDataCategories
  YesterdayDataCategories
  LastweekDataCategories

  TodaySeries = []
  YesterdaySeries = []
  LastweekSeries = []

  TodayLabels = []
  YesterdayLabels = []
  LastweekLabels = []

  TodayGrapsLabels = []
  YesterdayGrapsLabels = []
  LastweekGrapsLabels = []

  chart

  constructor(public checkinService: CheckInService) {
    this.checkinService.getCheckInEnabled().subscribe((value) => {
      this._targetEnabled = value
    })
    this.checkinService.getCheckInTarget().subscribe((value) => {
      this._targetValueToday = value
      if (this._graphMode === "today") {
        this._targetValue = value
      }
    })
    this.checkinService.getTargetEmployeeWinnerToday().subscribe((value) => {
      this._targetEmployeeWinnerToday = value
      if (this._graphMode === "today") this._targetEmployeeWinner = value
    })
    this.checkinService.getTargetEmployeeWinnerYesterday().subscribe((value) => {
      this._targetEmployeeWinnerYesterday = value
    })
    this.checkinService.getTargetEmployeeWinnerLastweek().subscribe((value) => {
      this._targetEmployeeWinnerLastweek = value
    })
    this.checkinService.getTargetIncomeToday().subscribe((value) => {
      this._targetIncomeToday = value
    })
    this.checkinService.getTargetIncomeYesterday().subscribe((value) => {
      this._targetIncomeYesterday = value
    })
    this.checkinService.getTargetIncomeLastweek().subscribe((value) => {
      this._targetIncomeLastweek = value
    })
  }

  @Output()
  updateGraphMode = new EventEmitter<any>()

  private unsubscribe = new Subject()

  @Input() type: string
  @Input() chartType: string

  ngOnInit(): void {}
  ngAfterViewInit(): void {}

  isReady = false
  colors: string[] = ["#E4BE5E", "#61566e", "#08a4c7", "#578a6b", "#d7263c", "#abaaad"]

  getLabels(): any[] {
    switch (this._graphMode) {
      case "today":
        return this.TodayLabels
      case "yesterday":
        return this.YesterdayLabels
      case "last week":
        return this.LastweekLabels
    }
  }

  _donutValue
  _donutTotal
  _targetEmployeeWinner = "AeroGuest"
  _targetEmployeeWinnerToday = "AeroGuest"
  _targetEmployeeWinnerYesterday = "AeroGuest"
  _targetEmployeeWinnerLastweek = "AeroGuest"
  _targetIncome = 0
  _targetIncomeToday = 0
  _targetIncomeYesterday = 0
  _targetIncomeLastweek = 0
  _targetValue = 0
  _targetValueToday = 0
  _targetValueYesterday = 0
  _targetEnabled = false

  _graphMode = "today"
  TodayNoData = false
  YesterdayNoData = false
  LastweekNoData = false

  @Input() set data(d: any) {
    if (d === undefined) return
    if (d[0] !== undefined) {
      //// Is today data
      this.TodayData = d[0].Data
      this.TodayDataCategories = d[0].Categories

      this.TodayData.forEach((item, index) => {
        const dataToPush = item.data.reduce((a, b) => a + b, 0)
        if (item.name === "All") {
          this.TodayAllCheckIns = dataToPush
        } else {
          this.TodaySeries.push(dataToPush)
          this.TodayGrapsLabels.push(item.name)
          const label = { name: item.name, color: this.colors[index], value: dataToPush }
          this.TodayLabels.push(label)
        }
      })

      this.TodayReservations = d[0].Reservations
      this.TodayCancellations = d[0].Cancellations
      this.TodayNonDigitalCheckIns = this.TodayAllCheckIns - this.TodaySeries.reduce((a, b) => a + b, 0)
      this.TodayDigitalCheckIns = this.TodayReservations - this.TodayCancellations - this.TodaySeries.reduce((a, b) => a + b, 0)
      this.TodaySeries.push(this.TodayDigitalCheckIns)
      this.TodayGrapsLabels.push("Not checked in")
      if (this.TodaySeries.reduce((a, b) => a + b, 0) === 0) {
        // Check if values are 0:
        this.TodayNoData = true
        this.TodaySeries.pop()
        this.TodaySeries.push(1)
      }

      this.graph.colors = this.colors
      this.graph.series = this.TodaySeries
      this.graph.labels = this.TodayGrapsLabels
      if (this.TodayNoData) {
        this.graph.tooltip.enabled = false
        this._donutValue = 0
        this._donutTotal = 0
      } else {
        this.graph.tooltip.enabled = true
        this._donutValue = this.TodaySeries.reduce((a, b) => a + b, 0) - this.TodayDigitalCheckIns
        this._donutTotal = this.TodayReservations - this.TodayCancellations
      }

      this.checkinService.setTotalCheckInsToday(this._donutValue)

      this.chart = new ApexCharts(document.querySelector("#responsive-chart"), this.graph)
      this.chart.render()
      this.setTodayShared()
    } else if (d[1] !== undefined && d[2] !== undefined) {
      this.YesterdayData = d[1].Data.filter(function (obj) {
        return obj.name !== "All"
      })

      this.YesterdayDataCategories = d[1].Categories

      this.YesterdayData.forEach((item, index) => {
        // let dataToPush = item.data.length > 0 ? item.data[0] : 0;
        const dataToPush = item.data.reduce((a, b) => a + b, 0)
        this.YesterdaySeries.push(dataToPush)
        this.YesterdayGrapsLabels.push(item.name)
        const label = { name: item.name, color: this.colors[index], value: dataToPush }
        this.YesterdayLabels.push(label)
      })

      if (this.YesterdaySeries.reduce((a, b) => a + b, 0) === 0) {
        // Check if values are 0:
        this.YesterdayNoData = true
        this.YesterdaySeries.push(1)
      }

      this.checkinService.setTotalCheckInsYesterday(this.YesterdaySeries.reduce((a, b) => a + b, 0))

      this.YesterdayReservations = d[1].Reservations
      this.YesterdayCancellations = d[1].Cancellations

      //// Is last week data
      this.LastweekData = d[2].Data.filter(function (obj) {
        return obj.name !== "All"
      })
      this.LastweekDataCategories = d[2].Categories

      this.LastweekData.forEach((item, index) => {
        // let dataToPush = item.data.length > 0 ? item.data[0] : 0;
        const dataToPush = item.data.reduce((a, b) => a + b, 0)
        this.LastweekSeries.push(dataToPush)
        this.LastweekGrapsLabels.push(item.name)
        const label = { name: item.name, color: this.colors[index], value: dataToPush }
        this.LastweekLabels.push(label)
      })

      if (this.LastweekSeries.reduce((a, b) => a + b, 0) === 0) {
        // Check if values are 0:
        this.LastweekNoData = true
        this.LastweekSeries.push(1)
      }

      this.checkinService.setTotalCheckInsLastweek(this.LastweekSeries.reduce((a, b) => a + b, 0))

      this._targetValueYesterday = this.checkinService.YesterdayCheckInTarget
    }
  }

  setTodayShared() {
    this._targetValue = this._targetValueToday
    this._targetEmployeeWinner = this._targetEmployeeWinnerToday
    this._targetIncome = this._targetIncomeToday
  }
  setToday() {
    this.setTodayShared()

    if (this.TodayNoData) {
      this.graph.tooltip.enabled = false
      this._donutValue = 0
      this._donutTotal = 0
    } else {
      this.graph.tooltip.enabled = true
      this._donutValue = this.TodaySeries.reduce((a, b) => a + b, 0) - this.TodayDigitalCheckIns
      this._donutTotal = this.TodayReservations - this.TodayCancellations
    }

    this.graph.series = this.TodaySeries
    this.graph.labels = this.TodayGrapsLabels

    this.chart.updateSeries(this.graph.series)
    this.chart.updateOptions(this.graph)
  }

  setYesterday() {
    this._targetValue = this._targetValueYesterday
    this._targetEmployeeWinner = this._targetEmployeeWinnerYesterday
    this._targetIncome = this._targetIncomeYesterday

    if (this.YesterdayNoData) {
      this.graph.tooltip.enabled = false
      this._donutValue = 0
      this._donutTotal = 0
    } else {
      this.graph.tooltip.enabled = true
      this._donutValue = this.YesterdaySeries.reduce((a, b) => a + b, 0)
      this._donutTotal = this.YesterdayReservations - this.YesterdayCancellations
    }

    this.graph.series = this.YesterdaySeries
    this.graph.labels = this.YesterdayGrapsLabels

    this.chart.updateSeries(this.graph.series)
    this.chart.updateOptions(this.graph)
  }

  setLastweek() {
    this._targetEmployeeWinner = this._targetEmployeeWinnerLastweek
    this._targetIncome = this._targetIncomeLastweek

    if (this.LastweekNoData) {
      this.graph.tooltip.enabled = false
      this._donutValue = 0
    } else {
      this.graph.tooltip.enabled = true
      this._donutValue = this.LastweekSeries.reduce((a, b) => a + b, 0)
    }

    this.graph.series = this.LastweekSeries
    this.graph.labels = this.LastweekGrapsLabels

    this.chart.updateSeries(this.graph.series)
    this.chart.updateOptions(this.graph)
  }

  graph = {
    chart: {
      type: "donut",
      height: "65%",
      width: "130px",
    },
    tooltip: {
      enabled: true,
    },
    labels: [],
    series: [],
    legend: {
      show: false,
    },
    colors: [],
    dataLabels: { enabled: false },
    fill: { width: 0 },
    stroke: { width: 0 },
    animations: {
      enabled: true,
      easing: "easeinout",
      speed: 800,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "85%",
          labels: {
            show: false,
          },
        },
      },
    },
  }

  todayTapped() {
    if (this._graphMode !== "today") {
      this.setToday()
      this._graphMode = "today"
      this.updateGraphMode.emit("today")
    }
  }

  yesterdayTapped() {
    if (this._graphMode !== "yesterday") {
      this.setYesterday()
      this._graphMode = "yesterday"
      this.updateGraphMode.emit("yesterday")
    }
  }

  lastweekTapped() {
    if (this._graphMode !== "last week") {
      this.setLastweek()
      this._graphMode = "last week"
      this.updateGraphMode.emit("last week")
    }
  }
}
