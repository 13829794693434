<div class="counter-wrapper">
  <div class="counter" [ngClass]="isInputFocused ? 'focused' : ''" [ngStyle]="{ width: specificWidth + 'px', height: specificHeight + 'px' }">
    <p (click)="edit()">
      <input #inputField class="input" [style.width.px]="getInputWidth()" type="number" [(ngModel)]="value" [min]="minimumNumber" [max]="maximumNumber" (change)="validateValue()" (focus)="onFocus()" (blur)="onBlur()" />
      {{ label }}
    </p>
    <div class="button-wrapper">
      <div class="up-button" [ngClass]="maximumNumber ? (value === maximumNumber ? 'disabled' : '') : ''" (click)="upClicked()">
        <img width="12" height="12" [src]="iconService.Icon.up_1_5" />
      </div>
      <div class="down-button" [ngClass]="minimumNumber ? (value === minimumNumber ? 'disabled' : '') : ''" (click)="downClicked()">
        <img width="12" height="12" [src]="iconService.Icon.down_1_5" />
      </div>
    </div>
  </div>
  <div class="info-container" *ngIf="info?.showInfo">
    <img class="info-img" [src]="iconService.Icon.i_letter_1_5" *ngIf="info.type === 'info'" />
    <img class="error-img" [src]="iconService.Icon.cross_1_5" *ngIf="info.type === 'error'" />
    <div class="info-text" [ngStyle]="{ color: info.type === 'info' ? '#222222' : '#E55E5E' }">
      {{ info.text }}
    </div>
  </div>
</div>
