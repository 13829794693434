import { Component, EventEmitter, Input, OnInit, Output, HostListener } from "@angular/core"
import { DisableDates, Placement } from "../ag-date-picker.component"
import { IconImageService } from "@shared/services/icon-image.service"
import { DatePipe } from "@angular/common"
import * as moment from "moment"

@Component({
  selector: "app-date-regular-box",
  templateUrl: "./date-regular-box.component.html",
  styleUrl: "./date-regular-box.component.scss",
})
export class DateRegularBoxComponent implements OnInit {
  selectedMonthYear: Date = new Date()
  weekdays: string[] = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]
  weeks: Date[][] = []
  selectedStartDate: Date | null = null
  selectedEndDate: Date | null = null
  label: any
  currentDate = "today"
  insideClick: boolean
  changedDate = false

  @Input() startDate?: Date = null
  @Input() endDate?: Date = null
  @Input() placement: Placement //if you want to change the left/right position just give the datepicker a parent div & change its width
  @Input() isDateRange?: boolean = false
  @Input() disableDates: DisableDates = "none"
  @Input() applyButton?: boolean = true
  @Input() maxDateRangeSelectable: number = 14
  @Input() datepickerShown = false

  @Output() showDatepickerOutput: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() dateRangeSelected: EventEmitter<Date[]> = new EventEmitter<Date[]>()

  @HostListener("document:click", ["$event"])
  clickedOutside() {
    if (this.insideClick === false) {
      this.datepickerShown = false
      this.showDatepickerOutput.emit(false)
    } else this.insideClick = false
  }

  constructor(public iconService: IconImageService, private datePipe: DatePipe) {}

  ngOnInit() {
    this.initializeDates()
    this.generateCalendar()
  }

  public previousMonth() {
    this.selectedMonthYear.setMonth(this.selectedMonthYear.getMonth() - 1)
    this.generateCalendar()
  }

  public nextMonth() {
    this.selectedMonthYear.setMonth(this.selectedMonthYear.getMonth() + 1)
    this.generateCalendar()
  }

  public selectToday() {
    if (this.currentDate !== "today") {
      const date = new Date()
      if (this.isDateRange) {
        const tomorrow = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0, 0)
        this.selectedEndDate = tomorrow
      }
      const today = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
      this.currentDate = "today"
      this.selectedStartDate = today
    }
  }

  public todayOrTomorrow(): boolean {
    if (moment(this.selectedStartDate).format("YYYY MMMM DD") === moment(new Date()).format("YYYY MMMM DD")) {
      this.currentDate = "today"
      this.changedDate = true
    } else if (moment(this.selectedStartDate).format("YYYY MMMM DD") === moment(new Date()).add(1, "days").format("YYYY MMMM DD")) {
      this.currentDate = "tomorrow"
      this.changedDate = true
    } else this.currentDate = "neither"
    return false
  }

  public selectTomorrow() {
    if (this.currentDate !== "tomorrow") {
      const date = new Date()
      if (this.isDateRange) {
        const tomorrowtomorrow = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 2, 0, 0, 0, 0)
        this.selectedEndDate = tomorrowtomorrow
      }
      const tomorrow = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0, 0)
      this.currentDate = "tomorrow"
      this.selectedStartDate = tomorrow
    }
  }

  private generateCalendar() {
    const year = this.selectedMonthYear.getFullYear()
    const month = this.selectedMonthYear.getMonth()
    const firstDay = new Date(year, month, 1)
    const lastDay = new Date(year, month + 1, 0)
    const startDate = new Date(firstDay)

    this.label = moment(this.selectedMonthYear).format("MMMM YYYY")
    const firstDayOfWeek = (startDate.getDay() + 6) % 7

    startDate.setDate(startDate.getDate() - firstDayOfWeek)

    this.weeks = []

    while (startDate <= lastDay) {
      const week: Date[] = []
      for (let i = 0; i < 7; i++) {
        week.push(new Date(startDate))
        startDate.setDate(startDate.getDate() + 1)
      }
      this.weeks.push(week)
    }
  }

  public inWeek(week: Date[]): boolean {
    var value = false
    week.forEach((date) => {
      var x = this.isDateSelected(date)
      var y = this.isLastDate(date)
      var z = this.isFirstDate(date)
      x ? (value = true) : ""
      y ? (value = true) : ""
      z ? (value = true) : ""
    })
    return value
  }

  public isDateSelected(date: Date): boolean {
    if (date !== this.selectedStartDate && date !== this.selectedEndDate && this.isDateRange) {
      return this.selectedStartDate && this.selectedEndDate && date > this.selectedStartDate && date < this.selectedEndDate
    }
  }

  public isFirstDate(isDate: Date): boolean {
    if (this.selectedStartDate !== null && this.selectedStartDate !== undefined) {
      var newStartDate = moment(new Date(this.selectedStartDate.getFullYear(), this.selectedStartDate.getMonth(), this.selectedStartDate.getDate(), 0, 0, 0, 0)).format("DD MMM YYYY")
      var date = moment(isDate).format("DD MMM YYYY")
      return date === newStartDate
    }
  }

  public isLastDate(isDate: Date): boolean {
    if (this.selectedEndDate !== null && this.selectedEndDate !== undefined) {
      var newEndDate = moment(new Date(this.selectedEndDate.getFullYear(), this.selectedEndDate.getMonth(), this.selectedEndDate.getDate(), 0, 0, 0, 0)).format("DD MMM YYYY")
      var date = moment(isDate).format("DD MMM YYYY")
      return date === newEndDate
    }
  }

  public toggleWeekSelection(mon: Date, sun: Date) {
    if (this.isDateRange) {
      this.toggleDateSelection(mon)
      this.toggleDateSelection(sun)
    }
  }

  public toggleDateSelection(date: Date) {
    if (this.isDateDisabled(date) || !this.isInSpecificMonth(date)) {
      return
    } else {
      this.changedDate = true
      if (this.isDateRange) {
        if (this.selectedEndDate !== null) {
          this.selectedStartDate = date
          this.selectedEndDate = null
        } else if (date < this.selectedStartDate) {
          this.selectedStartDate = date
          this.selectedEndDate = null
        } else if (date >= this.selectedStartDate) {
          this.selectedEndDate = date
        }
      } else {
        this.selectedStartDate = date
      }

      if (!this.applyButton) this.submit()
    }
  }

  private isDateDisabled(day: Date): boolean {
    const newDate = new Date()
    const currentDate = new Date(this.datePipe.transform(newDate, "yyyy-MM-dd"))
    const selectedDate = new Date(this.datePipe.transform(day, "yyyy-MM-dd"))
    if (this.disableDates === "future") {
      if (currentDate < selectedDate) {
        return true
      } else if (currentDate === selectedDate) {
        return false
      } else {
        return false
      }
    } else if (this.disableDates === "past") {
      if (currentDate > selectedDate) {
        return true
      } else if (currentDate === selectedDate) {
        return false
      } else {
        return false
      }
    } else {
      return false
    }
  }

  public calculateWeekNumber(date: Date): number {
    const startDate = new Date(date.getFullYear(), 0, 1)
    const days = Math.floor((date.getTime() - startDate.getTime()) / (24 * 3600 * 1000))
    const weekNumber = Math.ceil((days + startDate.getDay() + 1) / 7)
    return weekNumber
  }

  public isBeforeCurrentDate(day: Date): boolean {
    if (this.disableDates === "none") {
      return true
    } else {
      if (this.disableDates === "past") {
        const newDate = new Date()
        const currentDate = new Date(this.datePipe.transform(newDate, "yyyy-MM-dd"))
        const selectedDate = new Date(this.datePipe.transform(day, "yyyy-MM-dd"))
        if (currentDate < selectedDate) {
          return true
        } else if (currentDate.getTime() === selectedDate.getTime()) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }

  public isAfterCurrentDate(day: Date): boolean {
    if (this.disableDates === "none") {
      return true
    } else {
      if (this.disableDates === "future") {
        const newDate = new Date()
        const currentDate = new Date(this.datePipe.transform(newDate, "yyyy-MM-dd"))
        const selectedDate = new Date(this.datePipe.transform(day, "yyyy-MM-dd"))
        if (currentDate > selectedDate) {
          return true
        } else if (currentDate.getTime() === selectedDate.getTime()) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }

  public isInSpecificMonth(date: Date): boolean {
    var targetMonth = this.selectedMonthYear.getMonth()
    var targetYear = this.selectedMonthYear.getFullYear()
    const dateMonth = date.getMonth()
    const dateYear = date.getFullYear()
    return dateMonth === targetMonth && dateYear === targetYear
  }

  public return() {
    this.selectedStartDate = null
    this.selectedEndDate = null
    this.showDatepickerOutput.emit(false)
    this.datepickerShown = false
    this.initializeDates()
  }

  private initializeDates() {
    if (this.startDate !== null) {
      var newStartDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate(), 0, 0, 0, 0)
    }
    if (this.endDate !== null) {
      var newEndDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate(), 0, 0, 0, 0)
    }
    if (this.isDateRange) {
      this.selectedEndDate = newEndDate
      this.selectedStartDate = newStartDate
    }
    if (!this.isDateRange && this.startDate !== null) {
      this.selectedStartDate = newStartDate
    }
    if (!this.isDateRange && this.startDate == null) {
      const date = new Date()
      this.selectedStartDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
    }
  }

  public newDates(): boolean {
    var retVal = false
    if (this.isDateRange) {
      if (moment(this.selectedStartDate).format("YYYY MMMM DD") === moment(this.startDate).format("YYYY MMMM DD") && moment(this.selectedEndDate).format("YYYY MMMM DD") === moment(this.endDate).format("YYYY MMMM DD")) retVal = true
    } else {
      if (moment(this.selectedStartDate).format("YYYY MMMM DD") === moment(this.startDate).format("YYYY MMMM DD")) retVal = true
    }
    return retVal
  }

  public submit() {
    this.changedDate = true
    if (this.isDateRange) this.dateRangeSelected.emit([this.selectedStartDate, this.selectedEndDate])
    else this.dateRangeSelected.emit([this.selectedStartDate])
    this.datepickerShown = false
  }
}
