import { Component, OnInit, Input, HostListener, AfterViewInit, ElementRef, ViewChild, Renderer2, EventEmitter, Output } from "@angular/core"

@Component({
  selector: "app-quickaction",
  templateUrl: "./quick-action.component.html",
  styleUrls: ["./quick-action.component.scss"],
})
export class QuickActionComponent implements AfterViewInit {
  @ViewChild("quickaction", { static: true }) quickAction: ElementRef
  @ViewChild("arrow", { static: true }) arrow: ElementRef
  @Input() actions: any[]
  @Input() actionState: string
  @Input() ref: any
  @Input() position: string
  @Input() color: string
  @Output() change: EventEmitter<string> = new EventEmitter<string>()

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    const targetRef = this.ref.nativeElement
    const quickActionRef = this.quickAction.nativeElement
    const arrowRef = this.arrow.nativeElement

    // Set background color
    this.renderer.setStyle(quickActionRef, "background-color", this.color)

    // Position the quickAction
    const targetRect = targetRef.getBoundingClientRect()
    switch (this.position) {
      case "left":
        this.renderer.setStyle(quickActionRef, "left", `${targetRect.left + window.pageXOffset - quickActionRef.offsetWidth - 20}px`)
        this.renderer.setStyle(quickActionRef, "top", `${targetRect.top + window.pageYOffset - 7.5}px`)

        this.renderer.setStyle(arrowRef, "left", `${targetRect.left + window.pageXOffset - 20}px`)
        this.renderer.setStyle(arrowRef, "top", `${targetRect.top + window.pageYOffset + 15}px`)
        this.renderer.setStyle(arrowRef, "border-left-color", this.color)
        break
      case "right":
        this.renderer.setStyle(quickActionRef, "left", `${targetRect.left + window.pageXOffset + targetRef.offsetWidth + 20}px`)
        this.renderer.setStyle(quickActionRef, "top", `${targetRect.top + window.pageYOffset - 30}px`)

        this.renderer.setStyle(arrowRef, "left", `${targetRect.left + window.pageXOffset + targetRef.offsetWidth + 10}px`)
        this.renderer.setStyle(arrowRef, "top", `${targetRect.top + window.pageYOffset + targetRef.offsetHeight / 2 - 10}px`)
        this.renderer.setStyle(arrowRef, "border-right-color", this.color)
        break
      case "top":
        break
      case "bottom":
        break
    }
  }

  @HostListener("window:resize")
  onWindowResize(): void {
    this.change.emit(undefined)
  }
  @HostListener("mouseleave")
  mouseOver(): void {
    this.change.emit(undefined)
  }

  actionClicked(action) {
    switch (action.action) {
      case "ResendSMS":
        this.change.emit("ResendSMS")
        break
      case "ResendEmail":
        this.change.emit("ResendEmail")
        break
      case "AddPhone":
        this.change.emit("AddPhone")
        break
      case "AddEmail":
        this.change.emit("AddEmail")
        break
      case "EditPhone":
        this.change.emit("EditPhone")
        break
      case "EditImage":
        this.change.emit("EditImage")
        break
      case "PreviewImage":
        this.change.emit("PreviewImage")
        break
      case "KeyCard":
        this.change.emit("KeyCard")
        break
      case "EditBlackPhone":
        this.change.emit("EditBlackPhone")
        break
      case "DeleteBlackPhone":
        this.change.emit("DeleteBlackPhone")
        break
      case "EditBlackEmail":
        this.change.emit("EditBlackEmail")
        break
      case "DeleteBlackEmail":
        this.change.emit("DeleteBlackEmail")
        break
      case "RemoveJourney":
        this.change.emit("RemoveJourney")
        break
      case "HandlePayment":
        this.change.emit("HandlePayment")
        break
      case null:
        this.change.emit(undefined)
        break
      case undefined:
        this.change.emit(undefined)
        break
    }
  }
  getArrowClass(): string {
    switch (this.position) {
      case "left":
        return "right"
      case "right":
        return "left"
      case "bottom":
        return "top"
      case "top":
        return "bottom"
    }
  }
}
