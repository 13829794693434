<div class="toast-container">
  <div *ngIf="alert" class="{{ cssClass() }} toast-wrapper" [ngClass]="{ 'toast-active': alert }">
    <div class="header-container">
      <img class="{{ iconClass() }}" height="24px" width="24px" [src]="getToastIcon()" />
      <div class="toast-title" [ngStyle]="{ color: getTitleColor() }">
        {{ alert?.title }}
      </div>
      <div class="close-button-container" (click)="clearToast()">
        <img class="{{ iconClass() }}" height="16px" width="16px" [src]="iconService.Icon.cross" style="height: 16px; width: 16px" />
      </div>
    </div>
    <div class="body-container" *ngIf="alert.message || alert.undo">
      <div class="toast-message-container">
        {{ alert?.message }}
        <a *ngFor="let link of alert?.links" routerLink="{{ link?.link }}">{{ link?.title }}</a>
      </div>
      <div class="undo-container" *ngIf="alert?.undo" (click)="undoApi()">
        <div class="p-semibold" style="text-decoration: underline">Undo</div>
        <img [src]="iconService.Icon.undo" style="height: 16px; width: 16px" />
      </div>
    </div>
  </div>
</div>
