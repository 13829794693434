import { Component, OnInit, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms"
import { ToastService } from "app/toast/toast.service"
import { AgFormValidation } from "@shared/helpers/ag-form-validation"
import { ItemForm, SpecialDeliveryFee } from "@shared/models/point-of-sale/room-service-forms"
import { v4 as uuidv4 } from "uuid"

@Component({
  selector: "app-special-delivery-fee-dialog",
  templateUrl: "./special-delivery-fee-dialog.component.html",
  styleUrls: ["./special-delivery-fee-dialog.component.scss"],
})
export class SpecialDeliveryFeeDialogComponent implements OnInit, AgFormValidation {
  public form: UntypedFormGroup

  /* Parameters */
  public formIsValid: boolean
  public fee: SpecialDeliveryFee
  public items: string[]
  public chosenItems: string[]
  private itemsToFilter: string[]
  private itemsToSave: ItemForm[]
  private filter: string = ""
  public prerequisites: string = "Missing prerequisites"

  constructor(
    public toastService: ToastService,
    public dialogRef: MatDialogRef<SpecialDeliveryFeeDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { itemsList: ItemForm[]; feeToEdit: SpecialDeliveryFee; isEditing: boolean; currency: string },
  ) {
    this.form = this.fb.group({
      Name: [null, Validators.required],
      Enabled: [false],
      Value: [null, [Validators.required]],
      RemoveDefaultFee: [false],
      ApplyFeePerItem: [false],
      Items: [null],
      Search: [],
    })
    this.items = []
    this.itemsToFilter = []
    this.chosenItems = []
    this.itemsToSave = []

    this.onSearchChange()
  }

  ngOnInit(): void {
    if (this.data?.isEditing) {
      this.fee = this.data?.feeToEdit
      this.form.patchValue(this.fee)
      this.form.get("Items").patchValue("")
      this.chosenItems = [] //reset chosen items
      for (let feeItem of this.fee.Items) {
        for (let dataItem of this.data.itemsList) {
          if (dataItem.Header === feeItem.Header) {
            this.chosenItems = this.chosenItems.concat(feeItem.Header)
          }
        }
      }
    } else {
      this.fee = <SpecialDeliveryFee>{
        FeeId: uuidv4(),
        Enabled: true,
      }
    }
    this.createItemsArraysFromItemsList()
  }

  public async submit() {
    if (AgFormValidation.validate(this.form)) {
      this.writeInformationToContainerObject(this.fee)
      this.dialogRef.close(this.fee)
      if (this.data.isEditing) {
        this.toastService.success({ message: `Edited fee ${this.fee.Name}` })
      } else {
        this.toastService.success({ message: `Created fee ${this.fee.Name}` })
      }
    }
  }

  private writeInformationToContainerObject(fee: SpecialDeliveryFee): void {
    fee.Name = this.form.get("Name")?.value
    fee.Value = this.form.get("Value")?.value
    fee.RemoveDefaultFee = this.form.get("RemoveDefaultFee")?.value
    fee.ApplyFeePerItem = this.form.get("ApplyFeePerItem")?.value
    fee.Items = this.saveChosenItems()
  }

  public onSearchChange(): void {
    this.form.valueChanges.subscribe((_) => {
      if (this.form.controls.Search.value != null && this.form.controls.Search.value != undefined) {
        this.filter = this.form.controls.Search.value
      }

      this.items = this.filterArray()
      this.formIsValid = this.validForm()
      this.Prerequisites()

      //if a new item is chosen, and isn't already on the list, add it.
      if (!!this.form.controls.Items.value && !this.chosenItems.includes(this.form.controls.Items.value.toString())) {
        this.chosenItems = this.chosenItems.concat(this.form.controls.Items.value)
      }
    })
  }

  public createItemsArraysFromItemsList(): void {
    for (let item of this.data.itemsList) {
      this.items = this.items.concat(item.Header)
    }
    this.itemsToFilter = this.items
  }

  public filterArray(): string[] {
    return this.itemsToFilter.filter((option) => option.toLowerCase().includes(this.filter))
  }

  public unchooseItem(item: string) {
    const index: number = this.chosenItems.indexOf(item)
    if (index !== -1) {
      this.chosenItems.splice(index, 1)
    }
  }

  public saveChosenItems(): ItemForm[] {
    for (let items of this.chosenItems) {
      for (let item of this.data.itemsList) {
        if (items === item.Header) {
          this.itemsToSave.push(item)
        }
      }
    }
    return this.itemsToSave
  }

  public validForm(): boolean {
    if (AgFormValidation.validate(this.form)) {
      return true
    }
    return false
  }

  public Prerequisites() {
    if (this.form.get("Name")?.value === null || this.form.get("Name")?.value === undefined || this.form.get("Name")?.value === "") {
      this.prerequisites = "Missing name"
    } else if (this.form.get("Value")?.value === null || this.form.get("Value")?.value === undefined || this.form.get("Value")?.value === "") {
      this.prerequisites = "Missing amount"
    } else {
      this.prerequisites = ""
    }
  }
}
