import { Pipe, PipeTransform } from "@angular/core"
@Pipe({
  name: "filterCountryCodes",
})
export class FilterCountryCodes implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return []
    if (!searchText) return items
    searchText = searchText.toLowerCase()
    return items.filter((it) => {
      return it.Name.toLowerCase().includes(searchText) || it.NativeName.toLowerCase().includes(searchText) || it.CallingCodes[0].toLowerCase().includes(searchText)
    })
  }
}
