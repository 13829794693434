<div style="height: fit-content; max-height: 100%; width: 100%; position: relative" [hidden]="!showBox ? showBoxClicked() : false">
  <div [ngClass]="!useFixedPosition ? 'container-standard' : 'container-fixed'">
    <div class="form-div" *ngIf="showSearchField">
      <div class="search-bar">
        <input class="search-bar-input" #inputField placeholder="Search" (input)="onInputChange(inputField.value)" />
        <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/magnifier.svg" class="form-img" />
      </div>
    </div>
    <div class="line"></div>
    <div class="top-bottom-padding" [ngStyle]="{ 'max-height': maxHeight - 43 + 'px' }">
      <div [hidden]="list.length < 1">
        <label class="header" [hidden]="isTyping" *ngIf="showHeaders">ALPHABETICAL</label>
        <div class="spacer" *ngIf="showHeaders"></div>
        <div class="items-container">
          <div *ngFor="let item of displayedList">
            <div class="hovering-item">
              <div class="item" (click)="onItemClicked(item)">
                <ng-container *ngIf="checkSelectedItems(item) && checkboxType === 'square' && !isItemDisabled(item.label)">
                  <img class="img" [src]="iconService.Icon.checkbox_tick" />
                </ng-container>
                <ng-container *ngIf="!checkSelectedItems(item) && checkboxType === 'square' && !isItemDisabled(item.label)">
                  <img class="img" [src]="iconService.Icon.checkbox_grey_empty" />
                </ng-container>
                <ng-container *ngIf="!checkSelectedItems(item) && checkboxType === 'square' && isItemDisabled(item.label)">
                  <img class="img" [src]="iconService.Icon.checkbox_disabled" />
                </ng-container>
                <ng-container *ngIf="checkSelectedItems(item) && checkboxType === 'circle' && !isItemDisabled(item.label)">
                  <img class="img" [src]="iconService.Icon.round_checkbox_grey_tick" />
                </ng-container>
                <ng-container *ngIf="!checkSelectedItems(item) && checkboxType === 'circle' && !isItemDisabled(item.label)">
                  <img class="img" [src]="iconService.Icon.round_checkbox_grey_empty" />
                </ng-container>
                <ng-container *ngIf="!checkSelectedItems(item) && checkboxType === 'circle' && isItemDisabled(item.label)">
                  <img class="img" [src]="iconService.Icon.round_checkbox_grey_disabled" />
                </ng-container>
                <label [ngClass]="item?.label === currentValue?.label ? 'item-text-bold' : 'item-text'" [ngStyle]="{ color: isItemDisabled(item.label) ? '#A6A6A6' : '#222222' }" class="disable-selection">
                  {{ item.label }}
                </label>
              </div>
            </div>
          </div>
          <div class="no-result-text" *ngIf="displayedList.length === 0">No results</div>
        </div>
      </div>
    </div>
  </div>
</div>
