import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, SimpleChanges } from "@angular/core"
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms"
import { IconImageService } from "@shared/services/icon-image.service"

@Component({
  selector: "app-search-box-v3",
  template: `
    <div style="height: fit-content; max-height: 100%; width: 100%; position: relative;" [hidden]="!showBox ? showBoxClicked() : false">
      <div [ngClass]="!useFixedPosition ? 'container-standard' : 'container-fixed'">
        <div class="form-div" *ngIf="showSearchField">
          <input class="form-text" #inputField placeholder="Search" (input)="onInputChange(inputField.value)" />
          <img width="20" height="20" [src]="iconService.Icon.magnifier" class="form-img" />
          <div class="line"></div>
        </div>
        <div *ngIf="multipleOutput" class="select-all-container">
          <button [ngClass]="selectedSelectAll ? 'not-clickable' : 'clickable'" [disabled]="disableSelectAll" (click)="onSelectAllClicked()">Select all</button>
          <button [ngClass]="selectedDeselectAll ? 'not-clickable' : 'clickable'" [disabled]="disableDeselectAll" (click)="onDeselectAllClicked()">Deselect all</button>
        </div>
        <div class="top-bottom-padding" [ngStyle]="{ 'max-height': maxHeight - 43 + 'px' }">
          <div [hidden]="frequentList.length < 1 || isTyping">
            <label class="header" *ngIf="showHeaders">FREQUENT</label>
            <div class="spacer" *ngIf="showHeaders"></div>
            <div class="items-container">
              <div *ngFor="let item of frequentList">
                <div class="item" (click)="onItemClicked(item)">
                  <ng-container *ngIf="!multipleOutput">
                    <label [ngClass]="item === currentValue ? 'item-text-bold' : 'item-text'" class="disable-selection">
                      {{ item.label }}
                    </label>
                  </ng-container>
                  <ng-container *ngIf="multipleOutput">
                    <label class="item-text disable-selection">
                      <ng-container *ngIf="currentValueMultiple.includes(item); else UnCheckedBox">
                        <img style="padding-right: 12px; pointer-events: none;" [src]="iconService.Icon.checkbox_tick" />
                      </ng-container>
                      <ng-template #UnCheckedBox>
                        <img style="padding-right: 12px; pointer-events: none;" [src]="iconService.Icon.checkbox_empty" />
                      </ng-template>
                      {{ item.label }}
                    </label>
                  </ng-container>
                </div>
              </div>
              <div class="no-result-text" *ngIf="displayedAlphabeticalList.length === 0">No results</div>
            </div>
          </div>
          <div [hidden]="frequentList.length < 1 || isTyping" class="spacer"></div>
          <div [hidden]="alphabeticalList.length < 1">
            <label class="header" [hidden]="isTyping" *ngIf="showHeaders">ALPHABETICAL</label>
            <div class="spacer" *ngIf="showHeaders"></div>
            <div class="items-container">
              <div *ngFor="let item of displayedAlphabeticalList">
                <div class="item" (click)="onItemClicked(item)">
                  <ng-container *ngIf="!multipleOutput && hasIcons">
                    <label [ngClass]="item?.label === currentValue?.label ? 'item-text-bold' : 'item-text'" class="disable-selection">
                      <img src="{{ item.value }}" />
                      <ng-container *ngIf="!noIconText">{{ item.label }}</ng-container>
                    </label>
                  </ng-container>
                  <ng-container *ngIf="!multipleOutput && !hasIcons">
                    <label [ngClass]="item?.label === currentValue?.label ? 'item-text-bold' : 'item-text'" class="disable-selection">
                      {{ item.label }}
                    </label>
                  </ng-container>
                  <ng-container *ngIf="multipleOutput">
                    <label class="item-text disable-selection">
                      <ng-container *ngIf="checkSelectedItems(item); else UnCheckedBox">
                        <img style="padding-right: 12px; pointer-events: none;" [src]="iconService.Icon.checkbox_tick" />
                      </ng-container>
                      <ng-template #UnCheckedBox>
                        <img style="padding-right: 12px; pointer-events: none;" [src]="iconService.Icon.checkbox_empty" />
                      </ng-template>
                      {{ item.label }}
                    </label>
                  </ng-container>
                </div>
              </div>
              <div class="no-result-text" *ngIf="displayedAlphabeticalList.length === 0">No results</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
                       .additional-charges-form {
                         background-color: #ffffff;
                         width: 95%;
                         height: 45px;
                         padding-inline-start: 8px;
                         padding-top: 3px;
                         font-family: "Poppins-Regular";
                         font-size: 14px;
                         font-weight: normal;
                         font-stretch: normal;
                         font-style: normal;
                         line-height: normal;
                         letter-spacing: normal;
                         color: #333333;
                         overflow: hidden;
                         text-overflow: ellipsis;
                         white-space: nowrap;
                         padding-right: 24px;
                         padding-left: 15px;
                         margin-bottom: 0;
                         border-radius: 16px;
                         display: flex;
                         align-items: center;
                       }
                       .disable-selection {
                         -webkit-touch-callout: none;
                         -webkit-user-select: none;
                         -khtml-user-select: none;
                         -moz-user-select: none;
                         -ms-user-select: none;
                         user-select: none;
                       }
                       .container-standard {
                         padding-left: 0px;
                         position: inherit;
                         z-index: 10;
                         max-height: 100%;
                         height: fit-content;
                         width: 100%;
                         min-width: 82px;
                         border-radius: 16px;
                         box-shadow: 0 3px 8px 0 rgba(140, 140, 140, 0.5);
                         border: solid 1px #dfdfdf;
                         background-color: #ffffff;
                       }
                       .container-fixed {
                         padding-left: 0px;
                         padding-right: 8px;
                         position: fixed;
                         z-index: 1010;
                         max-height: 100%;
                         height: fit-content;
                         border-radius: 8px;
                         box-shadow: 0 3px 8px 0 rgba(140, 140, 140, 0.5);
                         border: solid 1px #dfdfdf;
                         background-color: #ffffff;
                       }
                       .header {
                         padding-left: 16px;
                         font-family: Lato;
                         font-size: 14px;
                         font-weight: bold;
                         font-stretch: normal;
                         font-style: normal;
                         line-height: normal;
                         letter-spacing: normal;
                         color: #8f8e95;
                       }
                       .items-container {
                         padding-left: 8px;
                         padding-right: 8px;
                       }
                       .item {
                         width: 100%;
                         height: 33px;
                         border-radius: 20px;
                         background-color: #ffffff;
                         cursor: pointer;
                         position: relative;
                       }
                       .item:hover {
                         background-color: #eaeaea;
                       }
                       .item-text {
                         position: absolute;
                         left: 8px;
                         top: 8px;
                         vertical-align: sub;
                         font-family: "Poppins-Regular";
                         font-style: normal;
                         font-weight: 400;
                         font-size: 12px;
                         line-height: 18px;
                         letter-spacing: normal;
                         color: #333333;
                         overflow: hidden;
                         text-overflow: ellipsis;
                         white-space: nowrap;
                         max-width: calc(100% - 12px);
                         cursor: pointer;
                       }
                       .item-text-bold {
                         position: absolute;
                         left: 8px;
                         top: 8px;
                         vertical-align: sub;
                         font-family: "Poppins-Regular";
                         font-size: 12px;
                         font-style: normal;
                         font-weight: 400;
                         font-size: 12px;
                         line-height: 18px;
                         letter-spacing: normal;
                         color: #a6a6a6;
                         overflow: hidden;
                         text-overflow: ellipsis;
                         white-space: nowrap;
                         max-width: calc(100% - 12px);
                         cursor: pointer;
                       }
                 
                       .select-all-container {
                         display: flex;
                         flex-direction: row;
                         justify-content: center;
                         align-items: center;
                         gap: 84px;
                       }
                 
                       .clickable {
                         background: none;
                         border: none;
                         padding: 0;
                         font-family: "Poppins-Regular";
                         font-style: normal;
                         font-weight: 500;
                         font-size: 14px;
                         line-height: 24px;
                         cursor: pointer;
                         color: #4f356e;
                         text-decoration: underline;
                       }
                 
                       .not-clickable {
                         background: none;
                         border: none;
                         padding: 0;
                         font-family: "Poppins-Regular";
                         font-style: normal;
                         font-weight: 500;
                         font-size: 14px;
                         line-height: 24px;
                         cursor: pointer;
                         color: #a6a6a6;
                         text-decoration: underline;
                       }
                 
                       .spacer {
                         padding-bottom: 8px;
                       }
                       .top-bottom-padding {
                         margin-bottom: 5px;
                         padding-top: 6px;
                         padding-bottom: 16px;
                         height: fit-content;
                         display: block;
                         overflow-y: auto;
                         overflow-x: hidden;
                       }
                       .form-div {
                         position: relavtive;
                         width: 95%;
                         padding-left: 12px;
                       }
                       .form-img {
                         position: absolute;
                         left: 20px;
                         top: 14px;
                       }
                 
                       .code-changeable-img {
                         position: absolute;
                         right: 8px;
                         top: 15px;
                         transform: rotate(-180deg);
                       }
                       .form-text {
                         border: 0px;
                         width: 100%;
                         height: 38px;
                         font-family: "Poppins-Regular";
                         font-size: 14px;
                         font-weight: normal;
                         font-stretch: normal;
                         font-style: normal;
                         line-height: normal;
                         letter-spacing: normal;
                         padding-inline-start: 46px;
                         overflow: hidden;
                         text-overflow: ellipsis;
                       }
                 
                       .no-result-text {
                         color: #a6a6a6;
                         text-align: center;
                         font-weight: 400;
                         font-size: 12px;
                         line-height: 16px;
                         font-family: "Poppins-Regular";
                       }
                 
                       input:focus {
                         outline: none;
                       }
                       .line {
                         width: 100%;
                         height: 1px;
                         background-color: #c8c7cc;
                       }
                       ::-webkit-scrollbar {
                         width: 5px;
                       }
                       ::-webkit-scrollbar-thumb {
                         background: #d8d8d8;
                         border-radius: 3px;
                         padding: 16px 13px;
                       }
                       .selected-select-all {
                       }
                     `,
  ],
})
export class SearchBoxV3Component implements OnInit {
  @Input() frequentList: LabelValue[] = []
  @Input() alphabeticalList: LabelValue[] = []
  @Input() showBox = false
  @Input() showSearchField = true
  @Input() useFixedPosition = false
  @Input() showHeaders: boolean = false
  @Input() multipleOutput = false
  @Input() maxHeight: number = 1000
  @Input() currentValue: LabelValue
  @Input() currentValueMultiple: LabelValue[] = []
  @Input() hasIcons: boolean = false
  @Input() noIconText: boolean = false
  @Input() hint = "" //This can only be used if you don't use currentValue or currentValueMultiple

  @Output() output = new EventEmitter<LabelValue>()
  @Output() outputMultiple = new EventEmitter<LabelValue[]>()
  @Output() searchBoxFieldClicked = new EventEmitter<boolean>()

  @ViewChild("inputField", { static: false }) inputField: ElementRef
  @ViewChild("arrowImage") arrowImage

  public selectedSelectAll = false
  public selectedDeselectAll = false
  public displayedAlphabeticalList: LabelValue[] = []
  public searchForm: UntypedFormGroup
  public isTyping = false

  public disableSelectAll = true
  public disableDeselectAll = false

  constructor(private fb: UntypedFormBuilder, public iconService: IconImageService) {}

  ngOnInit() {
    this.searchForm = this.fb.group({
      searchInput: [""],
    })
    this.displayedAlphabeticalList = this.alphabeticalList
    if (this.multipleOutput) {
      this.selectedSelectAll = true
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentValueMultiple !== undefined) {
      changes.currentValueMultiple.currentValue.length === this.alphabeticalList.length ? (this.selectedSelectAll = true) : (this.selectedSelectAll = false)
    }
  }

  onInputChange(value: string) {
    if (value === "") {
      this.isTyping = false
      this.displayedAlphabeticalList = this.alphabeticalList
    } else {
      this.isTyping = true
      this.displayedAlphabeticalList = this.alphabeticalList.filter((x) => x.label.toLowerCase().includes(value.toLowerCase()))
    }
  }

  showBoxClicked() {
    if (!this.isTyping || (this.inputField && this.inputField.nativeElement && this.inputField.nativeElement.value === "")) {
      this.displayedAlphabeticalList = this.alphabeticalList
    }
    return true
  }

  onSelectAllClicked() {
    this.currentValueMultiple = this.alphabeticalList
    this.outputMultiple.emit(this.currentValueMultiple)
    this.disableSelectAll = true
    this.disableDeselectAll = false
    this.selectedSelectAll = true
    this.selectedDeselectAll = false
  }

  onDeselectAllClicked() {
    this.currentValueMultiple = []
    this.outputMultiple.emit(this.currentValueMultiple)
    this.disableDeselectAll = true
    this.disableSelectAll = false
    this.selectedDeselectAll = true
    this.selectedSelectAll = false
  }

  onItemClicked(value: LabelValue) {
    if (this.multipleOutput) {
      if (this.currentValueMultiple.includes(value)) {
        this.currentValueMultiple = this.currentValueMultiple.filter((item) => item !== value)
        this.disableSelectAll = false
        this.disableDeselectAll = false
        this.selectedSelectAll = false
        this.selectedDeselectAll = false
      } else {
        this.currentValueMultiple.push(value)
        this.disableSelectAll = false
        this.disableDeselectAll = false
        this.selectedSelectAll = false
        this.selectedDeselectAll = false
      }
      this.currentValueMultiple.length === this.displayedAlphabeticalList.length ? (this.selectedSelectAll = true) : (this.selectedSelectAll = false)
      this.outputMultiple.emit(this.currentValueMultiple)
    } else {
      this.currentValue = value
      this.output.emit(value)
      this.showBox = false
    }
  }

  onFieldClicked() {
    this.showBox = false
    this.searchBoxFieldClicked.emit(true)
  }

  public checkSelectedItems(item: LabelValue): boolean {
    if (this.currentValueMultiple.find((selected) => selected.label === item.label)) {
      return true
    }
    return false
  }
}

export interface LabelValue {
  label: string
  value: any
}
