import { Injectable } from "@angular/core"
import { API_HOTEL, API_HOTEL_V2, API_PAYMENTV1, API_PMS } from "app/app-settings"
import { ChooseRoomPricingForm } from "app/shared/models/choose-room/choose-room-pricing-form"
import { ApiService } from "app/shared/services/api.service"
import { AuthenticationService } from "app/shared/services/authentication.service"
import { flatMap } from "rxjs/operators"
import { UpSellKeyValue } from "./choose-room.component"

@Injectable()
export class ChooseRoomService {
  constructor(private apiService: ApiService, private authService: AuthenticationService) {}

  public getHotel() {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.authService.fetchHotel(hotel.Id)))
  }

  // public updateHotel() {
  //     return this.authService.getHotelPreference().pipe(flatmap(hotel) => this.apiService.post(`${API_HOTEL_V2}Hotel`, ))
  // }

  public getRoomTypes() {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.get(`${API_HOTEL_V2}RoomType/Hotel/${hotel.Id}`)))
  }

  public getSite() {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.get(`${API_PAYMENTV1}Site/Hotel/${hotel.Id}`)))
  }

  public updateAutoChargeChooseRoom(id: string, autoChargeChooseRoom: boolean, UpsellTransactionCodes: UpSellKeyValue) {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.post(`${API_PAYMENTV1}Site`, { Id: id, AutoChargeChooseRoom: autoChargeChooseRoom, UpsellTransactionCodes })))
  }

  public getRoomStatus() {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.get(`${API_HOTEL}RoomStatus/RoomFeature?hotelId=${hotel.Id}`)))
  }

  public getChooseRoomPricings() {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.get(`${API_HOTEL}RoomStatus/ChooseRoom/${hotel.Id}`)))
  }

  public setChooseRoomPricings(chooseRoomPricings: any) {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.put(`${API_HOTEL}RoomStatus/ChooseRoom/${hotel.Id}`, { ChooseRoomPricings: chooseRoomPricings })))
  }

  public setStandardPrice(hotelId: any, price: any) {
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) =>
        this.apiService.post(`${API_HOTEL_V2}/Hotel`, {
          Id: hotelId,
          // OrganizationId: organizationId,
          ChooseRoomAmount: price,
        })
      )
    )
  }

  // public getChooseRoom() {

  //     return this.authService.getHotelPreference()
  //     .pipe(flatMap (hotel => this.apiService.get(`${API_HOTEL}`)))
  // }
}
