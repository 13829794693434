import { Injectable } from "@angular/core"
import { TemplateResponse } from "@api/communication/models/template"

@Injectable({
  providedIn: "root",
})
export class TemplateService {
  private template: TemplateResponse = null
  private isNewTemplate: boolean = false
  private duplicateCall: boolean = false

  constructor() {}

  public setTemplate(value: TemplateResponse) {
    this.template = value
  }
  public resetTemplate() {
    this.template = null
  }

  getTemplate() {
    return this.template
  }

  public setIsNewTemplate(value: boolean) {
    this.isNewTemplate = value
  }
  public getIsNewTemplate(): boolean {
    return this.isNewTemplate
  }
  public setDuplicateCall(value: boolean) {
    this.duplicateCall = value
  }
  public getDuplicateCall(): boolean {
    return this.duplicateCall
  }
}
