import { Component, Input, Output, EventEmitter } from "@angular/core"
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexPlotOptions, ApexYAxis, ApexXAxis, ApexFill, ApexTooltip, ApexStroke, ApexLegend, ApexGrid, ApexStates, ApexMarkers } from "ng-apexcharts"
import { Options } from "@angular-slider/ngx-slider"

declare var ApexCharts: any

@Component({
  selector: "app-dashboard-graph2",
  templateUrl: "./graph2.component.html",
  styleUrls: ["./graph2.component.scss"],
})
export class Graph2Component {
  constructor() {}
  isReady = false

  randomNumber = Math.floor(Math.random() * 998877665544).toString()
  @Input() chartType: string

  CurrentPeriodicity = "Daily"
  HourlySeries
  DailySeries
  MonthlySeries
  HourlySeriesCategories
  DailySeriesCategories
  MonthlySeriesCategories

  sliderValue = 7
  sliderOptions: Options = {
    showTicks: true,
    stepsArray: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 8 }, { value: 9 }, { value: 10 }, { value: 11 }, { value: 12 }, { value: 13 }, { value: 14 }],
  }

  optionsMain: Partial<ChartOptions> = {
    chart: {
      id: "chartMain" + this.randomNumber,
      type: "bar",
      height: 230,
      width: "96%",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      parentHeightOffset: 0,
      stacked: true,
      events: {
        zoomed: (chartContext, { xaxis, yaxis }) => {
          this.optionsNavigator.chart.selection.xaxis.min = xaxis.min
          this.optionsNavigator.chart.selection.xaxis.max = xaxis.max

          const chart = new ApexCharts(document.querySelector("#chart1" + this.randomNumber), this.optionsNavigator)

          ApexCharts.exec(
            "chart1" + this.randomNumber,
            "updateOptions",
            {
              chart: {
                selection: {
                  xaxis: {
                    min: xaxis.min,
                    max: xaxis.max,
                  },
                },
              },
            },
            false,
            true,
          )
        },
      },
      selection: {
        enabled: true,
        xaxis: {
          min: new Date("19 Jun 2018").getTime(),
          max: new Date("14 Dec 2019").getTime(),
        },
      },
      brush: {
        autoScaleYaxis: true,
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        format: "dd MMM HH:mm",
      },
      y: {
        title: {
          formatter: (seriesName) => {
            return seriesName
          },
        },
      },
    },
    colors: [],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
    },
    fill: {
      opacity: 1,
      type: "solid",
    },
    markers: {
      size: 0,
    },
    series: [
      {
        name: "",
        data: [],
        type: "column",
      },
    ],
    xaxis: {
      type: "datetime",
      categories: [],
      labels: {
        show: true,
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM",
          day: "dd MMM",
          hour: "dd MMM HH:mm",
        },
      },
      min: 0,
      max: 0,
      axisBorder: {
        show: false,
      },
    },
    yaxis: [
      {
        max: 100,
        decimalsInFloat: 0,
        forceNiceScale: true,
        labels: {
          show: true,
        },
        opposite: false,
        title: {
          text: "",
          style: {
            fontWeight: 400,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "90%",
      },
    },
    legend: {
      labels: {
        useSeriesColors: true,
      },
    },
  }

  optionsNavigator: Partial<ChartOptions> = {
    chart: {
      id: "chartNavigator" + this.randomNumber,
      height: 130,
      width: "96%",
      type: "bar",
      parentHeightOffset: 0,
      brush: {
        target: "chartMain" + this.randomNumber,
        enabled: true,
      },
      animations: {
        enabled: false,
      },
      events: {
        selection: (chartContext, { xaxis, yaxis }) => {
          this.optionsMain.xaxis.min = xaxis.min

          let minXpos = chartContext.opts.xaxis.categories.reduce(function (prev, curr) {
            return Math.abs(curr - xaxis.min) < Math.abs(prev - xaxis.min) ? curr : prev
          })

          let maxXpos = chartContext.opts.xaxis.categories.reduce(function (prev, curr) {
            return Math.abs(curr - xaxis.max) < Math.abs(prev - xaxis.max) ? curr : prev
          })

          minXpos = this.optionsMain.xaxis.categories.indexOf(minXpos)
          maxXpos = chartContext.opts.xaxis.categories.indexOf(maxXpos)

          let selArr = []
          for (let i = 0; i < this.optionsMain.series[0].data.length; i++) {
            let value = 0
            for (let a = 0; a < this.optionsMain.series.length; a++) {
              value += this.optionsMain.series[a].data[i] as number
            }
            selArr.push(value)
          }
          selArr = selArr.slice(minXpos, maxXpos + 1)

          if (selArr.length > 0) {
            const setYaxis = this.generateYaxis(this.optionsMain.series, selArr, yaxis, minXpos, maxXpos)
            this.optionsMain.yaxis = setYaxis
          }
          this.optionsMain.xaxis.min = xaxis.min
          this.optionsMain.xaxis.max = xaxis.max

          this.chartXAxisUpdated.emit([xaxis.min, xaxis.max])
        },
      },
      selection: {
        enabled: true,
        xaxis: {
          min: 0,
          max: 0,
        },
      },
    },
    colors: [],
    series: [
      {
        data: [],
      },
    ],
    fill: {
      opacity: 1,
      type: "solid",
    },
    grid: {
      padding: {
        left: 50,
      },
    },
    stroke: {
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "90%",
      },
    },
    xaxis: {
      labels: {
        show: true,
        offsetX: 50,
      },
      type: "datetime",
      categories: [],
      tooltip: {
        enabled: false,
      },
      min: undefined,
      max: undefined,
    },
    yaxis: [
      {
        labels: {
          show: false,
        },
      },
    ],
  }

  @Output() chartXAxisUpdated = new EventEmitter<any>()

  @Input() set chartData(data: any) {
    this.setChartType()

    if (data[0] !== undefined) {
      // Daily
      data[0].Data = data[0].Data.filter(function (obj) {
        return obj.name !== "All"
      })

      let minDate = new Date()
      if (data[0].Categories[0]) minDate = new Date(data[0].Categories[0])
      minDate.setDate(1)

      const maxDate = new Date()
      maxDate.setDate(maxDate.getDate() + 1)

      this.optionsNavigator.xaxis.min = minDate.getTime()
      this.optionsNavigator.xaxis.max = maxDate.getTime()
      this.optionsNavigator.chart.selection.xaxis.max = maxDate.getTime()
      this.optionsMain.chart.selection.xaxis.max = maxDate.getTime()

      this.DailySeriesCategories = data[0].Categories
      this.DailySeries = data[0].Data

      let initMin = new Date().setMonth(new Date().getMonth() - 3)
      if (minDate.getTime() < initMin) {
        this.optionsNavigator.chart.selection.xaxis.min = initMin
        this.optionsMain.chart.selection.xaxis.min = initMin
      } else {
        this.optionsNavigator.chart.selection.xaxis.min = minDate.getTime()
        this.optionsMain.chart.selection.xaxis.min = minDate.getTime()
        initMin = minDate.getTime()
      }

      this.optionsNavigator.xaxis.categories = this.DailySeriesCategories
      this.optionsMain.xaxis.categories = this.DailySeriesCategories
      this.optionsNavigator.series = this.DailySeries
      this.optionsMain.series = this.DailySeries

      this.chartXAxisUpdated.emit([initMin, maxDate.getTime()])

      if (this.chartType === "locksAssigned") {
        this.DailySeries.forEach((element) => {
          element.type = "area"
        })
      } else if (this.chartType === "upselling") {
        this.DailySeries.forEach((element) => {
          if (element.name === "Revenue") {
            element.type = "line"
          } else {
            element.type = "column"
          }
        })
      } else {
        this.DailySeries.forEach((element) => {
          element.type = "column"
        })
      }

      this.isReady = true
    }

    if (data[1] !== undefined) {
      // Hourly
      data[1].Data = data[1].Data.filter(function (obj) {
        return obj.name !== "All"
      })

      this.HourlySeriesCategories = data[1].Categories
      this.HourlySeries = data[1].Data

      if (this.chartType === "locksAssigned") {
        this.HourlySeries.forEach((element) => {
          element.type = "area"
        })
      } else if (this.chartType === "upselling") {
        this.HourlySeries.forEach((element) => {
          if (element.name === "Revenue") {
            element.type = "line"
          } else {
            element.type = "column"
          }
        })
      }
    }

    if (data[2] !== undefined) {
      // Monthly
      data[2].Data = data[2].Data.filter(function (obj) {
        return obj.name !== "All"
      })

      this.MonthlySeriesCategories = data[2].Categories
      this.MonthlySeries = data[2].Data

      if (this.chartType === "locksAssigned") {
        this.MonthlySeries.forEach((element) => {
          element.type = "area"
        })
      } else if (this.chartType === "upselling") {
        this.MonthlySeries.forEach((element) => {
          if (element.name === "Revenue") {
            element.type = "line"
          } else {
            element.type = "column"
          }
        })
      }
    }
  }

  setChartType() {
    switch (this.chartType) {
      case "checkIn":
        this.optionsMain.colors = ["#D7263C", "#578A6B", "#08a4c7", "#5B5072", "#E4BE5E", "#DD8706"]
        return
      case "upselling":
        this.optionsMain.colors = ["#5B5072", "#E4BE5E", "#D7263C", "#578A6B"]
        return
      case "payment":
        this.optionsMain.colors = ["#5b5072", "#578a6b", "#000000"]
        this.optionsNavigator.colors = ["#5b5072", "#578a6b", "#000000"]
        return
      case "loyalty-newMember":
        this.optionsMain.colors = ["#5B5072", "#E4BE5E", "#333333", "#D7263C", "#578A6B", "#DD8706", "#08A4C7", "#C2C2C2"]
        return
      case "loyalty-income":
        this.optionsMain.colors = ["#5B5072", "#E4BE5E", "#333333", "#D7263C", "#578A6B", "#DD8706", "#08A4C7", "#C2C2C2"]
        return
      case "loyalty-stampIssued":
        this.optionsMain.colors = ["#5B5072", "#E4BE5E", "#333333", "#D7263C", "#578A6B", "#DD8706", "#08A4C7", "#C2C2C2"]
        return
      case "loyalty-stampcardUsed":
        this.optionsMain.colors = ["#5B5072", "#E4BE5E", "#333333", "#D7263C", "#578A6B", "#DD8706", "#08A4C7", "#C2C2C2"]
        return
      case "locksAssigned":
        this.optionsNavigator.chart.type = "area"
        this.optionsMain.chart.type = "area"
        this.optionsMain.chart.stacked = false
        return
      case "upselling":
        this.optionsMain.chart.type = "line"
        this.optionsMain.stroke.width = [0, 0, 0, 2]
        this.optionsMain.yaxis = [
          { title: { text: "Actions", style: { fontWeight: 400 } }, max: 100, decimalsInFloat: 0, forceNiceScale: true, labels: { show: true }, opposite: false },
          { title: { text: "", style: { fontWeight: 400 } }, max: 100, decimalsInFloat: 0, forceNiceScale: true, labels: { show: false }, opposite: false },
          { title: { text: "", style: { fontWeight: 400 } }, max: 100, decimalsInFloat: 0, forceNiceScale: true, labels: { show: false }, opposite: false },
          { opposite: true, title: { text: "Revenue", style: { fontWeight: 400 } }, max: 100, decimalsInFloat: 0, forceNiceScale: true, labels: { show: true } },
        ]
        return
    }
  }

  onUpdateData(periodicity: string) {
    if (this.CurrentPeriodicity === periodicity) return
    switch (periodicity) {
      case "Hourly":
        this.setDataSeries(periodicity)
        break
      case "Daily":
        this.setDataSeries(periodicity)
        break
      case "Monthly":
        this.setDataSeries(periodicity)
        break
    }
    this.CurrentPeriodicity = periodicity
  }

  setDataSeries(periodicity: string) {
    let dat
    let datCat

    switch (periodicity) {
      case "Hourly":
        dat = this.HourlySeries
        datCat = this.HourlySeriesCategories
        break
      case "Daily":
        dat = this.DailySeries
        datCat = this.DailySeriesCategories
        break
      case "Monthly":
        dat = this.MonthlySeries
        datCat = this.MonthlySeriesCategories
        break
    }

    const chartNavigator = ApexCharts.getChartByID("chartNavigator" + this.randomNumber)
    const chartMain = ApexCharts.getChartByID("chartMain" + this.randomNumber)

    const min = chartMain.grid.w.globals.xAxisScale.niceMin
    const max = chartMain.grid.w.globals.xAxisScale.niceMax

    this.optionsNavigator.xaxis.categories = datCat
    this.optionsNavigator.series = dat
    this.optionsMain.xaxis.categories = datCat
    this.optionsMain.series = dat

    this.optionsNavigator.chart.selection.xaxis.min = min
    this.optionsNavigator.chart.selection.xaxis.max = max

    const chart2Xaxis = {
      type: "datetime",
      categories: datCat,
      labels: {
        show: true,
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM",
          day: "dd MMM",
          hour: "dd MMM hh:mm",
        },
      },
      min: min,
      max: max,
      axisBorder: {
        show: false,
      },
    }
    this.optionsMain.xaxis = chart2Xaxis as ApexXAxis

    if (this.optionsNavigator.xaxis.categories.length > 0) {
      let minXpos = this.optionsNavigator.xaxis.categories.reduce(function (prev, curr) {
        return Math.abs(curr - min) < Math.abs(prev - min) ? curr : prev
      })
      let maxXpos = this.optionsNavigator.xaxis.categories.reduce(function (prev, curr) {
        return Math.abs(curr - max) < Math.abs(prev - max) ? curr : prev
      })
      minXpos = this.optionsNavigator.xaxis.categories.indexOf(minXpos)
      maxXpos = this.optionsNavigator.xaxis.categories.indexOf(maxXpos)

      let selArr = []
      for (let i = 0; i < this.optionsMain.series[0].data.length; i++) {
        let value = 0
        for (let a = 0; a < this.optionsMain.series.length; a++) {
          value += this.optionsMain.series[a].data[i] as number
        }
        selArr.push(value)
      }
      selArr = selArr.slice(minXpos, maxXpos + 1)
      if (selArr.length > 0) {
        const chart2Yaxis = this.generateYaxis(this.optionsMain.series, selArr, chartNavigator.opts.yaxis, min, max)
        this.optionsMain.yaxis = chart2Yaxis
        this.optionsMain.xaxis = chart2Xaxis as ApexXAxis
      }
      this.optionsMain.xaxis = chart2Xaxis as ApexXAxis
    }

    this.chartXAxisUpdated.emit([min, max])
  }

  generateYaxis(series: any, selArr: any, yaxis: any, minXpos, maxXpos) {
    let maxYaxe = (yaxis ?? { max: 100 }).max
    maxYaxe = Math.max(Math.max.apply(Math, selArr))

    let forceNiceScale = true
    if (this.chartType === "locksAssigned") {
      maxYaxe = 100
      this.optionsMain.yaxis[0].forceNiceScale = false
      forceNiceScale = false
    }

    const setYaxis = []
    setYaxis[0] = this.setYAxis("", maxYaxe, forceNiceScale, false, true)

    if (this.chartType === "upselling") {
      const incomeArray = series[3].data.slice(minXpos, maxXpos + 1)

      setYaxis[0].title = { text: "Actions" }
      setYaxis[1] = this.setYAxis("", maxYaxe, true, false, false)
      setYaxis[2] = this.setYAxis("", maxYaxe, true, false, false)
      setYaxis[3] = this.setYAxis("Revenue", Math.max(Math.max.apply(Math, incomeArray)), true, true, true)
    }
    return setYaxis
  }

  setYAxis(text: string, max: number, forceNiceScale: boolean, opposite: boolean, labelsShow: boolean) {
    return {
      title: { text: text },
      min: 0,
      max: max,
      forceNiceScale: forceNiceScale,
      decimalsInFloat: 0,
      opposite: opposite,
      labels: {
        show: labelsShow,
        formatter: function (val) {
          let decimals
          if (Math.floor(val.valueOf()) === val.valueOf()) {
            decimals = 0
          } else {
            decimals = val.toString().split(".")[1].length || 0
          }
          switch (decimals) {
            case 0:
              return val.toFixed(0)
            case 1:
              return val.toFixed(1)
            default:
              return val.toFixed(2)
          }
        },
      },
    }
  }

  sliderOnValueChange(): void {
    switch (this.sliderValue) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        this.setDataSeries("Monthly")
        break
      case 6:
      case 7:
      case 8:
      case 9:
        this.setDataSeries("Daily")
        break
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
        this.setDataSeries("Hourly")
        break
    }
  }
}

interface ChartOptions {
  series: ApexAxisChartSeries
  chart: ApexChart
  dataLabels: ApexDataLabels
  plotOptions: ApexPlotOptions
  yaxis: ApexYAxis[]
  xaxis: ApexXAxis
  fill: ApexFill
  tooltip: ApexTooltip
  stroke: ApexStroke
  legend: ApexLegend
  grid: ApexGrid
  states: ApexStates
  colors: string[]
  markers: ApexMarkers
}
