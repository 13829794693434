import { Component, Input } from "@angular/core"
import { trigger, state, style, transition, animate } from "@angular/animations"

@Component({
  selector: "app-global-loader",
  template: `
    <div class="center-loader">
      <div *ngIf="isLoading" class="loader bigger-font"></div>
    </div>
    <div [hidden]="isLoading" [@dropDown]="isLoading" class="content-height">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      .center-loader {
        display: flex;
        justify-content: center;
      }
      .bigger-font {
        font-size: 20px !important;
      }
      .content-height {
        margin-top: -40px;
      }
    `,
  ],
  animations: [
    // trigger('dropDown', [
    //     state('true', style({ height: '0px', opacity: 0 })),
    //     state('false', style({ height: '*', opacity: 1 })),
    //     transition('true<=>false', animate('250ms ease-out'))
    //   ])
    trigger("dropDown", [state("true", style({ transform: "translateY(0px)", "z-index": 0, opacity: 0 })), state("false", style({ transform: "translateY(40px)", "z-index": 1000, opacity: 1 })), transition("true=>false", animate("250ms ease-out"))]),
  ],
})
export class GlobalLoaderComponent {
  @Input() isLoading: boolean = false

  constructor() {}
}
