import { Component, EventEmitter, Input, Output } from "@angular/core"

@Component({
  selector: "ag-button-slider-component",
  template: `
    <label class="switch">
      <input type="checkbox" (change)="toggleCheckBox($event)" value="{{ CheckBoxValue }}" [checked]="CheckBoxValue" name="vatEnabledToggle" />
      <span class="slider round"><div class="line"></div></span>
    </label>
  `,
  styles: [
    `
                       .switch {
                         position: relative;
                         display: inline-block;
                         width: 50px;
                         height: 30px;
                         margin-right: 30px;
                       }
                 
                       .switch input {
                         opacity: 0;
                         width: 0;
                         height: 0;
                       }
                 
                       .slider {
                         position: absolute;
                         cursor: pointer;
                         top: 0;
                         left: 0;
                         right: 0;
                         bottom: 0;
                         background-color: #f1f1f1;
                         -webkit-transition: 0.4s;
                         transition: 0.4s;
                       }
                 
                       .slider:before {
                         position: absolute;
                         content: "";
                         height: 22px;
                         width: 22px;
                         left: 4px;
                         bottom: 4px;
                         box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
                         background-color: white;
                         -webkit-transition: 0.4s;
                         transition: 0.4s;
                       }
                 
                       input:checked + .slider {
                         background-color: #61566e;
                       }
                 
                       .line {
                         position: absolute;
                         left: 28%;
                         right: 68%;
                         top: 36.67%;
                         bottom: 36.67%;
                         height: 8px;
                         width: 2px;
                         border-radius: 1px;
                         background-color: #61566e;
                         -webkit-transition: 0.4s;
                         transition: 0.4s;
                       }
                 
                       input:checked + .slider .line {
                         background-color: #333333;
                         -webkit-transform: translateX(20px);
                         -ms-transform: translateX(20px);
                         transform: translateX(20px);
                       }
                 
                       input:checked + .slider.round.disabled {
                         background-color: #f1f1f1;
                       }
                 
                       input:focus + .slider {
                         box-shadow: 0 0 1px #61566e;
                       }
                 
                       input:checked + .slider:before {
                         -webkit-transform: translateX(20px);
                         -ms-transform: translateX(20px);
                         transform: translateX(20px);
                       }
                 
                       /* Rounded sliders */
                       .slider.round {
                         border-radius: 12px;
                       }
                 
                       .slider.round.disabled {
                         cursor: not-allowed;
                       }
                 
                       .slider.round.disabled:before {
                         cursor: not-allowed;
                         background-color: #f1f1f1;
                       }
                 
                       .slider.round:before {
                         border-radius: 9px;
                       }
                 
                       .outerSection {
                         display: flex;
                         flex-direction: column;
                         padding-right: 20px;
                       }
                 
                       .toggleTitle {
                         padding-right: 10px;
                         margin-bottom: 10px;
                         font-size: small;
                         align-items: center;
                       }
                 
                       .vatInput {
                         position: relative;
                       }
                 
                       .errorVatMessage {
                         position: relative;
                         margin-top: 5px;
                         font-size: 10px;
                         font-family: "Poppins-Regular";
                         font-style: normal;
                         font-weight: 400;
                         font-size: 10px;
                         color: red;
                       }
                 
                       /* Chrome, Safari, Edge, Opera */
                       input::-webkit-outer-spin-button,
                       input::-webkit-inner-spin-button {
                         -webkit-appearance: none;
                         margin: 0;
                       }
                 
                       /* Firefox */
                       input[type="number"] {
                         -moz-appearance: textfield;
                       }
                     `,
  ],
})
export class AgButtonSliderComponent {
  @Output() public toggleCheck: EventEmitter<boolean> = new EventEmitter<boolean>()

  @Input() CheckBoxValue = false

  constructor() {}

  toggleCheckBox(event: any) {
    this.toggleCheck.emit(event.target.checked)
  }
}
