import { HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { API_HOTEL, API_HOTEL_V2, API_HOTEL_V3, API_KEY, API_PAYMENTV1, API_PAYMENTV4, API_PAYMENTV5, API_USER_V2, VG_HOTEL, VG_HOTEL_V2 } from "app/app-settings"
import { JourneyGuest, Room, RoomType } from "app/shared/models"
import { Keys } from "app/shared/models/keys"
import { User } from "app/shared/models/user"
import { ApiService } from "app/shared/services/api.service"
import { AuthenticationService } from "app/shared/services/authentication.service"
import { BehaviorSubject, Observable } from "rxjs"
import { finalize, flatMap, tap } from "rxjs/operators"

@Injectable()
export class JourneyDetailService {
  pristine = true
  pristineContent = true
  isLoading = new BehaviorSubject<boolean>(false)

  constructor(private apiService: ApiService, private authService: AuthenticationService) {}

  fetchPassportVerification(userId: string, bookingId: string): Observable<any> {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.get(`${VG_HOTEL}RegistrationCard/user/${userId}/booking/${bookingId}/images?verificationType=Passport`)))
  }

  fetchDriversLicenseVerification(userId: string, bookingId: string): Observable<any> {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.get(`${VG_HOTEL}RegistrationCard/user/${userId}/booking/${bookingId}/images?verificationType=DriversLicense`)))
  }

  fetchDriversLicenseBackVerification(userId: string, bookingId: string): Observable<any> {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.get(`${VG_HOTEL}RegistrationCard/user/${userId}/booking/${bookingId}/images?verificationType=DriversLicenseBack`)))
  }

  fetchCovidPassVerification(userId: string, bookingId: string): Observable<any> {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.get(`${VG_HOTEL}RegistrationCard/user/${userId}/booking/${bookingId}/images?verificationType=CovidPass`)))
  }

  fetchOtherVerification(userId: string, bookingId: string): Observable<any> {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.get(`${VG_HOTEL}RegistrationCard/user/${userId}/booking/${bookingId}/images?verificationType=Other`)))
  }

  //New versions of fetch with new keys
  fetchPassportVerificationV2(hotelId: string, PMSBookingNumber: string, ReservationNumber: string): Observable<any> {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.get(`${VG_HOTEL_V2}RegistrationCard/hotel/${hotelId}/pms/${PMSBookingNumber}/reservation/${ReservationNumber}/images?verificationType=Passport`)))
  }

  fetchDriversLicenseVerificationV2(hotelId: string, PMSBookingNumber: string, ReservationNumber: string): Observable<any> {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.get(`${VG_HOTEL_V2}RegistrationCard/hotel/${hotelId}/pms/${PMSBookingNumber}/reservation/${ReservationNumber}/images?verificationType=DriversLicense`)))
  }

  fetchDriversLicenseBackVerificationV2(hotelId: string, PMSBookingNumber: string, ReservationNumber: string): Observable<any> {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.get(`${VG_HOTEL_V2}RegistrationCard/hotel/${hotelId}/pms/${PMSBookingNumber}/reservation/${ReservationNumber}/images?verificationType=DriversLicenseBack`)))
  }

  fetchCovidPassVerificationV2(hotelId: string, PMSBookingNumber: string, ReservationNumber: string): Observable<any> {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.get(`${VG_HOTEL_V2}RegistrationCard/hotel/${hotelId}/pms/${PMSBookingNumber}/reservation/${ReservationNumber}/images?verificationType=CovidPass`)))
  }

  fetchOtherVerificationV2(hotelId: string, PMSBookingNumber: string, ReservationNumber: string): Observable<any> {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.get(`${VG_HOTEL_V2}RegistrationCard/hotel/${hotelId}/pms/${PMSBookingNumber}/reservation/${ReservationNumber}/images?verificationType=Other`)))
  }

  getBooking(id: string): Observable<any> {
    this.isLoading.next(true)
    return this.apiService.get(`${API_HOTEL}Booking?id=${id}`).pipe(finalize(() => this.isLoading.next(false)))
  }

  getJourney(pms: string, reservation: string, source: string): Observable<any> {
    this.isLoading.next(true)
    return this.authService
      .getHotelPreference()
      .pipe(
        flatMap((hotel: any) =>
          this.apiService.get(`${API_HOTEL_V2}Journey?hotelId=${encodeURIComponent(hotel.Id)}&pmsBookingNumber=${encodeURIComponent(pms)}&reservationNumber=${encodeURIComponent(reservation)}&source=${encodeURIComponent(source)}`).pipe(finalize(() => this.isLoading.next(false)))
        )
      )
  }

  getJourneyV3(pms: string, reservation: string): Observable<any> {
    this.isLoading.next(true)
    return this.authService
      .getHotelPreference()
      .pipe(flatMap((hotel: any) => this.apiService.get(`${API_HOTEL_V3}Journey/Hotels/${encodeURIComponent(hotel.Id)}?pmsBookingNumber=${encodeURIComponent(pms)}&reservationNumber=${encodeURIComponent(reservation)}`).pipe(finalize(() => this.isLoading.next(false)))))
  }

  getGuestRegistrationCard(id: string): Observable<any> {
    this.isLoading.next(true)
    return this.apiService.get(`${API_HOTEL}RegistrationCard/Signed/Guest/${id}`).pipe(finalize(() => this.isLoading.next(false)))
  }

  getGuestRegistrationCardVaerify(hotelId: string, userId: string, guestId: string): Observable<any> {
    this.isLoading.next(true)
    return this.apiService.get(`${VG_HOTEL}RegistrationCard/Sign/${hotelId}/${userId}/${guestId}`).pipe(finalize(() => this.isLoading.next(false)))
  }

  getGuestRegistrationCardVaerifyV2(hotelId: string, PMSBookingNumber: string, reservationNumber: string): Observable<any> {
    this.isLoading.next(true)
    return this.apiService.get(`${VG_HOTEL_V2}RegistrationCard/Sign/${hotelId}/${PMSBookingNumber}/${reservationNumber}`).pipe(finalize(() => this.isLoading.next(false)))
  }

  fetchIfUserHasKeys(userId: string, guestId: string): Observable<Keys> {
    this.isLoading.next(true)
    return this.apiService.get(`${API_KEY}Key/Chain/Keys/${userId}/${guestId}`).pipe(finalize(() => this.isLoading.next(false)))
  }

  removeKey(guests: JourneyGuest[], roomNumbers: number[], bookingid: string): Observable<any> {
    // This is a bad way to send a request. Should be redone in a proper way with
    // arrays and objects. Backend api must be changed.
    const dictionaryGuests: { [userId: string]: string } = {}
    for (const guest of guests) {
      dictionaryGuests[guest.UserId] = guest.Id
    }

    this.isLoading.next(true)
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel: any) =>
        this.apiService.post(`${API_KEY}Key/Chain/RevokeRoomKeys`, {
          HotelId: hotel.Id,
          Guest: dictionaryGuests,
          InternalRoomNumbers: roomNumbers,
          BookingId: bookingid,
        })
      ),
      tap(
        (data) => this.isLoading.next(false),
        () => this.isLoading.next(false)
      )
    )
  }

  cancelBooking(id: string): Observable<any> {
    this.isLoading.next(true)
    return this.apiService.post(`${API_HOTEL}Booking/Cancel/${id}`, {}).pipe(
      tap(
        (data) => {
          this.isLoading.next(false)
        },
        () => {
          this.isLoading.next(false)
        }
      )
    )
  }

  putCheckin(guestId) {
    this.isLoading.next(true)
    return this.apiService.put(`${API_HOTEL}/Guest/Checkin`, { GuestId: guestId }).pipe(
      tap(
        () => this.isLoading.next(false),
        () => this.isLoading.next(false)
      )
    )
  }

  postCheckout(guestId) {
    this.isLoading.next(true)
    return this.apiService.post(`${API_HOTEL}Guest/Checkout`, { Id: guestId }).pipe(
      tap(
        () => this.isLoading.next(false),
        () => this.isLoading.next(false)
      )
    )
  }

  fetchRoomNumbers(hotelId: string): Observable<{ Rooms: Room[] }> {
    this.isLoading.next(true)
    return this.apiService.get(`${API_HOTEL}Room/Rooms?hotelId=${hotelId}`).pipe(
      tap(
        () => this.isLoading.next(false),
        () => this.isLoading.next(false)
      )
    )
  }

  fetchRoomTypes(hotelId: string): Observable<RoomType[]> {
    this.isLoading.next(true)
    return this.apiService.get(`${API_HOTEL}RoomType/GetAllRoomTypes?hotelId=${hotelId}`).pipe(
      tap(
        () => this.isLoading.next(false),
        () => this.isLoading.next(false)
      )
    )
  }

  assignRoom(bookingId: string, roomNumber: number): Observable<RoomType[]> {
    this.isLoading.next(true)
    return this.apiService
      .put(`${API_HOTEL}Booking/${bookingId}/Room`, {
        RoomNumber: roomNumber,
      })
      .pipe(
        tap(
          () => this.isLoading.next(false),
          () => this.isLoading.next(false)
        )
      )
  }

  getUser(phoneNumber: string): Observable<User> {
    this.isLoading.next(true)
    return this.apiService.get(`${API_USER_V2}User/UserByPhoneNumber?phoneNumber=${encodeURIComponent(phoneNumber)}`).pipe(tap(() => this.isLoading.next(false)))
  }

  getBill(bookingId: string, context: string): Observable<any> {
    this.isLoading.next(true)
    return this.apiService.get(`${API_PAYMENTV1}Bill/${bookingId}@${context}`).pipe(tap(() => this.isLoading.next(false)))
  }

  getCreditCardsOnFile(bookingId: string): Observable<any> {
    this.isLoading.next(true)
    return this.apiService.get(`${API_PAYMENTV1}CreditCardOnFile/Booking/${bookingId}`).pipe(tap(() => this.isLoading.next(false)))
  }

  getCreditCardsOnFileV2(hotelId: string, bookingId: string): Observable<any> {
    this.isLoading.next(true)
    return this.apiService
      .get(
        `${API_PAYMENTV1}CreditCardOnFile`,
        new HttpParams({
          fromObject: {
            hotelId: hotelId,
            bookingId: bookingId,
          },
        })
      )
      .pipe(tap(() => this.isLoading.next(false)))
  }

  getByBookingBill(bookingId: string, phoneNumber: string): Observable<any> {
    this.isLoading.next(true)
    const url = `${API_PAYMENTV1}Bill/Booking/${bookingId}/PhoneNumber/${encodeURIComponent(phoneNumber)}`
    return this.apiService.get(url).pipe(tap(() => this.isLoading.next(false)))
  }

  getPayment(bookingId: string): Observable<any> {
    this.isLoading.next(true)
    return this.apiService.get(`${API_PAYMENTV4}Payment/Booking/${bookingId}`).pipe(tap(() => this.isLoading.next(false)))
  }

  setPaymentHandledByOther(hotelId, pmsBookingNumber, reservationId, snapSessionId) {
    return this.apiService.post(`${API_PAYMENTV1}/PaymentInstruction/Handled`, {
      HotelId: hotelId,
      PMSBookingNumber: pmsBookingNumber,
      ReservationId: reservationId,
      SnapSessionId: snapSessionId,
    })
  }

  getWebCheckLinks(hotelId: string, pmsBookingNumber: string, reservationNumber: string): Observable<any> {
    this.isLoading.next(true)
    return this.apiService.get(`${API_HOTEL_V3}WebCheckInLink/Booking?hotelId=${hotelId}&pmsBookingNumber=${pmsBookingNumber}&reservationNumber=${reservationNumber}`).pipe(tap(() => this.isLoading.next(false)))
  }

  getReceipt(bookingId: string): Observable<any> {
    this.isLoading.next(true)
    return this.apiService.get(`${API_PAYMENTV1}Receipt/List?bookingId=${bookingId}`).pipe(tap(() => this.isLoading.next(false)))
  }

  postRefund(paymentId: string, amount: number, reason: string, hotelId: string, forwardToPms: boolean): Observable<any> {
    this.isLoading.next(true)
    return this.apiService.post(`${API_PAYMENTV5}Payment/Refund`, { PaymentId: paymentId, Reason: reason, Amount: amount, HotelId: hotelId, ForwardToPms: forwardToPms }).pipe(
      tap(
        () => this.isLoading.next(false),
        () => this.isLoading.next(false)
      )
    )
  }

  postCharge(hotelId: string, bookingId: string, amount: number, cardId: string, firstName: string, lastName: string, description: string) {
    return this.apiService.post(`${API_PAYMENTV1}HandleTransaction/Manual/Transaction`, {
      HotelId: hotelId,
      BookingId: bookingId,
      Amount: amount,
      CardId: cardId,
      FirstName: firstName,
      LastName: lastName,
      Description: description,
    })
  }

  postPayment(billId: string, cardId: string, phoneNumber: string, totalBalance: number): Observable<any> {
    this.isLoading.next(true)
    return this.apiService
      .post(`${API_PAYMENTV4}Payment`, {
        BillId: billId,
        PhoneNumber: phoneNumber,
        TotalBalance: totalBalance,
        PaymentMethodType: "CreditCard",
        CardId: cardId,
      })
      .pipe(
        tap(
          () => this.isLoading.next(false),
          () => this.isLoading.next(false)
        )
      )
  }

  createBill(bookingId: string, userId: string, receiverId: string, receiverName: string, amount: number, description: string, currencyCode: string, employeeid: string, cardId: string, maskednumber: string, cardToken: string, brand: string): Observable<any> {
    this.isLoading.next(true)
    return this.apiService
      .post(`${API_PAYMENTV1}Bill`, {
        BookingId: bookingId, // booking id
        UserId: userId,
        Status: "Unpaid",
        CurrencyCode: currencyCode,
        TotalAmount: amount,
        BillItemList: [
          {
            Amount: amount,
            Description: description,
            Type: "item",
            header: "Extra",
          },
        ],
        Context: "AeroGuestFlow",
        InvoiceImage: "NaN",
        PaymentMethodType: "CreditCard",
        CardId: cardId,
        MaskedNumber: maskednumber,
        CardType: brand,
        SpreedlyCardToken: cardToken,
        EmployeeId: employeeid,
        ReceiverId: receiverId, // hotel id
        ReceiverName: receiverName, // hotel name
      })
      .pipe(
        tap(
          () => this.isLoading.next(false),
          () => this.isLoading.next(false)
        )
      )
  }

  capture(paymentId: string, reason: string, hotelId: string): Observable<any> {
    return this.apiService.post(`${API_PAYMENTV5}Payment/Capture`, { PaymentId: paymentId, Reason: reason, HotelId: hotelId }).pipe(
      tap(
        () => this.isLoading.next(false),
        () => this.isLoading.next(false)
      )
    )
  }

  release(paymentId: string, reason: string, hotelId: string): Observable<any> {
    return this.apiService.post(`${API_PAYMENTV5}Payment/Release`, { PaymentId: paymentId, Reason: reason, HotelId: hotelId }).pipe(
      tap(
        () => this.isLoading.next(false),
        () => this.isLoading.next(false)
      )
    )
  }

  receipt(paymentId: string): Observable<any> {
    return this.apiService.get(`${API_PAYMENTV5}Payment/Receipt/${paymentId}`).pipe(
      tap(
        () => this.isLoading.next(false),
        () => this.isLoading.next(false)
      )
    )
  }
}
