import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core"
import { BrowserModule } from "@angular/platform-browser"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { AuthenticationService } from "./shared/services/authentication.service"
import { NotificationService } from "./shared/services/notification.service"
import { AppComponent } from "./app.component"
import { ApiService } from "./shared/services/api.service"
import { SharedModule } from "./shared/shared.module"
import { WebSocketService } from "./shared/services/websocket.service"
import { ToastService } from "app/toast/toast.service"
import { ToastServiceV2 } from "@shared/services/toast-v2/toast-v2.service"
import { AuthGuard } from "app/shared/services/auth-guard.service"
import { LoginGuard } from "./shared/services/login-guard.service"
import { NavigationService } from "./shared/services/navigation.service"
import { DatePipe } from "@angular/common"
import { NumericDirective } from "./shared/directives/number.directive"
import { LockedGuard } from "./shared/services/locked-guard.service"
import { HttpClientModule } from "@angular/common/http"
import { NgbModule } from "@ng-bootstrap/ng-bootstrap"
import { IntegrationStatusService } from "./shared/services/integration-status.service"
import { QuillModule } from "ngx-quill"
import { GoogleMapsModule } from "@angular/google-maps"
import "froala-editor/js/froala_editor.pkgd.min.js"
import * as $ from "jquery"
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg"
import { ZestComponent } from "./zest/zest.component"
import { SoundService } from "./shared/services/sound.service"
import { CommunicationWebSocketService } from "@shared/services/websocket-communication.service"
window["$"] = $
window["jQuery"] = $

@NgModule({
  declarations: [AppComponent, NumericDirective, ZestComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    GoogleMapsModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    RouterModule.forRoot([
      { path: "", redirectTo: "login", pathMatch: "full" },
      {
        path: "core",
        loadChildren: () => import("app/main-page/main-page.module").then((m) => m.MainPageModule),
        canLoad: [AuthGuard],
      },
      {
        path: "login",
        loadChildren: () => import("app/login/login.module").then((m) => m.LoginModule),
        canLoad: [LoginGuard],
        canActivateChild: [LoginGuard],
      },
      {
        path: "pmsConnection",
        loadChildren: () => import("app/pms-landing-page/pms-landing-page.module").then((m) => m.PmsLandingPageModule),
      },
      {
        path: "**",
        redirectTo: "login",
        pathMatch: "full",
      },
    ]),
    NgbModule,
  ],
  providers: [WebSocketService, CommunicationWebSocketService, AuthenticationService, NotificationService, SoundService, ApiService, ToastService, ToastServiceV2, LoginGuard, AuthGuard, LockedGuard, DatePipe, NavigationService, IntegrationStatusService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
