<button
  type="button"
  [ngStyle]="{ width: specificWidth ?? '', backgroundColor: backgroundColor ?? '' }"
  [ngClass]="disabled ? 'ag-type-disabled-' + type : ''"
  class="ag-button ag-type-{{ type }}-destructive-{{ destructive }} ag-size-{{ size }} ag-state-{{ state }}"
  [disabled]="disabled"
  [class.padded]="label">
  <div class="flex-row flex-center flex-align-center" style="display: flex; gap: 8px">
    <ng-container *ngIf="label && !imagePath">
      <span
        [ngStyle]="{
          'padding-right': loading || !isLoadable || loadReplaceLabel ? '0px' : '14.5px',
          'padding-left': loading || !isLoadable || loadReplaceLabel ? '0px' : '14.5px',
          color: labelColor ? labelColor : ''
        }">
        <div *ngIf="!loading && loadReplaceLabel">{{ label }}</div>
        <div *ngIf="!loadReplaceLabel">{{ label }}</div>
        <div *ngIf="loading" class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </span>
    </ng-container>
    <ng-container *ngIf="label && imagePath">
      <span
        [ngStyle]="{
          'padding-right': loading || imagePlacement === 'right' ? '0px' : loading ? '21px' : '0px',
          color: labelColor ? labelColor : ''
        }">
        <img *ngIf="!loading && imagePlacement === 'left'" [ngStyle]="{ height: imgSize ? imgSize : '', width: imgSize ? imgSize : '' }" [ngClass]="{ 'filter-black-image': !disabled && blackImg && type === 'primary' }" [src]="imagePath" />
        <div *ngIf="loading && imagePlacement === 'left'" class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div *ngIf="!loading && loadReplaceLabel">{{ label }}</div>
        <div *ngIf="!loadReplaceLabel">{{ label }}</div>
        <img *ngIf="!loading && imagePlacement === 'right'" [ngStyle]="{ height: imgSize ? imgSize : '', width: imgSize ? imgSize : '' }" [ngClass]="{ 'filter-black-image': !disabled && blackImg && type === 'primary' }" [src]="imagePath" />
        <div *ngIf="loading && imagePlacement === 'right'" class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </span>
    </ng-container>
    <ng-container *ngIf="!label && imagePath">
      <span style="width: 24px">
        <img *ngIf="!loading" [ngStyle]="{ height: imgSize ? imgSize : '', width: imgSize ? imgSize : '' }" [ngClass]="{ 'filter-black-image': blackImg && type === 'primary' }" [src]="imagePath" />
        <div *ngIf="loading" class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </span>
    </ng-container>
  </div>
</button>
