import { Component, OnInit, Directive, Input, OnDestroy, ElementRef, HostListener } from "@angular/core"
import { TooltipService } from "app/shared/services/tooltip.service"

@Directive({ selector: "[appTooltip]" })
export class TooltipDirective implements OnDestroy {
  @Input() tooltipTitle = ""
  @Input() tooltipBody = ""
  @Input() tooltipPos = "right"
  @Input() tooltipColor = "#61566E"
  private id: number

  constructor(
    private tooltipService: TooltipService,
    private element: ElementRef,
  ) {}

  @HostListener("mouseenter")
  onMouseEnter(): void {
    this.id = Math.random()
    this.tooltipService.components.push({
      id: this.id,
      ref: this.element,
      title: this.tooltipTitle,
      body: this.tooltipBody,
      position: this.tooltipPos,
      color: this.tooltipColor,
    })
  }

  @HostListener("mouseleave")
  onMouseLeave(): void {
    this.destroy()
  }

  ngOnDestroy(): void {
    this.destroy()
  }

  destroy(): void {
    const idx = this.tooltipService.components.findIndex((t) => {
      return t.id === this.id
    })

    this.tooltipService.components.splice(idx, 1)
  }
}
