import { Component, OnInit, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms"
import { AuthenticationService } from "app/shared/services/authentication.service"
import { JourneyDetailService } from "app/bookings/journey-detail/journey-detail.service"
import { ToastService } from "app/toast/toast.service"
import { StateService } from "app/shared/services/state.service"
import { AgFormValidation } from "@shared/helpers/ag-form-validation"

@Component({
  selector: "app-refund-dialog",
  templateUrl: "./refund-dialog.component.html",
  styleUrls: ["./refund-dialog.component.scss"],
})
export class RefundDialogComponent implements OnInit, AgFormValidation {
  form: UntypedFormGroup
  isLoading: boolean

  /* Parameters */
  paymentId: string
  totalCharged: number
  totalRefunded: number
  currency: string
  recipient: string
  ForwardRefundToPms: boolean
  PmsName: string
  depositRefund: boolean

  constructor(
    public toastService: ToastService,
    public journeyDetailService: JourneyDetailService,
    public dialogRef: MatDialogRef<RefundDialogComponent>,
    public authService: AuthenticationService,
    private fb: UntypedFormBuilder,
    private stateService: StateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.paymentId = this.data.paymentId
    this.totalCharged = this.data.totalCharged
    this.totalRefunded = this.data.totalRefunded
    this.currency = this.data.currency
    this.recipient = this.data.recipient
    this.ForwardRefundToPms = this.data.ForwardRefundToPms
    this.PmsName = this.data.PmsName
    this.depositRefund = this.data.DepositRefund

    this.form = this.fb.group({
      forwardToPms: [false],
      reason: ["", [Validators.required, Validators.minLength(1)]],
      amount: this.fb.group({
        unit: [this.currency, [Validators.required]],
        value: [null, [Validators.required, Validators.min(0), Validators.max(this.totalCharged - this.totalRefunded)]],
      }),
    })

    this.form.patchValue({
      forwardToPms: this.ForwardRefundToPms,
    })
  }

  private validateForm() {
    for (const key in this.form.controls) {
      if (Object.prototype.hasOwnProperty.call(this.form.controls, key)) {
        const element = this.form.controls[key]
        element.markAsDirty()
      }
    }
  }

  async submit() {
    if (!AgFormValidation.validate(this.form)) {
      return
    }
    this.isLoading = true
    const amount = this.form.get("amount")?.value
    try {
      const employee = (this.authService.employeeInfo.FirstName || "") + " " + (this.authService.employeeInfo.LastName || "")
      const formattedReasonString = this.form.get("reason")?.value + " -- refunded by " + employee
      if (this.depositRefund) {
        this.closeDialog(true)
        this.isLoading = false
      } else {
        await this.journeyDetailService.postRefund(this.paymentId, amount.value, formattedReasonString, this.stateService.selectedHotel.Id, this.form.get("forwardToPms").value).toPromise()
        this.toastService.success({ message: `Refunded ${this.recipient} ${amount.value} ${this.currency}.` })
        this.closeDialog(true)
        this.isLoading = false
      }
    } catch {
      this.toastService.error({ message: `Failed to refund ${this.recipient} ${amount.value} ${this.currency}.` })
      this.isLoading = false
      this.closeDialog(false)
    }
  }

  closeDialog(success: boolean) {
    const amount = this.form.get("amount")?.value
    const reason: string = this.form.get("reason")?.value
    this.dialogRef.close({
      currency: this.currency,
      amount: amount.value,
      reason: reason,
      success: success,
      forwardToPms: this.form.get("forwardToPms").value,
    })
  }

  getAmountError() {
    const amountField = this.form.get("amount").get("value")
    if (amountField.hasError("required")) {
      return "An amount is required."
    } else if (amountField.hasError("min")) {
      return "The amount has to be positive."
    } else {
      return "The amount can't be higher than the captured amount."
    }
  }
}
