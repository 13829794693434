import { Component, Input, OnInit, Optional, Self } from "@angular/core"
import { UntypedFormControl, NgControl } from "@angular/forms"
import { IconImageService } from "@shared/services/icon-image.service"

@Component({
  selector: "ag-checkbox",
  templateUrl: "./ag-checkbox.component.html",
  styleUrls: ["./ag-checkbox.component.scss"],
})
export class AgCheckboxComponent implements OnInit {
  @Input() public size: AgSize = "default"
  @Input() public label: string

  public control: UntypedFormControl

  constructor(@Optional() @Self() private _ngControl: NgControl, public iconService: IconImageService) {
    if (this._ngControl) {
      this._ngControl.valueAccessor = this
    }
  }
  ngOnInit(): void {
    if (this._ngControl?.control) {
      this.control = this._ngControl.control as UntypedFormControl
    }
  }

  writeValue() {}
  registerOnChange() {}
  registerOnTouched() {}
}

export type AgSize = "large" | "default" | "small"
