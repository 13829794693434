import { Component, Input, Output, EventEmitter } from "@angular/core"
import { trigger, state, transition, style, animate } from "@angular/animations"

@Component({
  selector: "app-save-button2",
  template: `
    <span class="button-container">
      <button @EnterLeave *ngIf="display" class="button" (click)="onClick()" mat-button>Save changes</button>
    </span>
  `,
  styles: [
    `
                       .button {
                         width: 186px;
                         height: 62px;
                         border-radius: 30px;
                         box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.5);
                         background-color: #61566e;
                 
                         font-family: "Poppins-Regular";
                         font-size: 14px;
                         color: #f2eee7;
                 
                         transition-duration: 0.4s;
                 
                         position: fixed;
                         bottom: 16px;
                         left: calc(50% - 93px + 144px);
                       }
                 
                       .button:hover {
                         background-color: #3c3446;
                       }
                 
                       @media (max-width: 1390px) and (min-width: 600px) {
                         .button {
                           left: calc(50% - 93px + 40px);
                         }
                       }
                 
                       @media (max-width: 599px) {
                         .button {
                           left: calc(50% - 93px);
                           bottom: 69px;
                         }
                       }
                     `,
  ],
  animations: [trigger("EnterLeave", [state("flyIn", style({ transform: "translateY(0)" })), transition(":enter", [style({ transform: "translateY(100%)" }), animate("400ms ease-out")]), transition(":leave", [animate("0.4s ease-in", style({ transform: "translateY(100%)" }))])])],
})
export class SaveButton2Component {
  @Input() display = false
  @Output() onButtonClicked = new EventEmitter<unknown>()

  onClick() {
    this.onButtonClicked.emit()
  }
}
