<div (click)="insideClick = true">
  <div class="custom-datepicker placement-{{ placement }}" [@fadeInOut]="datepickerShown ? 'in' : 'out'" *ngIf="datepickerShown">
    <div class="header">
      <img class="image" [src]="iconService.Icon.left_1_5" (click)="previousYear()" />
      <span class="label">{{ label }}</span>
      <img class="image" [src]="iconService.Icon.right_1_5" (click)="nextYear()" />
    </div>
    <div class="divider"></div>
    <div class="calendar">
      <div *ngFor="let date of dates">
        <div
          [class.month]="!isDateSelected(date) && !isDateDisabled(date)"
          [class.selected-month]="isDateSelected(date) && !isDateRange"
          [class.in-between]="isDateBetween(date) && isDateRange"
          [class.first-date]="isFirstDate(date) && isDateRange"
          [class.last-date]="isLastDate(date) && isDateRange"
          [class.disabled]="isDateDisabled(date)"
          (click)="selectMonth(date)">
          {{ date | date : "MMM" }}
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="button-area" *ngIf="applyButton">
      <ag-button-v2 specificWidth="122px" size="small" label="Cancel" type="secondary" (click)="cancel()"></ag-button-v2>
      <ag-button-v2 specificWidth="122px" size="small" label="Apply" type="primary" (click)="submit()"></ag-button-v2>
    </div>
  </div>
</div>
