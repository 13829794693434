import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core"
import { InputInfo } from "../ag-input-field/ag-input-field.component"
import { IconImageService } from "@shared/services/icon-image.service"

@Component({
  selector: "ag-counter-field",
  standalone: false,
  templateUrl: "./ag-counter-field.component.html",
  styleUrl: "./ag-counter-field.component.scss",
})
export class AgCounterFieldComponent {
  @Input() label: string = ""
  @Input() disable: boolean = false
  @Input() value: number = 1
  @Input() allowNegativeNumbers: boolean = true
  @Input() maximumNumber: number = 10
  @Input() minimumNumber: number = 1
  @Input() specificWidth: number = 152
  @Input() specificHeight: number = 48
  @Input() info: InputInfo = {
    showInfo: false,
    type: "info",
    text: "Info",
  }
  @Output() valueChanged = new EventEmitter()

  isInputFocused: boolean = false

  @ViewChild("inputField") inputField!: ElementRef<HTMLInputElement>

  constructor(public iconService: IconImageService) {}

  upClicked() {
    if (this.value < this.maximumNumber) {
      this.value++
      this.valueChanged.emit(this.value)
    }
  }
  downClicked() {
    if (this.value > this.minimumNumber) {
      this.value--
      this.valueChanged.emit(this.value)
    }
  }

  validateValue() {
    this.value = this.clampValue(this.value)
    this.valueChanged.emit(this.value)
  }

  clampValue(val: number): number {
    if (val < this.minimumNumber) return this.minimumNumber
    if (val > this.maximumNumber) return this.maximumNumber
    return val
  }

  getInputWidth(): number {
    return this.value.toString().length * 10
  }

  edit() {
    setTimeout(() => {
      if (this.inputField) {
        this.inputField.nativeElement.focus()
        this.inputField.nativeElement.select()
      }
    }, 0)
  }

  onFocus() {
    this.isInputFocused = true
  }

  onBlur() {
    this.value = this.clampValue(this.value)
    this.isInputFocused = false
  }
}
