import { Component } from "@angular/core"
import { NavigationService } from "./shared/services/navigation.service"

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent {
  constructor(private navigationService: NavigationService) {}
}
