import { Component, OnInit, Input } from "@angular/core"
import { HotelApiService } from "@api/hotel/hotel-api.service"
import { UpdateEmployeeRequest } from "@api/hotel/models/update-employee-request"
import { Employee } from "@shared/models/employee"
import { StateService } from "@shared/services/state.service"
import { ToastService } from "app/toast/toast.service"

@Component({
  selector: "app-new-infobox",
  templateUrl: "./new-infobox.component.html",
  styleUrls: ["./new-infobox.component.scss"],
})
export class NewInfoboxComponent implements OnInit {
  @Input() headerText?: string

  @Input() bodyText?: string

  @Input() buttonText?: string

  @Input() imageSource?: string
  /**Add the name of your component, which will make sure it only gets showed until the button is clicked */
  @Input() ComponentName: string

  @Input() imageHeight: string
  @Input() imageWidth: string
  @Input() objectFit: string = "" //Set this to cover, when the image wont fit the container/div

  show: Boolean = true

  user: Employee

  constructor(private hotelApiService: HotelApiService, private stateService: StateService, private toastService: ToastService) {}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("user"))
    this.checkEmployee()
  }

  checkEmployee() {
    if (this.user.InfoBoxChecked != null || this.user.InfoBoxChecked != undefined) {
      if (this.user.InfoBoxChecked.includes(this.ComponentName)) this.show = false
    }
  }

  infoBoxClicked() {
    const employeeUpdateInfo: UpdateEmployeeRequest = {
      HotelId: this.stateService.selectedHotel.Id,
      Id: this.user.Id,
      OrganizationId: this.user.OrganizationId,
      WhichInfoBox: this.ComponentName,
    }
    this.show = false
    this.hotelApiService.V1Employee.updateEmployee(employeeUpdateInfo).subscribe((x) => {
      if (!x)
        this.toastService.alert({
          message: "Something went wrong, could'nt save update",
        })
    })
  }
}
