<div style="position: absolute; right: 0px; top: 2px">
  <div style="height: fit-content; max-height: 100%; width: fit-content; position: relative" [hidden]="!showBox ? true : false">
    <div class="container-standard">
      <div class="top-bottom-padding">
        <div [hidden]="list.length < 1">
          <ng-container *ngFor="let sectionSize of numberInSection; let sectionIndex = index">
            <div *ngIf="sectionIndex > 0" class="line"></div>
            <div *ngFor="let item of list.slice(getSectionStart(sectionIndex), getSectionEnd(sectionIndex)); let i = index">
              <div [ngClass]="checkForDisabled(item.label) ? 'disabled-item' : 'item'" (click)="!checkForDisabled(item.label)">
                <app-ag-checkbox-v2 [checked]="isChecked(item)" [disabled]="checkForDisabled(item.label)" size="small" [maxWidth]="maxWidth" (valueChange)="checked(item, $event)" [initValue]="item?.value" [label]="item?.label"></app-ag-checkbox-v2>
                <div class="hint-icon" *ngIf="checkForDisabled(item.label)">
                  <img [src]="iconService.Icon.i_letter_1" />
                  <div class="tooltip-box" [ngStyle]="{ width: maxWidth + 'px' }">
                    <p>{{ hintForDisabledInputs }}</p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
