import { Component, ContentChildren, Input, OnInit, QueryList } from "@angular/core"
import { trigger, state, style, animate, transition } from "@angular/animations"
import { IconImageService } from "@shared/services/icon-image.service"
@Component({
  selector: "app-ag-tree-v2-node",
  templateUrl: "./ag-tree-v2-node.component.html",
  styleUrls: ["./ag-tree-v2-node.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [style({ height: 0, opacity: 0 }), animate(".2s ease-out", style({ height: "*" })), animate(".2s ease-out", style({ opacity: 1 }))]),
      transition(":leave", [style({ height: "*", opacity: 1 }), animate(".2s ease-in-out", style({ opacity: 0, height: 0 }))]),
    ]),
  ],
})
export class AgTreeV2NodeComponent {
  @Input() public agTitle: string = ""
  @Input() public agDescription: string = ""
  @Input() public agDate: string = ""
  @Input() public agItems: number = 0
  @Input() public agTotalPrice: string = ""
  @Input() public collapsible: boolean = true
  @Input() public open: boolean = false
  @Input() public noPadding: boolean = false
  @ContentChildren(AgTreeV2NodeComponent, { descendants: true })
  nestedNodes!: QueryList<AgTreeV2NodeComponent>

  constructor(public iconService: IconImageService) {}

  get nodes(): AgTreeV2NodeComponent[] {
    return this.nestedNodes.map((n) => n)
  }

  toggle() {
    if (this.open) {
      this.nodes.forEach((node) => {
        node.open = false
      })
      this.open = false
    } else {
      this.open = true
    }
  }
}
