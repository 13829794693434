import { Injectable, NgZone } from "@angular/core"
import { BehaviorSubject, Observable, of } from "rxjs"
import { ApiService } from "app/shared/services/api.service"
import { AuthenticationService } from "app/shared/services/authentication.service"
import { flatMap, tap, first, map } from "rxjs/operators"
import { API_INVOICE, API_HOTEL_V2 } from "app/app-settings"
import { Hotel } from "app/shared/models"
const tzlookup = require("tz-lookup")
import { Moment } from "moment"
import * as _moment from "moment-timezone"
import { PaymentRulesService } from "app/property-setup/revenue/payment-rules/payment-rules.service"
import { PaymentRuleBackendModelContainer } from "app/shared/models/payment-rule/payment-rule"
let moment = require("moment")
if ("default" in moment) {
  moment = moment["default"]
}

@Injectable()
export class CheckInService {
  public hotelTimezone = ""
  public currency = "DKK"
  public YesterdayCheckInTarget = 0

  //////
  private CheckInTarget: BehaviorSubject<number>
  private CheckInEnabled: BehaviorSubject<boolean>
  private TargetEmployeeWinnerToday: BehaviorSubject<string>
  private TargetEmployeeWinnerYesterday: BehaviorSubject<string>
  private TargetEmployeeWinnerLastweek: BehaviorSubject<string>
  private TargetIncomeToday: BehaviorSubject<number>
  private TargetIncomeYesterday: BehaviorSubject<number>
  private TargetIncomeLastweek: BehaviorSubject<number>
  private TotalCheckInsToday: BehaviorSubject<number>
  private TotalCheckInsYesterday: BehaviorSubject<number>
  private TotalCheckInsLastweek: BehaviorSubject<number>

  getCheckInTarget(): Observable<number> {
    return this.CheckInTarget.asObservable()
  }
  setCheckInTarget(newValue): void {
    this.CheckInTarget.next(newValue)
  }

  getCheckInEnabled(): Observable<boolean> {
    return this.CheckInEnabled.asObservable()
  }
  setCheckInEnabled(newValue): void {
    this.CheckInEnabled.next(newValue)
  }

  getTargetEmployeeWinnerToday(): Observable<string> {
    return this.TargetEmployeeWinnerToday.asObservable()
  }
  setTargetEmployeeWinnerToday(newValue): void {
    this.TargetEmployeeWinnerToday.next(newValue)
  }

  getTargetEmployeeWinnerYesterday(): Observable<string> {
    return this.TargetEmployeeWinnerYesterday.asObservable()
  }
  setTargetEmployeeWinnerYesterday(newValue): void {
    this.TargetEmployeeWinnerYesterday.next(newValue)
  }

  getTargetEmployeeWinnerLastweek(): Observable<string> {
    return this.TargetEmployeeWinnerLastweek.asObservable()
  }
  setTargetEmployeeWinnerLastweek(newValue): void {
    this.TargetEmployeeWinnerLastweek.next(newValue)
  }

  getTargetIncomeToday(): Observable<number> {
    return this.TargetIncomeToday.asObservable()
  }
  setTargetIncomeToday(newValue): void {
    this.TargetIncomeToday.next(newValue)
  }

  getTargetIncomeYesterday(): Observable<number> {
    return this.TargetIncomeYesterday.asObservable()
  }
  setTargetIncomeYesterday(newValue): void {
    this.TargetIncomeYesterday.next(newValue)
  }

  getTargetIncomeLastweek(): Observable<number> {
    return this.TargetIncomeLastweek.asObservable()
  }
  setTargetIncomeLastweek(newValue): void {
    this.TargetIncomeLastweek.next(newValue)
  }

  getTotalCheckInsToday(): Observable<number> {
    return this.TotalCheckInsToday.asObservable()
  }
  setTotalCheckInsToday(newValue): void {
    this.TotalCheckInsToday.next(newValue)
  }

  getTotalCheckInsYesterday(): Observable<number> {
    return this.TotalCheckInsYesterday.asObservable()
  }
  setTotalCheckInsYesterday(newValue): void {
    this.TotalCheckInsYesterday.next(newValue)
  }

  getTotalCheckInsLastweek(): Observable<number> {
    return this.TotalCheckInsLastweek.asObservable()
  }
  setTotalCheckInsLastweek(newValue): void {
    this.TotalCheckInsLastweek.next(newValue)
  }
  //////

  isLoading = new BehaviorSubject<boolean>(false)
  isError = false

  constructor(private apiService: ApiService, private authService: AuthenticationService, private ngZone: NgZone, private paymentRulesService: PaymentRulesService) {
    this.CheckInTarget = new BehaviorSubject<number>(0)
    this.CheckInEnabled = new BehaviorSubject<boolean>(false)
    this.TargetEmployeeWinnerToday = new BehaviorSubject<string>("AeroGuest")
    this.TargetEmployeeWinnerYesterday = new BehaviorSubject<string>("AeroGuest")
    this.TargetEmployeeWinnerLastweek = new BehaviorSubject<string>("AeroGuest")
    this.TargetIncomeToday = new BehaviorSubject<number>(0)
    this.TargetIncomeYesterday = new BehaviorSubject<number>(0)
    this.TargetIncomeLastweek = new BehaviorSubject<number>(0)
    this.TotalCheckInsToday = new BehaviorSubject<number>(0)
    this.TotalCheckInsYesterday = new BehaviorSubject<number>(0)
    this.TotalCheckInsLastweek = new BehaviorSubject<number>(0)
  }

  fetchHotelCurrency() {
    this.paymentRulesService.getPaymentRules().subscribe((value) => {
      this.paymentRulesService.setPaymentRules(value)
      this.currency = value?.Rules[0]?.Rules[0]?.CurrencyCode
    })
  }

  fetchCheckinStatistics(timetype: string = "Utc", periodicity: string) {
    this.isLoading.next(true)
    this.isError = false
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => this.apiService.get(`${API_INVOICE}Statistic/CheckIn/Count/${hotel.Id}/Type/${timetype}/Periodicity/${periodicity}`)),
      tap(
        () => this.isLoading.next(false),
        () => {
          this.isLoading.next(false)
          this.isError = true
        }
      ),
      first()
    )
  }

  //// CheckIn ////

  CheckInStatistics(from: Moment, to: Moment, periodicity: string, graphType: string = undefined, toHour = 23, timetype: string = "Utc"): Observable<any[]> {
    this.isLoading.next(true)
    this.isError = false
    const UrlParam = "?graphType=" + graphType + "&toHour=" + toHour
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => this.apiService.get(`${API_INVOICE}Statistic/CheckIn/Count/${hotel.Id}/From/${from.format("YYYY-MM-DD")}/To/${to.format("YYYY-MM-DD")}/Type/${timetype}/Periodicity/${periodicity}` + UrlParam)),
      tap(
        () => this.isLoading.next(false),
        () => {
          this.isLoading.next(false)
          this.isError = true
        }
      ),
      first()
    )
  }

  CheckInStatisticsOrg(from: Moment, to: Moment, periodicity: string): Observable<any[]> {
    this.isLoading.next(true)
    this.isError = false
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => {
        const employeeInfo = this.authService.employeeInfo
        const organizationId = employeeInfo.OrganizationId
        return this.apiService.get(`${API_INVOICE}Statistic/CheckIn/Count/${organizationId}/From/${from.format("YYYY-MM-DD")}/To/${to.format("YYYY-MM-DD")}/Type/Utc/Periodicity/${periodicity}`)
      }),
      tap(
        () => this.isLoading.next(false),
        () => {
          this.isLoading.next(false)
          this.isError = true
        }
      ),
      first()
    )
  }

  CheckInStatisticsMin(): Observable<any[]> {
    this.isLoading.next(true)
    this.isError = false
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => {
        return this.apiService.get(`${API_INVOICE}Statistic/CheckIn/Count/${hotel.Id}/First/Type/Utc`)
      }),
      tap(
        () => this.isLoading.next(false),
        () => {
          this.isLoading.next(false)
          this.isError = true
        }
      ),
      first()
    )
  }

  CheckInStatisticsOrgMin(): Observable<any[]> {
    this.isLoading.next(true)
    this.isError = false
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => {
        const employeeInfo = this.authService.employeeInfo
        const organizationId = employeeInfo.OrganizationId
        return this.apiService.get(`${API_INVOICE}Statistic/CheckIn/Count/${organizationId}/First/Type/Utc`)
      }),
      tap(
        () => this.isLoading.next(false),
        () => {
          this.isLoading.next(false)
          this.isError = true
        }
      ),
      first()
    )
  }

  CheckInStatisticsMax(): Observable<any[]> {
    this.isLoading.next(true)
    this.isError = false
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => this.apiService.get(`${API_INVOICE}Statistic/CheckIn/Count/${hotel.Id}/Latest/Type/Utc`)),
      tap(
        () => this.isLoading.next(false),
        () => {
          this.isLoading.next(false)
          this.isError = true
        }
      ),
      first()
    )
  }

  CheckInStatisticsOrgMax(): Observable<any[]> {
    this.isLoading.next(true)
    this.isError = false
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => {
        const employeeInfo = this.authService.employeeInfo
        const organizationId = employeeInfo.OrganizationId
        return this.apiService.get(`${API_INVOICE}Statistic/CheckIn/Count/${organizationId}/Latest/Type/Utc`)
      }),
      tap(
        () => this.isLoading.next(false),
        () => {
          this.isLoading.next(false)
          this.isError = true
        }
      ),
      first()
    )
  }

  ////////

  //// CheckOut ////

  CheckOutStatistics(from: Moment, to: Moment, periodicity: string): Observable<any[]> {
    this.isLoading.next(true)
    this.isError = false
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => this.apiService.get(`${API_HOTEL_V2}Statistic/CheckOut/Count/${hotel.Id}/From/${from.format("YYYY-MM-DD")}/To/${to.format("YYYY-MM-DD")}/Type/Utc/Periodicity/${periodicity}`)),
      tap(
        () => this.isLoading.next(false),
        () => {
          this.isLoading.next(false)
          this.isError = true
        }
      ),
      first()
    )
  }

  CheckOutStatisticsOrg(from: Moment, to: Moment, periodicity: string): Observable<any[]> {
    this.isLoading.next(true)
    this.isError = false
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => {
        const employeeInfo = this.authService.employeeInfo
        const organizationId = employeeInfo.OrganizationId
        return this.apiService.get(`${API_HOTEL_V2}Statistic/CheckOut/Count/${organizationId}/From/${from.format("YYYY-MM-DD")}/To/${to.format("YYYY-MM-DD")}/Type/Utc/Periodicity/${periodicity}`)
      }),
      tap(
        () => this.isLoading.next(false),
        () => {
          this.isLoading.next(false)
          this.isError = true
        }
      ),
      first()
    )
  }

  CheckOutStatisticsMin(): Observable<any[]> {
    this.isLoading.next(true)
    this.isError = false
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => {
        return this.apiService.get(`${API_HOTEL_V2}Statistic/CheckOut/Count/${hotel.Id}/First/Type/Utc`)
      }),
      tap(
        () => this.isLoading.next(false),
        () => {
          this.isLoading.next(false)
          this.isError = true
        }
      ),
      first()
    )
  }

  CheckOutStatisticsOrgMin(): Observable<any[]> {
    this.isLoading.next(true)
    this.isError = false
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => {
        const employeeInfo = this.authService.employeeInfo
        const organizationId = employeeInfo.OrganizationId
        return this.apiService.get(`${API_HOTEL_V2}Statistic/CheckOut/Count/${organizationId}/First/Type/Utc`)
      }),
      tap(
        () => this.isLoading.next(false),
        () => {
          this.isLoading.next(false)
          this.isError = true
        }
      ),
      first()
    )
  }

  CheckOutStatisticsMax(): Observable<any[]> {
    this.isLoading.next(true)
    this.isError = false
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => this.apiService.get(`${API_HOTEL_V2}Statistic/CheckOut/Count/${hotel.Id}/Latest/Type/Utc`)),
      tap(
        () => this.isLoading.next(false),
        () => {
          this.isLoading.next(false)
          this.isError = true
        }
      ),
      first()
    )
  }

  CheckOutStatisticsOrgMax(): Observable<any[]> {
    this.isLoading.next(true)
    this.isError = false
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => {
        const employeeInfo = this.authService.employeeInfo
        const organizationId = employeeInfo.OrganizationId
        return this.apiService.get(`${API_HOTEL_V2}Statistic/CheckOut/Count/${organizationId}/Latest/Type/Utc`)
      }),
      tap(
        () => this.isLoading.next(false),
        () => {
          this.isLoading.next(false)
          this.isError = true
        }
      ),
      first()
    )
  }

  ////////

  UpdateCheckInTarget(value: number, enabled: boolean): Observable<any[]> {
    this.isLoading.next(true)
    this.isError = false
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => {
        return this.apiService.put(`${API_HOTEL_V2}Hotel/CheckInTarget` + "?hotelId=" + hotel.Id + "&checkInEnabled=" + enabled + "&checkInTarget=" + value)
      }),
      tap(
        () => this.isLoading.next(false),
        () => {
          this.isLoading.next(false)
          this.isError = true
        }
      ),
      first()
    )
  }

  GetCheckInTarget(from: Moment, to: Moment): Observable<any[]> {
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => {
        return this.apiService.get(`${API_HOTEL_V2}Hotel/CheckInTarget` + "?hotelId=" + hotel.Id + "&timetype=Local&from=" + from.format("YYYY-MM-DD") + "&to=" + to.format("YYYY-MM-DD"))
      }),
      tap(
        () => this.isLoading.next(false),
        () => {
          this.isLoading.next(false)
          this.isError = true
        }
      ),
      first()
    )
  }

  /////////

  public PadTimestamps(categories) {
    const offset = this.getOffset()
    const hotelOffset = this.getOffsetMoment(moment(), this.hotelTimezone)
    const returnCategories = []
    for (let i = 0; i < categories.length; i++) {
      returnCategories.push(categories[i] - offset * 3600000)
    }
    return returnCategories
  }

  private getOffset() {
    const offsetraw = new Date().getTimezoneOffset()
    let offset = 0
    if (offsetraw < 0) {
      offset = offsetraw / -60
    } else {
      offset = -(offsetraw / 60)
    }
    return offset
  }

  private getOffsetMoment(mom: Moment, timezone: string) {
    const offsetraw = moment.tz.zone(timezone).utcOffset(mom)
    let offset = 0
    if (offsetraw < 0) {
      offset = offsetraw / -60
    } else {
      offset = -(offsetraw / 60)
    }
    return offset
  }
}
