import { Component, OnInit, Inject } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"
import { CommunicationApiService } from "@api/communication/communication-api.service"
import { ParentNode } from "@api/communication/models/node"
import { ToastServiceV2 } from "@shared/services/toast-v2/toast-v2.service"

@Component({
  selector: "ag-confirm-dialog",
  templateUrl: "./ag-confirm-dialog.component.html",
  styleUrls: ["./ag-confirm-dialog.component.scss"],
})
export class AgConfirmDialogComponent implements OnInit {
  public dialogTitle: string
  public dialogDescription: string
  public destructive: boolean
  public buttonText: string
  public secondaryButtonText: string
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AgConfirmDialogComponent>) {}

  ngOnInit(): void {
    this.dialogTitle = this.data.dialogTitle
    this.dialogDescription = this.data.dialogDescription
    this.buttonText = this.data.buttonText
    this.secondaryButtonText = this.data.secondaryButtonText
    if (!this.buttonText) {
      this.buttonText = "Confirm"
    }
    if (!this.secondaryButtonText) {
      this.secondaryButtonText = "Cancel"
    }
    this.destructive = this.data.destructive
    if (!this.destructive) {
      this.destructive = false
    }
  }

  submitQuickAction() {
    this.dialogRef.close(true)
  }

  closeDialog() {
    this.dialogRef.close(false)
  }
}
