export enum AgReportStatus {
  Ok,
  Info,
  Warning,
  Error,
}

export class AgStatusIconMapper {
  static getIcon(status: AgReportStatus | undefined): string | undefined {
    switch (status) {
      case AgReportStatus.Ok:
        return "assets/icons/A-OK.svg"
      case AgReportStatus.Info:
        return "assets/icons/Info.svg"
      case AgReportStatus.Warning:
        return "assets/icons/Warning.svg"
      case AgReportStatus.Error:
        return "assets/icons/Error.svg"
      default:
        return undefined
    }
  }
}
