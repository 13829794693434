import { animate, state, style, transition, trigger } from "@angular/animations"
import { Component, Input, EventEmitter, Output, OnInit, HostListener, SimpleChanges } from "@angular/core"
import { RestCountryCodesDTO } from "@api/user/models/rest-country"
import { UserApiService } from "@api/user/user-api.service"
import { PhoneNumberUtil } from "google-libphonenumber"
import { IconImageService } from "@shared/services/icon-image.service"
import { InputInfo } from "@shared/components/ag-input-field/ag-input-field.component"
import { SecurityService } from "@api/security/security-api.service"

@Component({
  selector: "country-code-picker-component",
  template: `
    <div style="position: relative;">
      <div class="full-field" [ngStyle]="{ width: maxWidth }">
        <div (click)="insideClick = true">
          <div class="code-picker-box" [ngClass]="codePickerShown ? 'shown' : 'not-shown'" [ngStyle]="{ border: info.showInfo && info.type === 'error' ? '1px solid #E55E5E' : '' }" (click)="toggleDropdown()">
            <p class="black">{{ currentCode ? currentCode : "Code" }}</p>
            <img class="small-img" [src]="codePickerShown ? iconService.Icon.up_1_5 : iconService.Icon.down_1_5" />
          </div>
        </div>
        <div class="phone-number-box" *ngIf="withPhoneNumber" [ngStyle]="{ border: info.showInfo && info.type === 'error' ? '1px solid #E55E5E' : '' }">
          <input class="phone-input noscroll" (keydown)="onKeyDown($event)" type="number" (change)="updatePhoneNumber()" placeholder="Phone number" [(ngModel)]="currentNr" />
        </div>
      </div>
      <div *ngIf="withPhoneNumber && info.showInfo" [ngStyle]="{ width: maxWidth }" style="display: flex; gap: 8px; align-items: center; margin-top: 8px">
        <img width="12" height="12" [src]="info.type === 'error' ? iconService.Icon.cross_1_5 : iconService.Icon.exclamation_mark_1_5" [ngClass]="info.type === 'error' ? 'red-img' : 'black-img'" />
        <p class="info-text" [ngStyle]="{ color: info.type === 'error' ? '#E55E5E' : '#222222' }">{{ info.text }}</p>
      </div>

      <div class="code-picker-list placement-{{ placement }} loading-start loading-{{ codePickerIsLoading }} " [@dropDown]="codePickerShown && !codePickerIsLoading ? getHeight() : '0'" [@.disabled]="disableAnimations" (click)="insideClick = true">
        <div class="search-bar">
          <img class="search-img" [src]="iconService.Icon.magnifier" />
          <input class="search" type="text" placeholder="Search country code" [(ngModel)]="searchText" />
        </div>
        <div class="br-line"></div>
        <div class="list-entries size-{{ dropDownSize }}">
          <div *ngFor="let country of countryCodes | filterCountryCodes : searchText" (click)="pickCountryCode(country)">
            <div class="selected-country" *ngIf="currentCode === '+' + country.CallingCodes[0]">
              <div class="name">
                <p class="selected">{{ country.NativeName }}</p>
                <p class="selected-12px">{{ country.Name }}</p>
              </div>
              <p class="selected">+{{ country.CallingCodes }}</p>
            </div>
            <div class="country" *ngIf="currentCode !== '+' + country.CallingCodes[0]">
              <div class="name">
                <p class="black">{{ country.NativeName }}</p>
                <p class="grey">{{ country.Name }}</p>
              </div>
              <p class="black">+{{ country.CallingCodes }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  animations: [
    trigger("dropDown", [state("0", style({ height: "0%", padding: "0px 16px", display: "none" })), state("6-countries", style({ height: "460px" })), state("5-countries", style({ height: "370px" })), state("4-countries", style({ height: "330px" })), transition("* => *", animate("0.2s ease-in"))]),
  ],
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }
      .full-field {
        height: 48px;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
      }
      .code-picker-box {
        width: 104px;
        height: 48px;
        border-radius: 12px;
        padding: 12px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 1px solid transparent;

        &:hover {
          border: 1px solid #dfdfdf !important;
          background: #fff;
        }
      }
      .small-img {
        width: 16px;
        height: 16px;
      }
      .shown {
        border: 1px solid #dfdfdf;
        background: #fff;
      }
      .not-shown {
        background: #f2f2f2;
      }
      .phone-number-box {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        gap: 12px;
        border-radius: 12px;
        background: #f2f2f2;
        padding: 0 12px;
        overflow: hidden;
        border: 1px solid transparent;

        &:hover {
          border: 1px solid #dfdfdf !important;
          background: #fff;

          .phone-input {
            background: #fff;
          }
        }

        .phone-input:focus {
          background: #fff;
        }

        &:has(.phone-input:focus) {
          border: 1px solid #dfdfdf !important;
          background: #fff;
        }
      }

      .phone-input {
        width: 100%;
        outline: none;
        border: none;
        margin: 0;
        color: #222;
        background: #f2f2f2;
        font-family: "Poppins-Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      .code-picker-list {
        width: 280px;
        background: #fff;
        border: 1px solid #dfdfdf;
        border-radius: 12px;
        padding: 16px 16px 0 16px;
        position: absolute;
        z-index: 10;
        left: -176px;
        overflow: hidden;
      }
      .placement-top-left {
        bottom: 52px;
        right: 0;
      }
      .placement-top-right {
        bottom: 52px;
        left: 0;
      }
      .placement-bottom-left {
        top: 52px;
        right: 0;
      }
      .placement-bottom-right {
        top: 52px;
        left: 0;
      }
      .search-bar {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        gap: 12px;
        border-radius: 12px;
        background: #f2f2f2;
        padding: 0 12px;
        overflow: hidden;
        &:hover {
          border: 1px solid #dfdfdf;
          background: #fff;

          .search {
            background: #fff;
          }
        }

        .search:focus {
          background: #fff;
        }

        &:has(.search:focus) {
          border: 1px solid #dfdfdf;
          background: #fff;
        }
      }

      .search {
        width: 100%;
        outline: none;
        border: none;
        margin: 0;
        color: #222;
        background: #f2f2f2;
        font-family: "Poppins-Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      .search::placeholder {
        color: #a6a6a6;
      }
      .search-img {
        width: 24px;
        height: 24px;
        filter: invert(79%) sepia(0%) saturate(700%) hue-rotate(136deg) brightness(84%) contrast(90%);
      }
      .br-line {
        margin-top: 16px;
        margin-left: -16px;
        width: 120%;
        height: 1px;
        background: #dfdfdf;
      }
      .list-entries {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 13px;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
      .list-entries::-webkit-scrollbar {
        display: none;
      }
      .size-6-countries {
        height: 373px;
      }
      .size-5-countries {
        height: 295px;
      }
      .size-4-countries {
        height: 255px;
      }
      .country {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        align-items: flex-start;
        cursor: pointer;
      }
      .country:hover {
        opacity: 0.75;
      }
      .name {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 4px;
      }
      .black {
        font-family: "Poppins-Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
        color: #222;
      }
      .grey {
        font-family: "Poppins-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 0;
        color: #5c5c5c;
      }
      .selected-country {
        display: inline-flex;
        justify-content: space-between;
        align-items: flex-start;
        cursor: not-allowed;
        width: 100%;
      }
      .selected {
        font-family: "Poppins-Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
        color: #a6a6a6;
      }
      .selected-12px {
        font-family: "Poppins-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 0;
        color: #a6a6a6;
      }
      .info-text {
        font-family: "Poppins-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
      .loading-start {
        opacity: 0;
      }
      .loading-false {
        opacity: 1 !important;
      }
      .red-img {
        filter: invert(51%) sepia(43%) saturate(791%) hue-rotate(314deg) brightness(92%) contrast(95%);
      }
      .noscroll {
      }
    `,
  ],
})
export class CountryCodePickerComponent implements OnInit {
  codePickerShown: boolean = false
  insideClick: boolean
  currentCode: string = ""
  currentNr: string = ""
  countryCodes: RestCountryCodesDTO[] = []
  currentFullNr: string
  searchText: string
  PhoneNumberInvalid = false
  codePickerIsLoading: boolean = true
  disableAnimations: boolean = true
  defaultInfo: InputInfo

  @Input() withPhoneNumber?: boolean = false
  @Input() maxWidth?: string = "312px"
  @Input() dropDownSize?: DropDownSize = "6-countries"
  @Input() phoneNr: string = ""
  @Input() placement: Placement = "bottom-left"
  @Input() defaultCurrentCode: string = ""
  @Input() info: InputInfo = {
    showInfo: false,
    type: "info",
    text: "Info",
  }

  @Output() phoneNumber: EventEmitter<string> = new EventEmitter<string>()
  @Output() invalidPhoneNumber: EventEmitter<boolean> = new EventEmitter<boolean>()

  @HostListener("document:click", ["$event", "$event.target"])
  public onClick(): void {
    if (this.insideClick === false) {
      this.codePickerShown = false
      this.currentFullNr = this.currentCode + this.currentNr
      this.searchText = ""
    } else {
      this.insideClick = false
      this.codePickerIsLoading = false
    }
  }
  @HostListener("wheel", ["$event"])
  public noScroll(event: Event): void {
    event.preventDefault()
  }

  constructor(private userService: UserApiService, public iconService: IconImageService, private securityService: SecurityService) {
    this.codePickerShown = false
    this.disableAnimations = true
    this.codePickerIsLoading = true
  }

  ngOnInit(): void {
    this.defaultInfo = JSON.parse(JSON.stringify(this.info))
    this.codePickerShown = false
    this.disableAnimations = true
    this.initializeComponent()
  }

  public onKeyDown(event: KeyboardEvent) {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault()
    }
  }

  private initializeComponent(): void {
    this.codePickerIsLoading = true
    this.userService.V2Lookup.getCountryCodes().subscribe((country) => {
      this.countryCodes = country
      setTimeout(() => {
        this.codePickerShown = false
        this.codePickerIsLoading = false
        this.disableAnimations = false
      }, 500)
    })
    this.currentCode = this.getCodeFromPhone()
    if (this.currentCode === "" && this.defaultCurrentCode) {
      this.currentCode = this.defaultCurrentCode
    }
    if (this.phoneNr !== null) this.currentNr = this.phoneNr.replace(this.getCodeFromPhone(), "")
  }

  public updatePhoneNumber() {
    this.currentFullNr = this.currentCode + this.currentNr
    this.checkPhoneValid()
  }
  private getCodeFromPhone(): string {
    var countryCode = ""
    if (this.phoneNr?.length > 4) {
      const phoneUtil = PhoneNumberUtil.getInstance()
      const phone = phoneUtil.parseAndKeepRawInput(this.phoneNr)
      countryCode = "+" + phone.getCountryCode()
    }
    return countryCode
  }

  private checkPhoneValid() {
    if (this.withPhoneNumber) {
      if (this.currentNr === "") {
        this.invalidPhoneNumber.emit(true)
        this.phoneNumber.emit(this.currentFullNr)
      } else if (this.currentNr.length < 2) {
        this.info.showInfo = true
        this.info.text = "Incorrect phone number format."
        this.info.type = "error"
      } else if (this.currentCode !== undefined || this.currentCode !== null || this.currentCode !== "") {
        this.securityService.v2PhoneValidator.ValidatePhoneNumber(this.currentCode.replace("+", "%2B") + this.currentNr).subscribe((response) => {
          if (!response.IsValid) {
            this.info.showInfo = true
            this.info.text = "Incorrect phone number format."
            this.info.type = "error"
          } else {
            this.info = JSON.parse(JSON.stringify(this.defaultInfo))
          }
          this.PhoneNumberInvalid = !response.IsValid
          this.invalidPhoneNumber.emit(this.PhoneNumberInvalid)
          this.phoneNumber.emit(this.currentFullNr)
        })
      }
    } else {
      this.invalidPhoneNumber.emit(this.PhoneNumberInvalid)
      this.phoneNumber.emit(this.currentFullNr)
    }
  }

  public toggleDropdown() {
    this.codePickerShown = !this.codePickerShown
  }

  getHeight(): string {
    if (this.codePickerShown && this.countryCodes.length > 0) return this.dropDownSize
    else return "0"
  }

  public pickCountryCode(country: RestCountryCodesDTO) {
    this.currentCode = "+" + country.CallingCodes[0]
    this.currentFullNr = this.currentCode + this.currentNr
    this.checkPhoneValid()
    this.codePickerShown = false
    this.disableAnimations = true
  }

  public reset(): void {
    //this.currentCode = ""
    this.currentNr = ""
    this.currentFullNr = ""
    this.info = this.defaultInfo
    this.PhoneNumberInvalid = false
    this.searchText = ""
    this.codePickerShown = false
  }
}
export type DropDownSize = "4-countries" | "5-countries" | "6-countries"
export type Placement = "top-left" | "top-right" | "bottom-left" | "bottom-right"
