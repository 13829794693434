import { Injectable } from "@angular/core"
import * as iso3166 from "iso-3166-2"

export class Country {
  Alpha2Code!: string
  Name!: string

  constructor(alpha2Code: string, name: string) {
    this.Alpha2Code = alpha2Code
    this.Name = name
  }
}

export class Subdivision {
  RegionCode!: string
  Name!: string

  constructor(regionCode: string, name: string) {
    this.RegionCode = regionCode
    this.Name = name
  }
}

@Injectable({
  providedIn: "root",
})
export class AddressService {
  private _countries: Country[] = []
  public get countries(): Country[] {
    return this._countries
  }

  private _subdivisionMap: Map<string, Subdivision[]> = new Map()

  constructor() {
    this._countries = Object.keys(iso3166.data)
      .map((k) => new Country(k, iso3166.data[k].name))
      .sort((a, b) => (a.Name > b.Name ? 1 : -1))
  }

  public getSubdivisions(alpha2Code: string): Subdivision[] {
    if (!alpha2Code) {
      return []
    }
    const country = iso3166.country(alpha2Code)
    if (!country) {
      console.debug("Country not found", alpha2Code)
      return []
    }

    if (!this._subdivisionMap.has(alpha2Code)) {
      this._subdivisionMap[alpha2Code] = Object.keys(country.sub)
        .map((subDivCode) => new Subdivision(iso3166.subdivision(subDivCode)!.regionCode, iso3166.subdivision(subDivCode)!.name))
        .sort((a, b) => (a.Name > b.Name ? 1 : -1))
    }
    return this._subdivisionMap[alpha2Code]
  }

  public getCountryByAlpha3Code(alpha3Code: string): string {
    const country = iso3166.country(alpha3Code)
    return country?.code
  }
}
