<ng-container *ngIf="!alphabetical">
  <img *ngIf="showIcon && sortKey === wantedSortKey; else elseBlockHigh" src="assets/shared/descending_enabled.svg" />
  <ng-template #elseBlockHigh><img *ngIf="showIcon" src="assets/shared/descending.svg" /></ng-template>
  <img *ngIf="!showIcon && sortKey === wantedSortKey; else elseBlockLow" src="assets/shared/ascending_enabled.svg" />
  <ng-template #elseBlockLow><img *ngIf="!showIcon" src="assets/shared/ascending.svg" /></ng-template>
</ng-container>
<ng-container *ngIf="alphabetical">
  <img *ngIf="showIcon && sortKey === wantedSortKey; else elseBlockHigh" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons/descending_alphabetical.svg" />
  <ng-template #elseBlockHigh><img *ngIf="showIcon" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons/descending_alphabetical_gray.svg" /></ng-template>
  <img *ngIf="!showIcon && sortKey === wantedSortKey; else elseBlockLow" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons/ascending_alphabetical.svg" />
  <ng-template #elseBlockLow><img *ngIf="!showIcon" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons/ascending_alphabetical_gray.svg" /></ng-template>
</ng-container>
