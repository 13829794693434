import { ElementRef, Component, Input, HostListener, Output, EventEmitter, OnInit, ViewChild, Renderer2 } from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"

@Component({
  selector: "app-round-drop-down-list",
  template: `
    <div class="additional-charges-div">
      <label class="additional-charges-form" [ngStyle]="{ border: border ? 'solid 1px #979797' : 'solid 1px #fff' }" [ngClass]="!disable ? 'additional-charges-clickable' : ''" [attr.disabled]="disable ? '' : null" (click)="onFieldClicked()">
        <span class="dropDownTitle">{{ dropDownTitle }}</span>
        <br />
        <div class="dropDownContent">{{ currentValue }}</div>
        <br />
      </label>
      <div *ngIf="Icon === ''; else elseIcon">
        <img src="/assets/shared/expand-small.svg" class="code-changeable-img disable-selection" [ngClass]="!disable ? 'additional-charges-clickable' : ''" (click)="onFieldClicked()" #arrowImage />
      </div>
      <ng-template #elseIcon>
        <img src="{{ Icon }}" class="code-changeable-img disable-selection" [ngClass]="!disable ? 'additional-charges-clickable' : ''" (click)="onFieldClicked()" />
      </ng-template>

      <div class="search-box-wrapper">
        <app-search-box
          [currentValue]="currentValue"
          [showSearchField]="showSearchField"
          [maxHeight]="maxHeight"
          [showBox]="!hideSearchBox"
          [showHeaders]="false"
          [alphabeticalList]="list"
          [useFixedPosition]="useFixedPosition"
          (output)="onOutput($event)"
          [centerItem]="centerSearchBoxItem"></app-search-box>
      </div>
    </div>
  `,
  styles: [
    `
                       /* Chrome, Safari, Edge, Opera */
                       input::-webkit-outer-spin-button,
                       input::-webkit-inner-spin-button {
                         -webkit-appearance: none;
                         margin: 0;
                       }
                       /* Firefox */
                       input[type="number"] {
                         -moz-appearance: textfield;
                       }
                       .dropDownTitle {
                         font-size: 70%;
                         font-family: "Poppins-Regular";
                         font-style: normal;
                         font-weight: 400;
                         font-size: 10px;
                         line-height: 130%;
                         color: #61566e;
                       }
                       .dropDownContent {
                         font-family: "Poppins-Regular";
                         font-style: normal;
                         font-weight: 400;
                         font-size: 13px;
                         line-height: 140%;
                         color: #333333;
                       }
                       .disable-selection {
                         -webkit-touch-callout: none;
                         -webkit-user-select: none;
                         -khtml-user-select: none;
                         -moz-user-select: none;
                         -ms-user-select: none;
                         user-select: none;
                       }
                       .search-box-wrapper {
                         margin-top: 5px;
                         position: absolute;
                         width: 100%;
                       }
                       .additional-charges-form {
                         background-color: #ffffff;
                         width: 100%;
                         height: 45px;
                         border-radius: 15px;
                         padding-inline-start: 8px;
                         padding-top: 3px;
                         font-family: Lato;
                         font-size: 13px;
                         font-weight: normal;
                         font-stretch: normal;
                         font-style: normal;
                         line-height: normal;
                         letter-spacing: normal;
                         color: #333333;
                         border: 1px solid #f1f1f1;
                         overflow: hidden;
                         text-overflow: ellipsis;
                         white-space: nowrap;
                         padding-right: 24px;
                         padding-left: 15px;
                       }
                       .additional-charges-clickable {
                         cursor: pointer;
                       }
                       .additional-charges-div {
                         position: relative;
                         width: 100%;
                       }
                       .additional-charges-img {
                         position: absolute;
                         left: 52px;
                         top: 8px;
                       }
                       .code-changeable-img {
                         position: absolute;
                         right: 8px;
                         top: 11.5px;
                       }
                       .spacer {
                         padding-bottom: 4px;
                       }
                     `,
  ],
  animations: [],
})
export class RoundDropDownListComponent implements OnInit {
  @Input() disable = false
  @Input() list: string[] = []
  @Input() currentValue = "Select an option from the menu"
  @Input() dropDownTitle = "Title"
  @Input() useFixedPosition = false
  @Input() showSearchField = true
  @Input() maxHeight: number = 259
  @Input() border: boolean
  @Input() Icon: string = ""
  @Input() centerSearchBoxItem: boolean = false
  @Output() changedValue = new EventEmitter<string>()

  @ViewChild("arrowImage") arrowImage

  public currentCurrency = 0

  public currencyForm: UntypedFormGroup
  public hideSearchBox = true

  @HostListener("document:click", ["$event"])
  clickedOutside(event) {
    if (!this.eRef.nativeElement.contains(event.target) && !this.hideSearchBox) {
      this.hideSearchBox = true
      if (this.Icon === "") {
        this.arrowImage.nativeElement.style.transform = `rotate(${this.hideSearchBox ? 0 : 180}deg)`
      }
    }
  }

  constructor(
    private eRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {}

  onOutput(value: string) {
    this.currentValue = value
    this.changedValue.emit(value)
  }

  onFieldClicked() {
    if (!this.disable) {
      this.hideSearchBox = !this.hideSearchBox
      if (this.Icon === "") {
        this.arrowImage.nativeElement.style.transform = `rotate(${this.hideSearchBox ? 0 : 180}deg)`
      }
    }
  }
}
