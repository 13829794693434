import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from "@angular/core"
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, AbstractControl, ValidationErrors, UntypedFormControl, FormControlDirective, ControlContainer } from "@angular/forms"
import { EnumOption } from "app/api/booking/models/enum-option"

@Component({
  selector: "ag-radio-group",
  templateUrl: "./ag-radio-group.component.html",
  styleUrls: ["./ag-radio-group.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgRadioGroupComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AgRadioGroupComponent),
      multi: true,
    },
  ],
})
export class AgRadioGroupComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() options: any[] = []

  @Input() optionValue?: string
  @Input() optionText?: string
  @Input() optionSubText?: string
  @Input() optionIcon?: string
  @Input() specificWidth?: string

  @ViewChild(FormControlDirective, { static: true })
  formControlDirective!: FormControlDirective

  @Input() formControl!: UntypedFormControl
  @Input() formControlName!: string
  @Input() size?: RadioOptionSize = "default"

  @ViewChild("viewControl") viewControl?: ElementRef

  isLarge = false
  isSmall = false

  get control(): UntypedFormControl {
    return this.formControl || <UntypedFormControl>this.controlContainer?.control?.get(this.formControlName || "")
  }

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.isLarge = this.size === "large"
    this.isSmall = this.size === "small"
  }

  writeValue = (value: string): void => this.formControlDirective?.valueAccessor?.writeValue(value)
  registerOnChange = (fn: any): void => this.formControlDirective?.valueAccessor?.registerOnChange(fn)
  registerOnTouched = (fn: any): void => this.formControlDirective?.valueAccessor?.registerOnTouched(fn)

  validate(control: AbstractControl): ValidationErrors | null {
    return this.control.valid ? null : control.errors
  }
}

export type RadioOptionSize = "large" | "default" | "small"
