import { Component, OnInit, Inject } from "@angular/core"
import { AuthenticationService } from "app/shared/services/authentication.service"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"

@Component({
  selector: "app-charge-bill-dialog",
  templateUrl: "./charge-bill-dialog.component.html",
  styleUrls: ["./charge-bill-dialog.component.scss"],
})
export class ChargeBillDialogComponent implements OnInit {
  dialogTitle: string
  dialogInfo: string
  dialogQuestion: string
  dialogOKButtonName: string
  dialogImage: string
  dialogReason: string
  reason: string
  isLoading: boolean
  employee: string
  amount: number

  constructor(
    public dialogRef: MatDialogRef<ChargeBillDialogComponent>,
    public authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.dialogInfo = this.data.info
    this.dialogTitle = this.data.title
    this.dialogQuestion = this.data.question
    this.dialogOKButtonName = this.data.okButtonName
    this.dialogImage = this.data.image
    this.dialogReason = this.data.reason
  }
  closeDialog() {
    this.dialogRef.close("close")
  }

  setSpinnerFalse() {
    this.isLoading = false
  }
}
