import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-room-service",
  templateUrl: "./room-service.component.html",
  styleUrls: ["./room-service.component.scss"],
})
export class RoomServiceComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
