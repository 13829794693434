import { Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core"
import { AbstractControl, ControlValueAccessor, UntypedFormGroup, FormGroupDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms"
import { Subscription } from "rxjs"

@Component({
  selector: "ag-input-currency-v2",
  templateUrl: "./ag-input-currency-v2.component.html",
  styleUrls: ["./ag-input-currency-v2.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgInputCurrencyV2Component),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AgInputCurrencyV2Component),
      multi: true,
    },
  ],
})
export class AgInputCurrencyV2Component implements ControlValueAccessor, OnDestroy, Validator, OnInit {
  @Input() formGroupName!: string
  @Input() unit: string = ""
  @Input() label!: string
  @Input() className: string = "small" //give large or small - you can also give a new input name and make a new css class

  @Input() hint?: string
  showHint: boolean = false

  @ViewChild("viewControl") viewControl?: ElementRef

  public formGroup!: UntypedFormGroup
  subscriptions: Subscription[] = []

  constructor(private rootFormGroup: FormGroupDirective, private renderer: Renderer2) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe())
  }

  ngOnInit(): void {
    this.formGroup = this.rootFormGroup.control.get(this.formGroupName) as UntypedFormGroup
    this.subscriptions.push(
      this.formGroup.valueChanges.subscribe((value) => {
        value.unit = this.unit
        this.onChange(value)
        this.onTouched()
        this.showHint = false
      })
    )
  }

  writeValue(value: any): void {
    this.formGroup.setValue(value)
  }

  onChange = (_: any) => {}
  onTouched = () => {}
  registerOnChange = (fn: any): void => (this.onChange = fn)
  registerOnTouched = (fn: any): void => (this.onTouched = fn)

  validate(control: AbstractControl): ValidationErrors | null {
    const unitErrors = this.formGroup.get("unit")?.errors
    const valueErrors = this.formGroup.get("value")?.errors
    return unitErrors || valueErrors
      ? {
          unit: unitErrors,
          value: valueErrors,
        }
      : null
  }
}
