import { Injectable } from "@angular/core"
import { ApiService } from "app/shared/services/api.service"
import { Observable } from "rxjs"
import { API_PAYMENT, API_PAYMENTV1, API_PAYMENTV2, API_PAYMENTV4, API_PAYMENTV5 } from "app/app-settings"
import { UpdateTaxRateHotelDTO } from "./update-tax-rate-hotel"
import { BookingPaymentDetails } from "./models/V4Payment"
import { Site } from "@shared/models/site"
import { DepositRequest, DepositResponse, PMSDetails, PayByLinkRequest, PayByLinkRequestv2, PayByLinkResponseV2, PayByLinkResponse, Payment, V2PayByLink, ReleaseRequest, PayByLinkActivityLog } from "./models/V7Payment"
import { PaymentMethodTranslationResponse } from "./models/V2PaymentMethod"
import { PostToPmsRequest } from "./models/V5Payment"
import PayByLink from "./models/payByLink"
import { CreditCardOnFile } from "@shared/models/credit-card-on-file/CreditCardOnFile"

@Injectable({
  providedIn: "root",
})
export class PaymentApiService {
  constructor(private apiService: ApiService) {}

  /** Tax */

  /** Fetch tax rate of for a hotel */
  public getHotelTaxRate = (hotelId: string): Observable<number | null> => this.apiService.get<number | null>(`${API_PAYMENT}Tax/Hotel/${hotelId}`)

  /** Update taxrate for a Hotel */
  public updateHotelTax = (request: UpdateTaxRateHotelDTO): Observable<UpdateTaxRateHotelDTO> => this.apiService.put<UpdateTaxRateHotelDTO>(`${API_PAYMENT}Tax/Hotel`, request)

  /** Get site from payment */
  public getSite = (hotelId: string): Observable<Site> => this.apiService.get<Site>(`${API_PAYMENTV1}Site/hotel/${hotelId}`)

  public CreditCardOnFile = {
    getCreditCardOnFilePMS: (hotelId: string, pmsBookingNumber: string, reservationNumber: string): Observable<CreditCardOnFile[]> =>
      this.apiService.get<CreditCardOnFile[]>(`${API_PAYMENTV1}CreditCardOnFile/PmsBookingNumber?hotelId=${hotelId}&pmsBookingNumber=${pmsBookingNumber}&reservationNumber=${reservationNumber}`),
  }

  public Site = {
    updateSite: (hotelId: string, hotelEmailList: string): Observable<any> => this.apiService.post(`${API_PAYMENTV1}Site`),
  }

  public PayByLink = {
    getPayByLinks: (hotelId: string, fromDate: string, toDate: string): Observable<V2PayByLink[]> => this.apiService.get(`${API_PAYMENTV1}PayByLink/Hotel/${hotelId}/Creation/Range?from=${fromDate}&to=${toDate}`),
    createPayByLink: (request: PayByLinkRequestv2): Observable<any> => this.apiService.post(`${API_PAYMENTV1}PayByLink`, request),
    getPayByLink: (hotelId: string, billId: string): Observable<PayByLinkResponseV2> => this.apiService.get(`${API_PAYMENTV1}PayByLink?hotelId=${hotelId}&billId=${billId}`),
    getPayByLinkForBooking: (hotelId: string, PmsBookingNumber: string, reservationNumber: string): Observable<PayByLinkResponseV2> => this.apiService.get(`${API_PAYMENTV1}PayByLink/Booking?hotelId=${hotelId}&pmsBookingNumber=${PmsBookingNumber}&reservationNumber=${reservationNumber}`),
    disablePayByLink: (hotelId: string, billId: string): Observable<any> => this.apiService.put(`${API_PAYMENTV1}PayByLink/Disable?hotelId=${hotelId}&billId=${billId}`),
    resendPayByLink: (hotelId: string, billId: string): Observable<V2PayByLink> => this.apiService.put(`${API_PAYMENTV1}PayByLink/Resend?hotelId=${hotelId}&billId=${billId}`),
    getActivityLog: (billId: string): Observable<PayByLinkActivityLog[]> => this.apiService.get(`${API_PAYMENTV1}PayByLink/ActivityLog?billId=${billId}`),
  }

  public V2PaymentMethod = {
    /**Payment method translation from a type and gateway */
    getPaymentMethodTranslation: (gateway: string, paymentMethodName: string): Observable<PaymentMethodTranslationResponse> => this.apiService.get<PaymentMethodTranslationResponse>(`${API_PAYMENTV2}/PaymentMethodTranslation/${gateway}/${paymentMethodName}`),
  }

  public V4Payment = {
    getPaymentInfo: (bookingId: string): Observable<BookingPaymentDetails[]> => this.apiService.get<BookingPaymentDetails[]>(`${API_PAYMENTV4}Payment/Booking/${bookingId}`),
  }

  public V5Payment = {
    PostToPms: (request: PostToPmsRequest): Observable<any> => this.apiService.post<any>(`${API_PAYMENTV5}/Payment/PostToPms`, request),
  }

  public V7Payment = {
    createPayByLink: (payByLinkRequest: PayByLinkRequest): Observable<any> => this.apiService.post<any>(`${API_PAYMENT}V7/Payment/PayByLink`, payByLinkRequest),

    getPayByLink: (hotelid: string, PmsBookingNumber: string, reservationNumber: string): Observable<PayByLinkResponse[]> =>
      this.apiService.get<PayByLinkResponse[]>(`${API_PAYMENT}V7/Payment/PayByLink/Booking?hotelId=${hotelid}&pmsBookingNumber=${PmsBookingNumber}&reservationNumber=${reservationNumber}`),

    getPmsPaymentDetails: (hotelId: string, PmsBookingNumber: string, reservationNumber: string): Observable<PMSDetails> => this.apiService.get<PMSDetails>(`${API_PAYMENT}/V7/Payment/PMS/Details?hotelId=${hotelId}&pmsBookingNumber=${PmsBookingNumber}&reservationNumber=${reservationNumber}`),

    getPaymentsList: (hotelId: string, pmsBookingNumber: string, reservationNumber: string, bookingid: string): Observable<Payment[]> =>
      this.apiService.get<Payment[]>(`${API_PAYMENT}V7/Payment/PayByLink/Booking/Payment/List?hotelId=${hotelId}&pmsBookingNumber=${pmsBookingNumber}&reservationNumber=${reservationNumber}&bookingId=${bookingid}`),

    getDepositForBooking: (hotelId: string, pmsBookingNumber: string, reservationNumber: string): Observable<DepositResponse[]> =>
      this.apiService.get<DepositResponse[]>(`${API_PAYMENT}V7/Payment/Deposit/Booking?hotelId=${hotelId}&pmsBookingNumber=${pmsBookingNumber}&reservationNumber=${reservationNumber}`),

    updateDeposit: (deposit: DepositResponse): Observable<any> => this.apiService.put(`${API_PAYMENT}V7/Payment/Deposit`, deposit),

    refundDeposit: (deposit: DepositRequest): Observable<any> => this.apiService.put(`${API_PAYMENT}V7/Payment/Deposit/Refund`, deposit),

    releasePreAuth: (releaseRequest: ReleaseRequest): Observable<any> => this.apiService.post(`${API_PAYMENT}V7/Payment/PreAuthorisation/Cancel`, releaseRequest),
  }
}
