import { trigger, transition, style, animate } from "@angular/animations"
import { ChangeDetectionStrategy, Component, Input } from "@angular/core"

@Component({
  selector: "ag-tooltip",
  templateUrl: "./ag-tooltip.component.html",
  styleUrls: ["./ag-tooltip.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger("tooltip", [transition(":enter", [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]), transition(":leave", [animate(300, style({ opacity: 0 }))])])],
})
export class AgTooltipComponent {
  @Input() text: string = ""
}
