<ng-container *ngIf="tagType === 'text' || tagType === 'email'">
  <div style="display: flex; flex-direction: column; gap: 8px">
    <div [ngClass]="(isInputFocused && !disable) || (isInputHovered && !disable) ? 'main-container-focused' : 'main-container'" [ngStyle]="{ width: width ? width : '', border: errorMessage ? '1px solid #E55E5E' : '' }" (mouseenter)="onEnter()" (mouseleave)="onLeave()">
      <div class="tag-container">
        <ng-container *ngIf="userList?.length > 0">
          <div class="tag" *ngFor="let user of userList" [ngStyle]="{ 'background-color': tagsBackgroundColor }">
            <div class="initials">
              {{ getInitials(user.fullName) }}
            </div>
            {{ user.fullName }}
            # {{ user.pmsBookingNumber }}
            <img [src]="iconService.Icon.cross" style="height: 12px; width: 12px; cursor: pointer" (click)="removeUser(user)" />
          </div>
        </ng-container>
        <ng-container *ngIf="tagsList?.length > 0">
          <div class="tag" *ngFor="let tag of tagsList" [ngStyle]="{ 'background-color': tagsBackgroundColor, border: isEmailInvalid(tag) ? '1px solid #E55E5E' : '' }">
            {{ tag }}
            <img [src]="iconService.Icon.cross" style="height: 12px; width: 12px; cursor: pointer" (click)="removeTag(tag)" />
          </div>
        </ng-container>
        <input
          type="text"
          class="input"
          [ngClass]="(isInputFocused && !disable) || (isInputHovered && !disable) ? 'input-focused' : 'input'"
          #input
          [disabled]="disable"
          [placeholder]="tagsList?.length > 0 ? '' : placeholder"
          [(ngModel)]="inputValue"
          (focus)="onFocus()"
          (blur)="onBlur()"
          (keydown.enter)="addTag()" />
      </div>
    </div>
    <div *ngIf="errorMessage" class="error">
      <img class="error-message" [src]="iconService.Icon.cross_1_5" />
      {{ errorMessage }}
    </div>
  </div>
</ng-container>
<ng-container *ngIf="tagType === 'permanent'">
  <div class="permanent-main-container" [ngStyle]="{ width: width ? width : '' }">
    <div class="tag-section">
      <div class="queue-container">
        <img class="queue-img" [src]="iconService.Icon.queue" />
      </div>
      <div class="tag-container">
        <ng-container *ngIf="tagsList?.length > 0">
          <div class="permanent-tag" *ngFor="let tag of tagsList" [ngStyle]="{ 'background-color': tagsBackgroundColor }">
            {{ tag }}
          </div>
        </ng-container>
        <ng-container *ngIf="userList?.length > 0">
          <div *ngFor="let user of userList" class="permanent-tag" [ngStyle]="{ 'background-color': tagsBackgroundColor }">
            <div class="initials">
              {{ getInitials(user.fullName) }}
            </div>
            {{ user.fullName }}
            # {{ user.pmsBookingNumber }}
          </div>
          <div class="guest-tag" *ngIf="nonShownTags">
            {{ nonShownTags + " " + "guests" }}
          </div>
        </ng-container>
      </div>
    </div>
    <div style="height: 88px; width: 88px; display: flex; flex-direction: column; justify-content: flex-end">
      <div class="button-container">
        <div class="button">
          <img style="height: 16px; width: 16px" [src]="iconService.Icon.suitcase" />
        </div>
        <div class="button">
          <img style="height: 16px; width: 16px" [src]="iconService.Icon.check_1" />
        </div>
      </div>
    </div>
  </div>
</ng-container>
