<div class="content-container">
  <div class="paths-container" *ngIf="hasPath()">
    <div *ngFor="let item of paths">
      <div class="p-item" *ngIf="item?.Link">
        <div class="path-back" [ngStyle]="{ 'font-weight': bold ? '500' : '' }" routerLink="{{ item?.Link }}">
          {{ item?.Name }}
        </div>
        <img [src]="iconService.Icon.right_1_5" class="arrow" />
      </div>
    </div>
  </div>
  <div class="current-path" [ngStyle]="{ 'font-weight': bold ? '700' : '' }">
    {{ currentPath }}
  </div>
</div>
