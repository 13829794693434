import { ContextBoxStrategy } from "../strategy/context-box-strategy/context-box-strategy"
import { FullViewContextBoxStrategy } from "../strategy/context-box-strategy/fullview-context-box-strategy"
import { GraphColorStrategy } from "../strategy/graph-color-strategy/graph-color-strategy"
import { StackedbarColorStrategy } from "../strategy/graph-color-strategy/stackedbar-color-strategy"
import { FillDrawStrategy } from "../strategy/graph-draw-strategy/fill-draw-strategy"
import { GraphDrawStrategy } from "../strategy/graph-draw-strategy/graph-draw-strategy"
import { GraphTypeStrategy } from "../strategy/graph-type-strategy/graph-type-strategy"
import { StackedbarTypeStrategy } from "../strategy/graph-type-strategy/stackedbar-type-strategy"
import { NavigatorgraphTypeStrategy } from "../strategy/navigatorgraph-type-strategy/navigatorgraph-type-strategy"
import { StackedbarNavigatorgraphTypeStrategy } from "../strategy/navigatorgraph-type-strategy/stackedbar-navigatorgraph-type-strategy"
import { GraphFactory } from "./graph-factory"

export class StackedbarGraphFactory implements GraphFactory {
  produceGraphDrawStrategy(): GraphDrawStrategy {
    return new FillDrawStrategy()
  }
  produceContextBoxStrategy(): ContextBoxStrategy {
    return new FullViewContextBoxStrategy()
  }
  produceGraphColorStrategy(): GraphColorStrategy {
    return new StackedbarColorStrategy()
  }
  produceGraphTypeStrategy(): GraphTypeStrategy {
    return new StackedbarTypeStrategy()
  }
  produceNavigatorGraphTypeStrategy(): NavigatorgraphTypeStrategy {
    return new StackedbarNavigatorgraphTypeStrategy()
  }
}
