import { Component, Input } from "@angular/core"

@Component({
  selector: "app-journey-progress-wheel",
  templateUrl: "./journey-progress-wheel.component.html",
  styleUrls: ["./journey-progress-wheel.component.scss"],
})
export class JourneyProgressWheelComponent {
  @Input() percentage: number = 0
  @Input() wheelColor: string = "grey"
  @Input() adjacentText: string = ""
  @Input() contextBoxText: string = ""
  @Input() journeyIconName: string = ""

  journeyWheelImagePath = "/assets/journey/JourneyCircles/"
  journeyInsideIconPath = "/assets/journey/JourneyIcons/"

  constructor() {}
}
