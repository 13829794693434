import { Injectable } from "@angular/core"
import { ApiService } from "app/shared/services/api.service"
import { Observable } from "rxjs"

import { API_SECURITY_V2, API_SECURITY_V1 } from "app/app-settings"
import ValidatorResponse from "./models/v2-phone-validator"

@Injectable({
  providedIn: "root",
})
export class SecurityService {
  constructor(private apiService: ApiService) {}
  public Login = {
    /**Login with employee with optional 2FA*/
    login: (email: string, password: string): Observable<any> => this.apiService.put(`${API_SECURITY_V1}Login/Hotel/Employee`, { Identifier: email, Password: password }),
    /**2FA Email confirmation */
    loginEmailConfirmation: (email: string, code: string): Observable<any> => this.apiService.put(`${API_SECURITY_V1}Login/Hotel/Employee/MultiFactor`, { Identifier: email, ConfirmationCode: code }),
    /**Resend 2FA Email */
    resend2FAEmail: (identifier: string): Observable<any> => this.apiService.post(`${API_SECURITY_V1}Login/Hotel/Employee/MultiFactor/Resend`, { Identifier: identifier }),
  }

  public v2PhoneValidator = {
    ValidatePhoneNumber: (phoneNumber: string): Observable<ValidatorResponse> => this.apiService.get(`${API_SECURITY_V2}Validate?phoneNumber=${phoneNumber}`),
  }
}
