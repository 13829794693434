<div class="dialog-container">
  <button class="button-cancel purple-big" mat-icon-button (click)="closeDialog(false)">
    <mat-icon>clear</mat-icon>
  </button>
  <h1>{{ dialogTitle }}</h1>
  <div class="dialog-info" [innerHTML]="dialogInfo"></div>
  <ng-container *ngIf="dialogImage != null">
    <div class="centered-image">
      <img src="{{ dialogImage }}" />
    </div>
  </ng-container>
  <p>{{ dialogQuestion }}</p>
  <mat-dialog-actions class="dialog-actions">
    <button mat-button class="purple" (click)="closeDialog(true)">{{ dialogOKButtonName }}</button>
  </mat-dialog-actions>
</div>
