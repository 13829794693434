import { ElementRef, Component, Input } from "@angular/core"

import { animate, style, transition, trigger, state } from "@angular/animations"

@Component({
  selector: "app-list-dropdown-animation",
  template: `
    <div class="max-height" [@dropDown]="state">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
                       .max-height {
                         overflow: hidden;
                       }
                     `,
  ],
  animations: [trigger("dropDown", [state("initial", style({ height: "0px" })), state("final", style({ height: "*" })), transition("initial=>final", animate("150ms ease-out")), transition("final=>initial", animate("150ms ease-out"))])],
})
export class NavMenuHotelsListDropdownComponent {
  @Input()
  set expanded(value: boolean) {
    this.expand.show = !this.expand.show
    this.state = value ? "final" : "initial"
  }

  public expand = { show: true }
  public state = "initial"

  constructor(private element: ElementRef) {}
}
