<div class="dialog-container">
  <button class="button-cancel purple-big" mat-icon-button [mat-dialog-close]="true">
    <mat-icon>clear</mat-icon>
  </button>
  <h1>Refund</h1>
  <p>This will refund the selected transaction. You can refund the entire amount or make a partial refund.</p>
  <p>
    <b>{{ totalRefunded }} {{ currency }}</b>
    has been refunded of
    <b>{{ totalCharged }} {{ currency }}</b>
  </p>
  <form [formGroup]="form" (submit)="submit()">
    <div class="flex-row flex-start flex-align-start ag-form-row" style="display: flex; gap: 16px">
      <div style="flex-grow: 0">
        <ag-input-currency formGroupName="amount" [label]="'Amount'" [hint]="getAmountError()"></ag-input-currency>
      </div>
      <div style="flex-grow: 1">
        <ag-input formControlName="reason" [label]="'Reason for refund'" [hint]="'Please provide a reason.'"></ag-input>
      </div>
    </div>
    <div *ngIf="ForwardRefundToPms" style="display: flex; flex-direction: row; justify-content: space-between">
      Post refund to guest’s reservation in {{ PmsName }}
      <mat-checkbox formControlName="forwardToPms"></mat-checkbox>
    </div>
    <mat-dialog-actions class="dialog-actions">
      <button type="submit" mat-button class="primary" [disabled]="isLoading">
        <span *ngIf="!isLoading">Refund</span>
        <app-dot-loader-white-animation *ngIf="isLoading" height="40px"></app-dot-loader-white-animation>
      </button>
    </mat-dialog-actions>
  </form>
</div>
