import { Component, OnInit, Directive, Input, OnDestroy, ElementRef, HostListener } from "@angular/core"
import { QuickActionService } from "app/shared/services/quick-action.service"

@Directive({ selector: "[appQuickAction]" })
export class QuickActionDirective implements OnInit, OnDestroy {
  @Input() actionPos = "left"
  @Input() actionColor = "#fff"
  @Input() actionState = ""
  @Input() quickActions: any[]
  private id: number
  sub: any
  private isOpen = false

  constructor(
    private quickActionService: QuickActionService,
    private element: ElementRef,
  ) {}

  @HostListener("click")
  onMouseEnter(): void {
    if (this.isOpen) {
      this.destroy()
    } else {
      this.id = Math.random()
      this.quickActionService.components.push({
        id: this.id,
        ref: this.element,
        position: this.actionPos,
        actionState: this.actionState,
        color: this.actionColor,
        actions: this.getActions(),
      })
      this.isOpen = true
    }
  }

  // @HostListener('document:click', ['$event'])
  // clickOutside(event): void {
  //   if(this.element.nativeElement.contains(event.target)){
  //     //Do nothing
  //   }
  //   else if (this.isOpen){
  //     this.destroy();
  //   }
  // }

  ngOnInit() {
    this.sub = this.quickActionService.action.subscribe(() => {
      this.destroy()
    })
  }

  ngOnDestroy(): void {
    this.destroy()
    this.sub = null
  }

  destroy(): void {
    const idx = this.quickActionService.components.findIndex((t) => {
      return t.id === this.id
    })
    this.quickActionService.components.splice(idx, 1)
    this.isOpen = false
  }

  getActions(): any[] {
    if (this.actionState === "NoPhoneAndEmail") {
      return [
        { text: "Add phone number", action: "AddPhone" },
        { text: "Add email address", action: "AddEmail" },
        // {text: 'Confirm key hand-out', action: 'KeyCard'}
      ]
    }

    if (this.actionState === "Image") {
      return [{ text: "Preview", action: "PreviewImage" }]
    }

    if (this.actionState === "BlacklistPhone") {
      return [
        { text: "Edit", action: "EditBlackPhone" },
        { text: "Delete", action: "DeleteBlackPhone" },
      ]
    }

    if (this.actionState === "BlacklistEmail") {
      return [
        { text: "Edit", action: "EditBlackEmail" },
        { text: "Delete", action: "DeleteBlackEmail" },
      ]
    }

    if (this.actionState === "OnlyPhoneExists") {
      return [
        { text: "Resend SMS", action: "ResendSMS" },
        { text: "Edit phone number", action: "EditPhone" },
        { text: "Add email address", action: "AddEmail" },
      ]
    }

    if (this.actionState === "OnlyEmailExists") {
      return [
        { text: "Add phone number", action: "AddPhone" },
        { text: "Resend Email", action: "ResendEmail" },
      ]
    }

    return [
      { text: "Edit phone number", action: "EditPhone" },
      { text: "Resend SMS", action: "ResendSMS" },
      { text: "Resend Email", action: "ResendEmail" },
      // {text: 'Confirm key hand-out', action: 'KeyCard'}
    ]
  }
}
