import { EventEmitter, Injectable, Output } from "@angular/core"
import { Router, NavigationStart } from "@angular/router"
import { Observable } from "rxjs"
import { Subject } from "rxjs"
import { IToast, ToastType } from "./itoast"

@Injectable()
export class ToastServiceV2 {
  @Output() undo: EventEmitter<void> = new EventEmitter<void>()

  private subject = new Subject<IToast>()
  private keepAfterRouteChange = false

  constructor(private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false
        } else {
          // clear alert messages
          this.clear()
        }
      }
    })
  }

  getAlert(): Observable<any> {
    return this.subject.asObservable()
  }

  success(options: IToast, keepAfterRouteChange = false, delay = options?.delay || 5000) {
    options.type = ToastType.Success
    this.alert(options, keepAfterRouteChange, delay)
  }

  error(options: IToast, keepAfterRouteChange = false, delay = options?.delay || 5000) {
    options.type = ToastType.Error
    this.alert(options, keepAfterRouteChange, delay)
  }

  info(options: IToast, keepAfterRouteChange = false, delay = options?.delay || 5000) {
    options.type = ToastType.Info
    this.alert(options, keepAfterRouteChange, delay)
  }

  warn(options: IToast, keepAfterRouteChange = false, delay = options?.delay || 5000) {
    options.type = ToastType.Warning
    this.alert(options, keepAfterRouteChange, delay)
  }

  alert(options: IToast, keepAfterRouteChange = false, delay = options?.delay || 5000) {
    this.keepAfterRouteChange = keepAfterRouteChange
    this.subject.next(options)
    setTimeout(() => this.clear(), delay)
  }

  clear() {
    // clear alerts
    this.subject.next(null)
  }

  undoApi() {
    this.undo.emit()
  }

  showDefaultError() {
    this.error({ message: `Connection to the server failed. Please check your connection or contact the AeroGuest team.` })
  }
}
