import { NavigatorgraphTypeStrategy } from "./navigatorgraph-type-strategy"

export class StackedbarNavigatorgraphTypeStrategy implements NavigatorgraphTypeStrategy {
  constructNavigatorGraph(fc: any, xScale: any, yScale: any) {
    return fc
      .seriesWebglBar()
      .xScale(xScale)
      .yScale(yScale)
      .crossValue((d) => d.data.Date)
      .mainValue((d) => d[1])
      .baseValue((d) => d[0])
  }
}
