<div style="height: fit-content; max-height: 100%; width: 100%; position: relative" [hidden]="!showBox ? showBoxClicked() : false">
  <div [ngClass]="!useFixedPosition ? 'container-standard' : 'container-fixed'">
    <div class="form-div" *ngIf="showSearchField">
      <div class="search-bar">
        <input class="search-bar-input" #inputField placeholder="Search" (input)="onInputChange(inputField.value)" />
        <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/magnifier.svg" class="form-img" />
      </div>
    </div>
    <div class="line" *ngIf="chooseMultiple"></div>
    <div class="hovering-item">
      <div class="item" (click)="onSelectAllClicked()" *ngIf="chooseMultiple">
        <label class="disable-selection" [ngClass]="selectedSelectAll ? 'item-text-medium' : 'item-text'">
          <ng-container *ngIf="selectedSelectAll">
            <img class="checkbox" [src]="iconService.Icon.checkbox_tick" />
          </ng-container>
          <ng-container *ngIf="!selectedSelectAll">
            <img class="checkbox" [src]="iconService.Icon.checkbox_empty" />
          </ng-container>
          Select all
        </label>
      </div>
    </div>
    <div class="line" *ngIf="chooseMultiple"></div>
    <div class="top-bottom-padding" [ngStyle]="{ 'max-height': maxHeight - 43 + 'px', 'padding-top': chooseMultiple ? '0' : '8px' }">
      <div [hidden]="list.length < 1">
        <label class="header" [hidden]="isTyping" *ngIf="showHeaders">ALPHABETICAL</label>
        <div class="spacer" *ngIf="showHeaders"></div>
        <div class="items-container">
          <div *ngFor="let item of displayedList">
            <div class="hovering-item">
              <div [ngClass]="checkForDisabled(item.label) ? 'disabled-item' : 'item'" (click)="onItemClicked(item)">
                <label [ngStyle]="{ cursor: checkForDisabled(item.label) ? 'not-allowed' : 'pointer' }" class="disable-selection" [ngClass]="checkSelectedItems(item) ? 'item-text-bold' : 'item-text-left'">
                  {{ item.label }}
                </label>
                <ng-container *ngIf="checkSelectedItems(item)">
                  <img style="height: 16px; width: 16px; pointer-events: none" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/check.svg" />
                </ng-container>
              </div>
            </div>
          </div>
          <div class="no-result-text" *ngIf="displayedList.length === 0">No results</div>
        </div>
      </div>
    </div>
  </div>
</div>
