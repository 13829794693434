import { Injectable } from "@angular/core"
import { ApiService } from "app/shared/services/api.service"
import { Observable } from "rxjs"
import { map, shareReplay } from "rxjs/operators"
import { GetTextRequest } from "./models/get-text-request"
import { GetUserResponse } from "./models/get-user-response"
import { PassportResponse } from "./models/passport-response"
import { RestCountryCodesDTO, RestCountryDTO } from "./models/rest-country"

const USER_V2 = "userV2/"

const API = {
  V2_USER: USER_V2 + "User/",
  V2_LOOKUP: USER_V2 + "User/Lookup/",
  V2_IDENTITY: USER_V2 + "Identity/",
}

@Injectable({
  providedIn: "root",
})
export class UserApiService {
  /** Lookup */
  private $restCountries: Observable<RestCountryDTO[]>

  constructor(private apiService: ApiService) {}

  public V2User = {
    /** Get a user by his id */
    get: (userId: string): Observable<GetUserResponse> => this.apiService.get<GetUserResponse>(`${API.V2_USER}?id=${userId}`),
  }

  public V2Lookup = {
    /** Get country code prefixes */
    getRestCountries: (): Observable<RestCountryDTO[]> => (this.$restCountries ??= this.apiService.get<RestCountryDTO[]>(`${API.V2_LOOKUP}RestCountry`).pipe(shareReplay())),

    getCountryCodes: (): Observable<RestCountryCodesDTO[]> => {
      return this.apiService.get<RestCountryCodesDTO[]>(`${API.V2_LOOKUP}RestCountry`)
    },
  }

  public V2Identity = {
    /** Performs OCR on Base64 string */
    passportDetection: (request: GetTextRequest): Observable<PassportResponse> =>
      this.apiService.post<PassportResponse>(`${API.V2_IDENTITY}Passport/OCR`, request).pipe(
        map((data) => {
          data.ParsedMRZ.DateOfBirth = new Date(data.ParsedMRZ.DateOfBirth)
          data.ParsedMRZ.ExpireDate = new Date(data.ParsedMRZ.ExpireDate)
          return data
        })
      ),
  }
}
