<div class="content-header">
  <div class="path-header xs-hide" *ngIf="hasPath()">
    <div class="path-item">
      <div class="back" (click)="backClicked()">
        <img src="/assets/main-page/back.png" />
      </div>
      <div *ngFor="let item of path; let last = last">
        <div class="p-item" *ngIf="item?.Link">
          <div class="path-back" routerLink="{{ item?.Link }}">{{ item?.Name }}</div>
          <div class="path-back-arrow"></div>
        </div>
        <div class="p-item path-current-container" *ngIf="!item?.Link">
          <div class="path-current-before-arrow"></div>
          <div class="path-current">{{ item?.Name }}</div>
          <div class="path-current-arrow"></div>
        </div>
      </div>
    </div>
  </div>
  <h1 *ngIf="title">{{ title }}</h1>
</div>
