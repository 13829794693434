import { CdkDrag, CdkDragDrop, CdkDragMove, CdkDragRelease, CdkDropList, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop"
import { DOCUMENT } from "@angular/common"
import { Inject, Injectable } from "@angular/core"
import { Observable, Subject } from "rxjs"

@Injectable({ providedIn: "root" })
export class DragDropService {
  dropLists: CdkDropList[] = []
  currentHoverDropListId?: string

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public register(dropList: CdkDropList) {
    this.dropLists.push(dropList)
  }

  public resetList() {
    this.dropLists = []
  }

  dragMoved(event: CdkDragMove<any>) {
    let elementFromPoint = this.document.elementFromPoint(event.pointerPosition.x, event.pointerPosition.y)

    if (!elementFromPoint) {
      this.currentHoverDropListId = undefined
      return
    }

    if (elementFromPoint.classList.contains("no-drop")) {
      this.currentHoverDropListId = "no-drop"
      return
    }

    let dropList = elementFromPoint.classList.contains("cdk-drop-list") ? elementFromPoint : elementFromPoint.closest(".cdk-drop-list")

    if (!dropList) {
      this.currentHoverDropListId = undefined
      return
    }
    //console.log(dropList.id,"dropListId");
    this.currentHoverDropListId = dropList.id
  }

  isDropAllowed(drag: CdkDrag, drop: CdkDropList) {
    if (this.currentHoverDropListId == null) {
      return true
    }

    return drop.id === this.currentHoverDropListId
  }

  dragReleased(event: CdkDragRelease) {
    this.currentHoverDropListId = undefined
  }
}
