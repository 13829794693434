import { Component, OnInit, Inject } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"
import { IconImageService } from "@shared/services/icon-image.service"
import { DoorUnlockCase } from "app/shared/models/journey/journey-list"

@Component({
  selector: "app-journey-door-unlock-cases-dialog",
  templateUrl: "./journey-door-unlock-cases-dialog.component.html",
  styleUrls: ["./journey-door-unlock-cases-dialog.component.scss"],
})
export class JourneyDoorUnlockCasesDialogComponent implements OnInit {
  dialogTitle: string
  doorUnlockCases: DoorUnlockCase[]
  dialogOKButtonName: string

  constructor(public dialogRef: MatDialogRef<JourneyDoorUnlockCasesDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public iconService: IconImageService) {}

  ngOnInit() {
    this.dialogTitle = this.data.title
    this.doorUnlockCases = this.data.doorUnlockCases
    this.dialogOKButtonName = this.data.okButtonName
  }

  closeDialog() {
    this.dialogRef.close(false)
  }
}
