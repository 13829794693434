import { Component, OnInit, Input, ElementRef, Output, EventEmitter } from "@angular/core"
import { UntypedFormGroup, FormBuilder } from "@angular/forms"

@Component({
  selector: "app-labeled-text-field",
  template: `
    <label for="textInput">{{ description }}</label>
    <input type="text" id="textInput" name="textInput" [placeholder]="description" [disabled]="disable" [ngModel]="currentText" (ngModelChange)="onTextChanged($event)" />
  `,
  styles: [
    `
      label {
        margin-top: 24px;
      }
      input {
        width: 100%;
        height: 44px;
        border-radius: 12px;
        padding: 14px 12px;
        border: solid 1px #c8c7cc;
        background-color: #fff;
        font-size: 13px;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        outline: none;
      }

      input:focus {
        border: solid 2px #61566e;
        color: #333;
      }
    `,
  ],
})
export class LabeledTextFieldComponent implements OnInit {
  @Input() disable: boolean = false
  @Input() showInvalid: boolean = false
  @Input() description: string = ""

  @Output() changedText = new EventEmitter<string>()

  public currentText = ""
  public textForm: UntypedFormGroup

  constructor() {}

  ngOnInit() {}

  onTextChanged(value: string) {
    this.currentText = value
    this.changedText.emit(this.currentText)
  }
}
