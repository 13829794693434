import { ContextBoxStrategy } from "./context-box-strategy"

export class FullViewContextBoxStrategy implements ContextBoxStrategy {
  calculateMousePosition(canvas, contextBoxElement, hoveredDataLength, event) {
    const xDomainPercent = ((event.clientX - canvas.getBoundingClientRect().left) / canvas.getBoundingClientRect().width) * (hoveredDataLength - 1)
    const dataIndex = Math.round(xDomainPercent + 0.5)

    return dataIndex
  }
}
