import { Directive, Output, EventEmitter, HostBinding, HostListener } from "@angular/core"

@Directive({
  selector: "[appDragDropImageV2]",
})
export class DragDropImageDirectiveV2 {
  @Output() onFileDropped = new EventEmitter<any>()
  @Output() imageHovered = new EventEmitter<any>()

  @HostBinding("style.border") private border = "2px dashed #DFDFDF"

  // Dragover listener
  @HostListener("dragover", ["$event"]) onDragOver(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    this.border = "2px dashed #4F356E"
    this.imageHovered.emit(true)
  }

  // Dragleave listener
  @HostListener("dragleave", ["$event"]) public onDragLeave(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    this.border = "2px dashed  #DFDFDF"
    this.imageHovered.emit(false)
  }

  // Drop listener
  @HostListener("drop", ["$event"]) public ondrop(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    this.border = "2px dashed  #DFDFDF"
    this.imageHovered.emit(false)
    const files = evt.dataTransfer.files
    if (files.length > 0) {
      this.onFileDropped.emit(files)
    }
  }
}
