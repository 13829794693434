import { ElementRef, Component, Input, Output, EventEmitter } from "@angular/core"

import { animate, style, transition, trigger, state } from "@angular/animations"

@Component({
  selector: "app-animated-menu-item",
  template: `
    <div class="main-container">
      <div class="content-container">
        <ng-content select="[content]"></ng-content>
      </div>
      <div class="menu-container">
        <img class="menu-left disable-selection" src="/assets/shared/reversed_circle.svg" />
        <div class="menu-middle" [@swipe]="menuState">
          <ng-content select="[menu]"></ng-content>
        </div>
        <div class="menu-right" (click)="onExpand()">
          <div class="menu-image-container disable-selection">
            <img class="menu-image disable-selection" src="/assets/shared/more.svg" [ngClass]="menuState === 'initial' ? 'menu-image' : 'menu-image-rotate'" />
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
                       .main-container {
                         width: 100%;
                         height: 66px;
                         display: flex;
                         flex-direction: row;
                       }
                       .content-container {
                         height: 60px;
                         border-radius: 30px;
                         background-color: white;
                         width: 100%;
                         box-shadow: 3px 1px 3px 0 rgba(140, 140, 140, 0.1);
                         margin-top: 2px;
                         overflow: hidden;
                       }
                       .menu-container {
                         display: flex;
                         flex-direction: row;
                         height: 100%;
                         margin-left: -23px;
                         max-width: 100%;
                       }
                       .menu-left {
                         height: 66px;
                         width: 28px;
                       }
                       .menu-middle {
                         margin-top: 2px;
                         height: 60px;
                         background-color: white;
                         overflow: hidden;
                         box-shadow: 3px 1px 3px 0px rgba(140, 140, 140, 0.1);
                       }
                       .menu-right {
                         min-width: 33px;
                         height: 60px;
                         border-radius: 0px 30px 30px 0px;
                         background-color: white;
                         margin-top: 2px;
                         box-shadow: 3px 1px 3px 0 rgba(140, 140, 140, 0.1);
                         cursor: pointer;
                         display: flex;
                         flex-direction: row;
                       }
                       .disable-selection {
                         -webkit-touch-callout: none;
                         -webkit-user-select: none;
                         -khtml-user-select: none;
                         -moz-user-select: none;
                         -ms-user-select: none;
                         user-select: none;
                       }
                       .menu-image-container {
                         align-self: center;
                         margin-right: 12px;
                         display: flex;
                         flex-direction: row;
                       }
                       .menu-image {
                         transform: rotate(90deg);
                         transition: all 0.15s ease-in-out;
                         margin-left: 4px;
                       }
                       .menu-image-rotate {
                         transform: rotate(0deg);
                         transition: all 0.15s ease-in-out;
                         margin-left: 4px;
                       }
                     `,
  ],
  animations: [
    trigger("swipe", [state("initial", style({ width: "0px" })), state("final", style({ width: "*" })), transition("initial=>final", animate("150ms ease-out")), transition("final=>initial", animate("150ms ease-out"))]),
    trigger("reeee", [state("initial", style({ transform: "rotate(-90deg)" })), state("final", style({ transform: "rotate(0deg)" })), transition("initial=>final", animate("1500ms ease-out")), transition("final=>initial", animate("1500ms ease-out"))]),
  ],
})
export class AnimatedMenuItemComponent {
  @Output() onExpanded = new EventEmitter<boolean>()
  public menuState = "initial"

  constructor(private element: ElementRef) {}

  onExpand() {
    this.menuState = this.menuState === "initial" ? "final" : "initial"
    this.onExpanded.emit(this.menuState === "final")
  }
}
