import { Component, Input, OnInit } from "@angular/core"

@Component({
  selector: "ag-tree-leaf",
  templateUrl: "./ag-tree-leaf.component.html",
  styleUrls: ["./ag-tree-leaf.component.scss"],
})
export class AgTreeLeafComponent {
  @Input() public agTitle: string = ""
  @Input() public agDescription: string = ""

  constructor() {}
}
