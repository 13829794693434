import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { AgSize } from "app/shared/components/ag-button/ag-button.component"

@Component({
  selector: "ag-section",
  template: `
    <div [ngClass]="group ? 'divGroup' : ''">
      <section class="content-box ag-size-{{ size }}" [ngClass]="group ? 'inGroup' : ''" #section>
        <div class="content-box-header" *ngIf="sectionTitle" [ngClass]="{ clickable: collapsible }" (click)="open = !open">
          <mat-icon *ngIf="collapsible">{{ open ? "keyboard_arrow_up" : "keyboard_arrow_down" }}</mat-icon>
          <div class="content-box-title">{{ sectionTitle }}</div>
        </div>
        <div *ngIf="!collapsible || open">
          <div class="content-box-content ag-size-{{ size }}" [class.scroll]="height" [style.height]="height" [ngClass]="{ 'virtual-scroll': limitHeight }" (scroll)="onScroll($event)">
            <ng-content></ng-content>
          </div>
        </div>
      </section>
      <div *ngIf="group" class="group-shade-1"></div>
      <div *ngIf="group" class="group-shade-2"></div>
    </div>
  `,
  styles: [
    `
      .scroll {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
      }
      .divGroup {
        position: relative;
      }

      .content-box {
        background: white;
        border-radius: 20px;
        box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        border: 1px solid transparent;
        cursor: pointer;
      }
      .inGroup {
        position: relative;
        z-index: 3;
        margin-bottom: 20px !important;
      }
      .group-shade-1 {
        width: 98%;
        height: 58px;
        position: absolute;
        border-radius: 20px;
        border: 1px solid transparent;
        z-index: 2;
        background-color: #f2f2f2;
        box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.2);
        top: 8px;
        left: 1%;
      }
      .group-shade-2 {
        width: 96%;
        height: 58px;
        position: absolute;
        border-radius: 20px;
        border: 1px solid transparent;
        z-index: 1;
        background-color: #dfdfdf;
        box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.2);
        top: 12px;
        left: 2%;
      }

      .content-box.ag-size-default {
        padding: 32px 0;
        margin-bottom: 24px;
      }

      .content-box.ag-size-small {
        padding: 8px;
        margin-bottom: 8px;
      }

      .content-box-header {
        margin: 0 32px;
      }

      .content-box > div:not(:last-of-type) {
        margin-bottom: 16px;
      }

      .clickable:hover {
        cursor: pointer;
      }

      mat-icon {
        float: right;
        cursor: pointer;
      }

      .content-box-title {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.5px;
        text-align: left;
        color: #333333;
      }

      .content-box-content.ag-size-default {
        padding: 0 32px;
      }

      .virtual-scroll {
        max-height: 400px;
        margin-right: 12px;
        padding-right: 16px;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .scroll-shadow-top {
        box-shadow: inset 0px 10px 8px -10px rgb(140 140 140 / 75%);
      }

      ::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
      }

      /* background of the scrollbar except button or resizer */
      ::-webkit-scrollbar-track {
        background-color: transparent;
      }

      /* scrollbar itself */
      ::-webkit-scrollbar-thumb {
        background-color: #d8d8d8;
        border-radius: 3px;
        border: transparent;
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #babac0;
      }

      /* set button(top and bottom of the scrollbar) */
      ::-webkit-scrollbar-button {
        display: none;
      }
    `,
  ],
})
export class AgSectionComponent implements OnInit {
  @ViewChild("section") section

  @Input() public collapsible: boolean
  @Input() public open: boolean = true
  @Input() public limitHeight: boolean = false
  @Input() public sectionTitle: string
  @Input() public height: string
  @Input() public size: AgSize = "default"
  @Input() public group: boolean = false

  constructor() {}

  ngOnInit(): void {}

  onScroll($event) {
    const virtualbox = $event.srcElement
    const parentElement = virtualbox.parentElement
    if (virtualbox.scrollTop > 0) {
      parentElement.classList.add("scroll-shadow-top")
    } else {
      parentElement.classList.remove("scroll-shadow-top")
    }
  }
}
