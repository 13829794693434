<div [formGroup]="formGroup">
  <div>
    <!-- toggles goes here -->
  </div>

  <ng-container *ngFor="let weekday of weekdays; index as i">
    <div formArrayName="{{ weekday }}">
      <ng-container *ngIf="getArray(weekday).controls.length > 0; else hasElements">
        <div *ngFor="let durationcontrol of getArray(weekday).controls; index as j; first as isFirst; last as isLast">
          <div class="row">
            <div class="daycolumn">
              <div *ngIf="isFirst">
                {{ weekdayMap.get(i) }}
              </div>
            </div>
            <div class="duration" [formGroupName]="j">
              <ag-time formControlName="from" label="From" clockAsset="assets/shared/TimeStart.svg"></ag-time>
              <div class="dash">-</div>
              <ag-time formControlName="until" label="Until" clockAsset="assets/shared/TimeEnd.svg"></ag-time>
            </div>
            <div class="buttons">
              <div class="circle purple" (click)="removeDuration(weekday, j)">
                <img class="icon" src="/assets/shared/trash.svg" />
              </div>
              <div class="circle green" (click)="insertNewDuration(weekday, j + 1)" *ngIf="isLast">
                <img class="icon" src="/assets/shared/plus.svg" />
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #hasElements>
        <div class="row">
          <div class="daycolumn">
            {{ weekdayMap.get(i) }}
          </div>
          <div class="buttons">
            <div class="circle green" (click)="addNewDuration(weekday)">
              <img class="icon" src="/assets/shared/plus.svg" />
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-container>
</div>
