import { Component, Input, OnInit, ElementRef, OnDestroy } from "@angular/core"
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms"
import { Subscription } from "rxjs"

@Component({
  selector: "ag-text-switch",
  templateUrl: "./ag-text-switch.component.html",
  styleUrls: ["./ag-text-switch.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AgTextSwitchComponent,
    },
  ],
})
export class AgTextSwitchComponent implements ControlValueAccessor, OnInit, OnDestroy {
  ngOnDestroy(): void {
    // this.subscriptions.forEach(s => s.unsubscribe());
  }
  ngOnInit(): void {
    // this.subscriptions.push(
    //   this.formGroup.valueChanges.subscribe(value => {
    //     this.onChange(value);
    //     this.onTouched();
    //   })
    // )
  }

  @Input() value: boolean = false
  @Input() option1: string = "No"
  @Input() option2: string = "Yes"
  @Input() small: boolean = false
  @Input() width: string = "200px"

  subscriptions: Subscription[] = []
  onChange = (_value: boolean) => {}
  onTouched = () => {}
  touched = false
  disabled = false

  changeValue(newValue: boolean) {
    if (newValue === this.value) {
      return
    } else {
      this.value = newValue
      this.onChange(this.value)
    }
  }
  writeValue(value: boolean): void {
    this.value = value
  }
  registerOnChange(fn: any): void {
    this.onChange = fn
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched()
      this.touched = true
    }
  }
  onClick(val: boolean) {
    this.markAsTouched()
    this.value = val
    this.onChange(this.value)
  }
}
