import { Component, OnInit, Directive, Input, OnDestroy, ElementRef, HostListener } from "@angular/core"
import { TooltipService } from "app/shared/services/tooltip.service"
import { AdjustPosition, Position } from "./ag-tooltip-v2.component"

@Directive({ selector: "[agTooltipV2]" })
export class AgTooltipDirectiveV2 implements OnDestroy {
  @Input() agTooltipTitle = ""
  @Input() agTooltipBody = ""
  @Input() agTooltipPos: Position = "right"
  @Input() agTooltipAdjust: AdjustPosition = { position: "top", space: 0 }
  @Input("agTooltipCondition") condition: boolean = true
  private id: number

  constructor(private tooltipService: TooltipService, private element: ElementRef) {}

  @HostListener("mouseenter")
  onMouseEnter(): void {
    this.condition = true
    setTimeout(() => {
      if (this.condition) {
        this.push()
      }
    }, 500)
  }

  @HostListener("mouseleave")
  onMouseLeave(): void {
    this.destroy()
  }

  ngOnDestroy(): void {
    this.destroy()
  }

  destroy(): void {
    const idx = this.tooltipService.componentsV2.findIndex((t) => {
      return t.id === this.id
    })
    this.tooltipService.componentsV2.splice(idx, 1)
    this.condition = false
  }

  push() {
    this.id = Math.random()
    this.tooltipService.componentsV2.push({
      id: this.id,
      ref: this.element,
      title: this.agTooltipTitle,
      description: this.agTooltipBody,
      position: this.agTooltipPos,
      adjustPosition: this.agTooltipAdjust,
    })
  }
}
