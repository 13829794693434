import { Component, HostBinding, Input } from "@angular/core"
import { AnimationOptions } from "ngx-lottie"

@Component({
  selector: "ag-label",
  templateUrl: "./ag-label.component.html",
  styleUrls: ["./ag-label.component.scss"],
})
export class AgLabelComponent {
  @HostBinding("style.pointer-events") get pEvents(): string {
    return this.disabled ? "none" : "auto"
  }

  @Input() public label: string
  @Input() public imagePath: string
  @Input() public loading: boolean
  @Input() public disabled: boolean
  @Input() public size: AgSize = "big"
  @Input() public color: AgColor = "Default"
  @Input() public secondaryColor: AgSecondaryColor = "Outlined"
  @Input() public type: AgButtonType = "default"
  @Input() public fontWeight: string

  public options: AnimationOptions = {
    path: "assets/animations/loading/loading_animation_darkblue.json",
    loop: true,
    autoplay: true,
  }

  constructor() {}
}

export type AgSize = "big" | "small"
export type AgColor = "Default" | "Info" | "Success" | "Warning" | "Fail" | "Neutral" | "Ordinary"
export type AgSecondaryColor = "Outlined" | "Standard" | "Dark"
export type AgButtonType = "default" | "withImgLeft" | "withImgRight"
