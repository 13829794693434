import { Pipe, PipeTransform } from "@angular/core"
import { MediaFile } from "@api/utility/models/media-library"

@Pipe({ name: "filterSearchName" })
export class SearchNameFilterPipe implements PipeTransform {
  transform(items: MediaFile[], searchText: string): MediaFile[] {
    if (!items) return []
    if (!searchText) return items

    searchText = searchText.toLowerCase()
    return items.filter((item) => {
      return item.name.toLowerCase().includes(searchText)
    })
  }
}
