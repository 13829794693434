import { Pipe, PipeTransform } from "@angular/core"

@Pipe({ name: "combineFullName" })
export class CombineFullNamePipe implements PipeTransform {
  transform(firstName: string, lastName: string): string {
    if (!firstName && !lastName) return "-"

    let fullName = ""
    if (firstName) {
      fullName = firstName
    }

    if (lastName) {
      fullName += " " + lastName
    }

    return fullName
  }
}
