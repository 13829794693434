import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { AbstractControl, ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, ValidationErrors, ValidatorFn, Validators } from "@angular/forms"
import { DayAvailability } from "app/shared/models/point-of-sale/room-service-forms"
import { Subscription } from "rxjs"

@Component({
  selector: "ag-opening-hours",
  templateUrl: "./ag-opening-hours.component.html",
  styleUrls: ["./ag-opening-hours.component.scss"],
})
export class AgOpeningHoursComponent implements ControlValueAccessor, OnDestroy, OnInit {
  @Input() formGroupName!: string

  @Input() dayAvailabilities?: Array<DayAvailability>
  @Output() flattenList: EventEmitter<Array<DayAvailability>> = new EventEmitter()

  subscriptions: Subscription[] = []
  formGroup!: UntypedFormGroup
  weekdayMap!: Map<number, string>
  weekdayNumberMap!: Map<string, number>
  weekdays!: string[]

  constructor(private rootFormGroup: FormGroupDirective, private fb: UntypedFormBuilder) {
    this.buildmapAndRange()
  }

  writeValue(value: any): void {
    this.formGroup.setValue(value)
  }
  onChange = (_: any) => {}
  onTouched = () => {}
  registerOnChange = (fn: any): void => (this.onChange = fn)
  registerOnTouched = (fn: any): void => (this.onTouched = fn)

  ngOnInit(): void {
    // link to upper formgroup
    this.formGroup = this.rootFormGroup.control.get(this.formGroupName) as UntypedFormGroup
    if (this.dayAvailabilities) {
      this.patchList(this.dayAvailabilities)
    }
    this.subscriptions.push(
      this.formGroup.valueChanges.subscribe((value) => {
        this.onChange(value)
        this.onTouched()
        this.emitFlattenedList()
      })
    )
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe())
  }
  emitFlattenedList() {
    if (this.formGroup.valid) {
      this.flattenList.emit(this.flattenFormGroup())
    }
  }
  flattenFormGroup(): DayAvailability[] {
    const dayavailabilities = Array<DayAvailability>()
    Object.keys(this.formGroup.controls).forEach((key) => {
      if (this.formGroup.get(key) instanceof UntypedFormArray) {
        const array = this.formGroup.get(key) as UntypedFormArray
        for (const control of array.controls) {
          if (control instanceof UntypedFormGroup) {
            const duration: DayAvailability = {
              Ordering: this.weekdayNumberMap.get(key),
              Day: key,
              Enabled: true,
              StartTime: control.get("from")?.value,
              EndTime: control.get("until")?.value,
            }
            dayavailabilities.push(duration)
          }
        }
      }
    })
    return dayavailabilities
  }
  patchList(dayAvailabilities: Array<DayAvailability>) {
    dayAvailabilities.forEach((element) => {
      if (element.Day && this.formGroup.get(element.Day)) {
        ;(this.formGroup.get(element.Day) as UntypedFormArray).push(
          this.fb.group({
            from: [element.StartTime, [Validators.required, Validators.minLength(1)]],
            until: [element.EndTime, [Validators.required, Validators.minLength(1)]],
          })
        )
      }
    })
  }
  newDuration(): UntypedFormGroup {
    return this.fb.group({
      from: ["", [Validators.required, Validators.minLength(1)]],
      until: ["", [Validators.required, Validators.minLength(1)]],
    })
  }

  getArray(weekday: string): UntypedFormArray {
    return this.formGroup.get(weekday) as UntypedFormArray
  }

  insertNewDuration(weekday: string, index: number) {
    ;(this.formGroup.get(weekday) as UntypedFormArray)?.insert(index, this.newDuration())
  }
  addNewDuration(weekday: string) {
    ;(this.formGroup.get(weekday) as UntypedFormArray)?.push(this.newDuration())
  }

  removeDuration(weekday: string, index: number) {
    ;(this.formGroup.get(weekday) as UntypedFormArray)?.removeAt(index)
  }

  buildmapAndRange() {
    this.weekdayMap = new Map([
      [0, "Monday"],
      [1, "Tuesday"],
      [2, "Wednesday"],
      [3, "Thursday"],
      [4, "Friday"],
      [5, "Saturday"],
      [6, "Sunday"],
    ])
    this.weekdayNumberMap = new Map([
      ["monday", 0],
      ["tuesday", 1],
      ["wednesday", 2],
      ["thursday", 3],
      ["friday", 4],
      ["saturday", 5],
      ["sunday", 6],
    ])
    this.weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
  }
}
