import { Injectable } from "@angular/core"
const SOUNDS = "assets/sounds/"

@Injectable({
  providedIn: "root",
})
export class SoundService {
  private _buffers = new Map<Sound, AudioBuffer>()
  private audioContext: AudioContext

  constructor() {
    this.audioContext = new AudioContext()
    this.setupSample(SOUNDS + "shake.mp3").then((b) => {
      this._buffers.set(Sound.Shake, b)
    })
    this.setupSample(SOUNDS + "Hotel-bell.mp3").then((b) => {
      this._buffers.set(Sound.HotelBell, b)
    })
    this.setupSample(SOUNDS + "connection_lost.mp3").then((b) => {
      this._buffers.set(Sound.Disconnect, b)
    })
  }

  private playSample(audioBuffer) {
    const sampleSource = this.audioContext.createBufferSource()
    sampleSource.buffer = audioBuffer
    sampleSource.connect(this.audioContext.destination)
    sampleSource.start()
  }

  private async setupSample(filePath: string): Promise<AudioBuffer> {
    const response = await fetch(filePath)
    const arrayBuffer = await response.arrayBuffer()
    return await this.audioContext.decodeAudioData(arrayBuffer)
  }

  public play(sound: Sound) {
    this.playSample(this._buffers.get(sound))
  }
}

export enum Sound {
  Shake,
  HotelBell,
  Disconnect,
}
