export class DynamicVariableResponse {
  typeName: string
  isRoot: boolean
  properties: Properties[]
}

export class ListenableEventsResponse {
  name: string
  description: string
  typeName: string
}

export class Properties {
  type: string
  name: string
  displayName: string
  imageUrl: string
  context: string //Frontend only
}

export interface InputValue {
  direction: string
  value: number
}

export const CSharpTypeMapper: { [key: string]: string } = {
  Int32: "number",
  String: "string",
  DateTime: "DateTime",
  "IList<String>": "string[]",
}

export interface DynamicVariableList {
  placeholder: string
  functionId: string
}
