import { Injectable, NgZone } from "@angular/core"
import { Observable } from "rxjs"
import { ApiService } from "./api.service"
import { AuthenticationService } from "./authentication.service"
import { flatMap, tap } from "rxjs/operators"
import { API_HOTEL_V2, API_HEARTBEAT, API_KEY } from "app/app-settings"

@Injectable()
export class IntegrationStatusService {
  // notificationsEnabled = true;
  integrationErrors: Map<string, Map<string, number>> = Object()

  constructor(private apiService: ApiService, private authService: AuthenticationService) {}

  getTotalErrors(hotelId: string): number {
    if (this.integrationErrors[hotelId] === undefined) {
      return 0
    }
    return this.sum(this.integrationErrors[hotelId])
  }
  setTotalErrors(hotelId: string, name: string, value: number) {
    if (this.integrationErrors[hotelId] === undefined) {
      this.integrationErrors[hotelId] = Object()
    }
    this.integrationErrors[hotelId][name] = value
  }

  updateHotelHealth(emails: any, subject: string, content: string, forward: boolean): Observable<any> {
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => {
        let updateEmails = [emails]
        if (emails.includes(",")) {
          updateEmails = emails.split(",")
        }
        const body = {
          HotelId: hotel.Id,
          Emails: updateEmails.reduce((acc, curVal) => {
            return acc.concat(curVal)
          }, []),
          Subject: subject !== "" ? subject : undefined,
          Content: content !== "" ? content : undefined,
          IsEnabled: forward,
        }
        return this.apiService.post(`${API_HOTEL_V2}Health/PMS/Notification`, body)
      })
    )
  }

  fetchHotelHealth(): Observable<any> {
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => this.apiService.get(`${API_HOTEL_V2}Health/PMS/Notification/${hotel.Id}`)),
      tap(
        () => {},
        () => {}
      )
    )
  }

  checkHotelHealth(hotelId: string): Observable<{ Success: boolean; Timestamp: string; IsEnabled: boolean }> {
    return this.apiService.get(`${API_HOTEL_V2}Health/HotelId/${hotelId}/PMS/Latest`).pipe(
      tap(
        (res) => {
          if (res.IsEnabled === true && res.Success === false) {
            this.setTotalErrors(hotelId, "PMS", 1)
          } else {
            this.setTotalErrors(hotelId, "PMS", 0)
          }
        },
        (err) => {
          this.setTotalErrors(hotelId, "PMS", 1)
        },
        () => {}
      )
    )
  }

  getHotelPMSHealthStatus(): Observable<{ Created: boolean; Enabled: boolean }> {
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => {
        return this.apiService.get(`${API_HOTEL_V2}Health/HotelId/${hotel.Id}/PMS/Alert`)
      }),
      tap(
        () => {},
        () => {}
      )
    )
  }

  createHotelPMSHealthStatus(): Observable<any> {
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => {
        return this.apiService.put(`${API_HOTEL_V2}Health/HotelId/${hotel.Id}/PMS/Alert`)
      }),
      tap(
        () => {},
        () => {}
      )
    )
  }

  updateHotelPMSHealthStatus(enabled: boolean): Observable<any> {
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => {
        const body = { Enabled: enabled }
        return this.apiService.post(`${API_HOTEL_V2}Health/HotelId/${hotel.Id}/PMS/Alert`, body)
      }),
      tap(
        () => {},
        () => {}
      )
    )
  }
  //

  //// Site
  // updateSiteHealth(emails: string, subject: string, content: string, forward: boolean): Observable<any> {
  //   return this.authService.getHotelPreference().pipe(
  //     flatMap((hotel) => {
  //       let updateEmails = [emails]
  //       if (emails.includes(",")) {
  //         updateEmails = emails.split(",")
  //       }
  //       const body = {
  //         HotelId: hotel.Id,
  //         Emails: updateEmails.reduce((acc, curVal) => {
  //           return acc.concat(curVal)
  //         }, []),
  //         Subject: subject !== "" ? subject : undefined,
  //         Content: content !== "" ? content : undefined,
  //         IsEnabled: forward,
  //       }
  //       return this.apiService.post(`${API_HOTEL_V2}Health/SiteSoftware/Notification`, body)
  //     }),
  //     tap(
  //       () => {},
  //       () => {}
  //     )
  //   )
  // }

  // fetchSiteHealth(): Observable<any> {
  //   return this.authService.getHotelPreference().pipe(
  //     flatMap((hotel) => this.apiService.get(`${API_HOTEL_V2}Health/SiteSoftware/Notification/${hotel.Id}`)),
  //     tap(
  //       () => {},
  //       () => {}
  //     )
  //   )
  // }

  // fetchSiteHealthStatus(): Observable<any> {
  //   return this.authService.getHotelPreference().pipe(
  //     flatMap((hotel) => {
  //       return this.apiService.get(`${API_HEARTBEAT}Booking/Data/Hotel/${hotel.Id}/Organization/${this.authService.employeeInfo.OrganizationId}/Alert`)
  //     }),
  //     tap(
  //       () => {},
  //       () => {}
  //     )
  //   )
  // }

  // checkSiteHealth(hotelId: string): Observable<{ Success: boolean; Timestamp: string; IsEnabled: boolean }> {
  //   let didRun = false

  //   return this.apiService.get(`${API_HEARTBEAT}Booking/Data/Hotel/${hotelId}/Latest`).pipe(
  //     tap(
  //       (res) => {
  //         didRun = true
  //         if (res.Success === false) {
  //           this.setTotalErrors(hotelId, "Site", 1)
  //         } else {
  //           this.setTotalErrors(hotelId, "Site", 0)
  //         }
  //       },
  //       (err) => {
  //         this.setTotalErrors(hotelId, "Site", 1)
  //       },
  //       () => {}
  //     )
  //   )
  // }

  // createSiteHealthStatus(): Observable<any> {
  //   return this.authService.getHotelPreference().pipe(
  //     flatMap((hotel) => {
  //       return this.apiService.put(`${API_HEARTBEAT}Booking/Data/Hotel/${hotel.Id}/Organization/${this.authService.employeeInfo.OrganizationId}/Alert`)
  //     }),
  //     tap(
  //       () => {},
  //       () => {}
  //     )
  //   )
  // }

  // updateSiteHealthStatus(enabled: boolean): Observable<any> {
  //   return this.authService.getHotelPreference().pipe(
  //     flatMap((hotel) => {
  //       const body = { Enabled: enabled }
  //       return this.apiService.post(`${API_HEARTBEAT}Booking/Data/Hotel/${hotel.Id}/Organization/${this.authService.employeeInfo.OrganizationId}/Alert`, body)
  //     }),
  //     tap(
  //       () => {},
  //       () => {}
  //     )
  //   )
  // }

  //
  //// Key
  updateKeyHealth(emails: string, subject: string, content: string, forward: boolean): Observable<any> {
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => {
        let updateEmails = [emails]
        if (emails.includes(",")) {
          updateEmails = emails.split(",")
        }

        const body = {
          HotelId: hotel.Id,
          Emails: updateEmails.reduce((acc, curVal) => {
            return acc.concat(curVal)
          }, []),
          Subject: subject !== "" ? subject : undefined,
          Content: content !== "" ? content : undefined,
          IsEnabled: forward,
        }

        return this.apiService.post(`${API_KEY}Health/MobileKey/Notification`, body)
      }),
      tap(
        () => {},
        () => {}
      )
    )
  }

  fetchKeyHealth(): Observable<any> {
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => this.apiService.get(`${API_KEY}Health/MobileKey/Notification/${hotel.Id}`)),
      tap(
        () => {},
        () => {}
      )
    )
  }

  fetchKeyHealthStatus(): Observable<any> {
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => this.apiService.get(`${API_KEY}Health/Hotelid/${hotel.Id}/Organization/${this.authService.employeeInfo.OrganizationId}/LockIntegration/Alert`)),
      tap(
        () => {},
        () => {}
      )
    )
  }

  checkKeyHealth(hotelId: string): Observable<{ Success: boolean; Timestamp: string; IsEnabled: boolean }> {
    return this.apiService.get(`${API_KEY}Health/HotelId/${hotelId}/MobileKey/Latest`).pipe(
      tap(
        (res) => {
          if (res.IsEnabled === true && res.Success === false) {
            this.setTotalErrors(hotelId, "Key", 1)
          } else {
            this.setTotalErrors(hotelId, "Key", 0)
          }
        },
        (err) => {
          this.setTotalErrors(hotelId, "Key", 1)
        },
        () => {}
      )
    )
  }

  createKeyHealthStatus(): Observable<any> {
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => {
        return this.apiService.put(`${API_KEY}Health/Hotel/${hotel.Id}/Organization/${this.authService.employeeInfo.OrganizationId}/LockIntegration`)
      }),
      tap(
        () => {},
        () => {}
      )
    )
  }

  updateKeyHealthStatus(enabled: boolean): Observable<any> {
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) => {
        const body = { Enabled: enabled }
        return this.apiService.post(`${API_KEY}Health/HotelId/${hotel.Id}/Organization/${this.authService.employeeInfo.OrganizationId}/LockIntegration`, body)
      }),
      tap(
        () => {},
        () => {}
      )
    )
  }

  sum(obj) {
    return Object.keys(obj).reduce((val, key) => val + parseFloat(obj[key] || 0), 0)
  }
}
