import { Component, ContentChildren, Input, OnInit, QueryList } from "@angular/core"
import { AgTreeLeafComponent } from "./ag-tree-leaf/ag-tree-leaf.component"
import { AgTreeNodeComponent } from "./ag-tree-node/ag-tree-node.component"
import { IconImageService } from "@shared/services/icon-image.service"

@Component({
  selector: "ag-tree",
  templateUrl: "./ag-tree.component.html",
  styleUrls: ["./ag-tree.component.scss"],
})
export class AgTreeComponent implements OnInit {
  @ContentChildren(AgTreeNodeComponent, { descendants: true })
  nestedNodes!: QueryList<AgTreeNodeComponent>
  @ContentChildren(AgTreeLeafComponent, { descendants: true })
  nestedLeaves!: QueryList<AgTreeLeafComponent>

  @Input() showButtons: boolean = false

  constructor(public iconService: IconImageService) {}

  ngOnInit(): void {}
  get leaves(): AgTreeLeafComponent[] {
    return this.nestedLeaves.map((n) => n)
  }
  get nodes(): AgTreeNodeComponent[] {
    return this.nestedNodes.map((n) => n)
  }

  expandAll() {
    this.nodes.forEach((n) => (n.open = true))
  }
  collapseAll() {
    this.nodes.forEach((n) => (n.open = false))
  }
}
