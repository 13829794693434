import { Injectable } from "@angular/core"
import { Route, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router"
import { Observable } from "rxjs"
import { AuthenticationService } from "./authentication.service"

import { STORAGE_TOKEN, STORAGE_USER } from "../../app-settings"
import { Employee } from "../models/employee"

@Injectable()
export class LoginGuard  {
  constructor(private router: Router) {}

  /**
   * Called once when the route is requested. This method avoids module loading if it's unecessary and redirects
   * to /core url.
   * @param route
   */
  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    const { user, token } = this.extractData()

    // TODO: introducing profiles broke the lazy loading for login. Fix that in the future.
    // if (user && user.Id && user.Id.length > -1 && token) {
    //   this.router.navigate(['/core']);
    //   return false;
    // }

    return true
  }

  /**
   * Called always when child route is selected. Here we check if there's a need to onpe the route, otherwise
   * reroute to /core url.
   * @param childRoute
   * @param state
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const { user, token } = this.extractData()

    // If everything is in place - we shouldn't be here; navigate to main page.
    if (user && user.Id && user.Id.length > 0 && token) {
      const child = childRoute.url
      const url = child.length > 0 ? child[0].path : ""
      switch (url) {
        case "select":
        case "user":
          return true
        case "hotel":
        case "recovery":
          this.router.navigate(["/core"])
          return false
      }
    }

    return true
  }

  extractData() {
    const user = <Employee>JSON.parse(localStorage.getItem(STORAGE_USER))
    const token = localStorage.getItem(STORAGE_TOKEN)
    return { user, token }
  }
}
