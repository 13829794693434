import { Component, ElementRef, forwardRef, Input, OnInit, Renderer2, ViewChild } from "@angular/core"
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, FormControlDirective, UntypedFormControl, ControlContainer, AbstractControl, ValidationErrors } from "@angular/forms"

@Component({
  selector: "ag-select",
  templateUrl: "./ag-select.component.html",
  styleUrls: ["./ag-select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AgSelectComponent),
      multi: true,
    },
  ],
})
export class AgSelectComponent implements ControlValueAccessor, Validator, OnInit {
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective?: FormControlDirective

  @Input() formControl!: UntypedFormControl
  @Input() formControlName!: string
  @ViewChild("viewControl") viewControl?: ElementRef

  get control(): UntypedFormControl {
    return this.formControl || <UntypedFormControl>this.controlContainer?.control?.get(this.formControlName || "")
  }

  @Input() label!: string
  @Input() options: any = []

  @Input() optionValue?: string
  @Input() optionText?: string

  @Input() hint?: string

  constructor(
    private controlContainer: ControlContainer,
    private renderer: Renderer2,
  ) {}

  writeValue = (value: any): void => {
    this.formControlDirective?.valueAccessor?.writeValue(value)
  }
  registerOnChange = (fn: any): void => this.formControlDirective?.valueAccessor?.registerOnChange(fn)
  registerOnTouched = (fn: any): void => this.formControlDirective?.valueAccessor?.registerOnTouched(fn)

  validate(control: AbstractControl): ValidationErrors | null {
    return this.control.valid ? null : control.errors
  }

  onFocus() {
    this.renderer.addClass(this.viewControl?.nativeElement, "focussed")
  }

  onBlur() {
    this.renderer.removeClass(this.viewControl?.nativeElement, "focussed")
  }

  resolveOptionValuePath(option) {
    const path = this.optionValue?.split(".")
    let o = option
    for (const p of path) {
      o = o[p]
    }
    return o
  }

  resolveOptionTextPath(option) {
    const path = this.optionText?.split(".")
    let o = option
    for (const p of path) {
      o = o[p]
    }
    return o
  }

  ngOnInit(): void {}
}
