import { Injectable } from "@angular/core"
import { JourneyV3, JourneyV3Wrapper, JourneyV3WrapperWithGroups } from "@shared/models/journey/journey-list"
import { SnapSession } from "@shared/models/snap/snap-session"
import { API_HOTEL_V2, API_HOTEL_V3 } from "app/app-settings"
import { ApiService } from "app/shared/services/api.service"
import { Observable } from "rxjs"
import { ClaimBookingRequest } from "./models/claim-booking-request"
import { ClaimBookingResponse } from "./models/claim-booking-response"
import { RoomTypeDTO } from "./models/room-type"
import { SnapNumber } from "./models/snap-number"
import { UpdateSnapSessionRequest } from "./models/update-snap-session-request"
import { LinkSnapToJourneyRequest } from "./models/link-snap-to-journey-request"
import { WebCheckInCreateBookingRequest } from "./models/web-check-in-create-booking-request"
import { WebCheckInCreateBookingResponse } from "./models/web-check-in-create-booking-response"
import { CreateGroupBookingRequest, PmsAndResNumber } from "./models/create-group-booking-request"
import { UpdateEmployeeRequest } from "./models/update-employee-request"
import { GetGroupBookingRequest } from "./models/get-group-booking-request"
import { GetGroupBookingContactFormsRequest } from "./models/group-booking-contact-form-queue-request"
import { groupBookingAudittrail } from "./models/group-booking-audittrail-request"
import { JourneyV3WithExtras } from "./models/journey-v3"
import { HotelResponse } from "./models/v2-hotel-management"
import { flatMap } from "rxjs/operators"
import { AuthenticationService } from "@shared/services/authentication.service"

const API = "hotel/"
const API_V2 = "hotelV2/"
const API_V3 = "hotelV3/"

const API_HOTEL = {
  V1_BOOKING: API + "Booking/",
  V1_WEB_CHECK_IN: API + "WebCheckIn/",
  V2_ROOM_TYPE: API_V2 + "RoomType/",
  V1_SNAP: API + "Snap/",
  V1_EMPLOYEE: API + "Employee/",
  V2_HOTELCONTENT: API_V2 + "Hotel/Content",
  V3_JOURNEY: API_V3 + "Journey/",
}

@Injectable({
  providedIn: "root",
})
export class HotelApiService {
  constructor(private apiService: ApiService, private authService: AuthenticationService) {}

  public V2RoomType = {
    /** Get all room types for a hotel */
    getAllRoomTypes: (hotelId: string): Observable<RoomTypeDTO[]> => this.apiService.get<RoomTypeDTO[]>(`${API_HOTEL.V2_ROOM_TYPE}Hotel/${hotelId}`),
  }

  public V1WebCheckIn = {
    /** Get upselling room options */
    createBooking: (request: WebCheckInCreateBookingRequest): Observable<WebCheckInCreateBookingResponse> => this.apiService.post<WebCheckInCreateBookingResponse>(`${API_HOTEL.V1_WEB_CHECK_IN}Booking/Create`, request),
  }

  public V1Booking = {
    /** Claim an AeroGuest booking */
    claim: (request: ClaimBookingRequest): Observable<ClaimBookingResponse> => this.apiService.post<ClaimBookingResponse>(`${API_HOTEL.V1_BOOKING}Claim`, request),
  }

  public V1Employee = {
    updateEmployee: (request: UpdateEmployeeRequest): Observable<any> => this.apiService.post(`${API_HOTEL.V1_EMPLOYEE}Update`, request),
  }

  public V3Journey = {
    createJourney: (request: { Journey: JourneyV3 }): Observable<JourneyV3> => this.apiService.post<JourneyV3>(`${API_HOTEL_V3}Journey`, request),

    updateJourney: (request: JourneyV3): Observable<JourneyV3> => this.apiService.put<JourneyV3>(`${API_HOTEL_V3}Journey`, request),

    createGroupBooking: (groupBookingRequest: CreateGroupBookingRequest): Observable<any> => this.apiService.post(`${API_HOTEL.V3_JOURNEY}Hotels/GroupBooking/Create`, groupBookingRequest),

    getGroupBooking: (HotelId: string, pmsBookNum: string, reserverationnum: string): Observable<GetGroupBookingRequest> => this.apiService.get(`${API_HOTEL.V3_JOURNEY}Hotels/${HotelId}/GroupBooking/Get?pmsBookingNumber=${pmsBookNum}&reservationnumber=${reserverationnum}`),

    getGroupBookingByGroupId: (HotelId: string, groupId: string): Observable<GetGroupBookingRequest[]> => this.apiService.get(`${API_HOTEL.V3_JOURNEY}Hotels/${HotelId}/GroupBooking/GetByGroupId?groupId=${groupId}`),

    getJourneysByListOfPms: (HotelId: string, PmsAndRes: PmsAndResNumber[]): Observable<JourneyV3WithExtras[]> => this.apiService.post(`${API_HOTEL.V3_JOURNEY}Hotels/GetJourneysByListOfPms?hotelId=${HotelId}`, PmsAndRes),

    deleteGroupBooking: (HotelId: string, employeeName: string, pmsAndRes: PmsAndResNumber[]): Observable<any> => this.apiService.delete(`${API_HOTEL.V3_JOURNEY}Hotels/${HotelId}/GroupBooking/Delete?employeeName=${employeeName}`, pmsAndRes),

    sendGroupEmail: (
      HotelId: string,
      groupId: string,
      employeeName: string,
      email: string,
      postToPms: boolean,
      firstNameRequired: boolean,
      lastNameRequired: boolean,
      emailRequired: boolean,
      phoneNumberRequired: boolean,
      hideFromGuest: boolean,
      hideNumberOfGuests: boolean,
      remindGuest: boolean,
      reminderDate?: string,
      expireDate?: string,
      message?: string
    ): Observable<any> => {
      var expiredate = ""
      if (expireDate) expiredate = "&expiryDate=" + expireDate
      var messageToGuest = ""
      if (message) messageToGuest = "&messageToGuest=" + message
      var remindDate = ""
      if (reminderDate) remindDate = "&reminderDate=" + reminderDate
      return this.apiService.post(`${API_HOTEL.V3_JOURNEY}Hotels/${HotelId}/GroupBooking/sendGroupEmail?groupId=${groupId}&employeeName=${employeeName}&email=${email}&postToPms=${postToPms}
      &firstNameRequired=${firstNameRequired}&lastNameRequired=${lastNameRequired}&emailRequired=${emailRequired}&phoneNumberRequired=${phoneNumberRequired}&hideRoomFromGuest=${hideFromGuest}&hideNumberOfGuests=${hideNumberOfGuests}&remindGuest=${remindGuest}${remindDate}${expiredate}${messageToGuest}`)
    },
    editGroupEmail: (
      HotelId: string,
      groupId: string,
      employeeName: string,
      postToPms: boolean,
      firstNameRequired: boolean,
      lastNameRequired: boolean,
      emailRequired: boolean,
      phoneNumberRequired: boolean,
      hideFromGuest: boolean,
      hideNumberOfGuests: boolean,
      remindGuest: boolean,
      reminderDate?: string,
      expireDate?: string,
      message?: string
    ): Observable<any> => {
      var expiredate = ""
      if (expireDate) expiredate = "&expiryDate=" + expireDate
      var messageToGuest = ""
      if (message) messageToGuest = "&messageToGuest=" + message
      var remindDate = ""
      if (reminderDate) remindDate = "&reminderDate=" + reminderDate
      return this.apiService.post(`${API_HOTEL.V3_JOURNEY}Hotels/${HotelId}/GroupBooking/editGroupEmail?groupId=${groupId}&employeeName=${employeeName}&postToPms=${postToPms}
      &firstNameRequired=${firstNameRequired}&lastNameRequired=${lastNameRequired}&emailRequired=${emailRequired}&phoneNumberRequired=${phoneNumberRequired}&hideRoomFromGuest=${hideFromGuest}&hideNumberOfGuests=${hideNumberOfGuests}&remindGuest=${remindGuest}${remindDate}${expiredate}${messageToGuest}`)
    },

    expireGroupEmailLink: (HotelId: string, groupId: string): Observable<GetGroupBookingRequest> => this.apiService.put(`${API_HOTEL.V3_JOURNEY}Hotels/${HotelId}/GroupBooking/changeLinkToExpired?groupId=${groupId}`),

    getGroupBookingContactForms: (HotelId: string, groupId: string): Observable<GetGroupBookingContactFormsRequest[]> => this.apiService.get(`${API_HOTEL.V3_JOURNEY}Hotels/${HotelId}/GroupBooking/getGroupBookingContactForms?groupId=${groupId}`),

    updateGroupBooking: (HotelId: string, groupId: string, employeeName: string, groupName?: string, mainGuestName?: string, mainGuestEmail?: string, mainGuestPhoneNumber?: string): Observable<any> => {
      var grpname = ""
      if (groupName) grpname = "&groupName=" + groupName
      var guestname = ""
      if (mainGuestName) guestname = "&mainGuest=" + mainGuestName
      var guestemail = ""
      if (mainGuestEmail) guestemail = "&mainGuestemail=" + mainGuestEmail
      var guestphone = ""
      if (mainGuestPhoneNumber) guestphone = "&mainGuestPhoneNumber=" + mainGuestPhoneNumber
      return this.apiService.put(`${API_HOTEL.V3_JOURNEY}Hotels/${HotelId}/GroupBooking/UpdateGroup?groupId=${groupId}&employeeName=${employeeName}${grpname}${guestname}${guestemail}${guestphone}`)
    },

    getGroupAudittrail: (HotelId: string, groupId: string): Observable<groupBookingAudittrail[]> => this.apiService.get(`${API_HOTEL.V3_JOURNEY}Hotels/${HotelId}/GroupBooking/GetAudittrail?groupId=${groupId}`),

    getJourneysBetweenDatesSorted: (from: string, to: string, sortDirection: string, sortBy: string, filterBy: string, searchName?: string, showGroups?: string, aeroGuestProfile?: string): Observable<JourneyV3WrapperWithGroups> => {
      var searchString = ""
      var showGroupsString = ""
      var aeroGuestProfileString = ""
      if (!!searchName) searchString = "&searchName=" + searchName
      if (!!showGroups) showGroupsString = "&showGroups=" + showGroups
      if (!!aeroGuestProfile) aeroGuestProfileString = "&aeroGuestProfile=" + aeroGuestProfile
      return this.authService.getHotelPreference().pipe(
        flatMap((hotel) => {
          return this.apiService.get(`${API_HOTEL_V3}Journey/Hotels/${hotel.Id}/BetweenDatesSorted?from=${from}&to=${to}&sortDirection=${sortDirection}&sortBy=${sortBy}&filterBy=${filterBy}${searchString}${showGroupsString}${aeroGuestProfileString}`)
        })
      )
    },

    getJourneysBetweenDatesWithPmsWithGroups: (pms: string): Observable<JourneyV3Wrapper> => {
      return this.authService.getHotelPreference().pipe(
        flatMap((hotel) => {
          return this.apiService.get(`${API_HOTEL_V3}Journey/Hotels/${hotel.Id}/Search/Pms?pmsBookingNumber=${pms}`)
        })
      )
    },
  }

  public Snap = {
    /** Get all snap sessions for hotel by chaincode sitecode */
    getAllSnapSessions: (chainCode: string, siteCode: string): Observable<SnapSession[]> => this.apiService.get<SnapSession[]>(`${API_HOTEL.V1_SNAP}Session/All/ChainCode/${chainCode}/SiteCode/${siteCode}`),
    /** Add a global snap number to hotel. On linking to the journey, the snap number is dereferenced from the hotel */
    addGlobalSnapSession: (request: UpdateSnapSessionRequest): Observable<void> => this.apiService.post<void>(`${API_HOTEL.V1_SNAP}Add/Global`, request),
    /** Link a snap session to a journey. Snap session will be made awailable again for other journeys afterwards. */
    linkSnapSessionToJourney: (request: LinkSnapToJourneyRequest): Observable<SnapNumber> => this.apiService.post<SnapNumber>(`${API_HOTEL.V1_SNAP}Link`, request),
  }

  public V2HotelManagement = {
    fetchHotel: (id: string): Observable<HotelResponse> => this.apiService.get(`${API_HOTEL_V2}Hotel?id=${id}&fromArchive=true`),
  }

  public V2HotelContent = {
    /**Upload image to blobstorage for a hotel while specifying type and get url back */
    UploadImage: (hotelId: string, base64: string, typeString: string): Observable<any> => this.apiService.put(`${API_HOTEL.V2_HOTELCONTENT}/Image/Type`, { HotelId: hotelId, Type: typeString, Images: [base64] }),

    /**Delete image from blobstorage for a hotel (This works only for image container). HotelId, Type and fileName is needed */
    DeleteImage: (hotelId: string, type: string, imageFile: string): Observable<any> => this.apiService.delete(`${API_HOTEL.V2_HOTELCONTENT}/Image/Delete?hotelId=${hotelId}&type=${type}&imageFile=${imageFile}`),
  }

  public Booking = {
    getBooking: (id: string): Observable<any> => this.apiService.get(`${API_HOTEL.V1_BOOKING}?id=${id}`),
  }
}
