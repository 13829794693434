import { UntypedFormGroup } from "@angular/forms"

export function imageToBase64(img: any) {
  const canvas = document.createElement("canvas")
  canvas.width = img.width
  canvas.height = img.height
  const ctx = canvas.getContext("2d")
  ctx.drawImage(img, 0, 0)
  return canvas.toDataURL("image/png")
}

export function formErrorHandler(form: UntypedFormGroup, errors: any, validationMessages: any, data?: any) {
  if (!form || !errors) {
    return
  }

  for (const field of Object.keys(errors)) {
    // clear previous error message (if any)
    errors[field] = ""
    const control = form.get(field)

    if (control && control.dirty && !control.valid) {
      const messages = validationMessages[field]
      for (const key of Object.keys(control.errors)) {
        switch (key) {
          case "required":
            errors[field] = "Field is required"
            break
          case "matDatepickerParse":
            errors[field] = `Date "${control.errors.matDatepickerParse.text}" is invalid. Use format dd/mm/yyyy`
            break
          case "matDatepickerMin":
            errors[field] = `Date "${control.errors.matDatepickerMin.actual.format("DD/MM/YYYY")}" 
               is below minimum date "${control.errors.matDatepickerMin.min.format("DD/MM/YYYY")}"`
            break
          default:
            errors[field] = messages[key] + " "
            break
        }
      }
    }
  }
}

export function getBase64(file, cb, full = false) {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    // With split we remove encoded string headers
    if (full) cb(reader.result)
    else cb((<string>reader.result).split(",")[1])
  }
  reader.onerror = (error) => {}
}
