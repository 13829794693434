import { ElementRef, Component, Input, HostListener, Output, EventEmitter, OnInit, ViewChild, Renderer2 } from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"

@Component({
  selector: "app-round-drop-down-list-v2",
  template: `
    <div class="additional-charges-div">
      <label
        class="additional-charges-form"
        [ngStyle]="
          (oldV1Background && { background: '#fff', border: '1px solid rgb(151,151,151)' }) ||
          (backgroundColor && !displayHorizontally && { 'background-color': backgroundColor }) ||
          (displayHorizontally && { display: 'flex', 'flex-direction': 'row', gap: '16px', 'background-color': backgroundColor })
        "
        [ngClass]="!disable ? 'additional-charges-clickable' : ''"
        [attr.disabled]="disable ? '' : null"
        (click)="onFieldClicked()">
        <span style="font-size:12px; color: #61566E">{{ dropDownTitle }}</span>
        <div *ngIf="multipleOutput === false && hasIcons" [ngStyle]="dropDownTitle === '' && { 'margin-top': '12px' }"><img src="{{ currentValue.value }}" /></div>
        <div *ngIf="multipleOutput === false && !hasIcons && currentValue" [ngStyle]="dropDownTitle === '' && { 'margin-top': '12px' }">{{ currentValue.label }}</div>
        <div *ngIf="!currentValue || !currentValueMultiple" [ngStyle]="dropDownTitle === '' && { 'margin-top': '12px' }">{{ hint }}</div>
        <div style="overflow:hidden; text-overflow: ellipsis; width:95%;" *ngIf="multipleOutput">
          <ul style="list-style:none; display: flex; flex-wrap: nowrap;">
            <ng-container *ngFor="let item of currentValueMultiple; let i = index">
              <ng-container *ngIf="i > 0">, &nbsp;</ng-container>
              <li style="flex-basis: 20%;">{{ item.label }}</li>
            </ng-container>
          </ul>
        </div>
        <img src="/assets/shared/dropdown_large.svg" class="code-changeable-img disable-selection" [ngClass]="!disable ? 'additional-charges-clickable' : ''" #arrowImage />
      </label>
      <div class="search-box-wrapper">
        <app-search-box-v2
          [dropDownTitle]="dropDownTitle"
          [currentValue]="currentValue"
          [currentValueMultiple]="currentValueMultiple"
          [showSearchField]="showSearchField"
          [maxHeight]="maxHeight"
          [showBox]="!hideSearchBox"
          [showHeaders]="false"
          [alphabeticalList]="list"
          [hasIcons]="hasIcons"
          [useFixedPosition]="useFixedPosition"
          [multipleOutput]="multipleOutput"
          [hint]="hint"
          (output)="onOutput($event)"
          (outputMultiple)="onOutputMultiple($event)"
          (searchBoxFieldClicked)="onSearchBoxFieldClicked($event)"></app-search-box-v2>
      </div>
    </div>
  `,
  styles: [
    `
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      .disable-selection {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .search-box-wrapper {
        margin-top: -54px;
        position: absolute;
        width: 100%;
      }
      .additional-charges-form {
        background-color: #f2f2f2;
        width: 100%;
        height: 48px;
        border-radius: 16px;
        padding-inline-start: 8px;
        padding-top: 3px;
        font-family: "Poppins-Regular";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 24px;
        padding-left: 15px;
        margin-bottom: 0;
      }
      .additional-charges-form:hover {
        background-color: #ffffff;
        box-shadow: 2px 2px 10px rgba(51, 51, 51, 0.2);
      }
      .additional-charges-clickable {
        cursor: pointer;
      }
      .additional-charges-div {
        position: relative;
        width: 100%;
      }
      .additional-charges-img {
        position: absolute;
        left: 52px;
        top: 8px;
      }
      .code-changeable-img {
        position: absolute;
        right: 8px;
        top: 11px;
      }
      .spacer {
        padding-bottom: 4px;
      }
    `,
  ],
  animations: [],
})
export class RoundDropDownListV2Component implements OnInit {
  @Input() disable = false
  @Input() list: LabelValue[] = []
  @Input() currentValue: LabelValue
  @Input() currentValueMultiple: LabelValue[] = []
  @Input() dropDownTitle = "" //remember to give '' if you don't want a title
  @Input() useFixedPosition = false
  @Input() showSearchField = true
  @Input() maxHeight: number = 259
  @Input() multipleOutput = false
  @Input() oldV1Background = false
  @Input() hasIcons: boolean = false
  @Input() backgroundColor = ""
  @Input() displayHorizontally = false //This works only when you give '' to dropDownTitle
  @Input() hint = "" //This can only be used if you don't use currentValue or currentValueMultiple

  @Output() changedValue = new EventEmitter<LabelValue>()
  @Output() changedValueMultiple = new EventEmitter<LabelValue[]>()

  @ViewChild("arrowImage") arrowImage

  public currentCurrency = 0

  public currencyForm: UntypedFormGroup
  public hideSearchBox = true

  @HostListener("document:click", ["$event"])
  clickedOutside(event) {
    if (!this.eRef.nativeElement.contains(event.target) && !this.hideSearchBox) {
      this.hideSearchBox = true
      this.arrowImage.nativeElement.style.transform = `rotate(${this.hideSearchBox ? 0 : 180}deg)`
    }
  }

  constructor(
    private eRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {}

  public onOutput(value: LabelValue): void {
    this.currentValue = value
    this.changedValue.emit(value)
    this.hideSearchBox = true
    this.arrowImage.nativeElement.style.transform = `rotate(${this.hideSearchBox ? 0 : 180}deg)`
  }

  public onOutputMultiple(value: LabelValue[]): void {
    this.changedValueMultiple.emit(value)
  }

  public onSearchBoxFieldClicked(value: boolean): void {
    this.onFieldClicked()
  }

  public onFieldClicked(): void {
    if (!this.disable) {
      this.hideSearchBox = !this.hideSearchBox
      this.arrowImage.nativeElement.style.transform = `rotate(${this.hideSearchBox ? 0 : 180}deg)`
    }
  }
}
export interface LabelValue {
  label: string
  value: any
}
