export class Employee {
  Id: string
  Hotels: V2EmployeeHotelResponse[]
  Profiles: Employee[]
  Permissions: string[]
  OrganizationId: string
  FirstName: string
  LastName: string
  Email: string
  Status: string
  Type: string
  UnreadBadge: number
  LatestProfilePicture: string
  PinRequired: boolean
  Pin: string
  PhoneNumber: string
  Title: string
  LastActiveTime: number

  AccessEnabled: boolean // For Employee-setup in response model for call getRelatedEmployeesAndProfiles()
  EmployeeId: string
  EmailEmployee: string
  ProfileType: string
  PromptNewPassword: boolean
  InfoBoxChecked: string[]
}

export class EditEmployeeHotel {
  Id: string
  Name: string
  Active: boolean
}

export class V2EmployeeHotelResponse {
  Id?: string
  Image?: string
  Name?: string
  ChainCode?: string
  SiteCode?: string
  TimeZone?: string
}
