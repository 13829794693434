import { Component, Input } from "@angular/core"
import { AgReportMessage } from "./ag-report-message/ag-report-message"
import { AgReportStatus, AgStatusIconMapper } from "./ag-report-status"

@Component({
  selector: "ag-report",
  templateUrl: "./ag-report.component.html",
  styleUrls: ["./ag-report.component.scss"],
})
export class AgReportComponent {
  @Input()
  reportTitle!: string

  @Input()
  reportOkMessage?: string

  @Input()
  reportInfoMessage?: string

  @Input()
  reportWarningMessage?: string

  @Input()
  reportErrorMessage?: string

  @Input()
  messages: AgReportMessage[] = []

  expanded: boolean
  statusIcon: string | undefined

  private get _status() {
    const status = this.messages?.length > 0 ? Math.max(...this.messages.map((x) => x.Status)) : 0
    this.statusIcon = AgStatusIconMapper.getIcon(status)
    return status
  }

  get reportMessage(): string | undefined {
    switch (this._status) {
      case AgReportStatus.Ok:
        return this.reportOkMessage
      case AgReportStatus.Info:
        return this.reportInfoMessage
      case AgReportStatus.Warning:
        return this.reportWarningMessage
      case AgReportStatus.Error:
        return this.reportErrorMessage
      default:
        return undefined
    }
  }

  get statusClass(): string {
    switch (this._status) {
      case AgReportStatus.Ok:
        return "ag-success"
      case AgReportStatus.Info:
        return "ag-info"
      case AgReportStatus.Warning:
        return "ag-warning"
      case AgReportStatus.Error:
        return "ag-error"
      default:
        return ""
    }
  }

  constructor() {}
}
