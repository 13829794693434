<div class="image-form">
  <div class="img-container">
    <img src="{{ imageUrl }}" *ngIf="imageExists()" />
    <span *ngIf="!imageExists()">No image</span>
  </div>
  <div class="img-action-container">
    <div *ngIf="!imageExists()">
      <input (change)="onUploadImage($event)" type="file" accept="image/x-png,image/gif,image/jpeg" [hidden]="true" #fileInput />
      <button type="submit" class="upload-picture" (click)="fileInput.click()">Upload image</button>
    </div>
    <div class="image-delete-container" *ngIf="imageExists()">
      <span class="image-name" (click)="openImageNewTab(imageUrl)">{{ extractImageName(imageUrl) }}</span>
      <button (click)="deleteImage()" class="image-delete">x</button>
    </div>
  </div>
</div>
