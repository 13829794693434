<div class="flex-row flex-space-between flex-align-center ag-tree-leaf" style="display: flex; gap: 32px">
  <div class="flex-column flex-space-evenly flex-align-start">
    <span>
      <b>{{ agTitle }}</b>
    </span>
    <span *ngIf="agDescription" class="agDescription">{{ agDescription }}</span>
  </div>
  <div class="flex-row flex-end flex-align-center" style="display: flex; gap: 24px">
    <ng-content></ng-content>
  </div>
</div>
