import { Component, Inject, OnInit } from "@angular/core"
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog"

@Component({
  selector: "app-global-snap-dialog",
  templateUrl: "./global-snap-dialog.component.html",
  styleUrls: ["./global-snap-dialog.component.scss"],
})
export class GlobalSnapDialogComponent implements OnInit {
  dialogTitle: string
  dialogOKButtonName: string
  globalNumber: string

  constructor(
    public dialogRef: MatDialogRef<GlobalSnapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.dialogTitle = this.data.title
    this.dialogOKButtonName = this.data.okButtonName
  }

  onEnterKeydown() {
    this.dialogRef.close({ globalNumber: this.globalNumber })
  }

  onDone() {
    this.dialogRef.close({ globalNumber: this.globalNumber })
  }
}
