// export const WebSoketURL = 'ws://test.aeroguest.io:39285';
// export const WebSoketURL = 'wss://syst.aeroguest.io:39285';
// export const WebSoketURL = 'wss://prod.aeroguest.io:39285';

export const GoogleMapsApiKey = "AIzaSyBKoNGMNRanUSlbBTaUrnTvbzWzzr55jZk"

export const API_SOCIAL = "social/"
export const API_HOTEL = "hotel/"
export const API_HOTEL_V2 = "hotelV2/"
export const API_HOTEL_V3 = "hotelV3/"
export const API_HEARTBEAT = "heartbeat/"
export const API_POS = "pos/"
export const API_PAYMENT = "payment/"
export const API_PAYMENTV1 = "paymentV1/"
export const API_PAYMENTV2 = "paymentV2/"
export const API_PAYMENTV4 = "paymentV4/"
export const API_PAYMENTV5 = "paymentV5/"
export const API_SECURITY = "security/"
export const API_SECURITY_V2 = "securityV2/"
export const API_USER = "user/"
export const API_USER_V2 = "userV2/"
export const API_INVOICE = "invoice/"
export const API_INVOICE_V2 = "invoiceV2/"
export const API_BROADCAST = "broadcast/"
export const API_BROADCAST_V2 = "broadcastV2/"
export const API_KEY = "key/"
export const API_SERVICE = "service/"
export const API_SERVICE_V2 = "serviceV2/"
export const API_LOYALTY = "loyalty/"
export const API_BOOKING = "booking/"
export const API_APEXX = "apexx/"
export const API_PMS = "pms/"
export const API_CHANNELMANAGER_V1 = "channelManagerV1/"
export const SITE_SERVICE = `SftpClient/`

export const VG_HOTEL = "vghotelV1/"
export const VG_HOTEL_V2 = "vghotelV2/"

export const STORAGE_USER = "user"
export const STORAGE_TOKEN = "employeeToken"
export const STORAGE_PIN_SESSION = "sess"
export const STORAGE_EMAIL_PINS_obfuscated = "session"
export const STORAGE_BOOKING_ITEMS_PER_PAGE = "bookingItemsPerPage"
export const STORAGE_REQUESTS_ITEMS_PER_PAGE = "requestsItemsPerPage"
export const STORAGE_MEMBER_ITEMS_PER_PAGE = "memberItemsPerPage"
export const STORAGE_RATINGS_ITEMS_PER_PAGE = "ratingsItemsPerPage"
export const STORAGE_TOUR_ENABLED = "tourEnabled"
export const STORAGE_TOUR_BOOKING_CREATED = "tourBookingCreated"
export const STORAGE_SNAP_IS_SHOWN = "snapIsShown"
export const STORAGE_LAST_VISITED_PAGE = "lastVisitedPage"
export const STORAGE_BOOKING_INFO_REDIRECT = "redirectBooking"
