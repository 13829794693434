<div class="ag-form-control" #viewControl agTooltip="{{ hint }}" [agTooltipCondition]="!!hint && control.invalid && control.dirty" [class.has-errors]="control.invalid && (control.dirty || control.touched)" [class.disabled]="control.disabled">
  <div class="time-container">
    <img [src]="clockAsset" alt="" />
    <div class="grey-separator"></div>
    <div class="ag-value">
      <input #timepicker type="time" [formControl]="control" (focus)="onFocus()" (blur)="onBlur()" (keypress)="keyPressNumbers($event)" placeholder=" " />
      <label for="value">{{ label }}</label>
    </div>
  </div>
</div>
