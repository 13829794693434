import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { STORAGE_EMAIL_PINS_obfuscated, STORAGE_PIN_SESSION, STORAGE_TOKEN, STORAGE_USER } from "app/app-settings"
import { Observable, throwError } from "rxjs"
import { catchError, map } from "rxjs/operators"
import { StateService } from "./state.service"

@Injectable()
export class ApiService {
  constructor(private http: HttpClient, private stateService: StateService) {}

  private setHeaders(headers: HttpHeaders = new HttpHeaders()): HttpHeaders {
    if (!headers.has("Accept")) {
      headers = headers.append("Accept", "application/json")
    }

    if (!headers.has("Content-Type")) {
      headers = headers.append("Content-Type", "application/json")
    }

    if (localStorage.getItem("employeeToken")) {
      headers = headers.append("AGID", localStorage.getItem("employeeToken").split(",")[0])
    }

    return headers
  }
  private setHeadersForm(headers: HttpHeaders = new HttpHeaders()): HttpHeaders {
    if (!headers.has("Accept")) {
      headers = headers.append("Accept", "*/*")
    }

    if (localStorage.getItem("employeeToken")) {
      headers = headers.append("AGID", localStorage.getItem("employeeToken").split(",")[0])
    }

    return headers
  }

  private setResponseType(requestOptions: any) {
    if ((<HttpHeaders>requestOptions.headers).get("Content-Type") === "text/csv") {
      requestOptions["responseType"] = "text/csv"
    }
  }

  private formatErrors(error: any) {
    if (error.status === 403) {
      localStorage.removeItem(STORAGE_TOKEN)
      localStorage.removeItem(STORAGE_USER)
      this.stateService?.deleteSelectedHotel()
      localStorage.removeItem(STORAGE_EMAIL_PINS_obfuscated)
      localStorage.removeItem(STORAGE_PIN_SESSION)
      window.location.href = "../core"
    }
    return throwError(error.body ? error.json() : error)
  }

  get<Type = any>(path: string, params: HttpParams = new HttpParams(), headers: HttpHeaders = new HttpHeaders()): Observable<Type> {
    const requestOptions = {
      headers: this.setHeaders(headers),
      params,
    }
    this.setResponseType(requestOptions)

    return this.http.get<Type>(path, requestOptions).pipe(catchError(this.formatErrors))
  }

  put<Type = any>(path: string, body: Object = {}): Observable<Type> {
    return this.http.put<Type>(path, JSON.stringify(body), { headers: this.setHeaders() }).pipe(
      catchError(this.formatErrors),
      map((res) => {
        return res
      })
    )
  }
  patch<Type = any>(path: string, body: Object = {}): Observable<Type> {
    return this.http.patch<Type>(path, JSON.stringify(body), { headers: this.setHeaders() }).pipe(
      catchError(this.formatErrors),
      map((res) => {
        return res
      })
    )
  }

  post<Type = any>(path: string, body: Object = {}): Observable<Type> {
    return this.http.post<Type>(path, JSON.stringify(body), { headers: this.setHeaders() }).pipe(
      catchError(this.formatErrors),
      map((res) => res)
    )
  }
  postFormData<Type = any>(path: string, body: FormData): Observable<Type> {
    return this.http.post<Type>(path, body, { headers: this.setHeadersForm() }).pipe(
      catchError(this.formatErrors),
      map((res) => res)
    )
  }

  testPost(path: string, body: string): Observable<any> {
    return this.http.post(path, body, { headers: this.setHeaders() }).pipe(
      catchError(this.formatErrors),
      map((res) => res)
    )
  }

  delete(path, body?: any): Observable<any> {
    let options: any
    if (body) {
      options = { headers: this.setHeaders(), body: body }
    } else {
      options = { headers: this.setHeaders() }
    }
    return this.http.delete(path, options).pipe(catchError(this.formatErrors))
    // .map((res: Response) => res.json());
  }
}
