<div class="grey-outline" *ngIf="numberOfOptions" [ngStyle]="{ width: getContainerWidth() }">
  <div class="slider" [ngStyle]="{ width: getSliderWidth(), transform: getTransform() }"></div>

  <div class="option-wrapper" [ngStyle]="{ width: getSliderWidth(), 'min-Width': getSliderWidth() }" *ngIf="numberOfOptions >= 1" (click)="select('1')">
    <div [ngClass]="selectedOption === '1' ? 'selected-option' : 'not-selected-option'">
      <p class="p-medium">{{ option1 }}</p>
    </div>
  </div>

  <div class="option-wrapper" [ngStyle]="{ width: getSliderWidth(), 'min-Width': getSliderWidth() }" *ngIf="numberOfOptions >= 2" (click)="select('2')">
    <div [ngClass]="selectedOption === '2' ? 'selected-option' : 'not-selected-option'">
      <p class="p-medium">{{ option2 }}</p>
    </div>
  </div>

  <div class="option-wrapper" [ngStyle]="{ width: getSliderWidth(), 'min-Width': getSliderWidth() }" *ngIf="numberOfOptions >= 3" (click)="select('3')">
    <div [ngClass]="selectedOption === '3' ? 'selected-option' : 'not-selected-option'">
      <p class="p-medium">{{ option3 }}</p>
    </div>
  </div>

  <div class="option-wrapper" [ngStyle]="{ width: getSliderWidth(), 'min-Width': getSliderWidth() }" *ngIf="numberOfOptions >= 4" (click)="select('4')">
    <div [ngClass]="selectedOption === '4' ? 'selected-option' : 'not-selected-option'">
      <p class="p-medium">{{ option4 }}</p>
    </div>
  </div>
</div>
