<div class="centerBar">
  <div class="bottomBar">
    <div class="textArea">
      <h1 class="header">{{ headerText }}</h1>
      <b class="body">{{ bodyText }}</b>
    </div>
    <div class="buttonArea">
      <ag-button-v2 [label]="buttonLeftText" size="large" type="secondary" (click)="buttonLeftClicked()" *ngIf="buttonLeftText"></ag-button-v2>
      <ag-button-v2 [label]="buttonRightText" size="large" type="primary" [disabled]="buttonRightactive" (click)="buttonRightClicked()" *ngIf="buttonRightText"></ag-button-v2>
    </div>
  </div>
</div>
