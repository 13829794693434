import { Injectable } from "@angular/core"
import { API_PAYMENTV1, API_PAYMENTV4 } from "app/app-settings"
import { ApiService } from "app/shared/services/api.service"
import { AuthenticationService } from "app/shared/services/authentication.service"
import { flatMap } from "rxjs/operators"
import { MainCurrencyUpdateModel } from "../../../../app/shared/models/revenue-basic/revenue-basic"
import { IPaymentRules, VatRule } from "app/shared/models/site"
import { Observable } from "rxjs"
import { IDictionary } from "@shared/models"

@Injectable()
export class BasicSetupService {
  currencyModel: MainCurrencyUpdateModel

  constructor(private apiService: ApiService, private authService: AuthenticationService) {}

  public getHotel() {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.authService.fetchHotel(hotel.Id)))
  }

  public getHotelSite() {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.get(`${API_PAYMENTV1}Site/hotel/${hotel.Id}`)))
  }

  public updateMainCurrency(id: string, newMainCurrency: string, oldMainCurrency: string) {
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) =>
        this.apiService.post(`${API_PAYMENTV1}Site/MainCurrency`, {
          Id: hotel.Id,
          NewMainCurrency: newMainCurrency,
          OldMainCurrency: oldMainCurrency,
        })
      )
    )
  }
  public updateSite(
    gatewayType: string,
    paymentAcquirer: string,
    riskProfile: string,
    adyenApiKey: string,
    apexxAccountByCurrency: IDictionary[],
    apexxThreeDSCredentials: IDictionary[],
    adyenMerchantAccount: string,
    adyenClientEncryptionKey: string,
    adyenClientKey: string,
    adyenUrl: string,
    HideVat: boolean,
    VatRules: Array<VatRule>,
    EmailReceiverList: string,
    VivaWalletClientId: string,
    VivaWalletClientSecret: string,
    VivaWalletMerchantId: string,
    VivaWalletApiKey: string,
    VivaWalletSourceCode: string,
    VivaWalletResellerId: string,
    VivaWalletResellerSecret: string,
    VivaWalletNonAgMerchant: boolean
  ) {
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) =>
        this.apiService.post(`${API_PAYMENTV1}Site`, {
          Id: hotel.Id,
          PaymentGatewayType: gatewayType,
          PaymentAcquirer: paymentAcquirer,
          RiskProfile: riskProfile,
          AdyenApiKey: adyenApiKey,
          ApexxAccountByCurrency: apexxAccountByCurrency,
          ApexxThreeDSCredentials: apexxThreeDSCredentials,
          AdyenMerchantAccount: adyenMerchantAccount,
          AdyenClientEncryptionKey: adyenClientEncryptionKey,
          AdyenClientKey: adyenClientKey,
          AdyenUrl: adyenUrl,
          HideVat: HideVat,
          VatRules: VatRules,
          EmailReceiverList: EmailReceiverList,
          VivaWalletClientId: VivaWalletClientId,
          VivaWalletClientSecret: VivaWalletClientSecret,
          VivaWalletMerchantId: VivaWalletMerchantId,
          VivaWalletApiKey: VivaWalletApiKey,
          VivaWalletSourceCode: VivaWalletSourceCode,
          VivaWalletResellerId: VivaWalletResellerId,
          VivaWalletResellerSecret: VivaWalletResellerSecret,
          VivaWalletNonAgMerchant: VivaWalletNonAgMerchant,
        })
      )
    )
  }

  // Allow payment

  getPaymentRules(): Observable<IPaymentRules> {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.get(`${API_PAYMENTV4}Rule/${hotel.Id}`)))
  }

  setPaymentRules(rule: IPaymentRules): Observable<IPaymentRules> {
    return this.authService.getHotelPreference().pipe(
      flatMap((hotel) =>
        this.apiService.post(`${API_PAYMENTV4}Rule`, {
          HotelId: hotel.Id,
          AllowPayment: rule.AllowPayment,
        })
      )
    )
  }
}
