import { Injectable } from "@angular/core"
import { ApiService } from "app/shared/services/api.service"
import { AuthenticationService } from "app/shared/services/authentication.service"
import { flatMap, map } from "rxjs/operators"
import { API_PAYMENTV5 } from "app/app-settings"
import { PaymentRuleIdentifier, PaymentRuleBackendModel, PaymentRuleBackendModelContainer } from "app/shared/models/payment-rule/payment-rule"
import { BehaviorSubject, Observable } from "rxjs"
import { InvoicesComponent } from "app/invoices/invoices.component"
import { InvoicesModule } from "app/invoices/invoices.module"
import { InvoiceService } from "app/dashboard/statistics.service"

@Injectable()
export class PaymentRulesService {
  constructor(private apiService: ApiService, private authService: AuthenticationService) {}

  private paymentRules: BehaviorSubject<PaymentRuleBackendModelContainer[]> = new BehaviorSubject<PaymentRuleBackendModelContainer[]>(undefined)
  getPaymentRules(): Observable<any> {
    if (this.paymentRules.value) {
      return this.paymentRules.asObservable()
    } else {
      return this.getRules()
    }
  }
  setPaymentRules(newValue): void {
    this.paymentRules.next(newValue)
  }

  getRules() {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.get(`${API_PAYMENTV5}Rule?HotelId=${hotel.Id}`)))
  }

  putRules(ruleIdentifier: PaymentRuleIdentifier, rules: PaymentRuleBackendModel[]) {
    return this.authService.getHotelPreference().pipe(flatMap((hotel) => this.apiService.put(`${API_PAYMENTV5}Rule`, { HotelId: hotel.Id, RuleIdentifier: ruleIdentifier.toString(), Rules: rules })))
  }
}
