import { KeyValue } from "@angular/common"
import { ChangeContext, Options } from "@angular-slider/ngx-slider"
import { GraphFactory } from "./factory/graph-factory"
import { AreaGraphFactory } from "./factory/area-graph-factory"
import { LineGraphFactory } from "./factory/line-graph-factory"
import { StackedbarGraphFactory } from "./factory/stackedbar-graph-factory"
import { GraphDrawStrategy } from "./strategy/graph-draw-strategy/graph-draw-strategy"
import { GraphTypeStrategy } from "./strategy/graph-type-strategy/graph-type-strategy"
import { ContextBoxStrategy } from "./strategy/context-box-strategy/context-box-strategy"
import { GraphColorStrategy } from "./strategy/graph-color-strategy/graph-color-strategy"
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { NavigatorgraphTypeStrategy } from "./strategy/navigatorgraph-type-strategy/navigatorgraph-type-strategy"

import * as d3 from "d3"
import * as fc from "d3fc"
@Component({
  selector: "app-d3fc-webgl-chart",
  templateUrl: "./d3fc-webgl-chart.component.html",
  styleUrls: ["./d3fc-webgl-chart.component.scss"],
})
export class D3fcWebglChartComponent implements OnInit {
  @ViewChild("dataContext") dataContextContainer: ElementRef
  @ViewChild("chart", { static: true }) chartContainer: ElementRef
  @ViewChild("navigatorChart", { static: true }) navigatorChartContainer: ElementRef

  @Input() disableSlider = false
  @Input() shouldStackData = true

  // CHART INPUTS
  @Input() colors: string[]
  @Input() chartType: string
  @Input() chartTitle: string
  @Input() chartLabel: string
  @Input() legendNames: string[]
  @Input() tooltipSuffix: string

  @Output()
  onBrushUpdate = new EventEmitter<any>()

  // Code architecture:
  // Factory which produces different strategies
  graphFactory: GraphFactory

  // Produced strategies by factory
  graphTypeStrategy: GraphTypeStrategy
  graphDrawStrategy: GraphDrawStrategy
  graphColorStrategy: GraphColorStrategy
  contextBoxStrategy: ContextBoxStrategy
  navigationgraphTypeStrategy: NavigatorgraphTypeStrategy

  // PERIODICITY
  periodicity = {
    hourly: 1,
    daily: 2,
    monthly: 3,
    currentPeriodicity: undefined,
  }

  // TIMELABELS
  timeLabels = {
    fiveYears: 157784630000,
    twoYears: 63113852000,
    oneYear: 31556926000,

    sixMonths: 15778800000,
    threeMonths: 7889400000,
    oneMonth: 2592000000,

    twoWeeks: 1209600000,
    oneWeek: 604800000,
    oneDay: 86400000,

    oneHour: 3600000,
  }
  timeLabelThresholds
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  // CONFIG GLOBALS
  isLoading = true
  showToolTip = true
  minSelectionBound = 0.01
  maxSelectionBound = 0.99

  // DATA GLOBALS
  hourlyData
  dailyData
  monthlyData

  chartData

  // SCALE GLOBALS
  xScale
  yScale

  // SERIES GLOBALS
  webglBar
  navigatorSeries

  // SELECTION GLOBALS
  currentDomain
  currentSelection

  // CHART GLOBALS
  chart
  navigatorChart
  color
  mouseMoveFunction

  // SELECTED GLOBALS
  hourlySelected = false
  dailySelected = false
  monthlySelected = false

  // DATA CONTEXT GLOBALS
  dataContextDate
  dataContextData

  dateFormatter

  // BRUSH ELEMENTS
  brush
  brushElement
  brushOverlay
  brushSelectionBox

  // BRUSH OVERLAY
  brushOverlayW
  brushOverlayE

  // BRUSH HANDLES
  brushHandleW
  brushHandleWLine1
  brushHandleWLine2
  brushHandleWLongLine

  brushHandleE
  brushHandleELine1
  brushHandleELine2
  brushHandleELongLine

  //#region Slider setup
  value = 1
  options: Options = {
    showTicksValues: false,
    showTicks: true,
    disabled: this.disableSlider,
    translate: (v: number): string => {
      return this.getTooltip(v - 1)
    },
    ticksTooltip: (v: number): string => {
      return this.getTooltip(v)
    },
    stepsArray: [
      {
        value: 1,
      },
      {
        value: 2,
      },
      {
        value: 3,
      },
      {
        value: 4,
      },
      {
        value: 5,
      },
      {
        value: 6,
      },
      {
        value: 7,
      },
      {
        value: 8,
      },
      {
        value: 9,
      },
      {
        value: 10,
      },
    ],
  }
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return -1
  }

  ngOnInit(): void {
    this.options.disabled = this.disableSlider

    if (this.chartType === "stackedBar") {
      this.setGraphFactory(new StackedbarGraphFactory())
    } else if (this.chartType === "area") {
      this.setGraphFactory(new AreaGraphFactory())
    } else if (this.chartType === "line") {
      this.setGraphFactory(new LineGraphFactory())
    }
  }

  public setGraphFactory(graphFactory: GraphFactory) {
    this.graphFactory = graphFactory

    this.graphTypeStrategy = graphFactory.produceGraphTypeStrategy()
    this.graphDrawStrategy = graphFactory.produceGraphDrawStrategy()
    this.navigationgraphTypeStrategy = graphFactory.produceNavigatorGraphTypeStrategy()
    this.graphColorStrategy = graphFactory.produceGraphColorStrategy()
    this.contextBoxStrategy = graphFactory.produceContextBoxStrategy()
  }

  public changeViewToArea() {
    this.setGraphFactory(new AreaGraphFactory())
    this.renderChart(this.dailyData, this.currentSelection)
  }

  public changeViewToLine() {
    this.setGraphFactory(new LineGraphFactory())
    this.renderChart(this.dailyData, this.currentSelection)
  }

  public setDataWithPeriodicity(hourlyData, dailyData, monthlyData, initialData = dailyData) {
    if (this.legendNames === undefined) {
      this.legendNames = Object.keys(initialData[0]).filter((key) => key !== "Date")
    }

    switch (true) {
      case initialData === hourlyData:
        this.periodicity.currentPeriodicity = this.periodicity.hourly
        break
      case initialData === dailyData:
        this.periodicity.currentPeriodicity = this.periodicity.daily
        break
      case initialData === monthlyData:
        this.periodicity.currentPeriodicity = this.periodicity.monthly
        break
    }
    initialData = this.surroundDatachunksWithEmptyValues(initialData, this.periodicity.currentPeriodicity)
    this.hourlyData = this.surroundDatachunksWithEmptyValues(hourlyData, this.periodicity.hourly)
    this.dailyData = this.surroundDatachunksWithEmptyValues(dailyData, this.periodicity.daily)
    this.monthlyData = this.surroundDatachunksWithEmptyValues(monthlyData, this.periodicity.monthly)

    this.setNewData(initialData, [0, 1])
    this.currentSelection = [0, 1]

    // Brush is set using values from 0 - 1, therefore we need to calculate the decimal number a given timelabel is compared to the timestamp span of the entire dataset
    this.timeLabelThresholds = {
      fiveYears: this.calculateBrushPercentage(this.timeLabels.fiveYears),
      twoYears: this.calculateBrushPercentage(this.timeLabels.twoYears),

      oneYear: this.calculateBrushPercentage(this.timeLabels.oneYear),
      sixMonths: this.calculateBrushPercentage(this.timeLabels.sixMonths),
      threeMonths: this.calculateBrushPercentage(this.timeLabels.threeMonths),
      oneMonth: this.calculateBrushPercentage(this.timeLabels.oneMonth),

      twoWeeks: this.calculateBrushPercentage(this.timeLabels.twoWeeks),
      oneWeek: this.calculateBrushPercentage(this.timeLabels.oneWeek),
      oneDay: this.calculateBrushPercentage(this.timeLabels.oneDay),
    }
    this.highlightPeriodicityButton(this.periodicity.daily)
    this.value = 6
    this.renderChart(initialData, [0.7, 0.97])
    this.finishLoading()
  }

  private setNewData(data, brushRange) {
    const stack = d3.stack().keys(Object.keys(data[0] ?? {}).filter((k) => k !== "Date"))

    const group = fc.group().key("Date")

    this.chartData = {
      series: data,
      transformedSeries: this.shouldStackData ? stack(data) : group(data),
      stackedSeries: stack(data),
      brushedRange: brushRange,
    }
  }

  private renderChart(data = [], brushRange = [0.65, 0.9], reRenderNavigator = true) {
    // if(data.length === 0)
    // {
    //   return
    // }
    this.setNewData(data, brushRange)
    this.color = d3.scaleOrdinal(d3.schemeCategory10)

    const xExtent = fc
      .extentDate()
      .accessors([(d) => d.Date])
      .pad([0.05, 0.05])

    this.xScale = d3.scaleTime().domain(xExtent(this.chartData.series)).nice()

    const xScaleFull = d3.scaleTime().domain(xExtent(this.chartData.series)).nice()

    const yExtent = fc
      .extentLinear()
      .accessors([(a) => a.map((d) => d[1])])
      .include([0])

    const yExtendValue = yExtent(this.chartData.transformedSeries)

    this.yScale = d3.scaleLinear().domain(yExtendValue).nice()

    const yScaleFull = d3
      .scaleLinear()
      .domain(yExtendValue[1] < 5 ? [yExtendValue[0], 5] : yExtendValue)
      .nice()

    // Webgl bar series
    this.webglBar = this.graphTypeStrategy.constructGraph(fc, this.xScale, this.yScale)

    const gridline = fc.annotationCanvasGridline().xScale(this.xScale).yScale(this.yScale).xTicks(0)

    // Chart object
    this.chart = fc.chartCartesian(this.xScale, this.yScale).yLabel(this.chartLabel).webglPlotArea(this.webglBar).canvasPlotArea(gridline).yOrient("left")

    this.brush = fc
      .brushX()
      .on("start", (evt) => {
        if (!this.mouseMoveFunction) {
          return
        }

        const canvas = this.chartContainer.nativeElement.querySelector("d3fc-canvas").querySelector("canvas")
        canvas.removeEventListener("mousemove", this.mouseMoveFunction)
      })
      .on("brush", (evt) => {
        if (!evt.selection) {
          return
        }

        this.onBrush(evt.xDomain, evt.selection)
      })
      .on("end", (evt) => {
        if (!evt.selection) {
          return
        }

        this.onBrushEnd(evt.xDomain, evt.selection)
      })
      .handleSize([20])

    const navigatorGridline = fc.annotationCanvasGridline().xScale(xScaleFull).yScale(yScaleFull).yTicks(0)

    this.navigatorSeries = this.navigationgraphTypeStrategy.constructNavigatorGraph(fc, xScaleFull, yScaleFull)

    const syncedSeries = fc
      .seriesSvgMulti()
      .series([this.navigatorSeries, this.brush])
      .mapping((dat, index, series) => {
        switch (series[index]) {
          case this.navigatorSeries:
            return dat.transformedSeries
          case this.brush:
            return dat.brushedRange
        }
      })

    this.navigatorChart = fc.chartCartesian(this.xScale.copy(), this.yScale.copy()).svgPlotArea(syncedSeries).webglPlotArea(this.webglBar).canvasPlotArea(navigatorGridline).yOrient("left").xOrient("bottom")

    d3.select(this.navigatorChartContainer.nativeElement).datum(this.chartData).call(this.navigatorChart)

    this.reRenderWebglNavigator(this.xScale, this.yScale, this.navigatorSeries, this.chartData, reRenderNavigator)
    this.updateGraphView(this.chartData.brushedRange.map(d3.scaleLinear().domain(this.xScale.domain()).invert))
  }

  private onBrush(xDomain, selection) {
    const didBrushChangeLength = +(selection[1] - selection[0]).toFixed(10) !== +(this.currentSelection[1] - this.currentSelection[0]).toFixed(10)
    if (didBrushChangeLength) {
      this.updatePeriodicitySlider(xDomain[1] - xDomain[0])
    }

    // update the bound data based on the new selection
    this.currentDomain = xDomain
    this.currentSelection = selection
    this.chartData.brushedRange = selection

    // update the domain of the main chart to reflect the brush
    this.updateGraphView(xDomain)
    this.onBrushUpdate.emit(xDomain)
  }

  private onBrushEnd(xDomain, selection) {
    const currentSelectionData = this.getCurrentSelectionData(xDomain)
    const canvas = this.chartContainer.nativeElement.querySelector("d3fc-canvas").querySelector("canvas")

    this.chartData.brushedRange = selection

    this.updateGraphView([currentSelectionData[0]?.Date, xDomain[1]]) // Snap chart to nearest starting bar
    this.addContextBoxToCanvas(canvas, currentSelectionData)
  }

  private updatePeriodicitySlider(timespan) {
    if (timespan > this.getTimeDifferenceAll()) {
      return
    }

    const roundedTimespan = this.getClosestTimelabel(timespan)

    if (roundedTimespan < this.timeLabels.oneMonth && this.hourlyData?.length > 0) {
      this.swapPeriodicity(this.periodicity.hourly)
    } else if (roundedTimespan < this.timeLabels.twoYears && this.dailyData?.length > 0) {
      this.swapPeriodicity(this.periodicity.daily)
    } else {
      this.swapPeriodicity(this.periodicity.monthly && this.monthlyData?.length > 0)
    }
  }

  private addContextBoxToCanvas(canvas, hoveredData) {
    this.mouseMoveFunction = (evt) => {
      const contextBoxElement = this.dataContextContainer?.nativeElement as HTMLElement
      contextBoxElement.style.top = evt.clientY - contextBoxElement.offsetHeight / 2 + "px"
      contextBoxElement.style.left = evt.clientX + 30 + "px"

      const dataIndex = this.contextBoxStrategy.calculateMousePosition(canvas, contextBoxElement, hoveredData.length, evt)
      this.dataContextDate = this.getDateStringFromPeriodicity((hoveredData[dataIndex] ?? hoveredData[0]).Date, this.periodicity.currentPeriodicity)
      this.dataContextData = hoveredData[dataIndex] ?? hoveredData[0]
    }

    canvas.addEventListener("mousemove", this.mouseMoveFunction)
  }

  private updateGraphView(xDomain) {
    this.updateGraphOnXAxis(xDomain)
    this.updateGraphOnYAxis(xDomain)

    d3.select(this.chartContainer.nativeElement).datum(this.chartData.series).transition().duration(300).ease(d3.easeLinear).call(this.chart)

    this.reRenderWebglMain(this.xScale, this.yScale, this.webglBar, this.chartData)
    this.setupBrushOverlay()
    this.setupBrushHandles()
    this.updateBrushOverlay()
    this.updateBrushHandles()
  }

  private updateGraphOnXAxis(xDomain) {
    this.chart.xDomain(xDomain)
  }

  private updateGraphOnYAxis(xDomain) {
    const yExtentTest = fc
      .extentLinear()
      .accessors([
        (a) =>
          a.map((d) => {
            if (d.data.Date >= xDomain[0] && d.data.Date <= xDomain[1]) return d[1]
            return 0
          }),
      ])
      .include([0])

    const yDomain = yExtentTest(this.chartData.transformedSeries)

    // Hard limit what the graph can minimumly show.
    if (yDomain[1] < 5) {
      yDomain[1] = 5
    }

    this.chart.yDomain(yDomain)
  }

  private reRenderWebglMain(xScale, yScale, webglBar, chartData) {
    let pixels = null
    const gl = this.chartContainer.nativeElement.querySelector("canvas").getContext("webgl")

    d3.select(this.chartContainer.nativeElement.querySelector("d3fc-canvas"))
      .on("measure", (event) => {
        const { width, height } = event.detail
        xScale.range([0, width])
        yScale.range([height, 0])
        webglBar.context(gl)
      })
      .on("draw", () => {
        if (pixels === null) {
          pixels = new Uint8Array(gl.drawingBufferWidth * gl.drawingBufferHeight * 4)
        }
        chartData.transformedSeries.forEach((s, i) => {
          webglBar.decorate((program) => {
            this.graphDrawStrategy
              .drawMethod(fc)
              .value(() => {
                return this.graphColorStrategy.paintGraph(d3, this.colors[this.colors.length - 1 - i])
              })
              .data(chartData.series)(program)
          })(s)
        })
      })
  }

  private reRenderWebglNavigator(xScale, yScale, navigatorSeries, chartData, reRenderNavigator = true) {
    let pixels = null
    const gl = this.navigatorChartContainer.nativeElement.querySelector("canvas").getContext("webgl")

    d3.select(this.navigatorChartContainer.nativeElement.querySelector("d3fc-canvas"))
      .on("measure", (event) => {
        if (reRenderNavigator) {
          const { width, height } = event.detail
          xScale.range([0, width])
          yScale.range([height, 0])
          navigatorSeries.context(gl)
        }
      })
      .on("draw", () => {
        if (pixels === null && reRenderNavigator) {
          pixels = new Uint8Array(gl.drawingBufferWidth * gl.drawingBufferHeight * 4)
        }
        chartData.transformedSeries.forEach((s, i) => {
          navigatorSeries.decorate((program) => {
            this.graphDrawStrategy
              .drawMethod(fc)
              .value(() => {
                return this.graphColorStrategy.paintGraph(d3, this.colors[this.colors.length - 1 - i])
              })
              .data(chartData.series)(program)
          })(s)
        })
      })
  }

  private setupBrushOverlay() {
    this.brushElement = this.navigatorChartContainer.nativeElement.querySelector("d3fc-svg").getElementsByClassName("brush")[0]
    this.brushOverlay = this.navigatorChartContainer.nativeElement.querySelector("d3fc-svg").getElementsByClassName("brush")[0]?.querySelectorAll("rect")[0]
    this.brushSelectionBox = this.navigatorChartContainer.nativeElement.querySelector("d3fc-svg").getElementsByClassName("brush")[0]?.querySelectorAll("rect")[1]

    if (!this.brushElement || this.brushOverlayW) {
      // Brush hasn't loaded yet
      return
    }

    this.brushSelectionBox.setAttribute("fill-opacity", "0")
    this.brushSelectionBox.setAttribute("stroke", "#FFFFFFF")

    this.brushOverlayW = d3.select(this.brushElement).append("rect").attr("x", 0).attr("y", 0).attr("height", 63).attr("width", this.brushSelectionBox?.x.animVal.value).attr("pointer-events", "none").attr("fill", "#CCCCCC").attr("fill-opacity", "0.3")

    this.brushOverlayE = d3
      .select(this.brushElement)
      .append("rect")
      .attr("x", this.brushSelectionBox?.x.animVal.value + this.brushSelectionBox?.width.animVal.value + "")
      .attr("y", 0)
      .attr("height", 63)
      .attr("width", this.brushOverlay?.width.animVal.value - (this.brushSelectionBox?.x.animVal.value + this.brushSelectionBox?.width.animVal.value))
      .attr("pointer-events", "none")
      .attr("fill", "#CCCCCC")
      .attr("fill-opacity", "0.3")
  }

  private updateBrushOverlay() {
    this.brushOverlayW?.attr("width", this.brushSelectionBox?.x.animVal.valueAsString)

    this.brushOverlayE?.attr("x", this.brushSelectionBox?.x.animVal.value + this.brushSelectionBox?.width.animVal.value)
    this.brushOverlayE?.attr("width", this.brushOverlay?.width.animVal.value - (this.brushSelectionBox?.x.animVal.value + this.brushSelectionBox?.width.animVal.value))
  }

  private setupBrushHandles() {
    if (!this.brushElement || this.brushHandleW) {
      // Brush hasn't loaded yet
      return
    }

    this.brushHandleW = d3
      .select(this.brushElement)
      .append("rect")
      .attr("x", 10)
      .attr("y", 19)
      .attr("rx", 3)
      .attr("ry", 3)
      .attr("width", 15)
      .attr("height", 23)
      .attr("cursor", "ew-resize")
      .attr("transform", "translate(-12, 2)")
      .attr("pointer-events", "none")
      .attr("stroke", "black")
      .attr("stroke-width", "1")
      .attr("stroke-opacity", "0.2")
      .attr("fill", "#AEAEAE")

    this.brushHandleWLine1 = d3.select(this.navigatorChartContainer.nativeElement.querySelector("d3fc-svg").getElementsByClassName("brush")[0]).append("rect").attr("width", 2).attr("height", 10).attr("transform", "translate(-4,27)").attr("pointer-events", "none").attr("fill", "#FFFFFF")

    this.brushHandleWLine2 = d3.select(this.navigatorChartContainer.nativeElement.querySelector("d3fc-svg").getElementsByClassName("brush")[0]).append("rect").attr("width", 2).attr("height", 10).attr("transform", "translate(-9,27)").attr("pointer-events", "none").attr("fill", "#FFFFFF")

    this.brushHandleWLongLine = d3
      .select(this.navigatorChartContainer.nativeElement.querySelector("d3fc-svg").getElementsByClassName("brush")[0])
      .append("rect")
      .attr("rx", 1)
      .attr("width", 4)
      .attr("height", 63)
      .attr("transform", "translate(0,0)")
      .attr("pointer-events", "none")
      .attr("fill", "#AEAEAE")

    this.brushHandleE = d3
      .select(this.brushElement)
      .append("rect")
      .attr("x", 10)
      .attr("y", 19)
      .attr("rx", 3)
      .attr("ry", 3)
      .attr("width", 15)
      .attr("height", 23)
      .attr("cursor", "ew-resize")
      .attr("transform", "translate(-2, 2)")
      .attr("pointer-events", "none")
      .attr("stroke", "black")
      .attr("stroke-width", "1")
      .attr("stroke-opacity", "0.2")
      .attr("fill", "#AEAEAE")

    this.brushHandleELine1 = d3.select(this.navigatorChartContainer.nativeElement.querySelector("d3fc-svg").getElementsByClassName("brush")[0]).append("rect").attr("width", 2).attr("height", 10).attr("transform", "translate(7,27)").attr("pointer-events", "none").attr("fill", "#FFFFFF")

    this.brushHandleELine2 = d3.select(this.navigatorChartContainer.nativeElement.querySelector("d3fc-svg").getElementsByClassName("brush")[0]).append("rect").attr("width", 2).attr("height", 10).attr("transform", "translate(2,27)").attr("pointer-events", "none").attr("fill", "#FFFFFF")

    this.brushHandleELongLine = d3
      .select(this.navigatorChartContainer.nativeElement.querySelector("d3fc-svg").getElementsByClassName("brush")[0])
      .append("rect")
      .attr("rx", 1)
      .attr("width", 4)
      .attr("height", 63)
      .attr("transform", "translate(-3,0)")
      .attr("pointer-events", "none")
      .attr("fill", "#AEAEAE")
  }

  private updateBrushHandles() {
    this.brushHandleW?.attr("x", this.brushSelectionBox?.x.animVal.valueAsString)
    this.brushHandleWLine1?.attr("x", this.brushSelectionBox?.x.animVal.valueAsString)
    this.brushHandleWLine2?.attr("x", this.brushSelectionBox?.x.animVal.valueAsString)
    this.brushHandleWLongLine?.attr("x", this.brushSelectionBox?.x.animVal.valueAsString)

    this.brushHandleE?.attr("x", this.brushSelectionBox?.x.animVal.value + this.brushSelectionBox?.width.animVal.value + "")
    this.brushHandleELine1?.attr("x", this.brushSelectionBox?.x.animVal.value + this.brushSelectionBox?.width.animVal.value + "")
    this.brushHandleELine2?.attr("x", this.brushSelectionBox?.x.animVal.value + this.brushSelectionBox?.width.animVal.value + "")
    this.brushHandleELongLine?.attr("x", this.brushSelectionBox?.x.animVal.value + this.brushSelectionBox?.width.animVal.value)
  }

  private getCurrentSelectionData(xDomain) {
    let currentSelectionData

    switch (this.periodicity.currentPeriodicity) {
      case this.periodicity.hourly:
        currentSelectionData = this.getArrayWithEmptySpots(this.getNewArrayFromCurrentSelection(xDomain[0], xDomain[1], this.periodicity.hourly), this.timeLabels.oneHour, xDomain[0], xDomain[1])
        break
      case this.periodicity.daily:
        currentSelectionData = this.getArrayWithEmptySpots(this.getNewArrayFromCurrentSelection(xDomain[0], xDomain[1], this.periodicity.daily), this.timeLabels.oneDay, xDomain[0], xDomain[1])
        break
      case this.periodicity.monthly:
        currentSelectionData = this.getArrayWithEmptySpots(this.getNewArrayFromCurrentSelection(xDomain[0], xDomain[1], this.periodicity.monthly), this.timeLabels.oneMonth, xDomain[0], xDomain[1])
        break
    }

    return currentSelectionData
  }

  private getNewArrayFromCurrentSelection(xStart, xEnd, periodicity) {
    const arrayToReturn = []

    xStart = this.roundTimestamp(xStart + periodicity / 2, periodicity)

    this.chartData.series.forEach((element) => {
      if (element.Date >= xStart && element.Date <= xEnd) {
        arrayToReturn.push(element)
      }
    })
    return arrayToReturn
  }

  private getArrayWithEmptySpots(initialArray, roundValue, xStart, xEnd) {
    let index = 0
    const arrayToReturn = []
    let roundedXStart = this.roundTimestamp(xStart, roundValue)
    const roundedXEnd = this.roundTimestamp(xEnd, roundValue)

    while (roundedXStart < roundedXEnd) {
      if (roundedXStart === this.roundTimestamp(initialArray[index]?.Date, roundValue) && index !== initialArray.length) {
        arrayToReturn.push(initialArray[index])
        index++
      } else {
        const emptyObj = JSON.parse(JSON.stringify(this.chartData.series[0]))

        // We need to reset all the properties of the object
        const newEmptyObj: any = Object.keys(emptyObj).reduce((accumulator, current) => {
          accumulator[current] = 0
          return accumulator
        }, {})

        newEmptyObj.Date = roundedXStart
        arrayToReturn.push(newEmptyObj)
      }
      roundedXStart += roundValue
    }
    return arrayToReturn
  }

  private roundTimestamp(timestamp, roundValue) {
    return Math.round(timestamp / roundValue) * roundValue
  }

  public finishLoading() {
    this.isLoading = false
  }

  private changeShownData(value: number) {
    if (this.disableSlider) {
      return
    }

    switch (value) {
      case 1:
        if (this.periodicity.currentPeriodicity === this.periodicity.monthly) {
          this.currentSelection = [0, 1]
          this.renderChart(this.monthlyData, this.normalizeSelectionToBound([0, 1]), false)
        } else {
          this.periodicity.currentPeriodicity = this.periodicity.monthly
          this.renderChart(this.monthlyData, this.normalizeSelectionToBound([0, 1]), true)
        }
        break
      case 2:
        if (this.periodicity.currentPeriodicity === this.periodicity.monthly) {
          this.renderChart(this.monthlyData, this.getCalculatedBrushRange(this.timeLabelThresholds.fiveYears), false)
        } else {
          this.periodicity.currentPeriodicity = this.periodicity.monthly
          this.renderChart(this.monthlyData, this.getCalculatedBrushRange(this.timeLabelThresholds.fiveYears), true)
        }
        break
      case 3:
        if (this.periodicity.currentPeriodicity === this.periodicity.monthly) {
          this.renderChart(this.monthlyData, this.getCalculatedBrushRange(this.timeLabelThresholds.twoYears), false)
        } else {
          this.periodicity.currentPeriodicity = this.periodicity.monthly
          this.renderChart(this.monthlyData, this.getCalculatedBrushRange(this.timeLabelThresholds.twoYears), true)
        }
        break
      case 4:
        if (this.periodicity.currentPeriodicity === this.periodicity.daily) {
          this.renderChart(this.dailyData, this.getCalculatedBrushRange(this.timeLabelThresholds.oneYear), false)
        } else {
          this.periodicity.currentPeriodicity = this.periodicity.daily
          this.renderChart(this.dailyData, this.getCalculatedBrushRange(this.timeLabelThresholds.oneYear), true)
        }
        break
      case 5:
        if (this.periodicity.currentPeriodicity === this.periodicity.daily) {
          this.renderChart(this.dailyData, this.getCalculatedBrushRange(this.timeLabelThresholds.sixMonths), false)
        } else {
          this.periodicity.currentPeriodicity = this.periodicity.daily
          this.renderChart(this.dailyData, this.getCalculatedBrushRange(this.timeLabelThresholds.sixMonths), true)
        }
        break
      case 6:
        if (this.periodicity.currentPeriodicity === this.periodicity.daily) {
          this.renderChart(this.dailyData, this.getCalculatedBrushRange(this.timeLabelThresholds.threeMonths), false)
        } else {
          this.periodicity.currentPeriodicity = this.periodicity.daily
          this.renderChart(this.dailyData, this.getCalculatedBrushRange(this.timeLabelThresholds.threeMonths), true)
        }
        break
      case 7:
        if (this.periodicity.currentPeriodicity === this.periodicity.daily) {
          this.renderChart(this.dailyData, this.getCalculatedBrushRange(this.timeLabelThresholds.oneMonth), false)
        } else {
          this.periodicity.currentPeriodicity = this.periodicity.daily
          this.renderChart(this.dailyData, this.getCalculatedBrushRange(this.timeLabelThresholds.oneMonth), true)
        }
        break
      case 8:
        if (this.periodicity.currentPeriodicity === this.periodicity.hourly) {
          this.renderChart(this.hourlyData, this.getCalculatedBrushRange(this.timeLabelThresholds.twoWeeks), false)
        } else {
          this.periodicity.currentPeriodicity = this.periodicity.hourly
          this.renderChart(this.hourlyData, this.getCalculatedBrushRange(this.timeLabelThresholds.twoWeeks), true)
        }
        break
      case 9:
        if (this.periodicity.currentPeriodicity === this.periodicity.hourly) {
          this.renderChart(this.hourlyData, this.getCalculatedBrushRange(this.timeLabelThresholds.oneWeek), false)
        } else {
          this.periodicity.currentPeriodicity = this.periodicity.hourly
          this.renderChart(this.hourlyData, this.getCalculatedBrushRange(this.timeLabelThresholds.oneWeek), true)
        }
        break
      case 10:
        if (this.periodicity.currentPeriodicity === this.periodicity.hourly) {
          this.renderChart(this.hourlyData, this.getCalculatedBrushRange(this.timeLabelThresholds.oneDay), false)
        } else {
          this.periodicity.currentPeriodicity = this.periodicity.hourly
          this.renderChart(this.hourlyData, this.getCalculatedBrushRange(this.timeLabelThresholds.oneDay), true)
        }
    }

    this.highlightPeriodicityButton(this.periodicity.currentPeriodicity)
  }

  private calculateBrushPercentage(timestamp) {
    const timeDifference = this.getTimeDifferenceAll()

    // If the timestamp exceeds the range of the data set, set value to 1
    if (timestamp >= timeDifference) {
      return 1
    }

    return timestamp / timeDifference
  }

  private getTimeDifferenceAll() {
    const startTimestamp = this.chartData.series[0]?.Date
    const endTimestamp = this.chartData.series[this.chartData.series.length - 1]?.Date
    return endTimestamp - startTimestamp
  }

  private getCalculatedBrushRange(percentage) {
    this.currentSelection = [this.currentSelection[1] - percentage, this.currentSelection[1]]

    this.currentSelection[0] = this.currentSelection[0] < this.minSelectionBound ? this.minSelectionBound : this.currentSelection[0]
    this.currentSelection[1] = this.currentSelection[1] > this.maxSelectionBound ? this.maxSelectionBound : this.currentSelection[1]

    return this.currentSelection
  }

  public swapPeriodicity(periodicity) {
    if (this.disableSlider) {
      return
    }
    switch (periodicity) {
      case 1:
        if (this.periodicity.currentPeriodicity !== this.periodicity.hourly) {
          this.periodicity.currentPeriodicity = this.periodicity.hourly
          this.value = 8
          this.renderChart(this.hourlyData, this.getCalculatedBrushRange(this.timeLabelThresholds.twoWeeks))
        }
        break
      case 2:
        if (this.periodicity.currentPeriodicity !== this.periodicity.daily) {
          // HOURLY --> DAILY
          if (this.periodicity.currentPeriodicity === this.periodicity.hourly) {
            this.value = 6
            this.renderChart(this.dailyData, this.getCalculatedBrushRange(this.timeLabelThresholds.threeMonths))
          } else {
            this.value = 5
            this.renderChart(this.dailyData, this.getCalculatedBrushRange(this.timeLabelThresholds.sixMonths))
          }
          this.periodicity.currentPeriodicity = this.periodicity.daily
        }
        break
      case 3:
        if (this.periodicity.currentPeriodicity !== this.periodicity.monthly) {
          this.periodicity.currentPeriodicity = this.periodicity.monthly
          this.value = 3
          this.renderChart(this.monthlyData, this.getCalculatedBrushRange(this.timeLabelThresholds.twoYears))
        }
    }

    this.highlightPeriodicityButton(this.periodicity.currentPeriodicity)
  }

  private getClosestTimelabel(timespan) {
    let currNearest = Infinity
    for (const timestamp of Object.values(this.timeLabels)) {
      if (Math.abs(timestamp - timespan) < Math.abs(currNearest - timespan)) {
        currNearest = timestamp
      }
    }

    return currNearest
  }

  private getTimelabelIndex(timespan) {
    let index = 2 // index starts at 2 to make our life easier with the return type
    for (const timestamp of Object.values(this.timeLabels)) {
      if (timestamp === timespan) {
        return index
      }
      index++
    }
    return undefined
  }

  private highlightPeriodicityButton(periodicity) {
    switch (periodicity) {
      case this.periodicity.monthly:
        this.monthlySelected = true
        this.dailySelected = false
        this.hourlySelected = false
        break
      case this.periodicity.daily:
        this.monthlySelected = false
        this.dailySelected = true
        this.hourlySelected = false
        break
      case this.periodicity.hourly:
        this.monthlySelected = false
        this.dailySelected = false
        this.hourlySelected = true
        break
    }
  }

  /*
   * This function was created due to a visual bug when showing area charts.
   * If there was no datapoint inbetween two far away data points, the entire underlying
   * area of those two points would be filled due to the way area charts work.
   * To combat this, we want to surround datachunks with datapoints that is near zero to lower
   * the area chart before going to the next.
   */
  private surroundDatachunksWithEmptyValues(data, periodicity) {
    const dataToReturn: any[] = []
    let timeUnitOfPeriodicity

    if (!data || data.length === 0) {
      return []
    }

    switch (periodicity) {
      case this.periodicity.hourly: {
        timeUnitOfPeriodicity = this.timeLabels.oneHour
        break
      }

      case this.periodicity.daily: {
        timeUnitOfPeriodicity = this.timeLabels.oneDay
        break
      }

      case this.periodicity.monthly: {
        timeUnitOfPeriodicity = this.timeLabels.oneMonth
        break
      }
    }

    // prepend data with empty element since we know this is needed every time
    dataToReturn.push(this.cloneDateObjectAndSetToEmpty(data[0], data[0].Date - this.timeLabels.threeMonths - 2))
    dataToReturn.push(this.cloneDateObjectAndSetToEmpty(data[0], data[0].Date - timeUnitOfPeriodicity - 2))
    dataToReturn.push(this.cloneDateObjectAndSetZeroValuesToEmpty(data[0]))

    for (let i = 1; i < data.length; i++) {
      // If the prev element is not empty and the next object is empty. Insert empty object before chunk of empty objects starts.
      if (!this.isObjectEmpty(data[i - 1]) && this.isObjectEmpty(data[i])) {
        dataToReturn.push(this.cloneDateObjectAndSetToEmpty(data[i - 1], data[i - 1].Date + timeUnitOfPeriodicity))
        continue
      }
      // If the prev element is empty and the next object is not empty. Insert empty object before chunk of NON empty objects starts.
      if (this.isObjectEmpty(data[i - 1]) && !this.isObjectEmpty(data[i])) {
        dataToReturn.pop()
        dataToReturn.push(this.cloneDateObjectAndSetToEmpty(data[i], data[i].Date - timeUnitOfPeriodicity + 1))
      }

      dataToReturn.push(this.cloneDateObjectAndSetZeroValuesToEmpty(data[i]))
    }
    return dataToReturn
  }

  private isObjectEmpty(obj) {
    for (const key of Object.keys(obj).slice(1)) {
      if (obj[key] > 0.001) {
        return false
      }
    }
    return true
  }

  private cloneDateObjectAndSetToEmpty(objectToClone, date) {
    const emptyObj = JSON.parse(JSON.stringify(objectToClone))

    // We need to reset all the properties of the object
    const newEmptyObj: any = Object.keys(emptyObj).reduce((accumulator, current) => {
      accumulator[current] = 0.001
      return accumulator
    }, {})

    newEmptyObj.Date = date

    return newEmptyObj
  }

  private cloneDateObjectAndSetZeroValuesToEmpty(objectToClone) {
    const emptyObj = JSON.parse(JSON.stringify(objectToClone))

    // We need to reset all the properties of the object
    const newEmptyObj: any = Object.keys(emptyObj).reduce((accumulator, current) => {
      if (emptyObj[current] === 0) {
        accumulator[current] = 0.001
      } else {
        accumulator[current] = emptyObj[current]
      }
      return accumulator
    }, {})

    return newEmptyObj
  }

  private getTooltip(n: number): string {
    switch (n) {
      // MONTHLY
      case 0:
        return "All time"
      case 1:
        return "5 years"
      case 2:
        return "2 years"
      // DAILY
      case 3:
        return "1 year"
      case 4:
        return "6 months"
      case 5:
        return "3 months"
      case 6:
        return "1 month"
      // HOURLY
      case 7:
        return "2 weeks"
      case 8:
        return "1 week"
      case 9:
        return "1 day"
    }
  }

  private getDateStringFromPeriodicity(timestamp, periodicity) {
    if (periodicity === this.periodicity.hourly) {
      // Format should be: Mon 01 Jan 2021, 01:00 (PM)
      const date = new Date(timestamp)
      const splitDateString = date.toDateString().split(" ")
      const splitTimeString = date.toLocaleTimeString()
      const splitOnNonNumber = (str) => {
        const [, ...arr] = str.match(/(\d*)([\s\S]*)/)
        return arr
      }

      return splitDateString[0] + " " + splitDateString[2] + " " + splitDateString[1] + " " + splitDateString[3] + ", " + splitOnNonNumber(splitTimeString)[0] + ":00 " + (splitTimeString.split(" ")[1] ?? "")
    }

    if (periodicity === this.periodicity.daily) {
      // Format should be: Mon 01 Jan 2021
      const splitDateString = new Date(timestamp).toDateString().split(" ")
      return splitDateString[0] + " " + splitDateString[2] + " " + splitDateString[1] + " " + splitDateString[3]
    }

    if (periodicity === this.periodicity.monthly) {
      // Format should be: January 2021
      const date = new Date(timestamp)
      const splitDateString = date.toDateString().split(" ")
      return this.monthNames[date.getMonth()] + " " + splitDateString[3]
    }
  }

  private normalizeSelectionToBound(selection) {
    if (selection[0] < this.minSelectionBound) {
      selection[0] = this.minSelectionBound
    }

    if (selection[1] > this.maxSelectionBound) {
      selection[1] = this.maxSelectionBound
    }

    return selection
  }

  public round(numberToRound) {
    return Math.round(numberToRound)
  }

  private sliderChange(value: number, reRender: boolean = true) {
    // Re-render brush and chart data based on slider value
    if (reRender) {
      this.changeShownData(value)
    }
  }

  onUserChange(changeContext: ChangeContext): void {
    this.sliderChange(changeContext.value, true)
  }

  onUserChangeStart(changeContext: ChangeContext): void {
    this.showToolTip = false
  }

  onUserChangeEnd(changeContext: ChangeContext): void {
    this.showToolTip = true
  }

  onSliderClick() {
    this.showToolTip = false
  }
}
