<div class="zest-wrapper">
  <div class="zest-load-wrapper">
    <div *ngIf="userEmail">
      <h1>Redirecting support user: {{ userEmail }}</h1>
    </div>
    <div class="middle spinner-container" *ngIf="isLoadingHotels | async">
      <mat-spinner [diameter]="100" class="grey"></mat-spinner>
    </div>
  </div>
</div>
