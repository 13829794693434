import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChildren, QueryList } from "@angular/core"

@Component({
  selector: "ag-toggle-button",
  templateUrl: "./ag-toggle-button.component.html",
  styleUrls: ["./ag-toggle-button.component.scss"],
})
export class AgToggleButtonComponent implements OnInit {
  @Input() option1: string = "Option 1"
  @Input() option2: string = "Option 2"
  @Input() option3: string = "Option 3"
  @Input() option4: string = "Option 4"
  // If you want the options to have less than 120px per option, if the text doesnt fit, it automatically changes the width
  @Input() widthPerOption: number = 120
  @Input() numberOfOptions: number = 2

  @Output() public chosenOption = new EventEmitter<string>()

  @ViewChildren("optionText") optionTexts: QueryList<ElementRef>

  selectedOption: string

  constructor() {}

  ngOnInit(): void {
    if (this.numberOfOptions > 4) this.numberOfOptions = 4
    this.select("1")
    this.updateWidthPerOption()
  }

  select(option: string) {
    this.selectedOption = option

    if (this.selectedOption === "1") this.chosenOption.emit(this.option1)
    else if (this.selectedOption === "2") this.chosenOption.emit(this.option2)
    else if (this.selectedOption === "3") this.chosenOption.emit(this.option3)
    else if (this.selectedOption === "4") this.chosenOption.emit(this.option4)
  }

  getSliderWidth(): string {
    return `${this.widthPerOption}px`
  }

  getContainerWidth(): string {
    const totalWidth = this.numberOfOptions * this.widthPerOption + this.numberOfOptions * 4 + 4
    return `${totalWidth}px`
  }

  getTransform(): string {
    const optionIndex = parseInt(this.selectedOption, 10) - 1
    if (optionIndex !== 0) return `translateX(${optionIndex * (this.widthPerOption + 4) + 4}px)`
    else return `translateX(${4}px)`
  }

  updateWidthPerOption(): void {
    const options = [this.option1, this.option2, this.option3, this.option4].slice(0, this.numberOfOptions)

    let maxWidth = this.widthPerOption

    options.forEach((option) => {
      const textWidth = this.getTextWidth(option)
      if (textWidth > maxWidth) {
        maxWidth = textWidth
      }
    })

    this.widthPerOption = maxWidth
  }

  getTextWidth(text: string): number {
    const canvas = document.createElement("canvas")
    const context = canvas.getContext("2d")
    if (context) {
      context.font = "14px Poppins-Regular"
      const metrics = context.measureText(text)
      return metrics.width + 20
    }
    return 120
  }
}
