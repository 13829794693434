import { Injectable } from "@angular/core"
import { ApiService } from "app/shared/services/api.service"
import { Observable } from "rxjs"

import { API_SECURITY_V2 } from "app/app-settings"
import ValidatorResponse from "./models/v2-phone-validator"

@Injectable({
  providedIn: "root",
})
export class SecurityService {
  constructor(private apiService: ApiService) {}

  public v2PhoneValidator = {
    ValidatePhoneNumber: (phoneNumber: string): Observable<ValidatorResponse> => this.apiService.get(`${API_SECURITY_V2}Validate?phoneNumber=${phoneNumber}`),
  }
}
