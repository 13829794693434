import { Component, OnInit, Inject, Input } from "@angular/core"
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog"

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent implements OnInit {
  dialogTitle: string
  dialogInfo: string
  dialogQuestion: string
  dialogOKButtonName: string
  dialogImage: string
  dialogReason: string

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.dialogInfo = this.data.info
    this.dialogTitle = this.data.title
    this.dialogQuestion = this.data.question
    this.dialogOKButtonName = this.data.okButtonName
    this.dialogImage = this.data.image
    this.dialogReason = this.data.reason
  }
}
