import { Component, HostBinding, Input } from "@angular/core"

@Component({
  selector: "ag-button",
  templateUrl: "./ag-button.component.html",
  styleUrls: ["./ag-button.component.scss"],
})
export class AgButtonComponent {
  @HostBinding("style.pointer-events") get pEvents(): string {
    return this.disabled ? "none" : "auto"
  }

  @Input() public label: string
  @Input() public imagePath: string
  @Input() public disabled: boolean
  @Input() public size: AgSize = "default"
  @Input() public color: AgColor = "primary"
  @Input() public type: AgButtonType = "default"

  constructor() {}
}

export type AgSize = "large" | "default" | "small"
export type AgColor = "primary" | "secondary" | "accent" | "success" | "info" | "warning" | "danger" | "light" | "dark" | "white"
export type AgButtonType = "default" | "text" | "outline"
