<div class="dialog-main-container">
  <div class="dialog-information-container">
    <div class="dialog-title">{{ dialogTitle }}</div>
    <div *ngIf="dialogDescription">
      <div class="dialog-description">{{ dialogDescription }}</div>
    </div>
  </div>
  <div class="buttons-container">
    <div style="display: flex; flex-direction: row; gap: 8px" *ngIf="data">
      <ag-button-v2 size="large" label="Cancel" type="secondary" specificWidth="83px" (click)="closeDialog()"></ag-button-v2>
      <ag-button-v2 size="large" [destructive]="destructive" [label]="destructive ? 'Delete' : buttonText" type="primary" specificWidth="140px" (click)="submitQuickAction()"></ag-button-v2>
    </div>
  </div>
</div>
