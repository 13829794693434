import { Component, Input } from "@angular/core"

@Component({
  selector: "app-ag-tree-v2-leaf",
  templateUrl: "./ag-tree-v2-leaf.component.html",
  styleUrls: ["./ag-tree-v2-leaf.component.scss"],
})
export class AgTreeV2LeafComponent {
  @Input() public agTitle: string = ""
  @Input() public agDescription: string = ""
  @Input() public insideNode: boolean = true
  @Input() public spaceBetweenContent: boolean = false
  constructor() {}
}
