<div class="dialog-container">
  <button class="button-cancel purple-big" mat-icon-button [mat-dialog-close]="false">
    <mat-icon>clear</mat-icon>
  </button>
  <h1>{{ dialogTitle }}</h1>
  <div [innerHTML]="dialogInfo"></div>
  <form class="form-horizontal" (ngSubmit)="onSubmit()" form="ngform">
    <app-labeled-text-field [description]="'Reason for release'" (changedText)="onReasonChanged($event)"></app-labeled-text-field>
    <mat-dialog-actions class="dialog-actions">
      <button type="submit" mat-button class="primary" [disabled]="!reason || isLoading">
        <span *ngIf="!isLoading">{{ dialogOKButtonName }}</span>
        <app-dot-loader-white-animation *ngIf="isLoading" height="40px"></app-dot-loader-white-animation>
      </button>
    </mat-dialog-actions>
  </form>
</div>
