import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core"

@Component({
  selector: "app-asc-desc-icon",
  templateUrl: "./asc-desc-icon.component.html",
  styleUrls: ["./asc-desc-icon.component.scss"],
})
export class AscDescIconComponent implements OnInit, OnChanges {
  @Input() showIcon: boolean = false
  @Input() sortKey: string = ""
  @Input() wantedSortKey: string = ""
  @Input() alphabetical: boolean = false
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {}
}
