import { Component, OnInit, Inject } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"
import { CommunicationApiService } from "@api/communication/communication-api.service"
import { ParentNode } from "@api/communication/models/node"
import { ToastServiceV2 } from "@shared/services/toast-v2/toast-v2.service"

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent implements OnInit {
  public dialogTitle: string
  public dialogDescription: string

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.dialogTitle = this.data.dialogTitle
    this.dialogDescription = this.data.dialogDescription
  }

  submitQuickAction() {
    this.dialogRef.close({
      success: true,
    })
  }
  closeDialog() {
    this.dialogRef.close({
      success: false,
    })
  }
}
