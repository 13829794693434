<div class="image-container">
  <div class="image-exists" *ngIf="imageExists(); else uploadImage">
    <img src="{{ imageUrl }}" />
    <button class="change-image" (click)="fileInput.click()">
      <img src="assets/shared/hotel-logo-icon-white.png" />
      <br />
      <span>Upload hotel logo</span>
    </button>
  </div>
  <ng-template #uploadImage>
    <button (click)="fileInput.click()">
      <img src="assets/shared/hotel-logo-icon-black.png" />
      <br />
      <span>Upload hotel logo</span>
    </button>
  </ng-template>
</div>
<input (change)="onUploadImage($event)" type="file" accept="image/x-png,image/gif,image/jpeg" [hidden]="true" #fileInput />
