import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core"
import { AbstractControl, ControlContainer, ControlValueAccessor, UntypedFormControl, FormControlDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms"
@Component({
  selector: "ag-duration",
  templateUrl: "./ag-duration.component.html",
  styleUrls: ["./ag-duration.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgDurationComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AgDurationComponent),
      multi: true,
    },
  ],
})
/**
 * input controls adapted from https://www.cssscript.com/html-duration-picker/
 */
export class AgDurationComponent implements ControlValueAccessor, Validator, AfterViewInit {
  @Input() label!: string
  @Input() hint?: string

  @ViewChild(FormControlDirective, { static: true })
  formControlDirective!: FormControlDirective

  @Input() formControl!: UntypedFormControl
  @Input() formControlName!: string

  @ViewChild("viewControl") viewControl?: ElementRef
  @ViewChild("inputField") inputField?: ElementRef<HTMLInputElement>

  get control(): UntypedFormControl {
    return this.formControl || <UntypedFormControl>this.controlContainer?.control?.get(this.formControlName || "")
  }

  constructor(
    private controlContainer: ControlContainer,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    this.inputField.nativeElement.setAttribute("data-adjustment-factor", "3600")

    if (!this.control.value) {
      this.control.setValue("00:00")
    }
  }

  writeValue = (value: string): void => this.formControlDirective.valueAccessor!.writeValue(value)
  registerOnChange = (fn: any): void => this.formControlDirective.valueAccessor!.registerOnChange(fn)
  registerOnTouched = (fn: any): void => this.formControlDirective.valueAccessor!.registerOnTouched(fn)

  validate(control: AbstractControl): ValidationErrors | null {
    return this.control.valid ? null : control.errors
  }

  onFocus = () => this.renderer.addClass(this.viewControl?.nativeElement, "focussed")
  onBlur = () => this.renderer.removeClass(this.viewControl?.nativeElement, "focussed")

  public handleClickFocus(event): void {
    const { cursorSelection, hourMarker, minuteMarker, content } = this.getCursorSelection(event)
    if (!cursorSelection) {
      return
    }
    switch (cursorSelection) {
      case CursorSelection.Hours:
        this.updateActiveAdjustmentFactor(event.target, 3600)
        event.target.setSelectionRange(0, hourMarker)
        return
      case CursorSelection.Minutes:
        this.updateActiveAdjustmentFactor(event.target, 60)
        event.target.setSelectionRange(hourMarker + 1, minuteMarker + 3)
        return
      default:
        this.updateActiveAdjustmentFactor(event.target, 60)
        event.target.setSelectionRange(hourMarker + 1, minuteMarker + 3)
        return
    }
  }
  public handleInputFocus(event) {
    this.onFocus()
    // get input selection
    const inputBox = event.target
    const { maxDuration } = this.getMinMaxConstraints()
    const maxHourInput = Math.floor(maxDuration / 3600)
    const charsForHours = maxHourInput < 1 ? 0 : maxHourInput.toString().length

    /* this is for firefox and safari, when you focus using tab key, both selectionStart
    and selectionEnd are 0, so manually trigger hour seleciton. */
    if ((event.target.selectionEnd === 0 && event.target.selectionStart === 0) || event.target.selectionEnd - event.target.selectionStart > charsForHours || charsForHours === 0) {
      setTimeout(() => {
        inputBox.focus()
        inputBox.select()
        this.highlightTimeUnitArea(inputBox, 3600)
      }, 1)
    }
  }

  /**
   * Handles all key down event in the picker. It will also apply validation
   * and block unsupported keys like alphabetic characters
   * @param {*} event
   * @return {void}
   */
  public handleKeydown(event) {
    const changeValueKeys = ["ArrowDown", "ArrowUp", "ArrowLeft", "ArrowRight", "Enter"]
    let adjustmentFactor = this.getAdjustmentFactor(event.target)

    if (changeValueKeys.includes(event.key)) {
      switch (event.key) {
        // use up and down arrow keys to increase value;
        case "ArrowDown":
          this.changeValueByArrowKeys(event.target, "down")
          this.highlightTimeUnitArea(event.target, adjustmentFactor)
          break
        case "ArrowUp":
          this.changeValueByArrowKeys(event.target, "up")
          this.highlightTimeUnitArea(event.target, adjustmentFactor)
          break
        // use left and right arrow keys to shift focus;
        case "ArrowLeft":
          this.shiftTimeUnitAreaFocus(event.target, "left")
          break
        case "ArrowRight":
          this.shiftTimeUnitAreaFocus(event.target, "right")
          break
        default:
      }
      event.preventDefault()
    }

    // Allow tab to change selection and escape the input
    if (event.key === "Tab") {
      adjustmentFactor = this.getAdjustmentFactor(event.target)
      const rightAdjustValue = 60
      const direction = event.shiftKey ? "left" : "right"
      if ((direction === "left" && adjustmentFactor < 3600) || (direction === "right" && adjustmentFactor > rightAdjustValue)) {
        /* while the adjustment factor is less than 3600, prevent default shift+tab behavior,
        and move within the inputbox from mm to hh */
        event.preventDefault()
        this.shiftTimeUnitAreaFocus(event.target, direction)
      }
    }

    // The following keys will be accepted when the input field is selected
    const acceptedKeys = ["Backspace", "ArrowDown", "ArrowUp", "Tab"]
    if (isNaN(event.key) && !acceptedKeys.includes(event.key)) {
      event.preventDefault()
      return false
    }
    // Gets the cursor position and select the nearest time interval
    const { cursorSelection, content } = this.getCursorSelection(event)
    const sectioned = event.target.value.split(":")
    const { maxDuration } = this.getMinMaxConstraints()
    const maxHourInput = Math.floor(maxDuration / 3600)
    const charsForHours = maxHourInput < 1 ? 0 : maxHourInput.toString().length
    if ((cursorSelection === "hours" && content.length >= charsForHours) || sectioned[0].length < charsForHours) {
      if (content.length > charsForHours && charsForHours > 0) {
        event.preventDefault()
      }
    } else if ((cursorSelection === "minutes" && content.length === 2) || sectioned[1].length < 2) {
      if (content.length >= 2 && ["6", "7", "8", "9"].includes(event.key)) {
        event.preventDefault()
      }
    } else {
      event.preventDefault()
    }
  }
  /**
   * Handles any user input attempts into a picker
   * @param {Event} event
   * @return {void}
   */
  public handleUserInput(event) {
    const inputBox = event.target
    const sectioned = inputBox.value.split(":")
    const { cursorSelection } = this.getCursorSelection(event)
    if (sectioned.length < 1) {
      const constrainedValue = this.applyMinMaxConstraints(inputBox)
      this.insertFormatted(inputBox, constrainedValue, false)
      return
    }

    const { maxDuration } = this.getMinMaxConstraints()
    const maxHourInput = Math.floor(maxDuration / 3600)
    const charsForHours = maxHourInput < 1 ? 0 : maxHourInput.toString().length

    const mustUpdateValue = this.validateValue(event.target.value)

    if (mustUpdateValue !== false) {
      const constrainedValue = this.applyMinMaxConstraints(this.durationToSeconds(mustUpdateValue))
      this.insertFormatted(event.target, constrainedValue, false)
    }
    // done entering hours, so shift highlight to minutes
    if ((charsForHours < 1 && cursorSelection === "hours") || (sectioned[0]?.length >= charsForHours && cursorSelection === "hours")) {
      if (charsForHours < 1) {
        sectioned[0] = "00"
      }
      this.shiftTimeUnitAreaFocus(inputBox, "right")
    }
    // done entering minutes, so just highlight minutes
    if (sectioned[1]?.length >= 2 && cursorSelection === "minutes") {
      this.highlightTimeUnitArea(inputBox, 60)
    }
  }

  /**
   * Handles blur events on pickers, and applies validation only if necessary.
   * @param {Event} event
   * @return {void}
   */
  public handleInputBlur(event) {
    this.onBlur()
    const mustUpdateValue = this.validateValue(event.target.value)
    let constrainedValue
    if (mustUpdateValue !== false) {
      constrainedValue = this.applyMinMaxConstraints(this.durationToSeconds(mustUpdateValue))
      const newDuration = this.secondsToDuration(constrainedValue)
      event.target.value = newDuration
      this.control.setValue(newDuration)
      return
    }
    constrainedValue = this.applyMinMaxConstraints(this.durationToSeconds(event.target.value))
    if (event.target.value !== this.secondsToDuration(constrainedValue)) {
      const newDuration = this.secondsToDuration(constrainedValue)
      event.target.value = newDuration
      this.control.setValue(newDuration)
    }
  }

  /**
   *
   * @param {String} value
   * @return {false | String} return false if theres no need to validate, and a string of a modified value if the string neeeded validation
   */
  private validateValue(value): false | string {
    const sectioned = value.split(":")
    if (sectioned.length < 1) {
      return "00:00"
    }
    let mustUpdateValue

    // if the input does not have a single ":" or is like "01:02:03:04:05", then reset the input
    if (sectioned.length !== 2) {
      return "00:00" // fallback to default
    }
    // if hour (hh) input is not a number or negative set it to 0
    if (isNaN(sectioned[0] || sectioned[0] < 0)) {
      sectioned[0] = "00"
      mustUpdateValue = true
    }
    // if hour (mm) input is not a number or negative set it to 0
    if (isNaN(sectioned[1]) || sectioned[1] < 0) {
      sectioned[1] = "00"
      mustUpdateValue = true
    }
    // if minutes (mm) more than 59, set it to 59
    if (sectioned[1] > 59 || sectioned[1].length > 2) {
      sectioned[1] = "59"
      mustUpdateValue = true
    }
    if (mustUpdateValue) {
      return sectioned.join(":")
    }
    return false
  }

  /**
   * Gets the min and max constraints
   * @return {{minDuration: string, maxDuration: string}}
   */
  private getMinMaxConstraints() {
    const minDuration = 0
    const maxDuration = 99 * 3600 + 59 * 60
    // by default 99:59 is max
    return {
      minDuration,
      maxDuration,
    }
  }

  private getCursorSelection(event): { cursorSelection: string; hourMarker: number; minuteMarker: number; content: string } {
    const {
      target: { selectionStart, selectionEnd, value },
    } = event
    const hourMarker = value.indexOf(":")
    const minuteMarker = value.lastIndexOf(":")

    let cursorSelection
    // The cursor selection is: hours
    if (selectionStart <= hourMarker) {
      cursorSelection = CursorSelection.Hours
    } else if (selectionStart > hourMarker) {
      // The cursor selection is: minutes
      cursorSelection = CursorSelection.Minutes
    } else {
      throw new Error("Selection not supported.") // this can be expanded if seconds are a requirement - e.g. 04:15:59, (hh:mm:ss)
    }
    const content = value.slice(selectionStart, selectionEnd)

    return { cursorSelection, hourMarker, minuteMarker, content }
  }

  /**
   * Highlights/selects the time unit area hh, mm or ss of a picker
   * @param {HTMLInputElement} inputBox
   * @param {3600 | 60} adjustmentFactor
   */
  private highlightTimeUnitArea(inputBox: HTMLInputElement, adjustmentFactor: number) {
    const hourMarker = inputBox.value.indexOf(":")
    const sectioned = inputBox.value.split(":")
    if (adjustmentFactor >= 60 * 60) {
      inputBox.selectionStart = 0 // hours mode
      inputBox.selectionEnd = hourMarker
    } else {
      inputBox.selectionStart = hourMarker + 1 // minutes mode
      inputBox.selectionEnd = hourMarker + 1 + sectioned[1].length
      adjustmentFactor = 60
    }

    if (adjustmentFactor >= 1 && adjustmentFactor <= 3600) {
      inputBox.setAttribute("data-adjustment-factor", adjustmentFactor.toString())
    }
  }

  /**
   * shift focus (text selection) between hh, mm, and ss with left and right arrow keys;
   * @param {*} inputBox
   * @param {'left' | 'right'} direction
   */
  private shiftTimeUnitAreaFocus(inputBox: HTMLInputElement, direction: string) {
    const adjustmentFactor = this.getAdjustmentFactor(inputBox)
    switch (direction) {
      case "left":
        this.highlightTimeUnitArea(inputBox, adjustmentFactor < 3600 ? adjustmentFactor * 60 : 3600)
        break
      case "right":
        this.highlightTimeUnitArea(inputBox, adjustmentFactor > 60 ? adjustmentFactor / 60 : 1)
        break
      default:
    }
  }

  /**
   * Increases or decreases duration value by up and down arrow keys
   * @param {*} inputBox
   * @param {'up' | 'down'} direction
   */
  private changeValueByArrowKeys(inputBox, direction) {
    const adjustmentFactor = this.getAdjustmentFactor(inputBox)
    let secondsValue = this.durationToSeconds(inputBox.value)

    switch (direction) {
      case "up":
        secondsValue += adjustmentFactor
        break
      case "down":
        secondsValue -= adjustmentFactor
        if (secondsValue < 0) {
          secondsValue = 0
        }
        break
    }
    const constrainedValue = this.applyMinMaxConstraints(secondsValue)
    this.insertFormatted(inputBox, constrainedValue, false)
  }

  /**
   * Applies a picker's min and max duration constraints to a given value
   * @param {Number} value in seconds
   * @return {Number} number withing the min and max data attributes
   */
  private applyMinMaxConstraints(value): number {
    const { maxDuration, minDuration } = this.getMinMaxConstraints()
    return Math.min(Math.max(value, minDuration), maxDuration)
  }

  private getAdjustmentFactor(inputBox: HTMLInputElement) {
    let adjustmentFactor = 1
    if (Number(inputBox.getAttribute("data-adjustment-factor")) > 0) {
      adjustmentFactor = Number(inputBox.getAttribute("data-adjustment-factor"))
    }
    return adjustmentFactor
  }

  /**
   * @param {*} inputBox
   * @param {Number} secondsValue value in seconds
   * @param {Boolean} dispatchSyntheticEvents whether to manually fire 'input' and 'change' event for other event listeners to get it
   */
  private insertFormatted(inputBox, secondsValue, dispatchSyntheticEvents) {
    const formattedValue = this.secondsToDuration(secondsValue)
    const existingValue = inputBox.value
    // Don't use setValue method here because
    // it breaks the arrow keys and arrow buttons control over the input
    inputBox.value = formattedValue

    // manually trigger an "input" event for other event listeners
    if (dispatchSyntheticEvents) {
      if (existingValue !== formattedValue) {
        inputBox.dispatchEvent(this.createEvent("change", { bubbles: true, cancelable: true }))
      }
      inputBox.dispatchEvent(this.createEvent("input"))
    }
  }

  /**
   * Converts seconds to a duration string
   * @param {value} value
   * @return {String}
   */
  private secondsToDuration(value) {
    let secondsValue = value
    const hours = Math.floor(secondsValue / 3600)
    secondsValue %= 3600
    const minutes = Math.floor(secondsValue / 60)
    const formattedHours = String(hours).padStart(2, "0")
    const formattedMinutes = String(minutes).padStart(2, "0")
    return `${formattedHours}:${formattedMinutes}`
  }

  /**
   * Converts a given duration string to seconds
   * @param {String} value
   * @return {Number}
   */
  private durationToSeconds(value) {
    if (!/:/.test(value)) {
      return 0
    }
    const sectioned = value.split(":")
    if (sectioned.length < 1) {
      return 0
    } else {
      return (
        // Number(sectioned[2] ? (sectioned[2] > 59 ? 59 : sectioned[2]) : 0) + // seconds
        Number((sectioned[1] > 59 ? 59 : sectioned[1]) * 60) + Number(sectioned[0] * 60 * 60)
      )
    }
  }
  /**
   * Manually creates and fires an Event
   * @param {*} type
   * @param {*} option - event options
   * @return {Event}
   */
  private createEvent(type, option = { bubbles: false, cancelable: false }) {
    if (typeof Event === "function") {
      return new Event(type)
    } else {
      const event = new CustomEvent(type, (option = { bubbles: option.bubbles, cancelable: option.cancelable }))
      return event
    }
  }

  /**
   * Set the 'data-adjustment-factor' attribute for a picker
   * @param {*} inputBox
   * @param {3600 | 60 | 1} adjustmentFactor
   */
  private updateActiveAdjustmentFactor(inputBox, adjustmentFactor) {
    inputBox.setAttribute("data-adjustment-factor", adjustmentFactor)
  }
}

enum CursorSelection {
  Hours = "hours",
  Minutes = "minutes",
}
