import { GraphTypeStrategy } from "./graph-type-strategy"

export class StackedbarTypeStrategy implements GraphTypeStrategy {
  constructGraph(fc, xScale: any, yScale: any) {
    return fc
      .autoBandwidth(fc.seriesWebglBar())
      .xScale(xScale)
      .yScale(yScale)
      .crossValue((d) => d.data.Date)
      .mainValue((d) => d[1])
      .baseValue((d) => d[0])
  }
}
