<div class="dialog-container">
  <button class="button-cancel purple-big" mat-icon-button [mat-dialog-close]="false">
    <mat-icon>clear</mat-icon>
  </button>
  <h1>{{ dialogTitle }}</h1>
  <p class="dialog-content">Write the number the guest received.</p>
  <p>
    <b style="font-size: 13px">Snap number</b>
    <br />
    <input type="text" placeholder="Number" class="form-control" [(ngModel)]="globalNumber" (keydown.enter)="onEnterKeydown()" />
  </p>
  <mat-dialog-actions class="dialog-actions">
    <button mat-button class="purple" [mat-dialog-close]="true" (click)="onDone()">{{ dialogOKButtonName }}</button>
  </mat-dialog-actions>
</div>
