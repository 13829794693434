export interface IToast {
  title?: string
  type?: ToastType
  message?: string
  links?: ILink[]
  undo?: boolean
  delay?: number
}

export enum ToastType {
  Success,
  Error,
  Info,
  Warning,
}

export interface ILink {
  title: string
  link: string
}
