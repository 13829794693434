import { Component, Input, Output, EventEmitter, OnInit, Injectable } from "@angular/core"

@Component({
  selector: "app-round-button",
  template: `
    <div class="round-button" (click)="onClick()" [ngStyle]="{ 'background-color': _color, border: '1px solid ' + _borderColor, color: _textColor }">
      <div *ngIf="_btnImage != undefined" class="round-button-image"><img src="{{ _btnImage }}" /></div>
      <div class="round-button-text">{{ btnText }}</div>
    </div>
  `,
  styles: [
    `
      .round-button {
        box-shadow: 0 1px 3px 0 rgba(140, 140, 140, 0.3);
        width: fit-content;
        padding: 10px 16px 10px 16px;
        border-radius: 18.5px;
        justify-content: center;
        display: flex;
        cursor: pointer;
        min-width: 125px;
        padding-left: 16px;
      }

      .round-button-image {
        margin-right: 8px;
      }
    `,
  ],
  animations: [],
})
@Injectable()
export class RoundButtonComponent implements OnInit {
  _btnImage = undefined
  _color = "#578a6b"
  _borderColor = "transparent"
  _textColor = "#ffffff"
  @Input() btnText = undefined
  @Input() set color(s: string) {
    this._color = s
  }
  @Input() set borderColor(s: string) {
    this._borderColor = s
  }
  @Input() set btnImage(s: string) {
    this._btnImage = s
  }
  @Input() set textColor(s: string) {
    this._textColor = s
  }
  @Output() btnOnClick = new EventEmitter<any>()

  constructor() {}

  ngOnInit() {}

  onClick() {
    this.btnOnClick.emit()
  }
}
