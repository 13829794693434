<div class="dialog-container">
  <button class="button-cancel purple-big" mat-icon-button [mat-dialog-close]="false">
    <mat-icon>clear</mat-icon>
  </button>
  <h1>{{ dialogTitle }}</h1>
  <ul class="dialog-content">
    <li *ngFor="let doorUnlockCase of doorUnlockCases">
      <p>
        Device: {{ doorUnlockCase.DeviceId }}
        <br />
        Manufacture: {{ doorUnlockCase.Manufacture }}
        <br />
        DeviceModel: {{ doorUnlockCase.DeviceModel }}
        <br />
        OSVersion: {{ doorUnlockCase.OSVersion }}
        <br />
        OS: {{ doorUnlockCase.OS }}
        <br />
        AppVersion: {{ doorUnlockCase.AppVersion }}
      </p>
      <ul class="month-items">
        <li *ngFor="let doorUnlockSession of doorUnlockCase.DoorUnlockSessions">{{ doorUnlockSession.Timestamp | date: "dd-MM-yyyy HH:mm:ss" }}: {{ doorUnlockSession.EventType }}</li>
      </ul>
    </li>
  </ul>
  <mat-dialog-actions class="dialog-actions">
    <button mat-button class="purple" [mat-dialog-close]="true">{{ dialogOKButtonName }}</button>
  </mat-dialog-actions>
</div>
