import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { LabelValue } from "../ag-drop-down.component"
import { FormBuilder, FormGroup } from "@angular/forms"
import { IconImageService } from "@shared/services/icon-image.service"

@Component({
  selector: "ag-multiple-box",
  templateUrl: "./ag-multiple-box.component.html",
  styleUrls: ["./ag-multiple-box.component.scss"],
})
export class AgMultipleBoxComponent implements OnInit {
  @Input() list: LabelValue[] = []
  @Input() disabledInputs: LabelValue[] = []
  @Input() showBox = false
  @Input() showSearchField = true
  @Input() useFixedPosition = false
  @Input() showHeaders: boolean = false
  @Input() maxHeight: number = 1000
  @Input() currentValueMultiple: LabelValue[]
  @Input() checkboxType: checkboxType = "square"

  @Output() outputMultiple = new EventEmitter<LabelValue[]>()
  @Output() searchBoxFieldClicked = new EventEmitter<boolean>()

  @ViewChild("inputField", { static: false }) inputField: ElementRef
  @ViewChild("arrowImage") arrowImage

  public displayedList: LabelValue[] = []
  public searchForm: FormGroup
  public isTyping = false
  public selectedSelectAll: boolean = false

  constructor(private fb: FormBuilder, public iconService: IconImageService) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchInput: [""],
    })
    if (!this.currentValueMultiple) {
      this.currentValueMultiple = []
    }
    this.displayedList = this.list
  }

  onInputChange(value: string) {
    if (value === "") {
      this.isTyping = false
      this.displayedList = this.list
    } else {
      this.isTyping = true
      this.displayedList = this.list.filter((x) => x.label.toLowerCase().includes(value.toLowerCase()))
    }
  }

  showBoxClicked() {
    if (!this.isTyping || (this.inputField && this.inputField.nativeElement && this.inputField.nativeElement.value === "")) {
      this.displayedList = this.list
    }
    return true
  }

  onFieldClicked() {
    this.showBox = false
    this.searchBoxFieldClicked.emit(true)
  }

  onItemClicked(selected: LabelValue) {
    const disabledItem = this.disabledInputs.find((disabledInput) => disabledInput.label === selected.label)
    if (selected.value === "search-box-title" || disabledItem) {
      return
    } else {
      if (this.currentValueMultiple.some((item) => item.label === selected.label)) {
        this.currentValueMultiple = this.currentValueMultiple.filter((item) => item.label !== selected.label)
        this.selectedSelectAll = false
      } else {
        this.currentValueMultiple.push(selected)
        this.selectedSelectAll = false
      }
      this.currentValueMultiple.length === this.displayedList.length ? (this.selectedSelectAll = true) : (this.selectedSelectAll = false)
      this.outputMultiple.emit(this.currentValueMultiple)
    }
  }

  onSelectAllClicked() {
    if (this.selectedSelectAll) {
      this.currentValueMultiple = []
      this.selectedSelectAll = false
      this.outputMultiple.emit(this.currentValueMultiple)
    } else {
      this.currentValueMultiple = this.list.filter((item) => !this.disabledInputs.find((disabledItem) => disabledItem.value === item.value))
      this.currentValueMultiple = this.currentValueMultiple.filter((item) => item.value !== "search-box-title")
      this.selectedSelectAll = true
      this.outputMultiple.emit(this.currentValueMultiple)
    }
  }

  public checkSelectedItems(item: LabelValue): boolean {
    if (this.currentValueMultiple.some((selected) => selected?.label === item?.label)) {
      return true
    }
    return false
  }

  isItemDisabled(labelValue: LabelValue): boolean {
    const itemDisabled = this.displayedList.some((item) => !this.disabledInputs.some((di) => di.label === item.label))
    if (itemDisabled) {
      return this.disabledInputs.some((item) => item.label === labelValue.label)
    }
    return false
  }

  showDivider(item: LabelValue) {
    return item.value === "search-box-title" && item.label !== this.displayedList[0].label
  }
}

export type checkboxType = "square" | "circle" | "none"
