import { AbstractControl, UntypedFormArray, UntypedFormGroup } from "@angular/forms"

export abstract class AgFormValidation {
  /**
   * Recursively validates all fields of a form.
   * @param control The form to validate
   * @returns Validity of the control
   */
  public static validate(control: AbstractControl): boolean {
    this._validateForm(control)
    return control.valid
  }

  private static _validateForm(control: AbstractControl) {
    if (control instanceof UntypedFormGroup) {
      this._markFormGroupAsDirty(control)
    } else if (control instanceof UntypedFormArray) {
      this._markFormArrayAsDirty(control)
    }
  }

  private static _markFormGroupAsDirty(control: UntypedFormGroup) {
    for (const key in control.controls) {
      if (Object.prototype.hasOwnProperty.call(control.controls, key)) {
        const element = control.controls[key]
        if (element.enabled) {
          element.markAsDirty()
          this._validateForm(element)
        }
      }
    }
  }

  private static _markFormArrayAsDirty(control: UntypedFormArray) {
    for (const key in control.controls) {
      if (Object.prototype.hasOwnProperty.call(control.controls, key)) {
        const element = control.controls[key]
        if (element.enabled) {
          element.markAsDirty()
          this._validateForm(element)
        }
      }
    }
  }
}
