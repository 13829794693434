import { Component, ContentChildren, Input, OnInit, QueryList } from "@angular/core"
import { AgTreeV2NodeComponent } from "./ag-tree-v2-node/ag-tree-v2-node.component"
import { AgTreeV2LeafComponent } from "./ag-tree-v2-leaf/ag-tree-v2-leaf.component"
import { IconImageService } from "@shared/services/icon-image.service"

@Component({
  selector: "app-ag-tree-v2",
  templateUrl: "./ag-tree-v2.component.html",
  styleUrls: ["./ag-tree-v2.component.scss"],
})
export class AgTreeV2Component implements OnInit {
  @ContentChildren(AgTreeV2NodeComponent, { descendants: true })
  nestedNodes!: QueryList<AgTreeV2NodeComponent>
  @ContentChildren(AgTreeV2LeafComponent, { descendants: true })
  nestedLeaves!: QueryList<AgTreeV2LeafComponent>

  @Input() showButtons: boolean = false

  constructor(public iconService: IconImageService) {}

  ngOnInit(): void {}
  get leaves(): AgTreeV2LeafComponent[] {
    return this.nestedLeaves.map((n) => n)
  }
  get nodes(): AgTreeV2NodeComponent[] {
    return this.nestedNodes.map((n) => n)
  }

  expandAll() {
    this.nodes.forEach((n) => (n.open = true))
  }
  collapseAll() {
    this.nodes.forEach((n) => (n.open = false))
  }
}
