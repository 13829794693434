import { ContextBoxStrategy } from "../strategy/context-box-strategy/context-box-strategy"
import { FullViewContextBoxStrategy } from "../strategy/context-box-strategy/fullview-context-box-strategy"
import { GraphColorStrategy } from "../strategy/graph-color-strategy/graph-color-strategy"
import { StackedbarColorStrategy } from "../strategy/graph-color-strategy/stackedbar-color-strategy"
import { GraphDrawStrategy } from "../strategy/graph-draw-strategy/graph-draw-strategy"
import { StrokeDrawStrategy } from "../strategy/graph-draw-strategy/stroke-draw-strategy"
import { GraphTypeStrategy } from "../strategy/graph-type-strategy/graph-type-strategy"
import { LineTypeStrategy } from "../strategy/graph-type-strategy/line-type-strategy"
import { LineNavigatorgraphTypeStrategy } from "../strategy/navigatorgraph-type-strategy/line-navigatorgraph-type-strategy"
import { NavigatorgraphTypeStrategy } from "../strategy/navigatorgraph-type-strategy/navigatorgraph-type-strategy"
import { GraphFactory } from "./graph-factory"

export class LineGraphFactory implements GraphFactory {
  produceGraphDrawStrategy(): GraphDrawStrategy {
    return new StrokeDrawStrategy()
  }
  produceContextBoxStrategy(): ContextBoxStrategy {
    return new FullViewContextBoxStrategy()
  }
  produceGraphColorStrategy(): GraphColorStrategy {
    return new StackedbarColorStrategy()
  }
  produceGraphTypeStrategy(): GraphTypeStrategy {
    return new LineTypeStrategy()
  }
  produceNavigatorGraphTypeStrategy(): NavigatorgraphTypeStrategy {
    return new LineNavigatorgraphTypeStrategy()
  }
}
