import { Component, OnInit } from "@angular/core"
import { TooltipService } from "app/shared/services/tooltip.service"

@Component({
  selector: "app-tooltip-container",
  templateUrl: "./tooltip-container.component.html",
})
export class TooltipContainerComponent {
  constructor(public tooltipService: TooltipService) {}
}
