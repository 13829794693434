<div class="flex-row" style="display: flex; gap: 12px">
  <div style="flex: 1 1 30%; display: flex; align-items: center">
    <span class="report-message__title">{{ message.Title }}</span>
  </div>
  <div style="flex: 0 0 10%; display: flex; align-items: center" class="report-message__status">
    <img [src]="message.statusIcon!()" />
  </div>
  <span style="flex: 1 1 40%" class="report-message__message" [ngClass]="message.statusClass()">
    {{ message.Message }}
  </span>
  <div style="flex: 1 1 120px; min-width: 120px" class="report-message__action">
    <a [routerLink]="message.ActionUrl" *ngIf="message?.ActionUrl && message.ActionLabel">
      {{ message.ActionLabel }}
    </a>
  </div>
</div>
