<div class="textarea-container" [ngStyle]="{ width: textareaStyles?.specificWidth ? textareaStyles?.specificWidth : '' }">
  <div
    [ngClass]="isInputFocused || (isInputHovered && !disable) ? 'textarea-focused' : 'textarea'"
    (mouseenter)="onEnter()"
    (mouseleave)="onLeave()"
    [ngStyle]="{
      'align-items': htmlTextarea?.offsetHeight > 28 ? 'flex-end' : 'center',
      opacity: disable ? 0.5 : 1,
      border: !isInputFocused && !isInputHovered && info?.type === 'error' && !disable ? '1px solid #E55E5E' : textareaStyles?.border,
      backgroundColor: !isInputFocused && !isInputHovered && textareaStyles?.backgroundColor && !disable ? textareaStyles?.backgroundColor : ''
    }"
    (click)="onTextareaClick()">
    <textarea
      [ngClass]="isInputFocused || (isInputHovered && !disable) ? 'input-focused' : 'input'"
      #textarea
      contenteditable="true"
      rows="1"
      [disabled]="disable"
      [formControl]="control"
      [value]="initValue"
      [placeholder]="placeholder"
      (focus)="onFocus($event)"
      (blur)="onBlur()"
      (input)="onInput($event)"
      (mouseup)="onMouseUp($event)"
      [ngStyle]="{
        backgroundColor: !isInputFocused && !isInputHovered && textareaStyles?.backgroundColor && !disable ? textareaStyles?.backgroundColor : '',
        'min-height': textareaStyles?.specificHeight ? textareaStyles?.specificHeight : ''
      }"></textarea>
    <img *ngIf="showSendButton && info?.type !== 'error'" class="sendIcon" [src]="iconService?.Icon.send" [ngStyle]="{ opacity: message?.length > 0 ? '1' : '0.5', cursor: message?.length > 0 ? 'pointer' : 'default' }" (click)="sendInput()" />

    <img *ngIf="showSendButton && info?.type === 'error'" class="sendIconError" [src]="iconService.Icon.send" [ngStyle]="{ opacity: '0.5' }" />
  </div>
  <div class="bottom-container" (click)="onTextareaClick()">
    <div class="info-container" *ngIf="info?.showInfo">
      <img class="info-img" [src]="iconService?.Icon.cross_1_5" *ngIf="info?.type === 'info'" />
      <img class="error-img" [src]="iconService?.Icon.cross_1_5" *ngIf="info?.type === 'error'" />
      <div class="info-text" [ngStyle]="{ color: info?.type === 'error' ? '#E55E5E' : '#222222' }">
        {{ info?.text }}
      </div>
    </div>
    <div class="chars" *ngIf="info?.showChars && maxChars">{{ chars }}/{{ maxChars }}</div>
  </div>
  <div *ngIf="deleteButton" class="delete-button" (click)="deleteClicked()">
    <img width="16" height="16" [src]="iconService.Icon.trash_can" />
  </div>
  <div *ngIf="withPersonalisedTags" class="personalised-tag" [ngStyle]="{ right: deleteButton ? '46px' : '12px' }" [ngClass]="personalisedTagsDropDownShown ? 'personalised-tag-open' : ''" (click)="personalisedTagsDropDownShown = !personalisedTagsDropDownShown">
    <img width="16" height="16" [src]="iconService.Icon.person" />
  </div>
  <div
    class="personalised-tag-dropdown"
    (click)="$event.stopPropagation()"
    [ngClass]="personalisedTagsDropDownShown ? 'personalised-tag-dropdown-shown' : ''"
    [ngStyle]="{ height: personalisedTagsDropDownShown ? (selectedTagIndex === -1 ? personalisedTagDropDownHeight + 'px' : 'fit-content') : '0px' }">
    <div style="width: 100%; height: 100%">
      <div class="drop-down-header" *ngIf="selectedTagIndex === -1">
        <h4 class="h4-medium">Instert a tag</h4>
        <div style="display: flex; gap: 8px; align-items: center; cursor: pointer" (click)="personalisedTagsDropDownShown = false">
          <h4 class="h4-medium">Close</h4>
          <img width="16" height="16" style="cursor: pointer" [src]="iconService.Icon.cross" />
        </div>
      </div>
      <div class="drop-down-header" *ngIf="selectedTagIndex !== -1">
        <h4 class="h4-medium">{{ filteredTagsList[selectedTagIndex].key }}</h4>
        <div style="display: flex; gap: 8px; align-items: center; cursor: pointer">
          <img width="16" height="16" style="cursor: pointer" [src]="iconService.Icon.left_1_5" (click)="selectedTagIndex = -1" />
          <h4 class="h4-medium" (click)="selectedTagIndex = -1">Back</h4>
        </div>
      </div>
      <div class="drop-down-search" *ngIf="selectedTagIndex === -1">
        <input placeholder="Search for a personalised tag" [(ngModel)]="searchTerm" (input)="filterTags()" />
        <img width="20" height="20" [src]="iconService.Icon.magnifier" />
      </div>

      <div class="drop-down-list" [ngStyle]="{ height: personalisedTagDropDownHeight - 128 + 'px' }" *ngIf="selectedTagIndex === -1">
        <div *ngFor="let tag of filteredTagsList; let i = index; let last = last" class="list-item" [ngClass]="{ 'no-border': last }" (click)="selectedTagIndex = i">
          <p>{{ tag.key }}</p>
          <img width="16" height="16" [src]="iconService.Icon.right_1_5" />
        </div>
      </div>
      <div class="hide-or-replace">
        <div class="replace-row" (click)="replaceOrHide = 'hide'">
          <img [src]="replaceOrHide === 'hide' ? iconService.Icon.radio_checked : iconService.Icon.radio_unchecked" />
          <div class="text-button">
            <p class="p-medium">Hide when information is missing</p>
            <p>Don’t show any text if the information about {{ filteredTagsList[selectedTagIndex]?.key }} is missing from a recipient.</p>
          </div>
        </div>
        <div class="or-divider">
          <div class="half-divider"></div>
          <h4 class="h4-medium">or</h4>
          <div class="half-divider"></div>
        </div>
        <div class="replace-row">
          <img [src]="replaceOrHide === 'replace' ? iconService.Icon.radio_checked : iconService.Icon.radio_unchecked" (click)="replaceOrHide = 'replace'" />
          <div class="text-button">
            <p class="p-medium" (click)="replaceOrHide = 'replace'">Show fallback text</p>
            <p (click)="replaceOrHide = 'replace'">Set an alternative text that will show in the email when the information about {{ filteredTagsList[selectedTagIndex]?.key }} is missing from a recipient.</p>
            <div style="height: 74px; overflow: hidden">
              <form [formGroup]="replaceForm">
                <ag-input-field
                  label="Insert fallback text"
                  [inputType]="'text'"
                  fieldId="replacementText"
                  formControlName="replacementText"
                  (inputResult)="replaceText = $event"
                  [disable]="replaceOrHide === 'hide'"
                  [styles]="{ specificWidth: '100%', specificHeight: '48px' }"
                  [info]="{ showInfo: true, type: 'info', text: 'Fallback text can contain one or multiple words.' }"></ag-input-field>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="drop-down-footer" *ngIf="selectedTagIndex !== -1">
        <ag-button-v2 type="tertiary" label="Cancel" (click)="selectedTagIndex = -1"></ag-button-v2>
        <ag-button-v2 [disabled]="replaceOrHide === 'replace' ? replaceText === '' : false" label="Insert" (click)="insertTag(filteredTagsList[selectedTagIndex].value)"></ag-button-v2>
      </div>
    </div>
  </div>
</div>
