import { EventEmitter, Injectable, OnDestroy, OnInit } from "@angular/core"
import { Environment } from "environments/environment"
import { BehaviorSubject, interval, Subscription } from "rxjs"
import { webSocket, WebSocketSubject } from "rxjs/webSocket"
import { StateService } from "app/shared/services/state.service"
import { STORAGE_TOKEN, STORAGE_USER } from "../../app-settings"
import { Employee } from "../models/employee"
import { Hotel } from "../models"

@Injectable()
export class CommunicationWebSocketService {
  windowHasFocus = true
  socket: WebSocket
  webSocketIsActivated = false

  CommunicationWebSocketMessageEventEmitter: EventEmitter<any> = new EventEmitter<any>()
  constructor(private stateService: StateService) {
    this.initCommunicationWebSocket()

    window.addEventListener("focus", (event) => {
      this.windowHasFocus = true
      this.initCommunicationWebSocket()
    })

    window.addEventListener("beforeunload", (event) => {
      this.disconnect()
    })
  }

  initCommunicationWebSocket() {
    const user: Employee = JSON.parse(localStorage.getItem(STORAGE_USER))
    const token = localStorage.getItem(STORAGE_TOKEN)?.split(",")[0]
    if (!this.socket || this.socket === null) {
      this.socket = new WebSocket(Environment.WebsocketGateCommunication)
      this.socket.onopen = (event) => {
        this.sendMessage({ protocol: "json", version: 1 })
        var authObj = {
          type: 1,
          target: "AuthorizeReception",
          arguments: [token, this.stateService.selectedHotel.Id],
        }
        this.sendMessage(authObj)
      }
      this.socket.onmessage = (event) => {
        var msg = this.isJSONString(event.data.slice(0, 1)) ? JSON.parse(event.data.slice(0, -1)) : ""
        if (event.data === '{"type":6}\u001e') {
        } else if (msg?.target === "ReceiveMessage" && msg?.type === 1) {
          this.CommunicationWebSocketMessageEventEmitter.emit(msg)
        }
      }
      this.socket.onclose = (event) => {}
    }
  }

  private isJSONString(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  sendMessage(msg: any): void {
    const payload = JSON.stringify(msg)
    const messageWithSeparator = `${payload}\u001e` // Record separator
    this.socket.send(messageWithSeparator)
  }
  disconnect(): void {
    this.socket.close()
    this.socket = null
  }
}
