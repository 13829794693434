import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core"

@Component({
  selector: "app-data-display-icon",
  templateUrl: "./data-display-icon.component.html",
  styleUrls: ["./data-display-icon.component.scss"],
})
export class DataDisplayIconComponent implements OnInit, OnChanges {
  @Input() iconSrc = ""
  @Input() title = ""
  @Input() amount = ""
  @Input() percentage = ""
  @Input() backgroundColor = ""
  @Input() textColor = ""
  @Input() agTheme = ""
  @Input() descriptionText = ""
  @Input() descriptionTextClass = "grey-description-text"
  public boldFirstTwoWords = ""

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.descriptionText !== undefined && changes?.descriptionText.currentValue !== "") {
      let words = changes?.descriptionText.currentValue.split(" ")
      this.boldFirstTwoWords = `${words[0]} ${words[1]}`
      this.descriptionText = words.slice(2).join(" ")
    } else {
      this.descriptionText = ""
      this.boldFirstTwoWords = ""
    }
  }

  ngOnInit(): void {
    if (this.percentage) {
      var roundedPercentage: number = Math.round(Number(this.percentage))
      this.percentage = roundedPercentage.toString()
    }
  }
}
