<div class="flex-row" style="display: flex; gap: 12px" (click)="expanded = !expanded" *ngIf="messages.length > 0">
  <span style="flex: 1 1 30%">{{ reportTitle }}</span>
  <div style="flex: 0 0 10%; display: flex; align-items: center" class="remark-status">
    <img *ngIf="!expanded" [src]="statusIcon" />
  </div>
  <span style="flex: 1 1 40%" [ngClass]="statusClass">
    {{ reportMessage }}
  </span>
  <div style="flex: 1 1 120px; min-width: 120px" class="remarks-arrow">
    <img [src]="expanded ? 'assets/icons/CollapseLarge.svg' : 'assets/icons/ExpandLarge.svg'" />
  </div>
</div>
<ng-container *ngIf="expanded">
  <ag-report-message *ngFor="let message of messages" [message]="message"></ag-report-message>
</ng-container>
