import { Pipe, PipeTransform } from "@angular/core"
import { IBookingSession, LocalDate } from "@api/booking/models/booking-session"
import moment from "moment"

@Pipe({
  name: "SortDirectBookingsItemsPipe",
})
export class SortDirectBookingsItemsPipe implements PipeTransform {
  formatDate(date: LocalDate) {
    if (date) {
      return moment(new Date(date.Year, date.Month - 1, date.Day))
        .format("DD/MM/YY")
        .toString()
    }
  }

  getPriceEuro(booking: IBookingSession) {
    if (booking.Status == "Search" || !booking.Euro) {
      return ""
    } else {
      return this.formatEuro(booking.Euro)
    }
  }

  formatEuro(price) {
    return price != null ? `${Number(parseFloat(price).toFixed(2)).toLocaleString("de-DE")}` : ""
  }

  getPrice(booking: IBookingSession) {
    if (booking.Status == "Search" || !booking.TotalReservationPrice) {
      return ""
    } else {
      return this.formatTotalPrice(booking.TotalReservationPrice, booking?.Currency)
    }
  }

  formatTotalPrice(total: string, currency: string) {
    var price = total != null && total != undefined ? Number(parseFloat(total)).toLocaleString("de-DE") : ""
    var curr = currency != null ? currency : ""
    return curr != "EUR" ? `${curr} ${price}` : ""
  }

  getFullName(booking: IBookingSession) {
    if (booking.UserInfo != null && booking.UserInfo.Name != null && booking.UserInfo.Surname) {
      return `${booking.UserInfo.Name} ${booking.UserInfo.Surname}`
    } else {
      return `(NP)`
    }
  }

  getPaymentType(booking: IBookingSession) {
    if (booking.PaymentType !== null && booking.PaymentType !== undefined) {
      if (booking.Status === "Confirmed" && booking.PaymentType === "PayNow") {
        return "Yes"
      } else {
        return "No"
      }
    } else {
      return ""
    }
  }

  transform(items: any, key: string, direction: boolean) {
    if (!items) {
      return []
    }
    if (!key) {
      return items
    }

    items.sort((a, b) => {
      const compare = (a: string | number, b: string | number, isAsc: boolean) => (a < b ? -1 : 1) * (isAsc ? -1 : 1)
      switch (key) {
        case "Profile":
          return compare(this.getFullName(a), this.getFullName(b), direction)
        case "Paid":
          return compare(this.getPaymentType(a), this.getPaymentType(b), direction)
        case "Arrival":
          return compare(this.formatDate(a.ArrivalDate), this.formatDate(b.ArrivalDate), direction)
        case "Departure":
          return compare(this.formatDate(a.DepartureDate), this.formatDate(b.DepartureDate), direction)
        case "Status":
          return compare(a.Status, b.Status, direction)
        default:
          return 0
      }
    })

    return items
  }
}
