import { AfterViewInit, Component, ElementRef, HostListener, Input, Renderer2, ViewChild, SimpleChanges } from "@angular/core"
import { IconImageService } from "@shared/services/icon-image.service"
import { AgTooltipDirectiveV2 } from "./ag-tooltip-v2.directive"

@Component({
  selector: "ag-tooltip-v2",
  templateUrl: "./ag-tooltip-v2.component.html",
  styleUrls: ["./ag-tooltip-v2.component.scss"],
})
export class AgTooltipV2Component implements AfterViewInit {
  @ViewChild("tooltip", { static: false }) tooltip: ElementRef
  @Input() title: string
  @Input() description: string
  @Input() position: Position
  @Input() adjustPosition: AdjustPosition
  @Input() ref: any

  private checkErrorPosition: PositionOption = {
    position: "top",
    xSpace: 0,
    ySpace: 0,
  }
  private top: PositionOption = {
    position: "top",
    xSpace: 0,
    ySpace: 0,
  }
  private bottom: PositionOption = {
    position: "bottom",
    xSpace: 0,
    ySpace: 0,
  }
  private right: PositionOption = {
    position: "right",
    xSpace: 0,
    ySpace: 0,
  }
  private left: PositionOption = {
    position: "left",
    xSpace: 0,
    ySpace: 0,
  }

  private topForLeftRight: number
  private leftForLeft: number
  private leftForRight: number
  private topForTop: number
  private topForBottom: number
  private leftForTopBottom: number

  @HostListener("window:resize", ["$event"])
  onWindowResize(event: Event): void {
    this.createTooltip()
  }

  constructor(public iconService: IconImageService, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.checkErrorPosition.position = this.position
    this.createTooltip()
  }

  createTooltip(): void {
    const targetRef = this.ref.nativeElement
    const tooltipRef = this.tooltip.nativeElement

    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    const targetRect = targetRef.getBoundingClientRect()
    this.topForLeftRight = targetRect.top + targetRect.height / 2 - tooltipRef.offsetHeight / 2
    this.leftForLeft = targetRect.left - tooltipRef.offsetWidth - 6
    this.leftForRight = targetRect.left + targetRect.width + 6
    this.topForTop = targetRect.top - tooltipRef.offsetHeight - 6
    this.topForBottom = targetRect.top + targetRect.height + 6
    this.leftForTopBottom = targetRect.left + targetRect.width / 2 - tooltipRef.offsetWidth / 2

    this.setTopForLeftRight(targetRect.top, targetRect.height, tooltipRef.offsetHeight, this.adjustPosition)
    this.setTopForTop(targetRect.top, tooltipRef.offsetHeight, this.adjustPosition)
    this.setTopForBottom(targetRect.top, targetRect.height, this.adjustPosition)
    this.setLeftForTopBottom(targetRect.left, targetRect.width, tooltipRef.offsetWidth, this.adjustPosition)
    this.setLeftForLeft(targetRect.left, tooltipRef.offsetWidth, this.adjustPosition)
    this.setLeftForRight(targetRect.left, targetRect.width, this.adjustPosition)

    this.setPositionOptions(this.topForTop, this.topForBottom, this.leftForRight, this.leftForLeft, windowHeight, windowWidth)

    this.setInitPositionNumber(this.checkErrorPosition.position, this.topForTop, this.topForBottom, this.leftForLeft, this.leftForRight, windowHeight, windowWidth)

    this.setPosition(this.checkErrorPosition, this.top, this.bottom, this.left, this.right, windowHeight, windowWidth)

    switch (this.position) {
      case "left":
        this.renderer.setStyle(tooltipRef, "left", `${this.leftForLeft}px`)
        this.renderer.setStyle(tooltipRef, "top", `${this.topForLeftRight}px`)
        break
      case "right":
        this.renderer.setStyle(tooltipRef, "left", `${this.leftForRight}px`)
        this.renderer.setStyle(tooltipRef, "top", `${this.topForLeftRight}px`)
        break
      case "top":
        this.renderer.setStyle(tooltipRef, "left", `${this.leftForTopBottom}px`)
        this.renderer.setStyle(tooltipRef, "top", `${this.topForTop}px`)
        break
      case "bottom":
        this.renderer.setStyle(tooltipRef, "left", `${this.leftForTopBottom}px`)
        this.renderer.setStyle(tooltipRef, "top", `${this.topForBottom}px`)
        break
    }
  }

  setTopForLeftRight(targetTop, targetHeight, offsetHeight, adjustPosition: AdjustPosition) {
    this.topForLeftRight = targetTop + targetHeight / 2 - offsetHeight / 2
    if (adjustPosition.position === "top") {
      this.topForLeftRight = this.topForLeftRight - adjustPosition.space
    } else if (adjustPosition.position === "bottom") {
      this.topForLeftRight = this.topForLeftRight + adjustPosition.space
    }
  }

  setTopForTop(targetTop, targetHeight, adjustPosition: AdjustPosition) {
    this.topForTop = targetTop - targetHeight - 6
    if (adjustPosition.position === "top") {
      this.topForTop = this.topForTop - adjustPosition.space
    } else if (adjustPosition.position === "bottom") {
      this.topForTop = this.topForTop + adjustPosition.space
    }
  }

  setTopForBottom(targetTop, targetHeight, adjustPosition: AdjustPosition) {
    this.topForBottom = targetTop + targetHeight + 6
    if (adjustPosition.position === "top") {
      this.topForBottom = this.topForBottom + adjustPosition.space
    } else if (adjustPosition.position === "bottom") {
      this.topForBottom = this.topForBottom + adjustPosition.space
    }
  }

  setLeftForTopBottom(targetLeft, targetWidth, offsetWidth, adjustPosition: AdjustPosition) {
    this.leftForTopBottom = targetLeft + targetWidth / 2 - offsetWidth / 2
    if (adjustPosition.position === "left") {
      this.leftForTopBottom = this.leftForTopBottom - adjustPosition.space
    } else if (adjustPosition.position === "right") {
      this.leftForTopBottom = this.leftForTopBottom + adjustPosition.space
    }
  }

  setLeftForLeft(targetLeft, offsetWidth, adjustPosition: AdjustPosition) {
    this.leftForLeft = targetLeft - offsetWidth - 6
    if (adjustPosition.position === "left") {
      this.leftForLeft = this.leftForLeft - adjustPosition.space
    } else if (adjustPosition.position === "right") {
      this.leftForLeft = this.leftForLeft + adjustPosition.space
    }
  }

  setLeftForRight(targetLeft, targetWidth, adjustPosition: AdjustPosition) {
    this.leftForRight = targetLeft + targetWidth + 6
    if (adjustPosition.position === "left") {
      this.leftForRight = this.leftForRight - adjustPosition.space
    } else if (adjustPosition.position === "right") {
      this.leftForRight = this.leftForRight + adjustPosition.space
    }
  }

  setPositionOptions(top: number, bottom: number, right: number, left: number, windowHeight: number, windowWidth: number) {
    if (left > 0) {
      this.top = { position: "top", ySpace: top, xSpace: left }
    } else if (right < windowWidth) {
      this.top = { position: "top", ySpace: top, xSpace: right }
    }

    if (left > 0) {
      this.bottom = { position: "bottom", ySpace: bottom, xSpace: left }
    } else if (right < windowWidth) {
      this.bottom = { position: "bottom", ySpace: bottom, xSpace: right }
    }

    if (top > 0) {
      this.right = { position: "right", ySpace: top, xSpace: right }
    } else if (bottom < windowHeight) {
      this.right = { position: "right", ySpace: bottom, xSpace: right }
    }

    if (top > 0) {
      this.left = { position: "left", ySpace: top, xSpace: left }
    } else if (bottom < windowHeight) {
      this.left = { position: "left", ySpace: bottom, xSpace: left }
    }
  }

  setInitPositionNumber(position: string, top: number, bottom: number, left: number, right: number, windowHeight: number, windowWidth: number) {
    if (position === "top" && left < 0) {
      this.checkErrorPosition = { position: "top", ySpace: top, xSpace: left }
    } else if (position === "top" && right > windowWidth) {
      this.checkErrorPosition = { position: "top", ySpace: top, xSpace: right }
    }

    if (position === "bottom" && left < 0) {
      this.checkErrorPosition = { position: "bottom", ySpace: bottom, xSpace: left }
    } else if (position === "bottom" && right > windowWidth) {
      this.checkErrorPosition = { position: "bottom", ySpace: bottom, xSpace: right }
    }

    if (position === "right" && top < 0) {
      this.checkErrorPosition = { position: "right", ySpace: top, xSpace: right }
    } else if (position === "right" && bottom > windowHeight) {
      this.checkErrorPosition = { position: "right", ySpace: bottom, xSpace: right }
    }

    if (position === "left" && top < 0) {
      this.checkErrorPosition = { position: "left", ySpace: top, xSpace: left }
    } else if (position === "left" && bottom > windowHeight) {
      this.checkErrorPosition = { position: "left", ySpace: bottom, xSpace: left }
    }
  }

  setPosition(initPosition: PositionOption, top: PositionOption, bottom: PositionOption, left: PositionOption, right: PositionOption, windowHeight: number, windowWidth: number) {
    if (initPosition.xSpace < 0 || initPosition.ySpace < 0 || initPosition.ySpace > windowHeight || initPosition.xSpace > windowWidth) {
      if (right.xSpace < windowWidth) {
        this.position = right.position
      } else if (left.xSpace > 0) {
        this.position = left.position
      } else if (top.ySpace > 0 && left.xSpace > 0 && right.xSpace! > windowWidth) {
        this.position = top.position
      } else if (bottom.ySpace < windowHeight && left.xSpace > 0 && right.xSpace! > windowWidth) {
        this.position = bottom.position
      }
    }
  }
}

export type Position = "top" | "bottom" | "right" | "left"

export type PositionOption = {
  position: Position
  xSpace: number
  ySpace: number
}

export type AdjustPosition = {
  position: Position
  space: number
}
