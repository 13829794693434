import { Component, OnInit, Input, ElementRef } from "@angular/core"

@Component({
  selector: "app-dot-loader-white-animation",
  template: `
    <ng-lottie [height]="height" [options]="options" [styles]="styles"></ng-lottie>
  `,
})
export class DotLoaderWhiteAnimationComponent implements OnInit {
  @Input() height = "40px"

  public options = {
    path: "assets/animations/DotLoaderWhite.json",
    autoplay: true,
    loop: true,
  }

  public styles = {
    margin: "0 auto",
  }

  constructor() {}

  ngOnInit(): void {}
}
