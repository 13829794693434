<div class="ag-tree-node" [ngClass]="{ open: open }">
  <div class="flex-row flex-space-between flex-align-center row-container">
    <div style="flex-grow: 1" class="flex-column flex-space-evenly flex-align-start">
      <span>
        <b>{{ agTitle }}</b>
      </span>
      <span *ngIf="agDescription" class="agDescription">{{ agDescription }}</span>
    </div>
    <div class="flex-row flex-space-between flex-align-center" style="display: flex; gap: 24px">
      <ng-content select="[inline]"></ng-content>
    </div>
    <div class="flex-center flex-align-center">
      <div *ngIf="collapsible" (click)="toggle()" class="flex-row flex-center flex-align-center node-toggle">
        <img width="20" height="20" [src]="open ? iconService.Icon.up_1_5 : iconService.Icon.down_1_5" />
      </div>
    </div>
  </div>
  <div *ngIf="!collapsible || open" class="ag-tree-content" [@inOutAnimation]>
    <ng-content></ng-content>
  </div>
</div>
