import { Directive, Output, EventEmitter, HostBinding, HostListener } from "@angular/core"

@Directive({
  selector: "[appDragDropImage]",
})
export class DragDropImageDirective {
  @Output() onFileDropped = new EventEmitter<any>()

  @HostBinding("style.background-color") private background = ""
  @HostBinding("style.opacity") private opacity = "1"

  // Dragover listener
  @HostListener("dragover", ["$event"]) onDragOver(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    this.background = "#61566e"
    this.opacity = "0.5"
  }

  // Dragleave listener
  @HostListener("dragleave", ["$event"]) public onDragLeave(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    this.background = ""
    this.opacity = "1"
  }

  // Drop listener
  @HostListener("drop", ["$event"]) public ondrop(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    this.background = ""
    this.opacity = "1"
    const files = evt.dataTransfer.files
    if (files.length > 0) {
      this.onFileDropped.emit(files)
    }
  }
}
