<div class="main-container placement-{{ placement }}" [@fadeInOut]="datepickerShown ? 'in' : 'out'" *ngIf="datepickerShown" (click)="insideClick = true">
  <div class="flex-row">
    <div class="custom-datepicker">
      <div class="header">
        <img class="image" [src]="iconService.Icon.left_1_5" (click)="previousMonth()" />
        <span class="label">{{ label }}</span>
      </div>
      <div class="calendar">
        <table>
          <thead>
            <tr style="height: 18px">
              <th>
                <p class="week">W</p>
                <div class="br-line"></div>
              </th>
              <th *ngFor="let day of weekdays">
                <p class="week">{{ day }}</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let week of weeks; let i = index">
              <td class="not-in-month" [class.no-hover]="!isDateRange" [class.in-month]="inWeek(week)" (click)="toggleWeekSelection(week[0], week[6])">
                {{ calculateWeekNumber(week[1]) }}
              </td>
              <td *ngFor="let day of week" [class.selected]="isDateSelected(day) && isInSpecificMonth(day)" (click)="toggleDateSelection(day)">
                <div
                  *ngIf="isInSpecificMonth(day)"
                  class="not-in-month"
                  [class.in-month]="(isBeforeCurrentDate(day) || isAfterCurrentDate(day)) && (isInSpecificMonth(day) || isDateSelected(day))"
                  [class.first-date]="isFirstDate(day) && isInSpecificMonth(day) && isDateRange"
                  [class.last-date]="isLastDate(day) && isInSpecificMonth(day) && isDateRange"
                  [class.first-last-date]="(isFirstDate(day) || isLastDate(day)) && isInSpecificMonth(day) && !isDateRange">
                  {{ day | date : "d" }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="custom-datepicker">
      <div class="header-reverse">
        <span class="label">{{ secondLabel }}</span>
        <img class="image" [src]="iconService.Icon.right_1_5" (click)="nextMonth()" />
      </div>
      <div class="calendar">
        <table>
          <thead>
            <tr style="height: 18px">
              <th>
                <p class="week">W</p>
                <div class="br-line"></div>
              </th>
              <th *ngFor="let day of weekdays">
                <p class="week">{{ day }}</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let week of nextWeeks; let i = index">
              <td class="not-in-month" [class.no-hover]="!isDateRange" [class.in-month]="inWeek(week)" (click)="toggleWeekSelection(week[0], week[6])">
                {{ calculateWeekNumber(week[1]) }}
              </td>
              <td *ngFor="let day of week" [class.selected]="isDateSelected(day) && isInNextMonth(day)" (click)="toggleNextDateSelection(day)">
                <div
                  *ngIf="isInNextMonth(day)"
                  class="not-in-month"
                  [class.in-month]="(isBeforeCurrentDate(day) || isAfterCurrentDate(day)) && (isInNextMonth(day) || isDateSelected(day))"
                  [class.first-date]="isFirstDate(day) && isInNextMonth(day) && isDateRange"
                  [class.last-date]="isLastDate(day) && isInNextMonth(day) && isDateRange"
                  [class.first-last-date]="(isFirstDate(day) || isLastDate(day)) && isInNextMonth(day) && !isDateRange">
                  {{ day | date : "d" }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="divider"></div>
  <div class="button-area" *ngIf="applyButton">
    <ag-button-v2 specificWidth="140px" size="small" label="Cancel" type="secondary" specificWidth="105px" (click)="return()"></ag-button-v2>
    <ag-button-v2 specificWidth="140px" size="small" label="Apply" type="primary" specificWidth="105px" (click)="submit()" [disabled]="newDates()"></ag-button-v2>
  </div>
</div>
