<div class="flex-row ag-tree-leaf" style="display: flex; gap: 50px" [ngStyle]="{ 'justify-content': spaceBetweenContent ? 'space-between' : '' }">
  <div class="flex-column flex-space-evenly flex-align-start">
    <div class="flex-column flex-space-evenly flex-align-start" [ngStyle]="{ 'margin-left': insideNode ? '' : '30px' }">
      <span class="agTitle">
        <b>{{ agTitle }}</b>
      </span>
      <span *ngIf="agDescription" class="agDescription">{{ agDescription }}</span>
    </div>
  </div>
  <div class="flex-row flex-end flex-align-center" style="display: flex; gap: 24px">
    <ng-content></ng-content>
  </div>
</div>
