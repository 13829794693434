import { Component, Inject, OnInit } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"
import { JourneyDetailService } from "app/bookings/journey-detail/journey-detail.service"
import { AuthenticationService } from "app/shared/services/authentication.service"
import { StateService } from "app/shared/services/state.service"
import { ToastService } from "app/toast/toast.service"

@Component({
  selector: "app-charge-pre-auth-dialog",
  templateUrl: "./charge-pre-auth-dialog.component.html",
  styleUrls: ["./charge-pre-auth-dialog.component.scss"],
})
export class ChargePreAuthDialogComponent implements OnInit {
  dialogTitle: string
  dialogInfo: string
  dialogOKButtonName: string
  dialogReason: string
  isLoading: boolean
  currency: string
  amount: string
  reason: string
  paymentId: string
  recipient: string
  success: boolean = false

  constructor(
    public toastService: ToastService,
    public journeyDetailService: JourneyDetailService,
    public dialogRef: MatDialogRef<ChargePreAuthDialogComponent>,
    public authService: AuthenticationService,
    private stateService: StateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.dialogInfo = this.data.info
    this.dialogTitle = this.data.title
    this.dialogOKButtonName = this.data.okButtonName
    this.currency = this.data.currency
    this.amount = this.data.amount
    this.paymentId = this.data.paymentId
    this.recipient = this.data.recipient
  }

  async onSubmit() {
    if (!this.reason) {
      return
    }
    this.isLoading = true

    try {
      await this.journeyDetailService.capture(this.paymentId, this.reason, this.stateService.selectedHotel.Id).toPromise()
      this.toastService.success({ message: `Charged ${this.recipient} ${this.amount} ${this.currency}.` })
      this.success = true
    } catch {
      this.toastService.error({ message: `Failed to charge ${this.recipient} ${this.amount} ${this.currency}.` })
      this.isLoading = false
    } finally {
      this.closeDialog()
    }
  }

  closeDialog() {
    this.dialogRef.close({
      currency: this.currency,
      amount: this.amount,
      reason: this.reason,
      success: this.success,
    })
  }

  onReasonChanged(val: string) {
    this.reason = val
  }
}
