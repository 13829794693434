<div class="flex-column" [ngStyle]="{ 'min-width': maxWidth + 'px' }" (click)="labelClick()">
  <div class="flex-row">
    <div class="relative">
      <input
        [ngStyle]="{ 'background-color': blackVersion && checked === 'intdeterminate' ? '#222' : '' }"
        [ngClass]="blackVersion ? 'black-input-filter' : ''"
        [formControl]="control"
        type="checkbox"
        class="ag-size-{{ size }}"
        [class.ag-checkbox]="type === 'checkbox'"
        [class.radio-button]="type === 'radio-button' || type === 'round-checkbox'"
        [class.has-errors]="control?.invalid && control?.dirty"
        [class.disabled-checkbox]="control?.disabled"
        [ngStyle]="{ border: focus ? '1px solid #6E89DA' : '' }" />
      <img [ngClass]="blackVersion ? 'white-img-filter' : ''" [class.disabled]="control?.disabled" *ngIf="control?.value && checked !== 'intdeterminate' && (type === 'checkbox' || type === 'round-checkbox')" class="ag-checkbox-checked-icon-{{ size }}" [src]="iconService.Icon.check_rounded" />
      <img [ngClass]="blackVersion ? 'white-img-filter' : ''" [class.disabled]="control?.disabled" *ngIf="control?.value && checked !== 'intdeterminate' && type === 'radio-button'" class="radio-button-checked-icon-{{ size }}" [src]="iconService.Icon.radio_check_mark" />
      <img [ngClass]="blackVersion ? 'white-img-filter' : ''" [class.disabled]="control?.disabled" *ngIf="checked === 'intdeterminate' && type === 'checkbox'" class="ag-checkbox-checked-icon-{{ size }}" [src]="iconService.Icon.minus" />
    </div>
    <div *ngIf="label" [class.disabled]="control?.disabled" class="checkbox-label">{{ label }}</div>
  </div>
  <div class="checkbox-subtitle" class="ag-size-{{ size }}" *ngIf="subtitle">{{ subtitle }}</div>
</div>
