import { Injectable } from "@angular/core"
import { ReplaySubject } from "rxjs"

@Injectable()
export class QuickActionService {
  components: any[] = []

  action: ReplaySubject<any> = new ReplaySubject<any>()
  next(action: any): void {
    this.action.next(action)
  }
  resetParams() {
    this.action = new ReplaySubject<any>()
  }
}
