import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from "@angular/core"
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms"

@Component({
  selector: "app-search-box",
  template: `
    <div style="height: fit-content; max-height: 100%; width: 100%; position: relative;" [hidden]="!showBox ? showBoxClicked() : false">
      <div [ngClass]="!useFixedPosition ? 'container-standard' : 'container-fixed'">
        <div class="form-div" *ngIf="showSearchField">
          <input class="form-text" #inputField placeholder="Search" (input)="onInputChange(inputField.value)" />
          <img src="assets/shared/looking-glass.svg" class="form-img" />
          <div class="line"></div>
        </div>
        <div class="top-bottom-padding" [ngStyle]="{ 'max-height': maxHeight - 43 + 'px' }">
          <div [hidden]="frequentList.length < 1 || isTyping">
            <label class="header" *ngIf="showHeaders">FREQUENT</label>
            <div class="spacer" *ngIf="showHeaders"></div>
            <div class="items-container">
              <div *ngFor="let item of frequentList">
                <div class="item" (click)="onItemClicked(item)">
                  <label [ngStyle]="{ left: centerItem ? '50px' : '8px' }" [ngClass]="item === currentValue ? 'item-text-bold' : 'item-text'" class="disable-selection">
                    {{ item }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div [hidden]="frequentList.length < 1 || isTyping" class="spacer"></div>
          <div [hidden]="alphabeticalList.length < 1">
            <label class="header" [hidden]="isTyping" *ngIf="showHeaders">ALPHABETICAL</label>
            <div class="spacer" *ngIf="showHeaders"></div>
            <div class="items-container">
              <div *ngFor="let item of displayedAlphabeticalList">
                <div class="item" (click)="onItemClicked(item)">
                  <label [ngStyle]="{ left: centerItem ? '50px' : '8px' }" [ngClass]="item === currentValue ? 'item-text-bold' : 'item-text'" class="disable-selection">
                    {{ item }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
                       .disable-selection {
                         -webkit-touch-callout: none;
                         -webkit-user-select: none;
                         -khtml-user-select: none;
                         -moz-user-select: none;
                         -ms-user-select: none;
                         user-select: none;
                       }
                       .container-standard {
                         padding-left: 0px;
                         padding-right: 8px;
                         position: inherit;
                         z-index: 1;
                         max-height: 100%;
                         height: fit-content;
                         width: 100%;
                         border-radius: 8px;
                         box-shadow: 0 3px 8px 0 rgba(140, 140, 140, 0.5);
                         border: solid 1px #c8c7cc;
                         background-color: #ffffff;
                       }
                       .container-fixed {
                         padding-left: 0px;
                         padding-right: 8px;
                         position: fixed;
                         z-index: 1010;
                         max-height: 100%;
                         height: fit-content;
                         border-radius: 8px;
                         box-shadow: 0 3px 8px 0 rgba(140, 140, 140, 0.5);
                         border: solid 1px #c8c7cc;
                         background-color: #ffffff;
                       }
                       .header {
                         padding-left: 16px;
                         font-family: Lato;
                         font-size: 14px;
                         font-weight: bold;
                         font-stretch: normal;
                         font-style: normal;
                         line-height: normal;
                         letter-spacing: normal;
                         color: #8f8e95;
                       }
                       .items-container {
                         padding-left: 8px;
                         padding-right: 8px;
                       }
                       .item {
                         width: 100%;
                         height: 33px;
                         border-radius: 20px;
                         background-color: #ffffff;
                         cursor: pointer;
                         position: relative;
                       }
                 
                       .item-text {
                         position: absolute;
                         left: 8px;
                         top: 8px;
                         vertical-align: sub;
                         font-family: "Poppins-Regular";
                         font-size: 13px;
                         font-weight: normal;
                         font-stretch: normal;
                         font-style: normal;
                         line-height: normal;
                         letter-spacing: normal;
                         color: #333333;
                         overflow: hidden;
                         text-overflow: ellipsis;
                         white-space: nowrap;
                         max-width: calc(100% - 12px);
                         cursor: pointer;
                       }
                       .item-text-bold {
                         position: absolute;
                         left: 8px;
                         top: 8px;
                         vertical-align: sub;
                         font-family: "Poppins-Regular";
                         font-size: 13px;
                         font-weight: bold;
                         font-stretch: normal;
                         font-style: normal;
                         line-height: normal;
                         letter-spacing: normal;
                         color: #333333;
                         overflow: hidden;
                         text-overflow: ellipsis;
                         white-space: nowrap;
                         max-width: calc(100% - 12px);
                         cursor: pointer;
                       }
                       .spacer {
                         padding-bottom: 8px;
                       }
                       .top-bottom-padding {
                         padding-top: 16px;
                         padding-bottom: 16px;
                         height: fit-content;
                         display: block;
                         overflow-y: auto;
                         overflow-x: hidden;
                       }
                       .form-div {
                         position: relavtive;
                         padding-left: 16px;
                         padding-right: 8px;
                       }
                       .form-img {
                         position: absolute;
                         left: 16px;
                         top: 12px;
                       }
                       .form-text {
                         border: 0px;
                         width: 100%;
                         height: 38px;
                         font-family: Lato;
                         font-size: 14px;
                         font-weight: normal;
                         font-stretch: normal;
                         font-style: normal;
                         line-height: normal;
                         letter-spacing: normal;
                         padding-inline-start: 24px;
                       }
                       input:focus {
                         outline: none;
                       }
                       .line {
                         width: 100%;
                         height: 1px;
                         background-color: #c8c7cc;
                       }
                       ::-webkit-scrollbar {
                         width: 5px;
                       }
                       ::-webkit-scrollbar-thumb {
                         background: #d8d8d8;
                         border-radius: 3px;
                         padding: 16px 13px;
                       }
                     `,
  ],
})
export class SearchBoxComponent implements OnInit {
  @Input() frequentList: string[] = []
  @Input() alphabeticalList: string[] = []
  @Input() showBox = false
  @Input() showSearchField = true
  @Input() useFixedPosition = false
  @Input() showHeaders: boolean = false
  @Input() maxHeight: number = 1000
  @Input() currentValue: string = ""
  @Input() centerItem: boolean = false

  @Output() output = new EventEmitter<string>()

  @ViewChild("inputField", { static: false }) inputField: ElementRef

  public displayedAlphabeticalList: string[] = []
  public searchForm: UntypedFormGroup
  public isTyping = false

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.searchForm = this.fb.group({
      searchInput: [""],
    })

    this.displayedAlphabeticalList = this.alphabeticalList
  }

  onInputChange(value: string) {
    if (value === "") {
      this.isTyping = false
      this.displayedAlphabeticalList = this.alphabeticalList
    } else {
      this.isTyping = true
      this.displayedAlphabeticalList = this.alphabeticalList.filter((x) => x.toLowerCase().includes(value.toLowerCase()))
    }
  }

  showBoxClicked() {
    if (!this.isTyping || (this.inputField && this.inputField.nativeElement && this.inputField.nativeElement.value === "")) {
      this.displayedAlphabeticalList = this.alphabeticalList
    }
    return true
  }

  onItemClicked(value: string) {
    this.currentValue = value
    this.output.emit(value)
    this.showBox = false
  }
}
