import { Pipe, PipeTransform } from "@angular/core"

@Pipe({ name: "concatenateStringArray" })
export class ConcatenateStringArrayPipe implements PipeTransform {
  transform(strings: string[]): string {
    if (strings == undefined) return undefined

    return this.getFormattedStringArray(strings)
  }

  getFormattedStringArray(array: any): string {
    let formattedString = ""
    const size = array.length
    array.forEach((item, index) => {
      formattedString += item
      if (index < size - 1) {
        formattedString += "\n"
      }
    })

    return formattedString
  }
}
