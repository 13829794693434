import { Component, Input } from "@angular/core"
import { trigger, state, style, transition, animate } from "@angular/animations"

@Component({
  selector: "ag-circle-loader",
  template: `
    <div *ngIf="isLoading" class="circle" [ngStyle]="{ width: width ? width : '88px', height: height ? height : '88px', borderWidth: thickness ? thickness : '6px' }"></div>
  `,
  styles: [
    `
      .circle {
        border-style: solid;
        border-color: transparent;
        border-top: solid #dfdfdf;
        border-right: solid #dfdfdf;
        border-bottom: solid #dfdfdf;
        border-radius: 50%;
        animation: spin 2s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `,
  ],
  animations: [
    // trigger("dropDown", [state("true", style({ transform: "translateY(0px)", "z-index": 0, opacity: 0 })), state("false", style({ transform: "translateY(40px)", "z-index": 1000, opacity: 1 })), transition("true=>false", animate("250ms ease-out"))]),
  ],
})
export class CircleLoaderComponent {
  @Input() isLoading: boolean = false
  @Input() width: string = "88px"
  @Input() height: string = "88px"
  @Input() thickness: string = "6px"
  constructor() {}
}
