<div class="graph-component-wrapper">
  <div class="main-graph-wrapper">
    <apx-chart
      [hidden]="!isReady"
      id="{{ 'chartMain' + randomNumber }}"
      [series]="optionsMain.series"
      [chart]="optionsMain.chart"
      [dataLabels]="optionsMain.dataLabels"
      [plotOptions]="optionsMain.plotOptions"
      [yaxis]="optionsMain.yaxis"
      [legend]="optionsMain.legend"
      [fill]="optionsMain.fill"
      [stroke]="optionsMain.stroke"
      [tooltip]="optionsMain.tooltip"
      [xaxis]="optionsMain.xaxis"
      [grid]="optionsMain.grid"
      [states]="optionsMain.states"
      [colors]="optionsMain.colors"
      [markers]="optionsMain.markers"></apx-chart>
  </div>
  <div class="navigator-graph-wrapper">
    <apx-chart
      [hidden]="!isReady"
      id="{{ 'chartNavigator' + randomNumber }}"
      [series]="optionsNavigator.series"
      [chart]="optionsNavigator.chart"
      [dataLabels]="optionsNavigator.dataLabels"
      [plotOptions]="optionsNavigator.plotOptions"
      [yaxis]="optionsNavigator.yaxis"
      [legend]="optionsNavigator.legend"
      [fill]="optionsNavigator.fill"
      [stroke]="optionsNavigator.stroke"
      [tooltip]="optionsNavigator.tooltip"
      [xaxis]="optionsNavigator.xaxis"
      [grid]="optionsNavigator.grid"
      [states]="optionsNavigator.states"
      [colors]="optionsNavigator.colors"
      [markers]="optionsNavigator.markers"></apx-chart>
  </div>
  <div class="slider-wrapper">
    <div>
      <div class="slider-background">
        <div class="monthly-bg"></div>
        <div class="daily-bg"></div>
        <div class="hourly-bg"></div>
      </div>
      <ngx-slider [(value)]="sliderValue" [options]="sliderOptions" (valueChange)="sliderOnValueChange()"></ngx-slider>
    </div>
    <div class="slider-labels">
      <div>Monthly</div>
      <div>Daily</div>
      <div>Hourly</div>
    </div>
  </div>
</div>
