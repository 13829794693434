<div #viewControl style="display: flex; flex-wrap: wrap" class="flex-start flex-align-center flex-center" style="display: flex; flex-wrap: wrap; gap: 8px" [ngStyle]="{ width: specificWidth || 'fit-content' }">
  <div *ngFor="let option of options; let i = index">
    <input type="radio" [id]="optionValue && optionText ? option[optionValue] : option" [value]="optionValue && optionText ? option[optionValue] : option" [name]="formControlName" [attr.disabled]="option['Disabled'] ? true : null" [formControl]="control" />
    <label [ngClass]="{ 'ag-radio-group-option-small': isSmall, 'ag-radio-group-option-large': isLarge }" [for]="optionValue && optionText ? option[optionValue] : option" [class.disabled]="option['Disabled']" class="flex-column flex-center flex-align-center" style="display: flex; gap: 2px">
      <img *ngIf="optionValue && optionIcon" [src]="option[optionIcon]" />
      <span>
        {{ optionValue && optionText ? option[optionText] : option }}
      </span>
      <span class="subtext" *ngIf="optionValue && optionSubText">
        {{ option[optionSubText] }}
      </span>
    </label>
  </div>
</div>
