import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core"
import { UserResult } from "@api/communication/models/inbox"
import { IconImageService } from "@shared/services/icon-image.service"

@Component({
  selector: "ag-tags-input",
  templateUrl: "./ag-tags-input.component.html",
  styleUrls: ["./ag-tags-input.component.scss"],
})
export class AgTagsInputComponent implements OnInit {
  @ViewChild("input") input: ElementRef

  public inputValue: string = ""
  public isInputFocused: boolean = false
  public isInputHovered: boolean = false

  @Input() inputBackgroundColor: string = ""
  @Input() tagsBackgroundColor: string = ""
  @Input() placeholder: string = "Add tag"
  @Input() initValue: string[] = []
  @Input() tagsList: string[] = []
  @Input() userList: UserResult[] = []
  @Input() disable: boolean = false
  @Input() userTags: boolean = false
  @Input() noSpace: boolean = false
  @Input() tagType: TagType = "text"
  @Input() nonShownTags: number
  @Input() errorMessage: string

  //CSS
  @Input() width: string = ""
  @Output() tagsOutput = new EventEmitter()

  constructor(public iconService: IconImageService) {}

  ngOnInit(): void {
    if (this.initValue?.length > 0) {
      this.tagsList = [...this.initValue]
    }
    if (this.userList && this.nonShownTags) {
      this.userList.splice(this.userList.length - this.nonShownTags, this.nonShownTags)
    }
  }

  public removeTag(tag: string) {
    if (tag && this.tagType === "email") {
      if (!tag.includes("@") || !tag.includes(".")) {
        this.errorMessage = ""
      }
    }
    const index = this.tagsList.indexOf(tag)
    this.tagsList.splice(index, 1)
    this.input.nativeElement.focus()
    this.tagsOutput.emit(this.tagsList)
  }

  public removeUser(user: UserResult) {
    const index = this.userList.indexOf(user)
    this.userList.splice(index, 1)
    this.input.nativeElement.focus()
  }

  public addTag() {
    const tagInput = this.inputValue.trim()
    if (!this.userTags && (this.tagType === "text" || this.tagType === "email")) {
      if (tagInput) {
        if (this.tagsList.find((x) => x === tagInput)) {
        } else {
          this.tagsList.push(tagInput)
        }
      }
      this.inputValue = ""
      this.input.nativeElement.focus()
      this.tagsOutput.emit(this.tagsList)
    }
  }

  getInitials(userName: string): string {
    var initials = userName.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "")
    return initials
  }

  onEnter() {
    this.isInputHovered = true
  }

  onLeave() {
    this.isInputHovered = false
  }

  onFocus() {
    this.isInputFocused = true
  }

  onBlur() {
    this.isInputFocused = false
  }

  isEmailInvalid(tag: string): boolean {
    if (tag && this.tagType === "email") {
      if (!tag.includes("@") || !tag.includes(".")) {
        this.errorMessage = "Incorrect format in email address."
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
}

export type TagType = "text" | "permanent" | "email"
