import { Component, OnInit, Input, HostListener, ElementRef, Output, EventEmitter, ViewChild } from "@angular/core"
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms"
import { CurrencyOutput } from "../models/currency-output"
@Component({
  selector: "app-original-currency-field",
  template: `
    <div [ngClass]="!codeChangeable ? 'additional-charges-div' : 'additional-charges-div-2'">
      <form [formGroup]="currencyForm">
        <input
          class="form-control"
          [ngStyle]="showInvalid && { 'border-color': '#d7263c' }"
          [ngClass]="!codeChangeable ? 'additional-charges-form' : 'additional-charges-form-2'"
          placeholder="0"
          type="number"
          name="currencyInput"
          id="currencyInput"
          tabindex="1"
          formControlName="currencyInput"
          (ngModelChange)="onChangedCurrency(currencyInput.value)"
          #currencyInput
          [attr.disabled]="disable ? '' : null" />
      </form>
      <div [ngClass]="!disable ? 'code-container' : ''">
        <img [ngClass]="!codeChangeable ? 'additional-charges-img' : 'additional-charges-img-2'" src="assets/shared/vertical-line.svg" />
        <label spellcheck="false" #threeDSecureCode class="disable-selection" [ngClass]="!disable ? 'additional-charges-currency-label' : 'additional-charges-currency-label-2'">{{ initialCode }}</label>
      </div>
      <div class="spacer"></div>
    </div>
  `,

  // (click)="onCurrencyCodeClicked()"

  // <img [hidden]="!codeChangeable" src="/assets/shared/expand-small.svg" class="code-changeable-img" #arrowImage>

  // <div class="search-box-wrapper">
  //     <app-search-box [maxHeight]="259" [currentValue]="(currencyForm.get('currencyInput')?.value !== 0) ?
  // currencyForm.get('currencyInput') : initialCurrency" [showBox]="!hideSearchBox" showHeaders="true" [alphabeticalList]="supportedCurrencies"
  // [frequentList]="frequentCurrencies" [useFixedPosition]="useFixedPosition" (output)="onCodeClicked($event)"></app-search-box>
  // </div>
  styles: [
    `
                       /* Chrome, Safari, Edge, Opera */
                       input::-webkit-outer-spin-button,
                       input::-webkit-inner-spin-button {
                         -webkit-appearance: none;
                         margin: 0;
                       }
                       /* Firefox */
                       input[type="number"] {
                         -moz-appearance: textfield;
                       }
                       .disable-selection {
                         -webkit-touch-callout: none;
                         -webkit-user-select: none;
                         -khtml-user-select: none;
                         -moz-user-select: none;
                         -ms-user-select: none;
                         user-select: none;
                         text-transform: uppercase;
                       }
                       .search-box-wrapper {
                         position: absolute;
                         max-height: 259px;
                         height: fit-content;
                         width: 257px;
                       }
                       .additional-charges-form {
                         width: 140px;
                         height: 36px;
                         border-radius: 8px;
                         padding-inline-start: 65px;
                       }
                       .additional-charges-form-2 {
                         width: 180px;
                         height: 36px;
                         border-radius: 8px;
                         padding-inline-start: 60px;
                       }
                       .additional-charges-div {
                         position: relative;
                         width: 140px;
                       }
                       .additional-charges-div-2 {
                         position: relative;
                         width: 180px;
                       }
                       .additional-charges-img {
                         position: absolute;
                         left: 52px;
                         top: 8px;
                       }
                       .additional-charges-img-2 {
                         position: absolute;
                         left: 48px;
                         top: 8px;
                       }
                       .additional-charges-currency-label {
                         position: absolute;
                         left: 0px;
                         top: 0px;
                         width: 52px;
                         white-space: nowrap;
                         overflow: hidden;
                         padding-top: 9px;
                         padding-bottom: 9px;
                         padding-right: 12px;
                         padding-left: 12px;
                         font-family: "Lato-Regular";
                         font-size: 13px;
                         font-weight: lighter;
                         cursor: pointer;
                         outline: none;
                       }
                       .additional-charges-currency-label-2 {
                         position: absolute;
                         left: 0px;
                         top: 0px;
                         width: 52px;
                         white-space: nowrap;
                         overflow: hidden;
                         padding-top: 9px;
                         padding-bottom: 9px;
                         padding-right: 12px;
                         padding-left: 12px;
                         font-family: "Lato-Regular";
                         font-size: 13px;
                         font-weight: lighter;
                         outline: none;
                       }
                       .code-container {
                         cursor: pointer;
                         text-transform: uppercase;
                       }
                       .code-changeable-img {
                         position: absolute;
                         left: 48px;
                         top: 12px;
                       }
                       .spacer {
                         padding-bottom: 4px;
                       }
                     `,
  ],
})
export class OriginalCurrencyFieldComponent implements OnInit {
  @Input() codeChangeable = false
  @Input() currencyChangeable = false
  @Input() disable = false
  @Input() initialCode = "EEE"
  @Input() useFixedPosition = false

  // Use overwriteCurrency if initialCurrency does not work
  @Input() overwriteCurrency: any

  // @Input() initialCurrency: any;

  @Input()
  set initialCurrency(value: number) {
    if (value) {
      if (this.currencyForm) {
        this.currencyForm.get("currencyInput").setValue(value)
      }
    }
  }

  @Input() showInvalid = false

  @Output() output = new EventEmitter<CurrencyOutput>()
  @Output() changedCurrency = new EventEmitter<number>()
  @Output() changedCode = new EventEmitter<string>()

  @ViewChild("arrowImage") arrowImage

  public currentCurrency = 0

  public currencyForm: UntypedFormGroup
  public hideSearchBox = true

  public frequentCurrencies = ["USD - United States dollar", "EUR - Euro", "GBP - British pound", "JPY - Japanese yen", "SEK - Swedish krona"]
  public supportedCurrencies = [
    "AED - United Arab Emirates dirham",
    "AFN - Afghan afghani",
    "ALL - Albanian lek",
    "AMD - Armenian dram",
    "ANG - Netherlands Antillean guilder",
    "AOA - Angolan kwanza",
    "ARS - Argentine peso",
    "AUD - Australian dollar",
    "AWG - Aruban florin",
    "AZN - Azerbaijani manat",
    "BAM - Bosnia and Herzegovina convertible mark",
    "BBD - Barbadian dollar",
    "BDT - Bangladeshi taka",
    "BGN - Bulgarian lev",
    "BHD - Bahraini dinar",
    "BIF - Burundian franc",
    "BMD - Bermudian dollar",
    "BND - Brunei dollar",
    "BOB - Bolivian boliviano",
    "BRL - Brazilian real",
    "BSD - Bahamian dollar",
    "BTN - Bhutanese ngultrum",
    "BWP - Botswana pula",
    "BYN - New Belarusian ruble",
    "BYR - Old Belarusian ruble",
    "BZD - Belize dollar",
    "CAD - Canadian dollar",
    "CDF - Congolese franc",
    "CHF - Swiss franc",
    "CKD - Cook Islands dollar",
    "CLP - Chilean peso",
    "CNY - Chinese yuan",
    "COP - Colombian peso",
    "CRC - Costa Rican colón",
    "CUC - Cuban convertible peso",
    "CUP - Cuban peso",
    "CVE - Cape Verdean escudo",
    "CZK - Czech koruna",
    "DJF - Djiboutian franc",
    "DKK - Danish krone",
    "DOP - Dominican peso",
    "DZD - Algerian dinar",
    "EGP - Egyptian pound",
    "ERN - Eritrean nakfa",
    "ETB - Ethiopian birr",
    "EUR - Euro",
    "FJD - Fijian dollar",
    "FKP - Falkland Islands pound",
    "FOK - Faroese króna",
    "GBP - British pound",
    "GEL - Georgian Lari",
    "GHS - Ghanaian cedi",
    "GIP - Gibraltar pound",
    "GMD - Gambian dalasi",
    "GNF - Guinean franc",
    "GTQ - Guatemalan quetzal",
    "GYD - Guyanese dollar",
    "HKD - Hong Kong dollar",
    "HNL - Honduran lempira",
    "HRK - Croatian kuna",
    "HTG - Haitian gourde",
    "HUF - Hungarian forint",
    "IDR - Indonesian rupiah",
    "ILS - Israeli new shekel",
    "IMP - Manx pound",
    "INR - Indian rupee",
    "IQD - Iraqi dinar",
    "IRR - Iranian rial",
    "ISK - Icelandic króna",
    "JEP - Jersey pound",
    "JMD - Jamaican dollar",
    "JOD - Jordanian dinar",
    "JPY - Japanese yen",
    "KES - Kenyan shilling",
    "KGS - Kyrgyzstani som",
    "KHR - Cambodian riel",
    "KMF - Comorian franc",
    "KPW - North Korean won",
    "KRW - South Korean won",
    "KWD - Kuwaiti dinar",
    "KYD - Cayman Islands dollar",
    "KZT - Kazakhstani tenge",
    "LAK - Lao kip",
    "LBP - Lebanese pound",
    "LKR - Sri Lankan rupee",
    "LRD - Liberian dollar",
    "LSL - Lesotho loti",
    "LYD - Libyan dinar",
    "MAD - Moroccan dirham",
    "MDL - Moldovan leu",
    "MGA - Malagasy ariary",
    "MKD - Macedonian denar",
    "MMK - Burmese kyat",
    "MNT - Mongolian tögrög",
    "MOP - Macanese pataca",
    "MRO - Mauritanian ouguiya",
    "MUR - Mauritian rupee",
    "MVR - Maldivian rufiyaa",
    "MWK - Malawian kwacha",
    "MXN - Mexican peso",
    "MYR - Malaysian ringgit",
    "MZN - Mozambican metical",
    "NAD - Namibian dollar",
    "NGN - Nigerian naira",
    "NIO - Nicaraguan córdoba",
    "NOK - Norwegian krone",
    "NPR - Nepalese rupee",
    "NZD - New Zealand dollar",
    "OMR - Omani rial",
    "PAB - Panamanian balboa",
    "PEN - Peruvian sol",
    "PGK - Papua New Guinean kina",
    "PHP - Philippine peso",
    "PKR - Pakistani rupee",
    "PLN - Polish złoty",
    "PYG - Paraguayan guaraní",
    "QAR - Qatari riyal",
    "RON - Romanian leu",
    "RSD - Serbian dinar",
    "RUB - Russian ruble",
    "RWF - Rwandan franc",
    "SAR - Saudi riyal",
    "SBD - Solomon Islands dollar",
    "SCR - Seychellois rupee",
    "SDG - Sudanese pound",
    "SEK - Swedish krona",
    "SGD - Singapore dollar",
    "SHP - Saint Helena pound",
    "SLL - Sierra Leonean leone",
    "SOS - Somali shilling",
    "SRD - Surinamese dollar",
    "SSP - South Sudanese pound",
    "STD - São Tomé and Príncipe dobra",
    "SYP - Syrian pound",
    "SZL - Swazi lilangeni",
    "THB - Thai baht",
    "TJS - Tajikistani somoni",
    "TMT - Turkmenistan manat",
    "TND - Tunisian dinar",
    "TOP - Tongan paʻanga",
    "TRY - Turkish lira",
    "TTD - Trinidad and Tobago dollar",
    "TVD - Tuvaluan dollar",
    "TWD - New Taiwan dollar",
    "TZS - Tanzanian shilling",
    "UAH - Ukrainian hryvnia",
    "UGX - Ugandan shilling",
    "USD - United States dollar",
    "UYU - Uruguayan peso",
    "UZS - Uzbekistani so'm",
    "VEF - Venezuelan bolívar",
    "VND - Vietnamese đồng",
    "VUV - Vanuatu vatu",
    "WST - Samoan tālā",
    "XAF - Central African CFA franc",
    "XCD - East Caribbean dollar",
    "XOF - West African CFA franc",
    "XPF - CFP franc",
    "YER - Yemeni rial",
    "ZAR - South African rand",
    "ZMW - Zambian kwacha",
  ]

  @HostListener("document:click", ["$event"])
  clickedOutside(event) {
    if (!this.eRef.nativeElement.contains(event.target) && !this.hideSearchBox) {
      this.hideSearchBox = true
      this.arrowImage.nativeElement.style.transform = `rotate(${this.hideSearchBox ? 0 : 180}deg)`
    }
  }

  constructor(
    private fb: UntypedFormBuilder,
    private eRef: ElementRef,
  ) {}

  ngOnInit() {
    this.currentCurrency = this.initialCurrency
    this.currencyForm = this.fb.group({
      currencyInput: [this.initialCurrency === 0 ? undefined : this.initialCurrency],
    })

    if (this.overwriteCurrency) {
      this.currentCurrency = this.overwriteCurrency
      this.currencyForm = this.fb.group({
        currencyInput: [this.overwriteCurrency === 0 ? undefined : this.overwriteCurrency],
      })
    }
  }

  onChangedCurrency(value: string) {
    this.currentCurrency = +value
    this.output.emit({ Value: this.currentCurrency, Code: this.initialCode })
    this.changedCurrency.emit(this.currentCurrency)
  }

  onCodeClicked(value: string) {
    this.initialCode = value.substring(0, 3)
    this.output.emit({ Value: this.currentCurrency, Code: this.initialCode })
    this.changedCode.emit(this.initialCode)
    this.hideSearchBox = true
    this.arrowImage.nativeElement.style.transform = `rotate(${this.hideSearchBox ? 0 : 180}deg)`
  }

  onCurrencyCodeClicked() {
    if (this.codeChangeable) {
      if (!this.disable) {
        this.hideSearchBox = !this.hideSearchBox
        this.arrowImage.nativeElement.style.transform = `rotate(${this.hideSearchBox ? 0 : 180}deg)`
      }
    }
  }
}
