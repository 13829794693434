import { Component, Inject, OnInit } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"
import { ReleaseRequest } from "@api/payment/models/V7Payment"
import { PaymentApiService } from "@api/payment/payment-api.service"
import { JourneyDetailService } from "app/bookings/journey-detail/journey-detail.service"
import { AuthenticationService } from "app/shared/services/authentication.service"
import { StateService } from "app/shared/services/state.service"
import { ToastService } from "app/toast/toast.service"

@Component({
  selector: "app-release-dialog",
  templateUrl: "./release-dialog.component.html",
  styleUrls: ["./release-dialog.component.scss"],
})
export class ReleaseDialogComponent implements OnInit {
  dialogTitle: string
  dialogInfo: string
  dialogOKButtonName: string
  dialogReason: string
  isLoading: boolean
  currency: string
  amount: string
  reason: string
  paymentId: string
  recipient: string
  success: boolean = false
  postToPms: boolean = true
  bookingId: string
  billId: string
  constructor(
    public toastService: ToastService,
    public journeyDetailService: JourneyDetailService,
    public paymentApiService: PaymentApiService,
    public dialogRef: MatDialogRef<ReleaseDialogComponent>,
    public authService: AuthenticationService,
    private stateService: StateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.dialogInfo = this.data.info
    this.dialogTitle = this.data.title
    this.dialogOKButtonName = this.data.okButtonName
    this.currency = this.data.currency
    this.amount = this.data.amount
    this.paymentId = this.data.paymentId
    this.recipient = this.data.recipient
    this.billId = this.data.billId
    this.bookingId = this.data.bookingId
  }

  async onSubmit() {
    if (!this.reason) {
      return
    }
    this.isLoading = true
    try {
      var request: ReleaseRequest = {
        BillId: this.billId,
        BookingId: this.bookingId ? this.bookingId : this.billId.replace("@Checkin", "").replace("@Checkout", ""),
        HotelId: this.stateService.selectedHotel.Id,
        PaymentId: this.paymentId,
        PostToPms: this.postToPms,
      }

      await this.paymentApiService.V7Payment.releasePreAuth(request).toPromise()
      this.toastService.success({ message: `Released ${this.amount} ${this.currency} on ${this.recipient} credit card.` })
      this.success = true
    } catch {
      this.toastService.error({ message: `Failed to release ${this.amount} ${this.currency} on ${this.recipient} credit card.` })
      this.isLoading = false
    } finally {
      this.closeDialog()
    }
  }

  closeDialog() {
    this.dialogRef.close({
      currency: this.currency,
      amount: this.amount,
      reason: this.reason,
      success: this.success,
    })
  }

  onReasonChanged(val: string) {
    this.reason = val
  }
}
