import { Component, OnInit, Inject } from "@angular/core"
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog"
import { AuthenticationService } from "app/shared/services/authentication.service"
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms"
import { FormControl } from "@angular/forms"
import { JourneyDetailService } from "app/bookings/journey-detail/journey-detail.service"
import { ToastService } from "app/toast/toast.service"
import { StateService } from "@shared/services/state.service"
import { AgValidators } from "@shared/helpers/ag-validators"
import { AgFormValidation } from "@shared/helpers/ag-form-validation"
import { JourneyGuest } from "@shared/models/guest"

@Component({
  selector: "app-charge-dialog",
  templateUrl: "./charge-dialog.component.html",
  styleUrls: ["./charge-dialog.component.scss"],
})
export class ChargeDialogComponent implements OnInit, AgValidators, AgFormValidation {
  form: UntypedFormGroup
  isLoading: boolean

  /* Parameters */
  currency: string
  cardId: string
  bookingId: string
  primaryGuest: JourneyGuest

  constructor(
    public toastService: ToastService,
    public journeyDetailService: JourneyDetailService,
    public dialogRef: MatDialogRef<ChargeDialogComponent>,
    public authService: AuthenticationService,
    private fb: UntypedFormBuilder,
    private stateService: StateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.currency = this.data.currency?.trim().toUpperCase()

    this.form = this.fb.group({
      reason: ["", [Validators.required, Validators.minLength(1)]],
      amount: this.fb.group({
        unit: [this.currency, [Validators.required, AgValidators.currency]],
        value: [null, [Validators.required, Validators.min(0)]],
      }),
    })
  }

  async submit() {
    if (!AgFormValidation.validate(this.form)) {
      return
    }
    this.isLoading = true
    const amount = this.form.get("amount")?.value
    try {
      const employee = (this.authService.employeeInfo.FirstName || "") + " " + (this.authService.employeeInfo.LastName || "")
      const formattedReasonString = this.form.get("reason")?.value + " -- charged by " + employee
      await this.journeyDetailService.postCharge(this.stateService?.selectedHotel?.Id, this.data.bookingId, amount.value, this.data.cardId, this.data.firstName, this.data.lastName, formattedReasonString).toPromise()
      this.toastService.success({ message: `Charged ${amount.value} ${this.currency}.` })
      this.closeDialog(true)
      this.isLoading = false
    } catch {
      this.toastService.error({ message: `Failed to charge ${amount.value} ${this.currency}.` })
      this.isLoading = false
      this.closeDialog(false)
    }
  }
  closeDialog(success: boolean) {
    const amount = this.form.get("amount")?.value
    const reason: string = this.form.get("reason")?.value
    this.dialogRef.close({
      currency: this.currency,
      amount: amount.value,
      reason: reason,
      success: success,
    })
  }

  getAmountError() {
    const amountField = this.form.get("amount").get("value")
    if (amountField.hasError("required")) {
      return "An amount is required."
    } else if (amountField.hasError("min")) {
      return "The amount has to be positive."
    }
  }
}
