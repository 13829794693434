import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms"

export abstract class AgValidators {
  public static email(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null
      }
      return !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(control.value) ? { emailPattern: true } : null
    }
  }

  public static currency(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null
      }
      return !/\d*.?\d{1,2}$/.test(control.value) ? { currency: true } : null
    }
  }

  public static time(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null
      }
      return !/^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/.test(control.value) ? { time: true } : null
    }
  }

  public static notIn(list: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null
      }
      return list?.includes(control.value) ? { alreadyExists: true } : null
    }
  }
}
