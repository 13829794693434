import { Component, Input } from "@angular/core"
import { AgReportMessage } from "./ag-report-message"

@Component({
  selector: "ag-report-message",
  templateUrl: "./ag-report-message.component.html",
  styleUrls: ["./ag-report-message.component.scss"],
})
export class AgReportMessageComponent {
  @Input()
  message!: AgReportMessage

  constructor() {}
}
