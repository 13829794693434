import { Component, Input } from "@angular/core"

@Component({
  selector: "app-image-with-camera",
  templateUrl: "./image-with-camera.component.html",
  styleUrls: ["./image-with-camera.component.scss"],
})
export class ImageWithCameraComponent {
  _src = ""
  @Input() set src(url: string) {
    if (url === undefined || url.length === 0) return

    this._src = url
    this.isImageBroken = false
  }

  isImageBroken = true

  constructor() {}

  setBrokenImage() {
    this.isImageBroken = true
  }
}
