import { Component, OnInit, Input } from "@angular/core"
import { getBase64 } from "app/shared/utils"
import { Subject } from "rxjs"

@Component({
  selector: "app-image-upload-hover",
  templateUrl: "./image-upload-hover.component.html",
  styleUrls: ["./image-upload-hover.component.scss"],
})
export class ImageUploadHoverComponent implements OnInit {
  @Input() imageUrl: string
  @Input() uploadImageObservable: Subject<any>

  constructor() {}

  ngOnInit() {}

  imageExists(): boolean {
    return this.imageUrl && this.imageUrl !== ""
  }

  onUploadImage(e): void {
    if (e.target.files.length > 0) {
      getBase64(e.target.files[0], (base64) => {
        if (this.uploadImageObservable) {
          this.uploadImageObservable.next(base64)
        } else {
          console.error("File upload observable is not set.")
        }
      })
    }
  }
}
