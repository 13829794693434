import { AfterContentInit, Component, ElementRef, forwardRef, Input, Renderer2, ViewChild } from "@angular/core"
import { AbstractControl, ControlContainer, ControlValueAccessor, UntypedFormControl, FormControlDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from "@angular/forms"
import SignaturePad, { Options } from "signature_pad"

export interface IAgSignaturePadOptions extends Options {
  canvasHeight?: number
  canvasWidth?: number
}

@Component({
  selector: "ag-signature",
  template: `
    <div class="ag-signature-pad" [class.has-errors]="control.invalid && control.dirty" [class.disabled]="control.disabled" [style.height.px]="options.canvasHeight">
      <ag-button color="secondary" size="small" [label]="'Clear'" (click)="clear()" *ngIf="control.value"></ag-button>
      <img src="assets/icons/Signature_Placeholder.svg" [class.hidePlaceholder]="!showPlaceholder" />
      <canvas class="signature-pad-canvas" [options]="options"></canvas>
    </div>
  `,
  styleUrls: ["./ag-signature.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgSignatureComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AgSignatureComponent),
      multi: true,
    },
  ],
})
export class AgSignatureComponent implements ControlValueAccessor, AfterContentInit {
  @Input() public options: IAgSignaturePadOptions = { canvasHeight: 250 } as IAgSignaturePadOptions

  @ViewChild(FormControlDirective, { static: true })
  formControlDirective!: FormControlDirective

  @Input() formControl!: UntypedFormControl
  @Input() formControlName!: string

  @ViewChild("viewControl") viewControl?: ElementRef

  public showPlaceholder: boolean = true

  get control(): UntypedFormControl {
    return this.formControl || <UntypedFormControl>this.controlContainer?.control?.get(this.formControlName || "")
  }

  constructor(
    public elementRef: ElementRef,
    private controlContainer: ControlContainer,
    private renderer: Renderer2,
  ) {}

  writeValue = (value: string): void => this.formControlDirective?.valueAccessor!.writeValue(value)
  registerOnChange = (fn: any): void => this.formControlDirective?.valueAccessor!.registerOnChange(fn)
  registerOnTouched = (fn: any): void => this.formControlDirective?.valueAccessor!.registerOnTouched(fn)

  validate(control: AbstractControl): ValidationErrors | null {
    return this.control.valid ? null : control.errors
  }

  onFocus = () => this.renderer.addClass(this.viewControl?.nativeElement, "focussed")
  onBlur = () => this.renderer.removeClass(this.viewControl?.nativeElement, "focussed")

  public signaturePad: SignaturePad

  private _getCanvas = (): HTMLCanvasElement => this.elementRef.nativeElement.querySelector("canvas")

  ngAfterContentInit(): void {
    this.showPlaceholder = !this.control?.value
    const canvas: HTMLCanvasElement = this._getCanvas()
    canvas.width = this.options.canvasWidth ?? this.elementRef.nativeElement.offsetWidth
    canvas.height = this.options.canvasHeight ?? this.elementRef.nativeElement.offsetHeight
    this.signaturePad = new SignaturePad(canvas, this.options)
    this.signaturePad.addEventListener("beginStroke", (event: CustomEvent) => this.drawBegin(event.detail))
    this.signaturePad.addEventListener("endStroke", (event: CustomEvent) => this.drawComplete(event.detail))
  }

  /**
   * notify subscribers on signature begin
   */
  public drawBegin(event: MouseEvent | Touch): void {
    this.showPlaceholder = false
  }

  /**
   * notify subscribers on signature end
   */
  public drawComplete(event: MouseEvent | Touch): void {
    this.control.setValue(this.signaturePad.toDataURL())
  }

  public clear(): void {
    this.signaturePad.clear()
    this.showPlaceholder = true
    this.control.setValue(null)
  }
}
