<div class="background-container" [ngStyle]="{ 'background-color': backgroundColor }">
  <div style="display: flex; flex-direction: column">
    <div style="display: flex; flex-direction: row">
      <img class="icon-image" src="{{ iconSrc }}" />
      <div *ngIf="percentage" [ngClass]="agTheme" style="position: relative; width: 100px; height: 100px; display: flex; align-items: center; justify-content: center; padding-right: 24px">
        <mat-spinner style="width: 75px; height: 75px; position: absolute; top: 16px; left: 0px" [color]="'accent'" [mode]="'determinate'" diameter="63.65" [value]="'100'"></mat-spinner>
        <mat-spinner style="width: 75px; height: 75px; position: absolute; top: 16px; left: 0px" [color]="'primary'" [mode]="'determinate'" diameter="63.65" [value]="percentage"></mat-spinner>
        <div [ngStyle]="{ color: textColor }" class="percentage-inside-spinner">{{ percentage }}%</div>
      </div>
    </div>
    <h2 [ngStyle]="{ color: textColor }">{{ title }}</h2>
    <div style="display: flex; flex-direction: row">
      <h1 style="margin-bottom: 0" [ngStyle]="{ color: textColor }">{{ amount }}</h1>
    </div>
    <div style="display: flex; flex-direction: row">
      <div style="font-weight: 600" [ngClass]="descriptionTextClass">{{ boldFirstTwoWords !== undefined ? boldFirstTwoWords : "" }}&nbsp;</div>
      <div [ngClass]="descriptionTextClass">
        {{ descriptionText !== undefined ? descriptionText : "" }}
      </div>
    </div>
  </div>
</div>
