<div class="component-container">
  <div class="chart-container">
    <div class="title">
      {{ chartTitle }}
    </div>
    <!-- <button (click)="changeViewToArea()">
          <span>Area</span>
        </button>
        <button (click)="changeViewToLine()">
          <span>Line</span>
        </button> -->
    <div id="cartesian-chart-container">
      <div id="chart" #chart>
        <div class="data-context-box" #dataContext [hidden]="!dataContextData">
          <div class="data-context-date">{{ dataContextDate }}</div>
          <div class="data-context" *ngFor="let dataContext of dataContextData | keyvalue: originalOrder; let i = index">
            <div class="data-context-container">
              <div class="data-context-container" *ngIf="dataContext.key.toString() !== 'Date'">
                <div class="marker" [ngStyle]="{ 'background-color': colors[i] }"></div>
                <div class="name">{{ legendNames[i] }}</div>
                <div class="value">{{ round(dataContext.value) }} {{ tooltipSuffix }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mat-spinner id="spinner" [ngClass]="{ isLoading: !isLoading }" class="purple" [diameter]="32" color="primary"></mat-spinner>
    </div>
    <div id="navigator-chart" #navigatorChart></div>

    <div class="slider-container" [ngClass]="{ disableSlider: disableSlider }">
      <div class="slider-element">
        <ngx-slider class="slider" [ngClass]="{ showToolTip: !showToolTip }" (mousedown)="onSliderClick()" #chartSlider [(value)]="value" [options]="options" (userChange)="onUserChange($event)" (userChangeStart)="onUserChangeStart($event)" (userChangeEnd)="onUserChangeEnd($event)">
          <ng-template #tooltipTemplate let-tooltip="tooltip">
            <div class="h-100" [ngbTooltip]="tooltip"></div>
          </ng-template>
        </ngx-slider>

        <div class="slider-labels">
          <a>
            <button class="periodicity-button" [ngClass]="{ notAllowed: disableSlider }" #monthlyButton (click)="swapPeriodicity(3)" [style.background-color]="monthlySelected == true ? '#61566e' : 'white'" [style.color]="monthlySelected == true ? 'white' : '#333333'">Monthly</button>
          </a>
          <a>
            <button class="periodicity-button" [ngClass]="{ notAllowed: disableSlider }" #dailyButton (click)="swapPeriodicity(2)" [style.background-color]="dailySelected == true ? '#61566e' : 'white'" [style.color]="dailySelected == true ? 'white' : '#333333'">Daily</button>
          </a>
          <a>
            <button class="periodicity-button" [ngClass]="{ notAllowed: disableSlider }" #hourlyButton (click)="swapPeriodicity(1)" [style.background-color]="hourlySelected == true ? '#61566e' : 'white'" [style.color]="hourlySelected == true ? 'white' : '#333333'">Hourly</button>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="legend-container" *ngIf="legendNames != undefined">
    <div class="legend" *ngFor="let legendName of legendNames; let i = index">
      <div class="legend-marker" [ngStyle]="{ 'background-color': colors[i] }"></div>
      <div class="legend-name">{{ legendNames[i] }}</div>
    </div>
  </div>
</div>
