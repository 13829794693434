<div class="dialog-main-container">
  <div class="dialog-information-container">
    <div class="dialog-title">{{ dialogTitle }}</div>
    <div class="dialog-description">{{ dialogDescription }}</div>
  </div>
  <div class="buttons-container">
    <ag-button-v2 label="Cancel" type="secondary" (click)="closeDialog()"></ag-button-v2>
    <ag-button-v2 label="Confirm" type="primary" (click)="submitQuickAction()"></ag-button-v2>
  </div>
</div>
