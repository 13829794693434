import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { LabelValue } from "@shared/components/ag-drop-down/ag-drop-down.component"
import { FormBuilder, FormGroup } from "@angular/forms"
import { UserApiService } from "@api/user/user-api.service"
import { LabelType } from "@angular-slider/ngx-slider"
import { RestCountryCodesDTO } from "@api/user/models/rest-country"

@Component({
  selector: "ag-country-code-box",
  templateUrl: "./ag-country-code-box.component.html",
  styleUrls: ["./ag-country-code-box.component.scss"],
})
export class AgCountryCodeBoxComponent implements OnInit {
  @Input() showBox = false
  @Input() showSearchField = true
  @Input() useFixedPosition = false
  @Input() showHeaders: boolean = false
  @Input() maxHeight: number = 1000
  @Input() currentValue: LabelValue
  @Input() disabledInputs: LabelValue[] = []

  @Output() output = new EventEmitter<LabelValue>()
  @Output() searchBoxFieldClicked = new EventEmitter<boolean>()

  @ViewChild("inputField", { static: false }) inputField: ElementRef
  @ViewChild("arrowImage") arrowImage

  public list: RestCountryCodesDTO[] = []
  public displayedList: RestCountryCodesDTO[] = []
  public searchForm: FormGroup
  public isTyping = false
  public searchText: string

  constructor(private fb: FormBuilder, private userService: UserApiService) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchInput: [""],
    })
    this.userService.V2Lookup.getCountryCodes().subscribe((countries) => {
      this.list = countries
    })
    this.displayedList = this.list
  }

  onInputChange(value: string) {
    if (value === "") {
      this.isTyping = false
      this.displayedList = this.list
    } else {
      this.isTyping = true
      this.displayedList = this.list.filter((x) => x.Name.toLowerCase().includes(value.toLowerCase()))
    }
  }

  showBoxClicked() {
    if (!this.isTyping || (this.inputField && this.inputField.nativeElement && this.inputField.nativeElement.value === "")) {
      this.displayedList = this.list
    }
    return true
  }

  onItemClicked(selected: RestCountryCodesDTO) {
    const itemDisabled = this.disabledInputs.find((di) => di.label === selected.Name)
    if ("+" + selected.CallingCodes[0] !== this.currentValue.label && !itemDisabled) {
      this.currentValue.label = "+" + selected.CallingCodes[0]
      this.currentValue.value = "+" + selected.CallingCodes
      this.output.emit(this.currentValue)
      this.showBox = false
      this.searchText = ""
    }
  }

  onFieldClicked() {
    this.showBox = false
    this.searchBoxFieldClicked.emit(true)
  }

  isSelected(item: LabelValue): boolean {
    return this.currentValue !== null ?? this.currentValue.label === item.label
  }

  checkForDisabled(label: string): boolean {
    const itemDisabled = this.displayedList.some((item) => !this.disabledInputs.some((di) => di.label === item.Name))
    if (itemDisabled) {
      return this.disabledInputs.some((item) => item.label === label)
    }
    return false
  }
}
