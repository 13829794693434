import { Overlay, OverlayPositionBuilder, OverlayRef } from "@angular/cdk/overlay"
import { ComponentPortal } from "@angular/cdk/portal"
import { ComponentRef, Directive, ElementRef, HostListener, Input, OnInit } from "@angular/core"
import { AgTooltipComponent } from "./ag-tooltip.component"

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "[agTooltip]",
})
export class AgTooltipDirective implements OnInit {
  @Input("agTooltip") text = ""
  @Input("agTooltipCondition") condition: boolean = true

  private overlayRef!: OverlayRef

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions([
      {
        originX: "center",
        originY: "top",
        overlayX: "center",
        overlayY: "bottom",
        offsetY: -8,
      },
    ])

    this.overlayRef = this.overlay.create({ positionStrategy })
  }

  @HostListener("mouseover")
  show() {
    if (this.condition) {
      const tooltipRef: ComponentRef<AgTooltipComponent> = this.overlayRef.attach(new ComponentPortal(AgTooltipComponent))
      tooltipRef.instance.text = this.text
    }
  }

  @HostListener("mouseout")
  hide() {
    this.overlayRef.detach()
  }
}
