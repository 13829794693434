import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { sha3_512 } from "js-sha3"
import { Observable, of, ReplaySubject } from "rxjs"
import { first, map, mergeMap, tap } from "rxjs/operators"
import { API_HOTEL_V2, API_POS, API_SECURITY_V2, API_SOCIAL, STORAGE_EMAIL_PINS_obfuscated, STORAGE_PIN_SESSION, STORAGE_TOKEN, STORAGE_USER } from "../../app-settings"
import { ToastService } from "../../toast/toast.service"
import { Hotel } from "../models"
import { Employee } from "../models/employee"
import { NotificationService } from "./notification.service"
import { ApiService } from "./api.service"
import { StateService } from "./state.service"
import { WebSocketService } from "./websocket.service"
import { DatePipe } from "@angular/common"

@Injectable()
export class AuthenticationService {
  private authenticatedEmployee = new ReplaySubject<Employee>(1)

  securityPack
  session

  public temporaryPassword
  public employeeInfo: Employee
  private employeeSubscription: any
  organizationImageStyle = "url(/assets/login/background_hotel_1024.jpg)"

  public isAuthenticated = this.authenticatedEmployee.asObservable().pipe(map((user) => user && user.Id.length > -1))

  public isSuperUser = this.authenticatedEmployee.asObservable().pipe(
    map((user) => user && (user.Type === "Superuser" || user.Type === "SuperUser" || user.Type === "Support")),
    first()
  )

  public isProfileUser = this.authenticatedEmployee.asObservable().pipe(
    map((user) => user && (user.Type === "Profile" || user.Type === "Switch")),
    first()
  )

  public isActiveSession(): boolean {
    const token = localStorage.getItem(STORAGE_TOKEN)
    const session = localStorage.getItem(STORAGE_PIN_SESSION)
    return !!token && !!session
  }

  constructor(private router: Router, private webSocketService: WebSocketService, private apiService: ApiService, private toastService: ToastService, private notificationService: NotificationService, private stateService: StateService, private datepipe: DatePipe) {}

  autoSignIn(): Observable<Employee> {
    // If token detected, attempt to get & store user's info
    if (localStorage.getItem(STORAGE_TOKEN)) {
      if (localStorage.getItem(STORAGE_USER)) {
        const user: Employee = JSON.parse(localStorage.getItem(STORAGE_USER))
        const token = localStorage.getItem(STORAGE_TOKEN).split(",")[0]
        return this.fetchEmployeeLoginInfoById(user.Id, token)
      }
    } else {
      // Remove any potential remnants of previous auth states
      this.logout()
      return of(new Employee())
    }
  }

  changePin(id: string, pin: string) {
    return this.apiService.put(`${API_HOTEL_V2}Employee/${id}/Pin/${pin}`)
  }

  resetPin(employeeId: string, email: string, pin: string) {
    return this.apiService.post(`${API_HOTEL_V2}Employee/${employeeId}/Email/${email}/Pin/${pin}/Reset`)
  }

  requestPinRecovery(employeeId: string, email: string) {
    return this.apiService.post(`${API_HOTEL_V2}Employee/${employeeId}/Email/${email}/Pin/Forgot`)
  }

  cacheLogin(e: Employee = this.employeeInfo, ChangeToken = true, pin: string): Observable<boolean> {
    if (pin) {
      localStorage.setItem(STORAGE_PIN_SESSION, sha3_512(pin))
    } else {
      localStorage.setItem(STORAGE_PIN_SESSION, "")
    }
    if (this.session !== undefined) {
      localStorage.setItem(STORAGE_TOKEN, this.session.Token + "," + this.session.Level)
    } else {
      this.session = {}
      const storageToken = localStorage.getItem(STORAGE_TOKEN)
      if (storageToken !== undefined) {
        this.session.Token = storageToken?.split(",")[0]
        this.session.Level = storageToken?.split(",")[1]
      } else {
        localStorage.removeItem(STORAGE_PIN_SESSION)
        return of(false)
      }
    }

    let email = e.Email
    if (e.Type === "Profile") {
      if (this.securityPack !== undefined) {
        email = this.securityPack.Identifier
        e.EmailEmployee = email
      } else {
        const user = <Employee>JSON.parse(localStorage.getItem(STORAGE_USER))
        if (user === undefined) {
          return of(false)
        }
        email = user.EmailEmployee
      }
    }

    const token = localStorage.getItem(STORAGE_TOKEN).split(",")[0]

    if (ChangeToken) {
      return this.changeEmployeeToken(email, token).pipe(
        tap(
          (data) => {
            this.session = data.Sessions[0]
            localStorage.setItem(STORAGE_TOKEN, this.session.Token + "," + this.session.Level)
            this.securityPack = data
            this.setEmployee(e)
            return true
          },
          () => {
            this.logout()
            return false
          }
        )
      )
    } else {
      localStorage.setItem(STORAGE_TOKEN, this.session.Token + "," + this.session.Level)
      this.setEmployee(e)
      return of(true)
    }
  }

  sortEmployees(employees: Employee[]): Employee[] {
    return employees.sort((lhs, rhs) => (lhs.FirstName + " " + lhs.LastName).localeCompare(rhs.FirstName + " " + lhs.LastName))
  }

  saveLocalEmailPinHashing(employees: Employee[]) {
    if (employees.length === 1) {
      employees[0].PinRequired = false
    }

    const dict = {}
    employees.forEach((e) => {
      if (e.PinRequired) {
        dict[sha3_512(e.Email)] = e.Pin
      } else {
        dict[sha3_512(e.Email)] = ""
      }
    })
    localStorage.setItem(STORAGE_EMAIL_PINS_obfuscated, JSON.stringify(dict))
  }

  pinRequest(email: string, pin: string) {
    if (pin && pin.length < 7) {
      pin = sha3_512(pin)
    }
    const savedDb = <{}[]>JSON.parse(localStorage.getItem(STORAGE_EMAIL_PINS_obfuscated))
    if (!savedDb) {
      return false
    }
    const emailHash = sha3_512(email)
    return savedDb[emailHash] === "" || savedDb[emailHash] === null || savedDb[emailHash] === pin
  }

  loginRequest(email: string, pw: string) {
    return this.apiService.put(`${API_SECURITY_V2}Security/Session/Employee/Login`, {
      Identifier: email,
      Password: pw,
    })
  }

  fetchEmployeeLoginInfoById(id: string, token: string): Observable<Employee> {
    return this.fetchEmployeeById(id).pipe(
      tap(
        (e: Employee) => {
          return this.setupEmployee(e, token)
        },
        () => {
          this.logout()
          this.toastService.error({ message: "There was a failure to sign in. Please contact AeroGuest team or try again later." })
        }
      )
    )
  }

  fetchEmployeeLoginInfo(email: string, token: string): Observable<Employee> {
    if (this.session && this.session.Token) {
      localStorage.setItem(STORAGE_TOKEN, this.session.Token + "," + this.session.Level)
    }
    return this.fetchEmployeeByEmail(email).pipe(
      tap(
        (e: Employee) => {
          localStorage.removeItem(STORAGE_TOKEN)
          return this.setupEmployee(e, token)
        },
        () => {
          localStorage.removeItem(STORAGE_TOKEN)
          this.toastService.error({ message: "There was a failure to sign in. Please contact AeroGuest team or try again later." })
        }
      )
    )
  }

  updateEmployeePassword(old: string, neww: string): Observable<boolean> {
    if (!this.employeeInfo || !this.employeeInfo.Email) {
      return of(false)
    }
    if (this.session && this.session.Token) {
      localStorage.setItem(STORAGE_TOKEN, this.session.Token + "," + this.session.Level)
    }
    return this.apiService.put(`${API_SECURITY_V2}Security/Session/Employee/Password/Change`, { Id: this.employeeInfo.Id, Identifier: this.employeeInfo.Email, Old: old, New: neww }).pipe(
      tap(
        (data) => {
          localStorage.removeItem(STORAGE_TOKEN)
          return true
        },
        () => {
          localStorage.removeItem(STORAGE_TOKEN)
          this.toastService.error({ message: "There was a failure to update password. Please contact AeroGuest team or try again later." })
          return false
        }
      )
    )
  }

  private setupEmployee(e: Employee, token: string) {
    if (e.Id && e.Id.length > -1) {
      this.setEmployee(e)
      this.setupEmployeeHotelPreference()
      this.setupWebsocket(this.stateService.selectedHotel.Id, token, e.Email)
    }
  }

  setEmployee(e: Employee) {
    this.employeeInfo = e
    localStorage.setItem(STORAGE_USER, JSON.stringify(e))
    if (e) {
      this.authenticatedEmployee.next(e)
    }
  }

  changeEmployeeToken(email: string, token: string): Observable<any> {
    return this.apiService.put(`${API_SECURITY_V2}Security/Session/Employee/Change`, { Identifier: email, Key: token })
  }

  private setupEmployeeHotelPreference() {
    if (!this.stateService.selectedHotel) this.stateService.selectedHotel = this.employeeInfo.Hotels[0]
  }

  /**
   * [Deprecated] Use StateService.selectedHotel instead!
   * @returns An observable of the selected hotel from the state service.
   */
  getHotelPreference(): Observable<Hotel> {
    return this.onAuthentication().pipe(
      map(() => {
        return this.stateService.selectedHotel
      }),
      first()
    )
  }

  private setupWebsocket(hotelId: string, token: string, email: string) {
    this.webSocketService.AuthenticateAndOpenWebsocket(hotelId, email, token)
  }

  /**
   * Gets observable which emits if the user has been authenticated.
   * This observable emits employee if the user has been authenticated and undefined if it was not.
   */
  onAuthentication(): Observable<Employee> {
    return this.authenticatedEmployee.asObservable()
  }

  onLoseFocus() {
    if (this.webSocketService)
      if (this.employeeSubscription)
        // this.webSocketService.disconnect()

        this.employeeSubscription.unsubscribe()
  }

  refreshEmployee(): Observable<Employee> {
    if (localStorage.getItem("employeeToken")) {
      const user = <Employee>JSON.parse(localStorage.getItem(STORAGE_USER))
      return this.fetchEmployeeById(user.Id).pipe(
        tap(
          (e) => {
            return this.setEmployee(e)
          },
          () => {
            this.logout()
          }
        )
      )
    } else {
      this.logout()
    }
  }

  lockFlow() {
    localStorage.removeItem(STORAGE_PIN_SESSION)
    this.webSocketService.disconnectAdmin()
  }

  logout() {
    // Uncommented trying to fix login issues - dont know what it was used for
    // this.authenticatedEmployee.next(undefined);
    localStorage.removeItem(STORAGE_TOKEN)
    localStorage.removeItem(STORAGE_USER)
    this.stateService?.deleteSelectedHotel()
    localStorage.removeItem(STORAGE_EMAIL_PINS_obfuscated)
    localStorage.removeItem(STORAGE_PIN_SESSION)
    if (this.router.url.indexOf("core") >= 0) this.router.navigate(["/login"])

    this.webSocketService.disconnectAdmin()
  }

  getOrganizationImageByEmployeeEmail(email: string): Observable<string> {
    return this.apiService.get(`${API_HOTEL_V2}Employee/Email/${email}/OrganizationImage`)
  }

  fetchEmployees(hotelId: string): Observable<Employee[]> {
    return this.apiService.get(`${API_HOTEL_V2}Employee/Hotel/${hotelId}`)
  }

  fetchProfiles(employeeId: string, hotelId: string): Observable<Employee[]> {
    return this.apiService.get(`${API_HOTEL_V2}Employee/${employeeId}/Hotel/${hotelId}/Profiles`)
  }

  fetchEmployeeByEmail(email: string): Observable<Employee> {
    return this.apiService.get(`${API_HOTEL_V2}Employee/Email/${email}`)
  }

  fetchEmployeeById(id: string): Observable<Employee> {
    return this.apiService.get(`${API_HOTEL_V2}Employee/${id}`)
  }

  fetchHotel(id: string) {
    return this.apiService.get(`${API_HOTEL_V2}Hotel?id=${id}&fromArchive=true`)
  }

  requestPasswordRecovery(email: string) {
    return this.apiService.post(`${API_SECURITY_V2}Password/ForgotPassword/Employee`, {
      Identifier: email,
    })
  }

  changePassword(email: string, newPassword: string, code: string) {
    return this.apiService.post(`${API_SECURITY_V2}Password/Agent/Code`, {
      Identifier: email,
      NewPassword: newPassword,
      ConfirmationCode: code,
    })
  }

  checkCodeValidity(email: string, code: string): Observable<any> {
    return this.apiService.get(`${API_SECURITY_V2}Password/CheckConfirmation?identifier=${email}&confirmationCode=${code}`)
  }

  checkPasswordCodeValidity(code: string): Observable<any> {
    return this.apiService.get(`${API_SECURITY_V2}SignupConfirmation/${code}`)
  }

  generateHotelPublicApiToken(hotelId: string) {
    return this.apiService.post(`${API_SECURITY_V2}Hotel/Public/Token`, {
      HotelId: hotelId,
    })
  }

  existingPublicTokenCheck(hotelId: string) {
    return this.apiService.post(`${API_SECURITY_V2}Hotel/Public/Token/Lookup`, {
      HotelId: hotelId,
    })
  }
}
