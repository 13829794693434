<mat-dialog-content class="mat-dialog-content">
  <div class="dialog-container">
    <div>
      <button class="button-cancel" mat-icon-button mat-dialog-close>
        <mat-icon>clear</mat-icon>
      </button>
      <h2 class="header-h4">{{ data.isEditing ? "Edit" : "Add" }} special delivery fee</h2>
      <h4 class="header-h2">Configure a special delivery fee</h4>
      <hr class="hr-divider" />

      <form [formGroup]="form">
        <div class="dialog-container-inputs">
          <div class="dialog-container-input">
            <div class="information-text">
              <h4>Name</h4>
              <h4 class="grey">Give your fee a name. It is not visible to guests.</h4>
            </div>
            <ag-input class="dialog-input" formControlName="Name" label="Name" [hint]="'Give your fee a name'"></ag-input>
          </div>
          <hr class="hr-divider" />
          <div class="dialog-container-input">
            <div class="information-text">
              <h4>Amount</h4>
              <h4 class="grey">Add your fee in {{ this.data.currency }}</h4>
            </div>
            <ag-input class="dialog-input" [inputType]="'number'" formControlName="Value" label="Amount" [hint]="'How much does your fee cost?'"></ag-input>
          </div>
          <hr class="hr-divider" />
          <div class="dialog-container-input" style="margin-bottom: 30px">
            <div class="information-text">
              <h4>Remove default fee</h4>
              <h4 class="grey">Applies when one or more of these items are ordered.</h4>
            </div>
            <ag-checkbox formControlName="RemoveDefaultFee"></ag-checkbox>
          </div>
          <div class="dialog-container-checkbox">
            <div class="information-text">
              <h4>Apply fee per item</h4>
              <h4 class="grey">The fee will apply per item that guest add to their basket.</h4>
            </div>
            <ag-checkbox formControlName="ApplyFeePerItem"></ag-checkbox>
          </div>
          <hr class="hr-divider" />

          <div class="items-area">
            <div class="dialog-container-input">
              <div class="information-text">
                <h4>Items</h4>
                <h4 class="grey">Add items that the fee applies to.</h4>
              </div>

              <mat-form-field class="dialog-input" class="select">
                <mat-select formControlName="Items" class="items-container">
                  <ag-input formControlName="Search" label="Search" class="search"></ag-input>
                  <mat-option *ngFor="let item of this.items" [value]="item">{{ item }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="chosen-items-area">
              <button *ngFor="let item of this.chosenItems" (click)="unchooseItem(item)" class="chosenItem-button">
                {{ item }}
                <img src="https://aeroguestblobstoragedev.blob.core.windows.net/icons/close_small.svg" class="close-img" />
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="dialog-footer">
      <hr class="hr-divider" />
      <div class="dialog-container-input" style="padding: 0px 30px 20px 30px">
        <div class="information-text">
          <h4 *ngIf="!formIsValid">Confirm</h4>
          <h4 class="grey">{{ this.prerequisites }}</h4>
        </div>
        <mat-dialog-actions class="dialog-actions">
          <div style="flex-grow: 1" class="flex-row flex-center flex-align-center">
            <button class="confirm-button" (click)="submit()" [disabled]="!formIsValid">Confirm</button>
          </div>
        </mat-dialog-actions>
      </div>
    </div>
  </div>
</mat-dialog-content>
