import { Component, ElementRef, forwardRef, Input, OnInit, Renderer2, ViewChild } from "@angular/core"
import { AbstractControl, ControlContainer, ControlValueAccessor, UntypedFormControl, FormControlDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms"

@Component({
  selector: "ag-time",
  templateUrl: "./ag-time.component.html",
  styleUrls: ["./ag-time.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgTimeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AgTimeComponent),
      multi: true,
    },
  ],
})
export class AgTimeComponent implements ControlValueAccessor, Validator {
  @Input() label!: string
  @Input() hint?: string

  @ViewChild(FormControlDirective, { static: true })
  formControlDirective!: FormControlDirective

  @Input() formControl!: UntypedFormControl
  @Input() formControlName!: string
  @Input() clockAsset!: string

  @ViewChild("viewControl") viewControl?: ElementRef
  @ViewChild("timepicker") timepickerobj: any

  get control(): UntypedFormControl {
    return this.formControl || <UntypedFormControl>this.controlContainer?.control?.get(this.formControlName || "")
  }

  constructor(
    private controlContainer: ControlContainer,
    private renderer: Renderer2,
  ) {}

  writeValue = (value: string): void => this.formControlDirective.valueAccessor!.writeValue(value)
  registerOnChange = (fn: any): void => this.formControlDirective.valueAccessor!.registerOnChange(fn)
  registerOnTouched = (fn: any): void => this.formControlDirective.valueAccessor!.registerOnTouched(fn)

  validate(control: AbstractControl): ValidationErrors | null {
    return this.control.valid ? null : control.errors
  }

  onFocus = () => this.renderer.addClass(this.viewControl?.nativeElement, "focussed")

  onBlur = () => this.renderer.removeClass(this.viewControl?.nativeElement, "focussed")

  keyPressNumbers(event: any) {
    const charCode = event.which ? event.which : event.keyCode
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault()
      return false
    } else {
      return true
    }
  }
}
