import { BehaviorSubject } from "rxjs"
import { Injectable } from "@angular/core"

@Injectable()
export class NotificationService {
  public newMessageCount = 0
  public newOrderCount = 0
  constructor() {}

  updateCount(count: number) {
    this.newMessageCount = count
  }
  updateOrderUpdatesCount(count: number) {
    this.newOrderCount = count
  }
}
