import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"

@Component({
  selector: "app-list-item-image",
  templateUrl: "./list-item-image.component.html",
  styleUrls: ["./list-item-image.component.scss"],
})
export class ListItemImageComponent implements OnInit {
  _imageUrl = ""
  fontSize = 14

  @Input() item: any
  @Input() title = ""
  @Input() name = ""
  @Input() description = ""
  @Output() onItemClicked = new EventEmitter<any>()

  @Input() set imageUrl(url: string) {
    if (url === undefined || url.length === 0) return
    this._imageUrl = url
  }

  constructor() {}

  ngOnInit() {}

  onClick() {
    this.onItemClicked.emit(this.item)
  }
}
