import { Component, OnInit, Input, HostListener, HostBinding, AfterViewInit, ElementRef, ViewChild, Renderer2 } from "@angular/core"

@Component({
  selector: "app-tooltip",
  templateUrl: "./tooltip.component.html",
  styleUrls: ["tooltip.component.scss"],
})
export class TooltipContentComponent implements AfterViewInit {
  @ViewChild("tooltip", { static: true }) tooltip: ElementRef
  @ViewChild("arrow", { static: true }) arrow: ElementRef
  @Input() title: string
  @Input() body: string
  @Input() ref: any
  @Input() position: string
  @Input() color: string

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    const targetRef = this.ref.nativeElement
    const tooltipRef = this.tooltip.nativeElement
    const arrowRef = this.arrow.nativeElement

    // Set background color
    this.renderer.setStyle(tooltipRef, "background-color", this.color)
    this.renderer.setStyle(arrowRef, "background", `linear-gradient(to bottom right, transparent 50%, transparent 50%, ${this.color} 50%, ${this.color} 100%)`)

    // Position the tooltip
    const targetRect = targetRef.getBoundingClientRect()

    const mininumBoxHeight = 115
    const mininumTopPlacement = -35
    const dynamicBoxTopPlacement = (tooltipRef.offsetHeight - mininumBoxHeight) / 2 - mininumTopPlacement

    switch (this.position) {
      case "left":
        this.renderer.setStyle(tooltipRef, "left", `${targetRect.left + window.pageXOffset - tooltipRef.offsetWidth - 20}px`)
        this.renderer.setStyle(tooltipRef, "top", `${targetRect.top + window.pageYOffset - 30}px`)

        this.renderer.setStyle(arrowRef, "left", `${targetRect.left + window.pageXOffset - 35}px`)
        this.renderer.setStyle(arrowRef, "top", `${targetRect.top + window.pageYOffset + targetRef.offsetHeight / 2 - 10}px`)
        break
      case "left-journey":
        this.renderer.setStyle(tooltipRef, "left", `${targetRect.left + window.pageXOffset - tooltipRef.offsetWidth - 17}px`)
        this.renderer.setStyle(tooltipRef, "top", `${targetRect.top + window.pageYOffset - dynamicBoxTopPlacement}px`)

        this.renderer.setStyle(arrowRef, "left", `${targetRect.left + window.pageXOffset - 30}px`)
        this.renderer.setStyle(arrowRef, "top", `${targetRect.top + window.pageYOffset + targetRef.offsetHeight / 2 + 10}px`)
        break
      case "right":
        this.renderer.setStyle(tooltipRef, "left", `${targetRect.left + window.pageXOffset + targetRef.offsetWidth + 20}px`)
        this.renderer.setStyle(tooltipRef, "top", `${targetRect.top + window.pageYOffset - 30}px`)

        this.renderer.setStyle(arrowRef, "left", `${targetRect.left + window.pageXOffset + targetRef.offsetWidth + 10}px`)
        this.renderer.setStyle(arrowRef, "top", `${targetRect.top + window.pageYOffset + targetRef.offsetHeight / 2 - 10}px`)
        this.renderer.setStyle(arrowRef, "border-right-color", this.color)
        break
      case "top":
        break
      case "bottom":
        break
    }
  }

  @HostListener("window:resize")
  onWindowResize(): void {
    // update position based on `ref`
  }

  getArrowClass(): string {
    switch (this.position) {
      case "left":
        return "right"
      case "left-journey":
        return "right"
      case "right":
        return "left"
      case "bottom":
        return "top"
      case "top":
        return "bottom"
    }
  }
}
