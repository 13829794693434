import { AgReportStatus, AgStatusIconMapper } from "../ag-report-status"

export class AgReportMessage {
  Title!: string
  Status!: AgReportStatus

  ActionUrl?: string
  ActionLabel?: string
  Message?: string

  constructor(title: string, status: AgReportStatus, message?: string, actionUrl?: string, actionLabel?: string) {
    this.Title = title
    this.Status = status
    this.Message = message
    this.ActionUrl = actionUrl
    this.ActionLabel = actionLabel
  }

  public statusIcon?(): string | undefined {
    return AgStatusIconMapper.getIcon(this.Status)
  }

  public statusClass(): string {
    switch (this.Status) {
      case AgReportStatus.Ok:
        return "ag-success"
      case AgReportStatus.Info:
        return "ag-info"
      case AgReportStatus.Warning:
        return "ag-warning"
      case AgReportStatus.Error:
        return "ag-error"
      default:
        return ""
    }
  }
}
