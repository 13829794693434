import { Component } from "@angular/core"

@Component({
  selector: "app-pair-field",
  templateUrl: "./pair-field.component.html",
  styleUrls: ["./pair-field.component.scss"],
})
export class PairFieldComponent {
  leftValue = ""
  rightValue = ""
}
