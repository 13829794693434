import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core"
import { CommonModule } from "@angular/common"
import { AgTreeComponent } from "./ag-tree.component"
import { AgTreeNodeComponent } from "./ag-tree-node/ag-tree-node.component"
import { AgTreeLeafComponent } from "./ag-tree-leaf/ag-tree-leaf.component"

@NgModule({
  imports: [CommonModule],
  declarations: [AgTreeComponent, AgTreeNodeComponent, AgTreeLeafComponent],
  exports: [AgTreeComponent, AgTreeNodeComponent, AgTreeLeafComponent],

  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AgTreeModule {}
