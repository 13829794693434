import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { LabelValue } from "../ag-drop-down.component"
import { FormBuilder, FormGroup } from "@angular/forms"
import { IconImageService } from "@shared/services/icon-image.service"

@Component({
  selector: "ag-right-checkMark-box",
  templateUrl: "./ag-right-checkmark-box.component.html",
  styleUrls: ["./ag-right-checkmark-box.component.scss"],
})
export class AgRightCheckMarkBoxComponent implements OnInit {
  @Input() list: LabelValue[] = []
  @Input() disabledInputs: LabelValue[] = []
  @Input() showBox = false
  @Input() showSearchField = true
  @Input() useFixedPosition = false
  @Input() showHeaders: boolean = false
  @Input() maxHeight: number = 1000
  @Input() currentValueMultiple: LabelValue[]
  @Input() chooseMultiple: boolean = true

  @Output() outputMultiple = new EventEmitter<LabelValue[]>()
  @Output() outputSingle = new EventEmitter<LabelValue>()
  @Output() searchBoxFieldClicked = new EventEmitter<boolean>()

  @ViewChild("inputField", { static: false }) inputField: ElementRef
  @ViewChild("arrowImage") arrowImage

  public displayedList: LabelValue[] = []
  public searchForm: FormGroup
  public isTyping = false
  public selectedSelectAll: boolean = false

  constructor(private fb: FormBuilder, public iconService: IconImageService) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchInput: [""],
    })
    this.displayedList = this.list
    if (!this.currentValueMultiple) {
      this.currentValueMultiple = []
    }
  }

  checkForDisabled(label: string): boolean {
    const itemDisabled = this.list.some((item) => !this.disabledInputs.some((di) => di.label === item.label))
    if (itemDisabled) {
      return this.disabledInputs.some((item) => item.label === label)
    }
    return false
  }

  onInputChange(value: string) {
    if (value === "") {
      this.isTyping = false
      this.displayedList = this.list
    } else {
      this.isTyping = true
      this.displayedList = this.list.filter((x) => x.label.toLowerCase().includes(value.toLowerCase()))
    }
  }

  showBoxClicked() {
    if (!this.isTyping || (this.inputField && this.inputField.nativeElement && this.inputField.nativeElement.value === "")) {
      this.displayedList = this.list
    }
    return true
  }

  onFieldClicked() {
    this.showBox = false
    this.searchBoxFieldClicked.emit(true)
  }

  onItemClicked(selected: LabelValue) {
    const itemDisabled = this.disabledInputs.find((di) => di.label === selected.label)
    if (!itemDisabled) {
      if (this.chooseMultiple) {
        if (this.currentValueMultiple.some((item) => item.label === selected.label)) {
          this.currentValueMultiple = this.currentValueMultiple.filter((item) => item.label !== selected.label)
          this.selectedSelectAll = false
        } else {
          this.currentValueMultiple?.push(selected)
          this.selectedSelectAll = false
        }
        this.currentValueMultiple?.length === this.displayedList?.length ? (this.selectedSelectAll = true) : (this.selectedSelectAll = false)
        this.outputMultiple.emit(this.currentValueMultiple)
      } else {
        this.list.forEach((element) => {
          if (element.label === selected.label) {
            element.value = true
            this.outputSingle.emit(element)
          } else element.value = false
        })
      }
    }
  }

  onSelectAllClicked() {
    if (this.chooseMultiple) {
      if (this.selectedSelectAll) {
        this.currentValueMultiple = []
        this.selectedSelectAll = false
        this.outputMultiple.emit(this.currentValueMultiple)
      } else {
        if (this.disabledInputs) {
          this.currentValueMultiple = this.list.filter((item) => !this.disabledInputs.find((disabledItem) => disabledItem.value === item.value))
        } else {
          this.currentValueMultiple = this.list
        }
        this.selectedSelectAll = true
        this.outputMultiple.emit(this.currentValueMultiple)
      }
    }
  }

  public checkSelectedItems(item: LabelValue): boolean {
    if (this.chooseMultiple) {
      if (this.currentValueMultiple?.find((selected) => selected?.label === item?.label)) {
        return true
      }
      return false
    } else {
      return item.value
    }
  }
}
