import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { LabelValue } from "../ag-drop-down.component"
import { AgChecked } from "@shared/components/ag-checkbox-v2/ag-checkbox-v2.component"
import { IconImageService } from "@shared/services/icon-image.service"

@Component({
  selector: "ag-multiple-section-box",
  templateUrl: "./ag-multiple-section-box.component.html",
  styleUrl: "./ag-multiple-section-box.component.scss",
})
export class AgMultipleSectionBoxComponent implements OnInit {
  @Input() list: LabelValue[] = []
  @Input() disabledInputs: LabelValue[] = []
  @Input() hintForDisabledInputs: string = ""
  @Input() showBox = false
  @Input() useFixedPosition = false
  @Input() maxHeight: number = 1000
  @Input() maxWidth?: number
  @Input() currentValue: LabelValue
  @Input() numberInSection: number[] = [2, 2, 2, 2, 2]
  @Output() output = new EventEmitter<LabelValue[]>()

  ngOnInit(): void {}

  constructor(public iconService: IconImageService) {}

  getSectionIndex(itemIndex: number): number {
    let cumulativeIndex = 0
    for (let i = 0; i < this.numberInSection.length; i++) {
      cumulativeIndex += this.numberInSection[i]
      if (itemIndex < cumulativeIndex) {
        return i
      }
    }
    return -1
  }


  checkForDisabled(label: string): boolean {
    const hasOtherSelections = this.list.some((item) => item.value === true && !this.disabledInputs.some((di) => di.label === item.label))

    if (hasOtherSelections) {
      return this.disabledInputs.some((item) => item.label === label)
    }
    return false
  }

  checked(item: LabelValue, event: boolean) {
    const itemIndex = this.list.findIndex((element) => element.label === item.label)
    const sectionIndex = this.getSectionIndex(itemIndex)

    let startIndex = 0
    for (let i = 0; i < sectionIndex; i++) {
      startIndex += this.numberInSection[i]
    }

    const sectionSize = this.numberInSection[sectionIndex]

    for (let i = startIndex; i < startIndex + sectionSize; i++) {
      if (i < this.list.length) {
        if (this.list[i].label !== item.label && event) {
          this.list[i].value = false
        }
      }
    }

    item.value = event

    if (this.list.some((element) => element.value === true && !this.disabledInputs.some((di) => di.label === element.label))) {
      this.disabledInputs.forEach((disabledInput) => {
        const disabledItem = this.list.find((element) => element.label === disabledInput.label)
        if (disabledItem) {
          disabledItem.value = true
        }
      })
    }

    this.output.emit(this.list)
  }

  isChecked(item: LabelValue): AgChecked {
    return item.value ? "checked" : "unchecked"
  }

  getSectionStart(sectionIndex: number): number {
    return this.numberInSection.slice(0, sectionIndex).reduce((sum, size) => sum + size, 0)
  }

  getSectionEnd(sectionIndex: number): number {
    return this.getSectionStart(sectionIndex) + this.numberInSection[sectionIndex]
  }
}
