import { Component, EventEmitter, NgZone, OnInit, Output } from "@angular/core"
import { AnimationItem } from "lottie-web"
import { AnimationOptions } from "ngx-lottie"

@Component({
  selector: "ag-mobile-shutter-button",
  template: `
    <ng-lottie class="animation" width="62px" height="62px" [options]="animationOptions" (animationCreated)="animationCreated($event)" (click)="onClick()"></ng-lottie>
  `,
})
export class AgMobileShutterButtonComponent {
  private animation!: AnimationItem
  public animationOptions: AnimationOptions = {
    path: "/assets/animations/CameraButton.json",
    loop: false,
    autoplay: false,
  }

  @Output()
  public agClick: EventEmitter<void> = new EventEmitter<void>()

  constructor(private ngZone: NgZone) {}

  public animationCreated(animationItem: AnimationItem): void {
    this.animation = animationItem
    this.animation.setSpeed(2)
  }

  public onClick(): void {
    this.ngZone.runOutsideAngular(() => {
      this.animation.goToAndPlay(0)
    })
    setTimeout(() => this.agClick.emit(), 100)
  }
}
