import { Component, ContentChildren, Input, QueryList } from "@angular/core"
import { trigger, style, animate, transition } from "@angular/animations"
import { IconImageService } from "@shared/services/icon-image.service"

@Component({
  selector: "ag-tree-node",
  templateUrl: "./ag-tree-node.component.html",
  styleUrls: ["./ag-tree-node.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [style({ height: 0, opacity: 0 }), animate(".15s ease-out", style({ height: "*" })), animate(".15s ease-out", style({ opacity: 1 }))]),
      transition(":leave", [style({ height: "*", opacity: 1 }), animate(".15s ease-in", style({ opacity: 0, height: 0 }))]),
    ]),
  ],
})
export class AgTreeNodeComponent {
  @Input() public agTitle: string = ""
  @Input() public agDescription: string = ""
  @Input() public collapsible: boolean = true
  @Input() public open: boolean = false
  @ContentChildren(AgTreeNodeComponent, { descendants: true }) nestedNodes!: QueryList<AgTreeNodeComponent>

  constructor(public iconService: IconImageService) {}

  get nodes(): AgTreeNodeComponent[] {
    return this.nestedNodes.map((n) => n)
  }

  toggle() {
    if (this.open) {
      this.nodes.forEach((node) => {
        node.open = false
      })
      this.open = false
    } else {
      this.open = true
    }
  }
}
