import { Component, OnInit } from "@angular/core"
import { QuickActionService } from "app/shared/services/quick-action.service"

@Component({
  selector: "app-quick-action-container",
  templateUrl: "./quick-action-container.component.html",
})
export class QuickActionContainerComponent {
  constructor(public quickActionService: QuickActionService) {}

  actionClicked(event: any) {
    this.quickActionService.next(event)
  }
}
