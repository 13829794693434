import { Component, Input, ViewChild, AfterViewInit, Output, EventEmitter } from "@angular/core"
import { CdkDropList, moveItemInArray, CdkDropListGroup, CdkDragMove, CdkDrag } from "@angular/cdk/drag-drop"
import { ViewportRuler } from "@angular/cdk/overlay"

export interface ImageBrowserElement {
  Type: string
  Text: string
  Source: string
  Hidden: boolean
  HideHide: boolean
  HideDelete: boolean
}

@Component({
  selector: "app-image-browser",
  template: `
    <div class="example-container" cdkDropListGroup>
      <div cdkDropList [cdkDropListEnterPredicate]="dropListEnterPredicate" (cdkDropListDropped)="dropListDropped()"></div>
      <div cdkDropList *ngFor="let image of images; let i = index" [cdkDropListEnterPredicate]="dropListEnterPredicate" (cdkDropListDropped)="dropListDropped()">
        <div cdkDrag [cdkDragDisabled]="disableDrag" [ngClass]="hideText ? 'card-frame-notext' : 'card-frame'" (cdkDragMoved)="dragMoved($event)" (mouseenter)="mouseEnter(i)" (mouseleave)="mouseLeave()">
          <div class="card-image-container">
            <div class="blur-background-static" [ngClass]="{ 'blur-background': hoverIndex === i, overlay: image.Hidden }">
              <img [ngClass]="hideText ? 'card-image-notext' : 'card-image'" src="{{ image.Source }}" />
              <div class="absolute-placement-top">
                <div class="dark-text-outline-small" *ngIf="!disableOrder">
                  <div class="dark-text">{{ i + 1 }}</div>
                </div>
                <div style="width: 4px;" *ngIf="image.Type"></div>
                <div class="dark-text-outline" *ngIf="image.Type">
                  <div class="dark-text">{{ image.Type }}</div>
                </div>
                <div style="width: 4px;" *ngIf="image.Hidden"></div>
                <div class="dark-text-outline-small" *ngIf="image.Hidden">
                  <img src="/assets/property-setup/room/edit-room/hideimg.svg" />
                </div>
              </div>
            </div>
            <div class="absolute-placement-bottom" *ngIf="hoverIndex === i">
              <div class="center-content">
                <div class="purple-circle" cdkDragHandle *ngIf="!disableDrag">
                  <img src="/assets/property-setup/room/edit-room/drag.svg" />
                </div>
                <div class="tiny-spacing" [hidden]="image.HideDelete"></div>
                <div class="purple-circle" (click)="onClickDelete(i)" *ngIf="!image.HideDelete">
                  <img src="/assets/property-setup/room/edit-room/trash.svg" />
                </div>
                <div class="tiny-spacing" [hidden]="image.HideHide"></div>
                <div class="purple-circle" (click)="onClickHide(i)" *ngIf="!image.HideHide">
                  <img class="hide-image" src="/assets/property-setup/room/edit-room/hideimg.svg" *ngIf="!image.Hidden" />
                  <img class="hide-image" src="/assets/property-setup/room/edit-room/shown.svg" *ngIf="image.Hidden" />
                </div>
              </div>
            </div>
          </div>
          <div style="background-color: #cccccc; width: 100%; height: 1px;" [hidden]="hideText"></div>
          <div class="card-text-box" *ngIf="image.Text && !hideText" [ngClass]="image.Hidden ? 'overlay' : ''">
            <label class="card-text no-text-overflow" [attr.contenteditable]="true" (focusout)="onTextFocusOut(i, textField)" #textField>{{ image.Text }}</label>
          </div>
        </div>
      </div>
      <div class="center-content" [ngClass]="hideText ? 'card-frame-notext' : 'card-frame'" style="flex-direction: column" appDragDropImage (onFileDropped)="onImageDropped($event)" *ngIf="!hideAddImage">
        <img class="add-image" src="/assets/property-setup/hotel-image/add-image.png" />
        <div class="drop-an-image-text">Drop an image or</div>
        <div class="browse">
          <label for="file-upload" class="browse-button">Browse</label>
          <input type="file" multiple id="file-upload" (change)="onUploadImage($event)" type="file" accept="image/png,image/jpeg" #file [hidden]="true" />
        </div>
      </div>
    </div>
  `,
  styles: [
    `
                       .overlay {
                         background: #ffffff;
                         position: relative;
                         opacity: 0.5;
                       }
                       .add-image {
                         width: 27px;
                         height: 23px;
                         margin-top: 8px;
                         margin-bottom: 3px;
                         align-self: center;
                       }
                       .drop-an-image-text {
                         font-family: Lato;
                         font-size: 13px;
                         font-weight: normal;
                         font-style: normal;
                         line-height: normal;
                         letter-spacing: normal;
                         color: #333333;
                         text-align: center;
                       }
                       .browse {
                         padding-top: 5px;
                         display: flex;
                         justify-content: center;
                       }
                       .browse-button {
                         width: 72px;
                         height: 28px;
                         border-radius: 19px;
                         background-color: #333333;
                         font-family: Lato;
                         font-size: 12px;
                         font-weight: normal;
                         font-stretch: normal;
                         font-style: normal;
                         line-height: 2;
                         letter-spacing: normal;
                         text-align: center;
                         color: #ffffff;
                       }
                       .example-container {
                         display: flex;
                         flex-wrap: wrap;
                       }
                       .card-frame {
                         width: 137px;
                         height: 116px;
                         border-radius: 8px;
                         border: 1px solid #cccccc;
                         margin-right: 8px;
                         margin-bottom: 8px;
                       }
                       .card-frame-notext {
                         width: 137px;
                         height: 87px;
                         border-radius: 8px;
                         border: 1px solid #cccccc;
                         margin-right: 8px;
                         margin-bottom: 8px;
                       }
                       .card-image-container {
                         height: 87px;
                         width: 100%;
                         position: relative;
                       }
                       .card-image {
                         object-fit: cover;
                         height: inherit;
                         width: inherit;
                         border-radius: 8px 8px 0px 0px;
                       }
                       .card-image-notext {
                         object-fit: cover;
                         height: inherit;
                         width: inherit;
                         border-radius: 8px;
                       }
                       .card-text-box {
                         display: flex;
                         justify-content: center;
                         margin-top: 3px;
                         background-color: white;
                       }
                       .card-text {
                         max-width: 90%;
                         font-family: Lato;
                         font-size: 13px;
                         font-weight: normal;
                         font-stretch: normal;
                         font-style: normal;
                         line-height: normal;
                         letter-spacing: normal;
                         text-align: center;
                         color: #333333;
                         text-align: center;
                         outline: none;
                         padding-left: 2px;
                         max-width: 100%;
                       }
                       .no-text-overflow {
                         overflow: hidden;
                         text-overflow: ellipsis;
                         white-space: nowrap;
                       }
                       .absolute-placement-top {
                         position: absolute;
                         top: 4px;
                         left: 4px;
                         max-width: 90%;
                         display: flex;
                       }
                       .absolute-placement-bottom {
                         position: absolute;
                         bottom: -12px;
                         left: 50%;
                         transform: translate(-50%, -50%);
                         max-width: 90%;
                       }
                       .center-content {
                         display: flex;
                         justify-content: center;
                       }
                       .dark-text-outline-small {
                         background-color: #333333;
                         width: 18px;
                         height: 18px;
                         border-radius: 9px;
                         padding: 0px 8px;
                         display: flex;
                         justify-content: center;
                       }
                       .dark-text-outline {
                         background-color: #333333;
                         width: fit-content;
                         height: 18px;
                         border-radius: 9px;
                         padding: 0px 8px;
                         display: flex;
                         justify-content: center;
                       }
                       .dark-text {
                         font-family: Lato;
                         font-size: 10px;
                         font-weight: normal;
                         font-stretch: normal;
                         font-style: normal;
                         line-height: 2;
                         letter-spacing: normal;
                         text-align: center;
                         color: #f2eee7;
                       }
                       .blur-background-static {
                         height: inherit;
                         width: inherit;
                       }
                       .blur-background {
                         -webkit-filter: blur(2px);
                         -moz-filter: blur(2px);
                         -o-filter: blur(2px);
                         -ms-filter: blur(2px);
                         filter: blur(2px);
                       }
                       .purple-circle {
                         width: 30px;
                         height: 30px;
                         border-radius: 32px;
                         background-color: #61566e;
                         display: flex;
                         justify-content: center;
                         cursor: pointer;
                       }
                       .hide-image {
                         width: 18px;
                         height: 13px;
                         object-fit: cover;
                         align-self: center;
                       }
                       .tiny-spacing {
                         padding-left: 8px;
                       }
                 
                       .cdk-drag-preview {
                         box-sizing: border-box;
                         border-radius: 4px;
                         box-shadow:
                           0 5px 5px -3px rgba(0, 0, 0, 0.2),
                           0 8px 10px 1px rgba(0, 0, 0, 0.14),
                           0 3px 14px 2px rgba(0, 0, 0, 0.12);
                       }
                       .cdk-drag-placeholder {
                         opacity: 0.2;
                       }
                       .cdk-drag-animating {
                         transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
                       }
                     `,
  ],
})
export class ImageBrowserComponent implements AfterViewInit {
  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>
  @ViewChild(CdkDropList) placeholder: CdkDropList

  public target: CdkDropList
  public targetIndex: number
  public source: CdkDropList
  public sourceIndex: number
  public dragIndex: number
  public activeContainer

  public hoverIndex = -1

  @Output() onAddedImage: EventEmitter<string> = new EventEmitter<string>()
  @Output() onDeletedImage: EventEmitter<string> = new EventEmitter<string>()

  @Input() disableOrder = false
  @Input() disableDrag = false
  @Input() hideText = false
  @Input() hideAddImage = false
  @Input() images: ImageBrowserElement[] = []

  constructor(private viewportRuler: ViewportRuler) {
    this.target = null
    this.source = null
  }

  onTextFocusOut(index: number, textField) {
    if (this.images[index] && (!textField.textContent || textField.textContent.length <= 0)) {
      textField.textContent = "Image"
      this.images[index].Text = "Image"
    } else if (this.images[index]) {
      this.images[index].Text = textField.textContent
    }
  }

  mouseEnter(index: number) {
    this.hoverIndex = index
  }

  mouseLeave() {
    this.hoverIndex = -1
  }

  onClickHide(index: number) {
    if (this.images[index]) {
      this.images[index].Hidden = !this.images[index].Hidden
    }
  }

  onClickDelete(index: number) {
    if (this.images[index]) {
      this.images.splice(index, 1)
      this.onDeletedImage.emit(index.toString())
    }
  }

  getBase64(file: any, cb: any) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      // With split we remove encoded string headers
      cb((reader.result as string).split(",")[1])
    }
    reader.onerror = function (error) {
      console.error("Error: ", error)
    }
  }

  onUploadImage(e) {
    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        this.getBase64(e.target.files[i], (base64: string, typeString: string) => {
          this.onAddedImage.emit(base64)
        })
      }
    }
  }

  onImageDropped(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index]
      this.getBase64(element, (base64: string, typeString: string) => {
        this.onAddedImage.emit(base64)
      })
    }
  }

  ngAfterViewInit() {
    const phElement = this.placeholder.element.nativeElement

    phElement.style.display = "none"
    phElement.parentElement.removeChild(phElement)
  }

  dragMoved(e: CdkDragMove) {
    const point = this.getPointerPositionOnPage(e.event)

    this.listGroup._items.forEach((dropList) => {
      if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList
        return
      }
    })
  }

  dropListDropped() {
    if (!this.target) return

    const phElement = this.placeholder.element.nativeElement
    const parent = phElement.parentElement

    phElement.style.display = "none"

    parent.removeChild(phElement)
    parent.appendChild(phElement)
    parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex])

    this.target = null
    this.source = null

    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(this.images, this.sourceIndex, this.targetIndex)
    }
  }

  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
    if (drop === this.placeholder) return true

    if (drop !== this.activeContainer) return false

    const phElement = this.placeholder.element.nativeElement
    const sourceElement = drag.dropContainer.element.nativeElement
    const dropElement = drop.element.nativeElement

    const dragIndex = __indexOf(dropElement.parentElement.children, this.source ? phElement : sourceElement)
    const dropIndex = __indexOf(dropElement.parentElement.children, dropElement)

    if (!this.source) {
      this.sourceIndex = dragIndex
      this.source = drag.dropContainer

      phElement.style.width = sourceElement.clientWidth + "px"
      phElement.style.height = sourceElement.clientHeight + "px"

      sourceElement.parentElement.removeChild(sourceElement)
    }

    this.targetIndex = dropIndex
    this.target = drop

    phElement.style.display = ""
    dropElement.parentElement.insertBefore(phElement, dropIndex > dragIndex ? dropElement.nextSibling : dropElement)

    this.placeholder._dropListRef.enter(drag._dragRef, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop)
    return false
  }

  /** Determines the point of the page that was touched by the user. */
  getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = __isTouchEvent(event) ? event.touches[0] || event.changedTouches[0] : event
    const scrollPosition = this.viewportRuler.getViewportScrollPosition()

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top,
    }
  }
}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node)
}

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith("touch")
}

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
  const { top, bottom, left, right } = dropList.element.nativeElement.getBoundingClientRect()
  return y >= top && y <= bottom && x >= left && x <= right
}
