import { Component, OnInit, Output, EventEmitter } from "@angular/core"

@Component({
  selector: "app-overlay-fullscreen-box",
  template: `
    <div class="sheet">
      <div class="flex-row flex-end flex-align-strech-row">
        <div (click)="onClose()" class="close-btn">
          <mat-icon class="close-icon">close</mat-icon>
        </div>
      </div>
      <div class="content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
                       .sheet {
                         background: #fff;
                         z-index: 10000;
                         width: 100vw;
                         height: 95vh;
                         position: fixed;
                         bottom: 0;
                         right: 0;
                         border-top-left-radius: 24px;
                         border-top-right-radius: 24px;
                       }
                       .close-btn {
                         height: 24px;
                         width: 24px;
                         border-radius: 12px;
                         text-align: right;
                         margin: 8px 8px 0 0;
                       }
                       .close-icon {
                         color: #333;
                       }
                       .content {
                         margin: 8px 0 0 0;
                         height: 100%;
                       }
                     `,
  ],
})
export class OverlayFullscreenBoxComponent implements OnInit {
  @Output() close = new EventEmitter<boolean>()

  constructor() {}

  ngOnInit(): void {}

  onClose() {
    this.close.emit(true)
  }
}
