<div class="ag-form-control" #viewControl agTooltip="{{ hint }}" [agTooltipCondition]="!!hint && control.invalid && control.dirty" [class.has-errors]="control.invalid && control.dirty" [class.disabled]="control.disabled">
  <div class="ag-value">
    <select [formControl]="control" (focus)="onFocus()" (blur)="onBlur()">
      <option hidden></option>
      <ng-container *ngTemplateOutlet="optionValue && optionText ? objectTemplate : stringTemplate"></ng-container>
    </select>
    <label for="value" [ngClass]="{ 'has-value': control.value }">{{ label }}</label>
  </div>
</div>

<ng-template #stringTemplate>
  <option *ngFor="let u of options" [value]="u" [selected]="control.value === u">{{ u }}</option>
</ng-template>
<ng-template #objectTemplate>
  <option *ngFor="let u of options" [value]="u[optionValue]" [selected]="control.value === u[optionValue]">{{ u[optionText] }}</option>
</ng-template>
