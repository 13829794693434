<div style="height: fit-content; max-height: 100%; width: 100%; position: relative" [hidden]="!showBox ? showBoxClicked() : false">
  <div [ngClass]="!useFixedPosition ? 'container-standard' : 'container-fixed'">
    <div class="form-div" *ngIf="showSearchField">
      <div class="search-bar">
        <input class="search-bar-input" #inputField placeholder="Search" (input)="onInputChange(inputField.value)" />
        <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/magnifier.svg" class="form-img" />
      </div>
    </div>
    <div class="hovering-item">
      <div class="item flex" (click)="onSelectAllClicked()">
        <label class="item-text disable-selection" [ngStyle]="{ 'font-family': selectedSelectAll ? 'Poppins-Medium' : 'Poppins-Regular' }">
          <ng-container *ngIf="selectedSelectAll && checkboxType === 'square'">
            <img class="checkbox" [src]="iconService.Icon.checkbox_tick" />
          </ng-container>
          <ng-container *ngIf="!selectedSelectAll && checkboxType === 'square'">
            <img class="checkbox" [src]="iconService.Icon.checkbox_grey_empty" />
          </ng-container>
          <ng-container *ngIf="selectedSelectAll && checkboxType === 'circle'">
            <img class="checkbox" [src]="iconService.Icon.round_checkbox_grey_tick" />
          </ng-container>
          <ng-container *ngIf="!selectedSelectAll && checkboxType === 'circle'">
            <img class="checkbox" [src]="iconService.Icon.round_checkbox_grey_empty" />
          </ng-container>
          Select all
        </label>
      </div>
      <ng-container *ngIf="selectedSelectAll">
        <img class="img" [src]="iconService.Icon.check" />
      </ng-container>
    </div>
    <div class="line-container">
      <div class="line"></div>
    </div>
    <div class="top-bottom-padding" [ngStyle]="{ 'max-height': maxHeight - 43 + 'px' }">
      <div [hidden]="list.length < 1">
        <label class="header" [hidden]="isTyping" *ngIf="showHeaders">ALPHABETICAL</label>
        <div class="spacer" *ngIf="showHeaders"></div>
        <div class="items-container">
          <div *ngFor="let item of displayedList">
            <div class="divider" *ngIf="showDivider(item)"></div>
            <div [ngClass]="item.value !== 'search-box-title' ? 'hovering-item' : 'item-container'">
              <div [class.item]="!isItemDisabled(item) && item.value !== 'search-box-title'" [class.title]="item.value !== 'search-box-title'" [class.disabled-item]="isItemDisabled(item)" (click)="onItemClicked(item)">
                <label class="item-text disable-selection" [ngStyle]="{ cursor: isItemDisabled(item) ? 'default' : 'pointer', 'font-family': checkSelectedItems(item) ? 'Poppins-Medium' : 'Poppins-Regular' }" *ngIf="item.value !== 'search-box-title'">
                  <ng-container *ngIf="checkSelectedItems(item) && checkboxType === 'square' && !isItemDisabled(item)">
                    <img class="checkbox" [src]="iconService.Icon.checkbox_tick" />
                  </ng-container>
                  <ng-container *ngIf="!checkSelectedItems(item) && checkboxType === 'square' && !isItemDisabled(item)">
                    <img class="checkbox" [src]="iconService.Icon.checkbox_grey_empty" />
                  </ng-container>
                  <ng-container *ngIf="!checkSelectedItems(item) && checkboxType === 'square' && isItemDisabled(item)">
                    <img class="checkbox" [src]="iconService.Icon.checkbox_disabled" />
                  </ng-container>
                  <ng-container *ngIf="checkSelectedItems(item) && checkboxType === 'circle' && !isItemDisabled(item)">
                    <img class="checkbox" [src]="iconService.Icon.round_checkbox_grey_tick" />
                  </ng-container>
                  <ng-container *ngIf="!checkSelectedItems(item) && checkboxType === 'circle' && !isItemDisabled(item)">
                    <img class="checkbox" [src]="iconService.Icon.round_checkbox_grey_empty" />
                  </ng-container>
                  <ng-container *ngIf="!checkSelectedItems(item) && checkboxType === 'circle' && isItemDisabled(item)">
                    <img class="checkbox" [src]="iconService.Icon.round_checkbox_grey_disabled" />
                  </ng-container>
                  {{ item.label }}
                </label>
                <label *ngIf="item.value === 'search-box-title'" class="title">{{ item.label }}:</label>
              </div>
              <ng-container *ngIf="checkSelectedItems(item)">
                <img class="img" [src]="iconService.Icon.check" />
              </ng-container>
            </div>
          </div>
          <div class="no-result-text" *ngIf="displayedList.length === 0">No results</div>
        </div>
      </div>
    </div>
  </div>
</div>
