import { Location } from "@angular/common"
import { Component, Input, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { HeaderItem } from "app/shared/models/header"

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() title: string
  @Input() path: HeaderItem[] = []

  constructor(
    private _location: Location,
    private router: Router,
  ) {}

  ngOnInit() {}

  hasPath(): boolean {
    return this.path !== undefined && this.path.length > 0
  }

  backClicked() {
    if (this.router.url.includes("/core/property-setup/management/employee-setup/edit")) {
      this.router.navigate(["/core/property-setup/management/employee-setup"])
    } else {
      this._location.back()
    }
  }
}
