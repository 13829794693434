import { Injectable } from "@angular/core"

@Injectable({
  providedIn: "root",
})
export class CurrencyService {
  public currencies = [
    { Value: "AED", Text: "United Arab Emirates dirham" },
    { Value: "AFN", Text: "Afghan afghani" },
    { Value: "ALL", Text: "Albanian lek" },
    { Value: "AMD", Text: "Armenian dram" },
    { Value: "ANG", Text: "Netherlands Antillean guilder" },
    { Value: "AOA", Text: "Angolan kwanza" },
    { Value: "ARS", Text: "Argentine peso" },
    { Value: "AUD", Text: "Australian dollar" },
    { Value: "AWG", Text: "Aruban florin" },
    { Value: "AZN", Text: "Azerbaijani manat" },
    { Value: "BAM", Text: "Bosnia and Herzegovina convertible mark" },
    { Value: "BBD", Text: "Barbadian dollar" },
    { Value: "BDT", Text: "Bangladeshi taka" },
    { Value: "BGN", Text: "Bulgarian lev" },
    { Value: "BHD", Text: "Bahraini dinar" },
    { Value: "BIF", Text: "Burundian franc" },
    { Value: "BMD", Text: "Bermudian dollar" },
    { Value: "BND", Text: "Brunei dollar" },
    { Value: "BOB", Text: "Bolivian boliviano" },
    { Value: "BRL", Text: "Brazilian real" },
    { Value: "BSD", Text: "Bahamian dollar" },
    { Value: "BTN", Text: "Bhutanese ngultrum" },
    { Value: "BWP", Text: "Botswana pula" },
    { Value: "BYN", Text: "New Belarusian ruble" },
    { Value: "BYR", Text: "Old Belarusian ruble" },
    { Value: "BZD", Text: "Belize dollar" },
    { Value: "CAD", Text: "Canadian dollar" },
    { Value: "CDF", Text: "Congolese franc" },
    { Value: "CHF", Text: "Swiss franc" },
    { Value: "CKD", Text: "Cook Islands dollar" },
    { Value: "CLP", Text: "Chilean peso" },
    { Value: "CNY", Text: "Chinese yuan" },
    { Value: "COP", Text: "Colombian peso" },
    { Value: "CRC", Text: "Costa Rican colón" },
    { Value: "CUC", Text: "Cuban convertible peso" },
    { Value: "CUP", Text: "Cuban peso" },
    { Value: "CVE", Text: "Cape Verdean escudo" },
    { Value: "CZK", Text: "Czech koruna" },
    { Value: "DJF", Text: "Djiboutian franc" },
    { Value: "DKK", Text: "Danish krone" },
    { Value: "DOP", Text: "Dominican peso" },
    { Value: "DZD", Text: "Algerian dinar" },
    { Value: "EGP", Text: "Egyptian pound" },
    { Value: "ERN", Text: "Eritrean nakfa" },
    { Value: "ETB", Text: "Ethiopian birr" },
    { Value: "EUR", Text: "Euro" },
    { Value: "FJD", Text: "Fijian dollar" },
    { Value: "FKP", Text: "Falkland Islands pound" },
    { Value: "FOK", Text: "Faroese króna" },
    { Value: "GBP", Text: "British pound" },
    { Value: "GEL", Text: "Georgian Lari" },
    { Value: "GHS", Text: "Ghanaian cedi" },
    { Value: "GIP", Text: "Gibraltar pound" },
    { Value: "GMD", Text: "Gambian dalasi" },
    { Value: "GNF", Text: "Guinean franc" },
    { Value: "GTQ", Text: "Guatemalan quetzal" },
    { Value: "GYD", Text: "Guyanese dollar" },
    { Value: "HKD", Text: "Hong Kong dollar" },
    { Value: "HNL", Text: "Honduran lempira" },
    { Value: "HRK", Text: "Croatian kuna" },
    { Value: "HTG", Text: "Haitian gourde" },
    { Value: "HUF", Text: "Hungarian forint" },
    { Value: "IDR", Text: "Indonesian rupiah" },
    { Value: "ILS", Text: "Israeli new shekel" },
    { Value: "IMP", Text: "Manx pound" },
    { Value: "INR", Text: "Indian rupee" },
    { Value: "IQD", Text: "Iraqi dinar" },
    { Value: "IRR", Text: "Iranian rial" },
    { Value: "ISK", Text: "Icelandic króna" },
    { Value: "JEP", Text: "Jersey pound" },
    { Value: "JMD", Text: "Jamaican dollar" },
    { Value: "JOD", Text: "Jordanian dinar" },
    { Value: "JPY", Text: "Japanese yen" },
    { Value: "KES", Text: "Kenyan shilling" },
    { Value: "KGS", Text: "Kyrgyzstani som" },
    { Value: "KHR", Text: "Cambodian riel" },
    { Value: "KMF", Text: "Comorian franc" },
    { Value: "KPW", Text: "North Korean won" },
    { Value: "KRW", Text: "South Korean won" },
    { Value: "KWD", Text: "Kuwaiti dinar" },
    { Value: "KYD", Text: "Cayman Islands dollar" },
    { Value: "KZT", Text: "Kazakhstani tenge" },
    { Value: "LAK", Text: "Lao kip" },
    { Value: "LBP", Text: "Lebanese pound" },
    { Value: "LKR", Text: "Sri Lankan rupee" },
    { Value: "LRD", Text: "Liberian dollar" },
    { Value: "LSL", Text: "Lesotho loti" },
    { Value: "LYD", Text: "Libyan dinar" },
    { Value: "MAD", Text: "Moroccan dirham" },
    { Value: "MDL", Text: "Moldovan leu" },
    { Value: "MGA", Text: "Malagasy ariary" },
    { Value: "MKD", Text: "Macedonian denar" },
    { Value: "MMK", Text: "Burmese kyat" },
    { Value: "MNT", Text: "Mongolian tögrög" },
    { Value: "MOP", Text: "Macanese pataca" },
    { Value: "MRO", Text: "Mauritanian ouguiya" },
    { Value: "MUR", Text: "Mauritian rupee" },
    { Value: "MVR", Text: "Maldivian rufiyaa" },
    { Value: "MWK", Text: "Malawian kwacha" },
    { Value: "MXN", Text: "Mexican peso" },
    { Value: "MYR", Text: "Malaysian ringgit" },
    { Value: "MZN", Text: "Mozambican metical" },
    { Value: "NAD", Text: "Namibian dollar" },
    { Value: "NGN", Text: "Nigerian naira" },
    { Value: "NIO", Text: "Nicaraguan córdoba" },
    { Value: "NOK", Text: "Norwegian krone" },
    { Value: "NPR", Text: "Nepalese rupee" },
    { Value: "NZD", Text: "New Zealand dollar" },
    { Value: "OMR", Text: "Omani rial" },
    { Value: "PAB", Text: "Panamanian balboa" },
    { Value: "PEN", Text: "Peruvian sol" },
    { Value: "PGK", Text: "Papua New Guinean kina" },
    { Value: "PHP", Text: "Philippine peso" },
    { Value: "PKR", Text: "Pakistani rupee" },
    { Value: "PLN", Text: "Polish złoty" },
    { Value: "PYG", Text: "Paraguayan guaraní" },
    { Value: "QAR", Text: "Qatari riyal" },
    { Value: "RON", Text: "Romanian leu" },
    { Value: "RSD", Text: "Serbian dinar" },
    { Value: "RUB", Text: "Russian ruble" },
    { Value: "RWF", Text: "Rwandan franc" },
    { Value: "SAR", Text: "Saudi riyal" },
    { Value: "SBD", Text: "Solomon Islands dollar" },
    { Value: "SCR", Text: "Seychellois rupee" },
    { Value: "SDG", Text: "Sudanese pound" },
    { Value: "SEK", Text: "Swedish krona" },
    { Value: "SGD", Text: "Singapore dollar" },
    { Value: "SHP", Text: "Saint Helena pound" },
    { Value: "SLL", Text: "Sierra Leonean leone" },
    { Value: "SOS", Text: "Somali shilling" },
    { Value: "SRD", Text: "Surinamese dollar" },
    { Value: "SSP", Text: "South Sudanese pound" },
    { Value: "STD", Text: "São Tomé and Príncipe dobra" },
    { Value: "SYP", Text: "Syrian pound" },
    { Value: "SZL", Text: "Swazi lilangeni" },
    { Value: "THB", Text: "Thai baht" },
    { Value: "TJS", Text: "Tajikistani somoni" },
    { Value: "TMT", Text: "Turkmenistan manat" },
    { Value: "TND", Text: "Tunisian dinar" },
    { Value: "TOP", Text: "Tongan paʻanga" },
    { Value: "TRY", Text: "Turkish lira" },
    { Value: "TTD", Text: "Trinidad and Tobago dollar" },
    { Value: "TVD", Text: "Tuvaluan dollar" },
    { Value: "TWD", Text: "New Taiwan dollar" },
    { Value: "TZS", Text: "Tanzanian shilling" },
    { Value: "UAH", Text: "Ukrainian hryvnia" },
    { Value: "UGX", Text: "Ugandan shilling" },
    { Value: "USD", Text: "United States dollar" },
    { Value: "UYU", Text: "Uruguayan peso" },
    { Value: "UZS", Text: "Uzbekistani so'm" },
    { Value: "VEF", Text: "Venezuelan bolívar" },
    { Value: "VND", Text: "Vietnamese đồng" },
    { Value: "VUV", Text: "Vanuatu vatu" },
    { Value: "WST", Text: "Samoan tālā" },
    { Value: "XAF", Text: "Central African CFA franc" },
    { Value: "XCD", Text: "East Caribbean dollar" },
    { Value: "XOF", Text: "West African CFA franc" },
    { Value: "XPF", Text: "CFP franc" },
    { Value: "YER", Text: "Yemeni rial" },
    { Value: "ZAR", Text: "South African rand" },
    { Value: "ZMW", Text: "Zambian kwacha " },
  ]

  constructor() {}
}
