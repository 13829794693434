import { EventEmitter, Injectable, OnDestroy, OnInit } from "@angular/core"
import { Environment } from "environments/environment"
import { BehaviorSubject, interval, Subscription } from "rxjs"
import { webSocket, WebSocketSubject } from "rxjs/webSocket"
import { StateService } from "app/shared/services/state.service"
import { STORAGE_TOKEN, STORAGE_USER } from "../../app-settings"
import { Employee } from "../models/employee"
import { Hotel } from "../models"
import { Sound, SoundService } from "./sound.service"

@Injectable()
export class WebSocketService {
  windowHasFocus = true
  adminSocket: WebSocketSubject<any>
  private adminPingCount = 0
  private adminHealthPingCount = 0
  adminWebSocketIsActivated = false
  hotelIsRegistered = false
  private adminPingInterval: Subscription
  private adminHealthInterval: Subscription

  adminWebSocketMessageEventEmitter: EventEmitter<any> = new EventEmitter<any>()
  private latestMessage = new BehaviorSubject("")
  webSocketMessage = this.latestMessage.asObservable()

  PING_INTERVAL_SECONDS = 20 // 20 sec
  HEALTHCHECK_INTERVAL = 60 // 1 min
  constructor(private stateService: StateService, private soundService: SoundService) {
    this.initAdminWebSocket()

    window.addEventListener("focus", (event) => {
      this.windowHasFocus = true
      this.initializeWebsocket()
    })

    window.addEventListener("blur", (event) => {
      this.windowHasFocus = false
      this.initializeWebsocket()
    })

    window.addEventListener("beforeunload", (event) => {
      this.disconnectAdmin()
      this.latestMessage.complete()
    })
  }

  initializeWebsocket() {
    // console.debug('websocket: Initializing admin websocket');
    const user: Employee = JSON.parse(localStorage.getItem(STORAGE_USER))
    const token = localStorage.getItem(STORAGE_TOKEN)?.split(",")[0]
    if (this.stateService.selectedHotel?.Id && user?.Email && token) {
      this.initAdminWebSocket()
      this.AuthenticateAndOpenWebsocket(this.stateService.selectedHotel?.Id, user?.Email, token)
    }
  }

  initAdminWebSocket() {
    if (!this.adminSocket || this.adminSocket.closed) {
      this.adminSocket = webSocket({
        url: Environment.WebsocketGateAdmin,
        deserializer: (msg) => msg,
        openObserver: {
          next: () => {},
        },
      })
      this.adminPingCount = 0
      this.subscribeToAdminSocket()
    }
    this.initPingIntervals()
  }

  /**
   * When WebSocketSubject is subscribed, it attempts to make a socket connection,
   * unless there is one made already. This means that many subscribers will always
   * listen on the same socket, thus saving resources. If however, two instances
   * are made of WebSocketSubject, even if these two were provided with the same
   * url, they will attempt to make separate connections. When consumer of a
   * WebSocketSubject unsubscribes, socket connection is closed, only if there are
   * no more subscribers still listening. If after some time a consumer starts
   * subscribing again, connection is reestablished.
   */
  refreshWebSocket() {
    this.disconnectAdmin()
    this.initializeWebsocket()
  }

  private subscribeToAdminSocket() {
    this.adminSocket.subscribe(
      (msg: MessageEvent) => {
        if (msg.data === "Pong") {
          this.adminPingCount = 0
          this.adminWebSocketIsActivated = true
        } else if (msg.data === "Health Ok") {
          this.adminHealthPingCount = 0
          this.hotelIsRegistered = true
        } else {
          console.debug("websocket: received -> ", msg)
          this.adminWebSocketMessageEventEmitter.emit(msg.data)
        }
      },
      (err) => {
        //this.soundService.play(Sound.Disconnect)
        this.adminWebSocketIsActivated = false
        this.hotelIsRegistered = false
      },
      () => {
        this.adminWebSocketIsActivated = false // Called when connection is closed (for whatever reason).
        this.hotelIsRegistered = false
      }
    )
  }

  disconnectAdmin() {
    this.adminSocket?.unsubscribe()
    this.adminPingInterval?.unsubscribe()
    this.adminHealthInterval?.unsubscribe()
    this.adminWebSocketIsActivated = false
    this.hotelIsRegistered = false
  }

  private ping() {
    if (this.adminPingCount === 0) {
      this.adminPingCount++
      this.adminSocket.next({ Type: "Ping" })
    } else {
      if (this.adminPingCount === 1 && this.windowHasFocus && this.adminSocket && this.adminSocket.closed) {
        // TODO decide: should we try and reinit if
        this.adminPingCount++
        this.subscribeToAdminSocket()
      } else {
        this.disconnectAdmin()
      }
    }
  }
  private healthCheck() {
    if (this.adminHealthPingCount === 0) {
      this.adminHealthPingCount++
      this.adminSocket.next({
        Type: "HotelHealth",
        HotelId: this.stateService.selectedHotel?.Id,
      })
    } else {
      if (this.adminHealthPingCount === 1 && this.windowHasFocus && this.adminSocket && this.adminSocket.closed) {
        // TODO decide: should we try and reinit?
        this.adminHealthPingCount++
        this.subscribeToAdminSocket()
      } else {
        this.disconnectAdmin()
      }
    }
  }

  private initPingIntervals() {
    this.adminPingCount = 0
    this.adminHealthPingCount = 0
    this.initiateAdminPingInterval()
    this.initiateAdminHealthPingInterval()
  }
  private initiateAdminPingInterval() {
    if (this.adminPingInterval && !this.adminPingInterval.closed) return
    this.adminPingInterval = interval(this.PING_INTERVAL_SECONDS * 1000).subscribe(() => this.ping())
  }
  private initiateAdminHealthPingInterval() {
    if (this.adminHealthInterval && !this.adminHealthInterval.closed) return
    this.adminHealthInterval = interval(this.HEALTHCHECK_INTERVAL * 1000).subscribe(() => this.healthCheck())
  }

  /** Opens a connection if the email and token can be authenticated */
  public AuthenticateAndOpenWebsocket(hotelId, email, token) {
    if (!this.hotelIsRegistered) {
      // console.debug('websocket: Registering hotelid to websocket');

      this.initPingIntervals()
      this.adminSocket.next({
        TenantId: hotelId,
        Email: email,
        Token: token,
        Type: "Authenticate",
      })
      this.ping()
      this.healthCheck()
    }
  }

  OpenAdminWebSocketForHotel(hotelId: string) {
    if (hotelId) {
      this.adminSocket.next({
        HotelId: hotelId,
        Type: "HotelMessaging",
      })
    }
  }
}
