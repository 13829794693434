import { Injectable } from "@angular/core"

@Injectable()
export class IconImageService {
  public Logo = {
    logo: "https://aeroguestblobstorageprod.blob.core.windows.net/images/AG_Logo_gradient.svg",
    black_logo: "https://aeroguestblobstorageprod.blob.core.windows.net/logo/AeroGuest/ag_black_logo.svg",
    black_logo_rounded: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/ag_black_logo_rounded.svg",
  }

  public PaymentMethod = {
    alipay: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/alipay.svg",
    amazonpay: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/amazonpay.svg",
    amex: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/amex.svg",
    applepay: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/applepay.svg",
    bancontact: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/bancontact.svg",
    dankort: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/dankort.svg",
    diners: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/diners.svg",
    discover: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/discover.svg",
    generic: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/generic_method.svg",
    googlepay: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/googlepay.svg",
    ideal: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/ideal.svg",
    jcb: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/jcb.svg",
    klarna: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/klarna.svg",
    maestro: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/maestro.svg",
    mastercard: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/mastercard.svg",
    mobilepay: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/mobilepay.svg",
    paypal: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/paypal.svg",
    pix: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/pix.svg",
    samsungpay: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/samsungpay.svg",
    swish: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/swish.svg",
    trustly: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/trustly.svg",
    unionpay: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/unionpay.svg",
    vipps: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/vipps.svg",
    visa: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/visa.svg",
    wechatpay: "https://aeroguestblobstorageprod.blob.core.windows.net/icons/PaymentMethods/wechatpay.svg",
  }

  public Illustration = {
    community: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/groupbookings/community.svg",
    find_group: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/groupbookings/find_group.svg",
    archive: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/73.Archive.svg",
    tools: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/106.Tools.svg",
    inbox: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/Inbox.svg",
    email_delivery: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/messageTemplate/email-delivery.svg",
    sms_communication: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/messageTemplate/sms-communication.svg",
    app_communcation: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/messageTemplate/app-communication.svg",
    content_writer: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/346.Content-Writer.svg",
    sale_notification: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/238.Sale-Notification.svg",
    give_me_five: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/334.Give-Me-Five.svg",
    teaming_up: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/183.Teaming-Up.svg",
    teaming_up_lightpurple: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/183.Teaming-Up_lightpurple.svg",
    magic_wand: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/311.Magic-Wand.svg",
    support_24_7: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/351.Support-24_7.svg",
    upload: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/302.Upload.svg",
    upload_hover: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/302.Upload_hover.svg",
    upload_uploading: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/302.Upload_uploading.svg",
    icon_upload_box: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/Icons/168.svg",
    empty_box: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/131.Empty-box.png",
    pictures: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/237.Pictures.svg",
    not_found: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/190.Not-Found.svg",
    looking_for_friends: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/339.Looking-For-Friends.svg",
  }

  public SmallIllustrations = {
    sandals: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/smallillustrations/99.Sandals.svg",
    luggageCart: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/smallillustrations/111.LuggageCart.svg",
    inspiration: "https://aeroguestblobstorageprod.blob.core.windows.net/illustrations/404.Inspiration.svg",
  }

  public Icon = {
    //Flags
    Circle_default: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Circle_default.svg",
    Circle_CR: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Circle_CR.svg", //Chinese
    Circle_DE: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Circle_DE.svg", //German
    Circle_DK: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Circle_DK.svg", //Danish
    Circle_ES: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Circle_ES.svg", //Spanish
    Circle_EU: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Circle_EU.svg",
    Circle_FI: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Circle_FI.svg", //Finnish
    Circle_FR: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Circle_FR.svg", //French
    Circle_GB: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Circle_GB.svg", //English (United Kingdom)
    Circle_SE: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Circle_SE.svg", //Swedish
    Circle_TH: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Circle_TH.svg", //Thai
    Circle_US: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Circle_US.png", //USA
    Rectangle_CR: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Rectangle_CR.svg",
    Rectangle_DE: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Rectangle_DE.svg",
    Rectangle_DK: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Rectangle_DK.svg",
    Rectangle_ES: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Rectangle_ES.svg",
    Rectangle_EU: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Rectangle_EU.svg",
    Rectangle_FI: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Rectangle_FI.svg",
    Rectangle_FR: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Rectangle_FR.svg",
    Rectangle_GB: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Rectangle_GB.svg",
    Rectangle_SE: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Rectangle_SE.svg",
    Rectangle_TH: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Flags/Rectangle_TH.svg",

    //Info
    //1.0
    check_1: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.0/check.svg",
    cross_1: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.0/cross.svg",
    exclamation_mark_1: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.0/exclamation_mark.svg",
    i_letter_1: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.0/i_letter.svg",
    minus_1: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.0/minus.svg",
    plus_1: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.0/plus.svg",
    //1.5
    check_1_5: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/check.svg",
    cross_1_5: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/cross.svg",
    exclamation_mark_1_5: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/exclamation_mark.svg",
    i_letter_1_5: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/i_letter.svg",
    minus_1_5: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/minus.svg",
    plus_1_5: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/plus.svg",
    //filled
    filled_check: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/filled/filled_check.svg",

    //Navigation
    //1px
    down_1: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/1px/down.svg",
    left_1: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/1px/left.svg",
    right_1: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/1px/right.svg",
    up_1: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/1px/up.svg",
    down_arrow_1: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/1px/arrow/down.svg",
    left_arrow_1: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/1px/arrow/left.svg",
    right_arrow_1: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/1px/arrow/right.svg",
    up_arrow_1: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/1px/arrow/up.svg",
    undo: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/undo.svg",
    redo: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/redo.svg",
    //1.5px
    down_1_5: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/down.svg",
    left_1_5: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/left.svg",
    right_1_5: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/right.svg",
    up_1_5: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/up.svg",
    down_arrow_1_5: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/arrow_1_5px/down.png",
    left_arrow_1_5: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/arrow_1_5px/left.png",
    right_arrow_1_5: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/arrow_1_5px/right.png",
    up_arrow_1_5: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/arrow_1_5px/up.png",
    burger_menu: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/up.svg",
    check: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/check.svg",
    check_rounded: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/check_rounded.svg",
    collapse_menu: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/collapse_menu.svg",
    cross: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/cross.svg",
    export: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/export.svg",
    filter: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/filter.svg",
    kebab_menu: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/kebab_menu.svg",
    list: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/list.svg",
    minus: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/minus.svg",
    new_tab: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/new_tab.svg",
    plus: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/plus.svg",
    sliders: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/sliders.svg",
    uncollapse_menu: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Navigation/uncollapse_menu.svg",

    //Other - AddOns
    backside: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/backside.png",
    BBQ: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/BBQ.png",
    bedding: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/bedding.png",
    bike: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/bike.png",
    breakfast: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/breakfast.png",
    bus_tram: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/bus_tram.png",
    chairs_table: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/chairs_table.png",
    chairs_umbrella: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/chairs_umbrella.png",
    cocktail: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/cocktail.png",
    coffee: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/coffee.png",
    courtyard: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/courtyard.png",
    desk: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/desk.png",
    double_bed: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/double_bed.png",
    dumbbell: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/dumbbell.png",
    elevator: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/elevator.png",
    faq: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/faq.png",
    guide: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/guide.png",
    health: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/health.png",
    hotel: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/hotel.png",
    kitchen: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/kitchen.png",
    laundry: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/laundry.png",
    leafs: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/leafs.png",
    long_haul_bus: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/long_haul_bus.png",
    luggage: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/luggage.png",
    map: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/map.png",
    meeting_table: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/meeting_table.png",
    menu: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/menu.png",
    microwave: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/microwave.png",
    minigolf: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/minigolf.png",
    no_smoking: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/no_smoking.png",
    notebook: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/notebook.png",
    parking: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/parking.png",
    paw: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/paw.png",
    plane_clock: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/plane_clock.png",
    plate: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/plate.png",
    pool: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/pool.png",
    reception_bell: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/reception_bell.png",
    shopping: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/shopping.png",
    sofa: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/sofa.png",
    spa: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/spa.png",
    sun_snow: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/sun_snow.png",
    taxi: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/taxi.png",
    train: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/train.png",
    tripadvisor: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/tripadvisor.png",
    tv: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/tv.png",

    wifi: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/wifi.png",
    wine: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/AddOns/wine.png",

    //Other - Other
    alarm_clock: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/alarm_clock.svg",
    archive: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/archive.svg",
    arrival_date: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/arrival_date.svg",
    avatar: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/avatar.svg",
    bag_percentage: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/bag_percentage.svg",
    bell: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/bell.svg",
    buildings: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/buildings.svg",
    button: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/button.svg",
    calendar: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/calendar.svg",
    camera: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/camera.svg",
    card: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/card.svg",
    cart: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/cart.svg",
    check_in_link: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/check_in_link.svg",
    check_small: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/check.svg",
    checkbox_empty_filled: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/checkbox_empty_filled.svg",
    checkbox_empty: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/checkbox_empty.svg",
    checkbox_enabled_inderterminate: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/checkbox_enabled_inderterminate.svg",
    checkbox_tick_filled: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/checkbox_tick_filled.svg",
    checkbox_tick: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/checkbox_tick.svg",
    checkbox_grey_empty: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/checkbox_grey_empty.svg",
    checkbox_disabled: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/checkbox_disabled.svg",
    round_checkbox_grey_empty: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/round_checkbox_grey_empty.svg",
    round_checkbox_grey_tick: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/round_checkbox_grey_tick.svg",
    round_checkbox_grey_disabled: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/round_checkbox_grey_disabled.svg",
    cigarette: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/cigarette.svg",
    circles: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/circles.svg",
    clipboard: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/clipboard.svg",
    clock: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/clock.svg",
    coin: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/coin.svg",
    columns_1_3_to_2_3: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/columns_1_3-2_3.svg",
    columns_2_3_to_1_3: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/columns_2_3-1_3.svg",
    columns_1: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/columns_1.svg",
    columns_2: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/columns_2.svg",
    columns_3: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/columns_3.svg",
    columns_4: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/columns_4.svg",
    disable: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/disable.svg",
    ID: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/ID.svg",
    NFC: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/NFC.svg",
    QR_code: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/QR_code.svg",
    compass: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/compass.svg",
    create_aeroguest_profile: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/create_aeroguest_profile.svg",
    dashboard: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/dashboard.svg",
    delay: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/delay.svg",
    departure_date: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/departure_date.svg",
    divider: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/divider.svg",
    door_in: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/door_in.svg",
    door_out: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/door_out.svg",
    door: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/door.svg",
    download: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/download.svg",
    draft: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/draft.svg",
    drag_flat: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/drag_flat.svg",
    drag_updown: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/drag_updown.svg",
    drag: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/drag.svg",
    driving_licence: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/driving_licence.svg",
    duplicate: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/duplicate.svg",
    dynamic_island: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/dynamic-island.svg",
    Earth: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/earth.svg",
    edit: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/edit.svg",
    email_address: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/email_address.svg",
    envelope_flying: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/envelope_flying.svg",
    envelope: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/envelope.svg",
    eye_closed: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/eye_closed.svg",
    eye_open: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/eye_open.svg",
    finger: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/finger.svg",
    first_name: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/first_name.svg",
    full_name: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/full_name.svg",
    globe: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/globe.svg",
    heart: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/heart.svg",
    hex: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/hex.svg",
    home: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/home.svg",
    hotel_name: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/hotel_name.svg",
    if_else_a_b: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/if-else_a-b.svg",
    if_else: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/if-else.svg",
    in: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/in.svg",
    invoice: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/invoice.svg",
    iphone_statusbar: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/iphone_statusbar.svg",
    key: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/key.svg",
    last_name: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/last_name.svg",
    lightning: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/lightning.svg",
    line: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/line.svg",
    linked: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/linked.svg",
    location_pin: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/location_pin.svg",
    lock_closed: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/lock_closed.svg",
    lock_open: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/lock_open.svg",
    magnifier: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/magnifier.svg",
    minus_small: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/minus.svg",
    molecule: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/molecule.svg",
    money_bag: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/money_bag.svg",
    mug: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/mug.svg",
    mute: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/mute.svg",
    number_of_adults: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/number_of_adults.svg",
    number_of_children: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/number_of_children.svg",
    online_travel_agency: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/online_travel_agency.svg",
    out: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/out.svg",
    passport: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/passport.svg",
    pause: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/pause.svg",
    pen: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/pen.svg",
    person: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/person.svg",
    phone_header: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/phone_header.svg",
    phone_number: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/phone_number.svg",
    phone_status_icons: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/phone-status-icons.svg",
    phone: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/phone.svg",
    plane: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/plane.svg",
    play: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/play.svg",
    queue: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/queue.svg",
    radio_checked: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/radio_checked.svg",
    radio_unchecked: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/radio_unchecked.svg",
    radio_check_mark: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/radio_checkmark.svg",
    rebook: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/rebook.svg",
    receipt: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/receipt.svg",
    remove_key: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/remove_key.svg",
    reservation_number: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/reservation_number.svg",
    reservation_source: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/reservation_source.svg",
    reservation_status: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/reservation_status.svg",
    settings: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/settings.svg",
    send: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/send.svg",
    sign: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/sign.svg",
    smile: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/smile.svg",
    sort_ascending: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/sort_ascending.svg",
    sort_descending: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/sort_descending.svg",
    space_category: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/space_category.svg",
    space: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/space.svg",
    speech_bubble_dots: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/speech_bubble_dots.svg",
    speech_bubble_empty: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/speech_bubble_empty.svg",
    speech_bubble_follow: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/speech_bubble_follow.svg",
    speech_bubble_info: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/speech_bubble_info.svg",
    speech_bubble_question_mark: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/speech_bubble_question_mark.svg",
    speech_bubble_star: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/speech_bubble_star.svg",
    star_percentage: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/star-percentage.svg",
    star: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/star.svg",
    star_flying: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/star_flying.svg",
    stats: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/stats.svg",
    suitcase: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/suitcase.svg",
    swirl: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/swirl.svg",
    tag: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/tag.svg",
    target: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/target.svg",
    text_bold: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/text_bold.svg",
    text_centeralign: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/text_centeralign.svg",
    text_fillalign: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/text_fillalign.svg",
    text_italic: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/text_italic.svg",
    text_leftalign: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/text_leftalign.svg",
    text_lineheight: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/text_lineheight.svg",
    text_rightalign: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/text_rightalign.svg",
    text_underline: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/text_underline.svg",
    text_strike: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/text_strike.svg",
    text: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/text.svg",
    transparent: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/transparent.png",
    trash_can_red: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/trash_can_red.svg",
    trash_can: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/trash_can.svg",
    tray: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/tray.svg",
    trifold: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/trifold.svg",
    unread: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/unread.svg",
    view_large: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/view_large.svg",
    view_small: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/view_small.svg",
    water: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/water.svg",
    wheelchair: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/wheelchair.svg",
    black_rectangle: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/black_rectangle.svg",
    broken_image: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/broken_image.jpg",
    image: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/image.svg",
    folder: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/folder.svg",
    move: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/move.svg",
    replace: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/replace.svg",
    file: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/file.svg",
    grid: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/grid.svg",
    maximize_1: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/maximize_1.svg",
    //Other - RoomFeatures
    analog_clock: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/analog_clock.svg",
    anchor: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/anchor.svg",
    auditorium: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/auditorium.svg",
    baby_cot: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/baby_cot.svg",
    bali_bed: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/bali_bed.svg",
    bath_tub_shower: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/bath_tub_shower.svg",
    bath_tub: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/bath_tub.svg",
    bunk_beds: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/bunk_beds.svg",
    canal: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/canal.svg",
    chandelier: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/chandelier.svg",
    city: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/city.svg",
    connecting_rooms: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/connecting_rooms.svg",
    corner: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/corner.svg",
    dishwasher: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/dishwasher.svg",
    family: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/family.svg",
    fireplace: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/fireplace.svg",
    flower: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/flower.svg",
    french_balcony: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/french_balcony.svg",
    infinity: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/infinity.svg",
    king: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/king.svg",
    lake: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/lake.svg",
    mosquito: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/mosquito.svg",
    mountains: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/mountains.svg",
    nose_dripping: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/nose_dripping.svg",
    orangerie: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/orangerie.svg",
    oven: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/oven.svg",
    palace: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/palace.svg",
    penthouse: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/penthouse.svg",
    pouf: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/pouf.svg",
    queen: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/queen.svg",
    rollaway_bed: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/rollaway_bed.svg",
    rooftop_terrace: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/rooftop_terrace.svg",
    round_window: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/round-window.svg",
    satellite: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/satellite.svg",
    sea: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/sea.svg",
    separate_beds: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/separate_beds.svg",
    shower: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/shower.svg",
    snowflake: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/snowflake.svg",
    solarium: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/solarium.svg",
    spa_bath: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/spa_bath.svg",
    sparkle: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/sparkle.svg",
    square_meter: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/square_meter.svg",
    stairs: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/stairs.svg",
    street: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/street.svg",
    table_reservation: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/table_eservation.svg",
    tea: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/tea.svg",
    tree_bush: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/tree_bush.svg",
    trundle_bed: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/trundle_bed.svg",
    two_showers: "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/RoomFeatures/two_showers.svg",
  }
}
