<div class="dialog-container">
  <button class="button-cancel purple-big" mat-icon-button [mat-dialog-close]="true">
    <mat-icon>clear</mat-icon>
  </button>
  <h1>Charge</h1>
  <p>Make a charge on the guest’s credit card.</p>
  <form [formGroup]="form" (submit)="submit()">
    <div class="flex-row flex-start flex-align-start ag-form-row" style="display: flex; gap: 16px">
      <div style="flex-grow: 0">
        <ag-input-currency formGroupName="amount" [label]="'Amount'" [hint]="getAmountError()"></ag-input-currency>
      </div>
      <div style="flex-grow: 1">
        <ag-input formControlName="reason" [label]="'Reason for charge'" [hint]="'Please provide a reason.'"></ag-input>
      </div>
    </div>
    <div *ngIf="enableUpdateFeature" class="flex-row" style="align-items: center; justify-content: space-between">
      <div>Post payment to guest's reservation in {{ pmsType }}</div>
      <app-ag-checkbox-v2 [initValue]="CheckboxValue" (valueChange)="updatePostToPms($event)"></app-ag-checkbox-v2>
    </div>
    <mat-dialog-actions class="dialog-actions">
      <button type="submit" mat-button class="primary" [disabled]="isLoading">
        <span *ngIf="!isLoading">Charge</span>
        <app-dot-loader-white-animation *ngIf="isLoading" height="40px"></app-dot-loader-white-animation>
      </button>
    </mat-dialog-actions>
  </form>
</div>
