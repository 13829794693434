import { Component, ElementRef, forwardRef, Input, OnInit, Renderer2, ViewChild } from "@angular/core"
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, AbstractControl, ControlContainer, UntypedFormControl, FormControlDirective, ValidationErrors } from "@angular/forms"

@Component({
  selector: "ag-textarea",
  templateUrl: "./ag-textarea.component.html",
  styleUrls: ["./ag-textarea.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgTextareaComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AgTextareaComponent),
      multi: true,
    },
  ],
})
export class AgTextareaComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() label!: string
  @Input() rows: number = 4

  @ViewChild(FormControlDirective, { static: true })
  formControlDirective!: FormControlDirective

  @Input() formControl!: UntypedFormControl
  @Input() formControlName!: string

  @ViewChild("viewControl") viewControl?: ElementRef

  get control(): UntypedFormControl {
    return this.formControl || <UntypedFormControl>this.controlContainer?.control?.get(this.formControlName || "")
  }

  constructor(
    private controlContainer: ControlContainer,
    private renderer: Renderer2,
  ) {}
  ngOnInit(): void {}

  writeValue = (value: string): void => this.formControlDirective.valueAccessor!.writeValue(value)
  registerOnChange = (fn: any): void => this.formControlDirective.valueAccessor!.registerOnChange(fn)
  registerOnTouched = (fn: any): void => this.formControlDirective.valueAccessor!.registerOnTouched(fn)

  validate(control: AbstractControl): ValidationErrors | null {
    return this.control.valid ? null : control.errors
  }

  onFocus() {
    this.renderer.addClass(this.viewControl?.nativeElement, "focussed")
  }

  onBlur() {
    this.renderer.removeClass(this.viewControl?.nativeElement, "focussed")
  }
}
