import { pairwise, filter } from "rxjs/operators"
import { Injectable } from "@angular/core"
import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized } from "@angular/router"

@Injectable()
export class NavigationService {
  private _previousUrl: string = undefined
  get previousUrl(): string {
    return this._previousUrl
  }

  constructor(router: Router) {
    router.events
      .pipe(
        filter((e) => e instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((event: any[]) => {
        this._previousUrl = event[0].urlAfterRedirects
      })
  }
}
