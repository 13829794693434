import { Component, OnInit, OnDestroy, Input, Output } from "@angular/core"
import { IToast, ToastType } from "./itoast"
import { ToastServiceV2 } from "./toast-v2.service"
import { Subject } from "rxjs"
import { takeUntil } from "rxjs/operators"
import { animate, state, style, transition, trigger } from "@angular/animations"
import { IconImageService } from "../icon-image.service"
import { EventEmitter } from "stream"

@Component({
  selector: "app-toast-v2",
  templateUrl: "./toast-v2.component.html",
  styleUrls: ["./toast-v2.component.scss"],
})
export class ToastV2Component implements OnInit, OnDestroy {
  private unsubscribe = new Subject()
  alert: IToast
  animation = false

  constructor(private alertService: ToastServiceV2, public iconService: IconImageService) {
    this.alertService
      .getAlert()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async (alert: IToast) => {
        this.alert = alert
      })
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unsubscribe.next(true)
    this.unsubscribe.complete()
  }

  getToastIcon() {
    if (!this.alert) {
      return
    } else {
      switch (this.alert.type) {
        case ToastType.Success:
          return "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.0/check.svg"
        case ToastType.Error:
          return "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.0/cross.svg"
        case ToastType.Info:
          return "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.0/i_letter.svg"
        case ToastType.Warning:
          return "https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.0/exclamation_mark.svg"
      }
    }
  }

  getTitleColor() {
    if (!this.alert) {
      return
    } else {
      switch (this.alert.type) {
        case ToastType.Success:
          return "#2C6E58"
        case ToastType.Error:
          return "#993F3F"
        case ToastType.Info:
          return "#385480"
        case ToastType.Warning:
          return "#806B3D"
      }
    }
  }

  iconClass() {
    if (!this.alert) {
      return
    } else {
      switch (this.alert.type) {
        case ToastType.Success:
          return "success-filter"
        case ToastType.Error:
          return "danger-filter"
        case ToastType.Info:
          return "info-filter"
        case ToastType.Warning:
          return "warning-filter"
      }
    }
  }

  cssClass() {
    if (!this.alert) {
      return
    } else {
      switch (this.alert.type) {
        case ToastType.Success:
          return "toastt alert-success"
        case ToastType.Error:
          return "toastt alert-danger"
        case ToastType.Info:
          return "toastt alert-info"
        case ToastType.Warning:
          return "toastt alert-warning"
      }
    }
  }

  undoApi() {
    this.alertService.undoApi()
  }

  clearToast() {
    this.alertService.clear()
  }
}
