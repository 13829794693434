import { Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges, input } from "@angular/core"

@Component({
  selector: "bar-loader",
  standalone: false,
  templateUrl: "./bar-loader.component.html",
  styleUrls: ["./bar-loader.component.scss"], // Fixed to `styleUrls`
})
export class BarLoaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() height: string = "1px"
  @Input() width: string = "100%"
  @Input() isLoading: boolean = false
  @Input() greyBarWhenNotLoading: boolean = true
  @Input() heightGreyBar: string = "1px"
  @Input() greyBarColor: string = "#dfdfdf"
  @Input() roundedCorners: boolean = false

  gradientColors: string[] = [
    "#4f356e",
    "#563b78",
    "#5b427f",
    "#625da3",
    "#6a6bb3",
    "#6973be",
    "#6e89da",
    "#7595e1",
    "#7c9ce7",
    "#83a3ed",
    "#9690e4",
    "#a29ae0",
    "#ba96eb",
    "#c1a1f5",
    "#cb9def",
    "#c1a1f5",
    "#ba96eb",
    "#a29ae0",
    "#9690e4",
    "#83a3ed",
    "#7c9ce7",
    "#7595e1",
    "#6e89da",
    "#6973be",
    "#6a6bb3",
    "#625da3",
    "#563b78",
    "#4f356e",
  ]

  currentGradient: string = ""
  intervalId: any

  ngOnInit(): void {
    if (this.isLoading) {
      this.startGradientAnimation()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["isLoading"]) {
      if (this.isLoading) {
        this.startGradientAnimation()
      } else {
        this.stopGradientAnimation()
      }
    }
  }

  ngOnDestroy(): void {
    this.stopGradientAnimation()
  }

  startGradientAnimation() {
    this.updateGradient()

    this.intervalId = setInterval(() => {
      this.shiftColorsRight()
      this.updateGradient()
    }, 50)
  }

  stopGradientAnimation() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
    }
  }

  shiftColorsRight() {
    const lastColor = this.gradientColors.pop()
    if (lastColor) {
      this.gradientColors.unshift(lastColor)
    }
  }

  updateGradient() {
    this.currentGradient = `linear-gradient(to right, ${this.gradientColors.join(", ")})`
  }
}
