<div class="donut-graph-content">
  <div class="graphmaster">
    <div class="donut-graph" id="responsive-chart"></div>
    <div id="donutOverlay">
      <div id="donutvalue">{{ _donutValue }}</div>
      <div id="donuttotal" *ngIf="_graphMode === 'today'">
        OUT OF
        <br />
        {{ _donutTotal }}
      </div>
      <div id="donuttotal" *ngIf="_graphMode !== 'today'">
        CHECK-INS
        <br />
        &#8205;
      </div>
    </div>
  </div>
  <div class="donut-graph-right">
    <div class="donut-legend-container">
      <span class="allignleft">
        <div class="graph-header">Check-ins</div>
        <div [class]="_graphMode == 'today' ? 'button-active' : 'button'" (click)="todayTapped()"><p class="Text">Today</p></div>
        <div [class]="_graphMode == 'yesterday' ? 'button-active' : 'button'" (click)="yesterdayTapped()"><p class="Text">Yesterday</p></div>
        <div [class]="_graphMode == 'last week' ? 'button-active' : 'button'" (click)="lastweekTapped()"><p class="Text">Last week</p></div>
        <!-- <a id="graphday" (click)="graphdayClicked($event)">today</a> !-->
      </span>
    </div>
    <div class="donut-legend-container">
      <div *ngFor="let label of getLabels()" class="donut-legend-item">
        <div class="legend-cirle" [style.background-color]="label.color"></div>
        <p class="legend-content">
          <span class="legend-value">{{ label.value }}</span>
          <span class="legend-name">{{ label.name }}</span>
        </p>
      </div>
    </div>
    <div class="graph-footer">
      <div class="column" style="width: 100px">
        <span ngbTooltip="Total income amount in period from check-in services" placement="bottom" tooltipClass="tooltipClass">
          <img src="/assets/main-page/bookings/graph-payments.svg" class="goal" />
          <span id="graphTargetText" style="width: 80px">{{ checkinService.currency }} {{ _targetIncome.toLocaleString("de", { useGrouping: true }) }}</span>
        </span>
      </div>
      <div class="column">
        <span *ngIf="_graphMode != 'last week' && _targetEnabled">
          <span ngbTooltip="Target goal for digital check-ins handled" placement="bottom" tooltipClass="tooltipClass">
            <img src="/assets/main-page/bookings/graph-target.svg" class="goal" />
            <span id="graphTargetText">{{ _targetValue }}%</span>
          </span>
        </span>
      </div>
      <div class="column" style="width: 130px">
        <span ngbTooltip="Employee with most check-ins in given period" placement="bottom" tooltipClass="tooltipClass">
          <img src="/assets/main-page/bookings/graph-star.svg" class="goal" />
          <span id="graphTargetText" style="width: 95px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; display: inline-block; vertical-align: middle">{{ _targetEmployeeWinner }}</span>
        </span>
      </div>
    </div>
  </div>
</div>
