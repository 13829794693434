import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Optional, Output, Renderer2, Self, SimpleChanges, ViewChild } from "@angular/core"
import { IconImageService } from "@shared/services/icon-image.service"
import { AbstractControl, UntypedFormControl, NgControl, ValidationErrors, Validator, FormBuilder, FormGroup } from "@angular/forms"

@Component({
  selector: "ag-textarea-v2",
  templateUrl: "./ag-textarea.component.html",
  styleUrls: ["./ag-textarea.component.scss"],
})
export class AgTextareaV2Component implements OnInit, OnChanges, Validator {
  @ViewChild("textarea") textarea: ElementRef
  @ViewChild("textarea") htmlTextarea: HTMLTextAreaElement

  @Input() placeholder: string = ""
  @Input() hint?: string = ""
  @Input() disable: boolean = false
  @Input() initValue: string = ""
  @Input() showSendButton: boolean = false
  @Input() maxChars: number
  @Input() textareaStyles: TextareaStyles = {
    backgroundColor: "#F2F2F2",
    border: "",
    specificWidth: "314px",
    specificHeight: "24px",
  }
  @Input() info: InputInfo = {
    showInfo: false,
    showChars: false,
    type: "info",
    text: "Info",
  }
  @Input() withPersonalisedTags: boolean = false
  @Input() personalisedTagDropDownHeight: number = 472
  @Input() deleteButton: boolean = false
  @Output() inputResult = new EventEmitter()
  @Output() sendButtonPushed = new EventEmitter()
  @Output() deleteButtonPushed = new EventEmitter()

  public control: UntypedFormControl
  public message: string = ""
  public isInputFocused: boolean = false
  public isInputHovered: boolean = false
  public chars: number = 0
  public personalisedTagsList: Array<{ key: string; value: string }> = [
    { key: "Full name", value: "FullName" },
    { key: "First name", value: "FirstName" },
    { key: "Last name", value: "LastName" },
    { key: "Reservation number", value: "ReservationNumber" },
    { key: "Arrivale date", value: "ArrivalDate" },
    { key: "Departure date", value: "DepatureDate" },
    { key: "Phone number", value: "PhoneNumber" },
    { key: "Email", value: "Email" },
    { key: "Hotel name", value: "HotelName" },
  ]
  filteredTagsList = [...this.personalisedTagsList]
  searchTerm: string = ""
  selectedTagIndex: number = -1
  replaceOrHide: string = "hide"
  cursorPosition: number = 0
  replaceText: string = ""
  replaceForm: FormGroup
  personalisedTagsDropDownShown: boolean = false

  constructor(@Optional() @Self() private _ngControl: NgControl, private renderer: Renderer2, public iconService: IconImageService, private fb: FormBuilder, private eRef: ElementRef) {
    if (this._ngControl) {
      this._ngControl.valueAccessor = this
    }
  }
  selectioinchange(event: any) {
    console.log(event)
  }

  @HostListener("document:click", ["$event"])
  clickedOutside(event) {
    if (!this.eRef.nativeElement.contains(event.target) && this.personalisedTagsDropDownShown) {
      this.personalisedTagsDropDownShown = false
      this.resetForm()
    }
  }

  ngOnInit(): void {
    if (this._ngControl?.control) {
      this.control = this._ngControl.control as UntypedFormControl
    } else {
      this.control = new UntypedFormControl({ value: this.initValue, disabled: this.disable })
    }

    this.control.valueChanges.subscribe((value) => {
      this.chars = value?.length || 0
      this.inputResult.emit(value)
    })
    this.replaceForm = this.fb.group({
      replacementText: [""],
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["initValue"] && !changes["initValue"].isFirstChange()) {
      const newValue = changes["initValue"].currentValue
      this.control.setValue(newValue)
    }
  }

  writeValue() {}
  registerOnChange() {}
  registerOnTouched() {}

  validate(control: AbstractControl): ValidationErrors | null {
    return this.control.valid ? null : control.errors
  }

  public onFocus(event: Event) {
    this.isInputFocused = true
    const target = event.target as HTMLTextAreaElement
    this.cursorPosition = target.selectionStart || 0
  }

  public onBlur() {
    this.isInputFocused = false
  }

  public onEnter() {
    this.isInputHovered = true
  }

  public onLeave() {
    this.isInputHovered = false
  }

  public onInput(event: Event) {
    this.message = (event.target as HTMLTextAreaElement).value
    this.inputResult.emit(this.message)
    this.checkHeight(event)
    const target = event.target as HTMLTextAreaElement
    this.cursorPosition = target.selectionStart || 0
  }

  public onMouseUp(event: MouseEvent) {
    const target = event.target as HTMLTextAreaElement

    this.cursorPosition = target.selectionStart || 0
  }

  public checkHeight(event: Event) {
    this.htmlTextarea = event.target as HTMLTextAreaElement
    this.htmlTextarea.rows = 1
    const lineHeight = 24
    const height = this.htmlTextarea.scrollHeight
    const numberOfLines = Math.floor(height / lineHeight)
    this.htmlTextarea.rows = numberOfLines <= 4 ? numberOfLines : 4
    this.chars = this.htmlTextarea.value.length
  }

  public sendInput() {
    if (this.message.length === 0 || this.message.length < 0) {
      return
    } else {
      this.sendButtonPushed.emit(true)
    }
  }

  deleteClicked() {
    this.deleteButtonPushed.emit()
  }

  filterTags() {
    const searchTermLower = this.searchTerm.toLowerCase()
    this.filteredTagsList = this.personalisedTagsList.filter((tag) => tag.key.toLowerCase().includes(searchTermLower))
  }

  insertTag(tag: string) {
    const textarea = this.textarea.nativeElement as HTMLTextAreaElement
    const currentValue = textarea.value

    var newValue = ""
    if (this.replaceOrHide === "hide") {
      newValue = currentValue.slice(0, this.cursorPosition) + `[${tag} | hide]` + currentValue.slice(this.cursorPosition)
    } else {
      newValue = currentValue.slice(0, this.cursorPosition) + `[${tag} | ${this.replaceText}]` + currentValue.slice(this.cursorPosition)
    }

    textarea.value = newValue

    this.control.setValue(newValue)

    this.cursorPosition += tag.length + 4

    textarea.focus()
    textarea.setSelectionRange(this.cursorPosition, this.cursorPosition)
    this.personalisedTagsDropDownShown = false
    this.selectedTagIndex = -1
  }

  resetForm() {
    this.searchTerm = "" // Reset the search term
    this.selectedTagIndex = -1 // Reset the tag selection
    this.replaceOrHide = "hide" // Reset the replace/hide option
    this.replaceForm.reset() // Clear any form values if you're using a form
  }
  onTextareaClick() {
    this.personalisedTagsDropDownShown = false
    this.resetForm()
  }
}

export class TextareaStyles {
  backgroundColor?: string
  border?: string
  specificWidth?: string
  specificHeight?: string
}

export class InputInfo {
  showInfo?: boolean
  showChars?: boolean
  type?: Type
  text?: string
}

export type Type = "info" | "error"
