import { Inject, Injectable, Optional } from "@angular/core"
import { MomentDateAdapter } from "@angular/material-moment-adapter"
import { MAT_DATE_LOCALE } from "@angular/material/core"
import * as moment from "moment"

@Injectable()
export class MaterialDateAdapter extends MomentDateAdapter {
  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale)
  }

  parse(value: any, parseFormat: string | string[]): moment.Moment | null {
    if (value && typeof value === "string") {
      return moment.utc(value, parseFormat, this.locale, true)
    }
    return value ? moment.utc(value).locale(this.locale) : null
  }
}
